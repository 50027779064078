import React, { useEffect, useState } from "react";
import { Modal, Typography, Input, Button, Upload, message } from "antd";
import { useParams } from "react-router-dom";
import { CloseOutlined, CameraOutlined } from "@ant-design/icons";
import { put, uploadImg } from "../../../services/Apiclient";

const { TextArea } = Input;
const { Text } = Typography;

interface NotesModalProps {
  visible: boolean;
  onClose: () => void;
  onConfirm: (selectedObject: any) => void;
  value: string | null | undefined;
  prefilledImage?: any;
  refId?: any | undefined;
}

const NotesModal: React.FC<NotesModalProps> = ({
  visible,
  onClose,
  onConfirm,
  value,
  prefilledImage,
  refId,
}) => {
  const { id } = useParams();
  const [remark, setRemark] = useState<string>("");
  const [uploadedNotesImage, setUploadedNotesImage] = useState([]);
  const [uploadedNotesImageUrls, setUploadedNotesImageUrls] = useState([]);
  const maxLength = 255;
  useEffect(() => {
    setRemark(value || "");
  }, [value]);

  const handleRemarkChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setRemark(e.target.value);
  };

  const uploadNotesImageProps = {
    showUploadList: false,
    beforeUpload: (file: any, fileList: any[]) => {
      handleStoreLogoUpload(fileList); // Call handleUpload for custom behavior
      return false; // Prevent default upload
    },
  };

  const handleStoreLogoUpload = async (fileList: File[]) => {
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("file", file); // Ensure this matches the API's expected field name
    });

    try {
      const response = await uploadImg(
        "/outlet/uploadimage/outletlogo",
        formData
      );

      if (response?.data?.success) {
        setUploadedNotesImage(response.data.data);
        setUploadedNotesImageUrls(response.data.data);
      } else {
        message.error(response.data.message || "Failed to upload store logo.");
      }
    } catch (error) {
      message.error("An error occurred while uploading the store logo.");
      console.error("Upload error:", error);
    }
  };

  const handleCancel = async () => {
    onClose();
  };

  const handleConfirm = async () => {
    onConfirm(remark);
    onClose();
    if (refId) {
      try {
        const url = `/billing/signupdate/${id}`;
        const outletId = localStorage.getItem("outlet_id");
        const payload = {
          ref_no: refId,
          outlet_id: outletId,
          note: remark,
          note_images: [],
          sign: "",
        };

        await put(url, payload);

        // const response = await put(url, payload);

        // console.log('response....', response);
      } catch (error) {
        console.log("Error while updating note", error);
      }
    }
  };

  return (
    <Modal
      visible={visible}
      title="Note"
      onCancel={onClose}
      footer={null}
      centered
      bodyStyle={{ paddingBottom: "0" }}
    >
      <TextArea
        placeholder="Please enter"
        value={remark}
        onChange={handleRemarkChange}
        maxLength={maxLength}
        autoSize={{ minRows: 6, maxRows: 6 }}
        style={{ marginBottom: "10px", resize: "none" }}
      />
      {/* <div style={{ marginTop: "10px" }}>
        {values.storelogo !== "default.png" &&
        storeLogoUrl !== "" &&
        storeLogoUrl !== null &&
        storeLogoUrl !== undefined ? (
          <div className="image-preview">
            <img src={storeLogoUrl} alt={`Uploaded image`} />
            <CloseOutlined
              className="remove-icon"
              onClick={() => handleRemoveStoreLogoImage()}
            />
          </div>
        ) : (
          <>
            {values.storelogo == "default.png" ? (
              <>
                <div className="image-preview">
                  <img src={defaultStoreImage} alt={`Uploaded image`} />
                  <CloseOutlined
                    className="remove-icon"
                    onClick={() => handleRemoveStoreLogoImage()}
                  />
                </div>
              </>
            ) : (
              <>
                <Upload
                  {...uploadNotesImageProps}
                  multiple={false}
                  accept=".jpg,.png"
                >
                  <div className="upload-box">
                    <CameraOutlined className="upload-icon" />
                  </div>
                </Upload>
              </>
            )}
          </>
        )}
      </div> */}
      <div style={{ textAlign: "right" }}>
        <Button
          type="default"
          style={{ marginRight: "10px" }}
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button type="primary" onClick={handleConfirm}>
          Confirm
        </Button>
      </div>
    </Modal>
  );
};

export default NotesModal;
