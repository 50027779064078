import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Select,
  DatePicker,
  Button,
  Row,
  Col,
  message,
} from "antd";
import * as Yup from "yup";
import { get, post } from "../../services/Apiclient";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
const bgImage = `${process.env.PUBLIC_URL}/images/logo/Def_Bg_addCustomerProfile.png`;
const facebookIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Facebook.png`;
const instaIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Instagram.png`;
const whatsappIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_WhatsApp.png`;

const { Option } = Select;

interface FormValues {
  firstname: string;
  lastname: string;
  countrycode: string;
  phonenumber: string;
  email: string;
  gender: string;
  dob: dayjs.Dayjs | null | string;
  language: string;
  whatsapp: string;
  facebook: string;
  instagram: string;
  outlet_id: string | null;
  merchant_id: string | null;
}

const CustomerFormqr: React.FC = () => {
  const { outlet_id, merchant_id } = useParams();
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState<FormValues>({
    firstname: "",
    lastname: "",
    countrycode: "60",
    phonenumber: "",
    email: "",
    gender: "",
    dob: null,
    language: "",
    whatsapp: "",
    facebook: "",
    instagram: "",
    outlet_id: null,
    merchant_id: null,
  });

  const validationSchema = Yup.object().shape({
    firstname: Yup.string().required("First name is required"),
    lastname: Yup.string().required("Last name is required"),
    phonenumber: Yup.string()
      .required("Phone number is required")
      .min(10, "Phone number must be at least 10 digits")
      .max(15, "Phone number must not exceed 15 digits"),
    email: Yup.string().email("Invalid email address"),
    gender: Yup.string().required("Gender is required"),
  });

  const handleInputChange = (name: string, value: any) => {
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const generateCustomerCode = async () => {
    try {
      const response = await get(`/user/randomgenerated`);
      if (response?.data?.success) {
        const data = response.data.result;
        handleInputChange("customercode", data);
      }
    } catch (error) {
      message.error("Failed to generate customer code");
    }
  };

  const handleSubmit = async () => {
    try {
      await validationSchema.validate(formValues, { abortEarly: false });

      const url = "/customer/qrcustomeradd";
      const body = {
        ...formValues,
        outlet_id: outlet_id,
        merchant_id: merchant_id,
        dob: formValues.dob ? dayjs(formValues.dob).format("YYYY-MM-DD") : null,
      };

      let formattedPhoneNumber = body.phonenumber;

      // Remove country code from phone_number
      if (body.countrycode) {
        formattedPhoneNumber = body.phonenumber.replace(
          new RegExp(`^\\+?${body.countrycode}`),
          ""
        );
      }
      body.phonenumber = formattedPhoneNumber;

      const response = await post(url, body);

      if (response?.data?.success) {
        message.success("Added successfully!");
        setFormValues({
          firstname: "",
          lastname: "",
          countrycode: "60",
          phonenumber: "",
          email: "",
          gender: "",
          dob: null,
          language: "",
          whatsapp: "",
          facebook: "",
          instagram: "",
          outlet_id: null,
          merchant_id: null,
        });
        generateCustomerCode();
      } else {
        message.error(response?.data?.message || "Failed to submit data");
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        message.error(err.errors[0]); // Show the first validation error
      } else {
        message.error("An error occurred. Please try again.");
      }
    }
  };

  useEffect(() => {
    generateCustomerCode();
  }, []);

  return (
    <div
      style={{
        minHeight: "100vh",
        width: "calc(100vw - 20px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#f5f5f5",
      }}
    >
      <div
        style={{
          maxWidth: "800px",
          width: "100%",
          backgroundColor: "#fff",
          borderRadius: "8px",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          position: "relative",
        }}
      >
        <img
          src={bgImage}
          alt="No data available"
          style={{ height: "150px", width: "inherit" }}
        />
        <h2
          style={{
            marginBottom: "24px",
            position: "absolute",
            top: "60px",
            left: "24px",
            color: "#fff",
            fontWeight: "12px",
            fontSize: "medium",
          }}
        >
          Fill Out Your Information
        </h2>
        <Form
          layout="vertical"
          onFinish={handleSubmit}
          style={{
            width: "100%",
            padding: "24px",
            borderRadius: "20px 20px",
            display: "flex",
            flexDirection: "column",
          }}
          className="responsive-form"
        >
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <Form.Item label="First Name" required>
                <Input
                  placeholder="Enter first name"
                  value={formValues.firstname}
                  onChange={(e) =>
                    handleInputChange("firstname", e.target.value)
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item label="Last Name" required>
                <Input
                  placeholder="Enter last name"
                  value={formValues.lastname}
                  onChange={(e) =>
                    handleInputChange("lastname", e.target.value)
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="Phone Number" required>
            <PhoneInput
              country={"my"}
              value={formValues.phonenumber}
              enableSearch
              countryCodeEditable={false}
              onChange={(value: any, country: any) => {
                handleInputChange("countrycode", country.dialCode);
                handleInputChange("phonenumber", value);
              }}
              inputStyle={{
                width: "100%",
                height: "46px",
                paddingLeft: "35px", // Adjust space for the flag
                borderColor: "#e0e0e0", // Light border color for a softer look
                borderRadius: "5px",
                color: "#5c5c5c", // Softer text color
                backgroundColor: "#f8f9fd", // Light background color
              }}
              containerStyle={{
                width: "100%",
              }}
              placeholder="Please enter"
            />
          </Form.Item>
          <Form.Item label="Email">
            <Input
              placeholder="Enter email"
              value={formValues.email}
              onChange={(e) => handleInputChange("email", e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Gender" required>
            <Select
              placeholder="Select gender"
              value={formValues.gender}
              onChange={(value) => handleInputChange("gender", value)}
            >
              <Option value="Male">Male</Option>
              <Option value="Female">Female</Option>
              <Option value="Non-binary">Non-binary</Option>
              <Option value="Prefer not to say">Prefer not to say</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Date of Birth">
            <DatePicker
              style={{ width: "100%" }}
              value={formValues.dob ? dayjs(formValues.dob) : null}
              onChange={(date) => handleInputChange("dob", date)}
              placeholder="Select date of birth"
            />
          </Form.Item>
          <Form.Item label="Preferred notification language">
            <Select
              placeholder="Select language"
              value={formValues.language}
              onChange={(value) => handleInputChange("language", value)}
            >
              <Option value="English">English</Option>
              <Option value="简体中文">简体中文</Option>
              <Option value="繁體中文">繁體中文</Option>
            </Select>
          </Form.Item>
          <h3
            style={{
              margin: "5px 0px",
              fontWeight: "12px",
            }}
          >
            Social Media
          </h3>
          <Form.Item label="WhatsApp">
            <Input
              placeholder="Please enter"
              prefix={
                <img src={whatsappIcon} alt="WhatsApp" style={{ width: 20 }} />
              }
              value={formValues.whatsapp}
              onChange={(e) => handleInputChange("whatsapp", e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Facebook">
            <Input
              placeholder="Please enter"
              prefix={
                <img src={facebookIcon} alt="Facebook" style={{ width: 20 }} />
              }
              value={formValues.facebook}
              onChange={(e) => handleInputChange("facebook", e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Ins">
            <Input
              placeholder="Please enter"
              prefix={
                <img src={instaIcon} alt="Instagram" style={{ width: 20 }} />
              }
              value={formValues.instagram}
              onChange={(e) => handleInputChange("instagram", e.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              block
              style={{ marginTop: "20px" }}
            >
              {"Submit"}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default CustomerFormqr;
