import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  message,
  Row,
  Typography,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  ArrowLeftOutlined,
  CloseOutlined,
  EyeInvisibleOutlined,
  MailFilled,
  PhoneFilled,
  SwapOutlined,
  UserOutlined,
} from "@ant-design/icons";
import TipsModel from "../../components/model/billing/TipsModel";
import { get, post } from "../../services/Apiclient";
import TaxModel from "../../components/model/billing/TaxModel";
import { nanoid } from "nanoid";
import SplitAmountModal from "../../components/model/billing/SplitAmountModal";
const { Text } = Typography;

const FullPayIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_FullPay.png`;
const PaymentIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Payment_XJ.png`;
const splitIcon = `${process.env.PUBLIC_URL}/images/logo/split.png`;
const aftersplitIcon = `${process.env.PUBLIC_URL}/images/logo/aftersplit.png`;

interface BillData {
  order_date: string;
  outlet_id: string;
  ref_no: string;
  customer_id: string | null;
  customer_name: string | null;
  customer_code: string | null;
  customer_email: string | null;
  customer_phone: string | null;
  customer_gender: string | null;
  bill_status: string;
  discount: string;
  taxes: string;
  sub_total: string;
  final_total: string;
  note: string;
  note_images: any;
  items: any;
  male_count: number;
  female_count: number;
}

const Pay: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [showTipModal, setShowTipModal] = useState(false);
  const [showTaxModal, setShowTaxModal] = useState(false);
  const [showSplitModal, setShowSplitModal] = useState(false);
  const [billData, setBillData] = useState<BillData | null>(null);
  const [billPayloadData, setBillpayloadData] = useState<any>(null);
  const [paymentOptions, setPaymentOptions] = useState<any>([]);
  const [taxesItems, setTaxesItems] = useState<any[]>([]);
  const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set());
  const [selectAll, setSelectAll] = useState(false);
  const [selectAllUnpaid, setSelectAllUnpaid] = useState(false);
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] =
    useState<any>(null);
  const [selectedPaymentMethodName, setSelectedPaymentMethodName] =
    useState<any>(null);
  const [selectedPaymentChanges, setselectedPaymentChanges] = useState(false);
  const [loadData, setLoadData] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [selectedSplitId, setSelectedSplitId] = useState<any>(null);
  const [selectedSplitType, setSelectedSplitType] = useState<any>("outer");
  const [selectedPrice, setSelectedPrice] = useState("0");
  const fetchPaymentMethods = async () => {
    try {
      const response = await get(
        `/paymentmethod/list/${localStorage.getItem("outlet_id")}`
      );
      if (response.data.success) {
        const updatedData = response.data.data.map((payment: any) => ({
          ...payment,
          payment_paid_amount: 0,
        }));
        await setPaymentOptions(updatedData);
        await setSelectedPaymentMethodId(response.data.data[0].id);
        await setSelectedPaymentMethodName(
          response.data.data[0].paymentmethodtype
        );
      } else {
        message.error("Failed to fetch payment methods");
      }
    } catch (error) {
      console.error("Error fetching payment methods: ", error);
      message.error("Error fetching payment methods");
    }
  };
  const fetchCartDetails = async (id: any) => {
    try {
      const response = await get(`/billing/${id}`);
      if (response.data.success) {
        await setBillData(response.data.data);
      } else {
        message.error("Failed to fetch payment methods");
      }
    } catch (error) {
      console.error("Error fetching payment methods: ", error);
      message.error("Error fetching payment methods");
    }
  };

  const handlePaymentMethodSelection = async (
    methodId: string,
    methodName: string
  ) => {
    await setSelectedPaymentMethodId(methodId);
    await setSelectedPaymentMethodName(methodName);
    setselectedPaymentChanges(true);
  };

  const handleCheckboxChange = (itemId: string, checked: boolean) => {
    setSelectedItems((prevSelectedItems) => {
      const newSelectedItems = new Set(prevSelectedItems);
      if (checked) {
        newSelectedItems.add(itemId);
      } else {
        newSelectedItems.delete(itemId);
      }
      return newSelectedItems;
    });
  };

  const handleSelectAll = (e: any) => {
    const isChecked = e.target.checked;
    setSelectAll(isChecked);
    if (isChecked) {
      const allItemAndTaxIds = [
        ...billPayloadData.items.map((item: any) => item.item_id),
        ...billPayloadData.taxes.map((tax: any) => tax.tax_unique_id),
        billPayloadData?.tip_id,
      ];

      const allSplitPayIds = billPayloadData.items.flatMap(
        (item: any) =>
          item.item_split?.map((split: any) => split.split_pay_id) || []
      );

      const allIds = [...allItemAndTaxIds, ...allSplitPayIds];

      setSelectedItems(new Set(allIds));
    } else {
      setSelectedItems(new Set());
    }
  };

  const handleSelectAllUnpaid = (e: any) => {
    const isChecked = e.target.checked;
    setSelectAllUnpaid(isChecked);
    if (isChecked) {
      const unpaidSplitPayIds = billPayloadData.items.flatMap(
        (item: any) =>
          item.item_split
            ?.filter(
              (split: any) => split.split_item_payment_method === "unpaid"
            )
            .map((split: any) => split.split_pay_id) || []
      );
      setSelectedItems(new Set(unpaidSplitPayIds));
    } else {
      setSelectedItems(new Set());
    }
  };

  const updateSelectedItemsPaymentMethod = () => {
    if (billPayloadData && selectedItems && selectedItems.size > 0) {
      const updatedItems = billPayloadData?.items.map((item: any) => {
        // Check if the main item is selected
        if (selectedItems.has(item.item_id)) {
          // Update the main item
          return {
            ...item,
            item_payment_method: selectedPaymentMethodName || "",
            item_payment_id: selectedPaymentMethodId || "",
            item_split: item.item_split?.map((split: any) => {
              // Update item_split entries based on split_pay_id
              if (selectedItems.has(split.split_pay_id)) {
                return {
                  ...split,
                  split_item_payment_method: selectedPaymentMethodName || "",
                  split_item_payment_id: selectedPaymentMethodId || "",
                };
              }
              return split;
            }),
          };
        }

        // Check if item_split entries are selected
        const updatedSplits = item.item_split?.map((split: any) => {
          if (selectedItems.has(split.split_pay_id)) {
            return {
              ...split,
              split_item_payment_method: selectedPaymentMethodName || "",
              split_item_payment_id: selectedPaymentMethodId || "",
            };
          }
          return split;
        });

        return {
          ...item,
          item_split: updatedSplits,
        };
      });

      // Calculate payment sums for items
      const paymentSums = updatedItems.reduce((acc: any, item: any) => {
        const price = parseFloat(item.item_price);
        const qty = parseFloat(item.item_qty);

        if (!isNaN(price) && !isNaN(qty)) {
          const paymentId = item.item_payment_id;
          acc[paymentId] = (acc[paymentId] || 0) + price * qty;
        }

        // Include item_split entries in payment calculations
        item.item_split?.forEach((split: any) => {
          const splitPrice = parseFloat(split.split_item_price);
          const splitPaymentId = split.split_item_payment_id;

          if (!isNaN(splitPrice)) {
            acc[splitPaymentId] = (acc[splitPaymentId] || 0) + splitPrice;
          }
        });

        return acc;
      }, {});

      const updatedTaxesItems = billPayloadData?.taxes.map(
        (item: any, index: any) => {
          if (selectedItems.has(item.tax_unique_id)) {
            return {
              ...item,
              tax_payment_method: selectedPaymentMethodName,
              tax_payment_id: selectedPaymentMethodId,
            };
          }
          return item;
        }
      );

      const paymentTaxesSums = updatedTaxesItems.reduce(
        (acc: any, item: any) => {
          const taxamount = item.tax_amount;

          if (!isNaN(taxamount)) {
            const paymentId = item.tax_payment_id;
            acc[paymentId] = (acc[paymentId] || 0) + taxamount;
          }

          return acc;
        },
        {}
      );

      let updatedTip = {};

      if (billPayloadData.tip_id && selectedItems.has(billPayloadData.tip_id)) {
        const tipPaymentMethod = selectedPaymentMethodName;
        const tipPaymentId = selectedPaymentMethodId;

        const tipValue = parseFloat(billPayloadData.tips);

        if (!isNaN(tipValue)) {
          updatedTip = {
            tip_payment_method: tipPaymentMethod,
            tip_payment_id: tipPaymentId,
            tip_value: tipValue,
            tips: tipValue,
          };
        }
      }

      if (paymentOptions && paymentOptions.length > 0) {
        const updatedPaymentOptions = paymentOptions.map((payment: any) => {
          const itemPayment = paymentSums[payment.id] || 0;
          const taxPayment = paymentTaxesSums[payment.id] || 0;
          let totalTipAmount = 0;
          if (billPayloadData.tip_payment_id === payment.id) {
            totalTipAmount = billPayloadData.tip_value || 0;
          }
          return {
            ...payment,
            payment_paid_amount: itemPayment + taxPayment + totalTipAmount,
          };
        });

        setPaymentOptions(updatedPaymentOptions);
      }
      setBillpayloadData((prevData: any) => ({
        ...prevData,
        items: updatedItems,
        taxes: updatedTaxesItems,
        ...updatedTip,
      }));
    }
    setSelectedItems(new Set());
    setselectedPaymentChanges(false);
  };

  const splitItem = (id: any, price: any, item: any, type: any) => {
    setSelectedItem(item);
    setSelectedSplitId(id);
    setSelectedSplitType(type);
    if (type == "outer") {
      let itemdata = billPayloadData?.items.filter(
        (x: any) => x.item_id == item.item_id
      )[0];
      if (itemdata) {
        if (itemdata.item_split && itemdata.item_split.length > 0) {
          const totalSplitSum = itemdata.item_split
            .filter((split: any) => !split.is_main_split) // Exclude `is_main_split` entries
            .reduce(
              (sum: number, split: any) => sum + Number(split.split_item_price),
              0
            );

          const remainingprice = Number(price) - totalSplitSum;

          setSelectedPrice(remainingprice.toString());
        } else {
          setSelectedPrice(price);
        }
      }
    } else {
      setSelectedPrice(price);
    }
    setShowSplitModal(true);
  };

  const removeSplitItem = (id: any, item: any) => {
    let itemdata = billPayloadData?.items.filter(
      (x: any) => x.item_id == item.item_id
    )[0];
    if (itemdata) {
      if (itemdata.item_split && itemdata.item_split.length > 2) {
        itemdata.item_split = itemdata.item_split.filter(
          (split: any) => split.split_pay_id !== id
        );
        const totalSplitSum = itemdata.item_split
          .filter((split: any) => !split.is_main_split)
          .reduce(
            (sum: number, split: any) => sum + Number(split.split_item_price),
            0
          );

        const mainSplit = itemdata.item_split.find(
          (split: any) => split.is_main_split
        );
        if (mainSplit) {
          mainSplit.split_item_price = (
            Number(itemdata.item_price) - totalSplitSum
          ).toString();
        }
      } else if (itemdata.item_split && itemdata.item_split.length == 2) {
        itemdata.item_split = [];
        itemdata.is_split = false;
      }

      setBillpayloadData((prevData: any) => ({
        ...prevData,
        item: itemdata,
      }));
    }
  };

  const handleTipSubmit = (body: any) => {
    setBillpayloadData((prevData: any) => ({
      ...prevData,
      tip_id: body.tip_id,
      tip_rate: body.tip_rate,
      tip_value: body.tip_value,
      tips: body.tip_value,
      tip_payment_method: paymentOptions
        ? paymentOptions[0]?.paymentmethodtype
        : "",
      tip_payment_id: paymentOptions ? paymentOptions[0]?.id : "",
    }));
    setShowTipModal(false);
  };

  const handleSplitEdit = (data: any) => {
    let itemdata = billPayloadData?.items.filter(
      (x: any) => x.item_id == selectedItem.item_id
    )[0];

    if (itemdata) {
      itemdata.is_split = true;

      if (itemdata.item_split && itemdata.item_split.length > 0) {
        const mainSplit = itemdata.item_split.find(
          (split: any) => split.is_main_split
        );

        if (mainSplit) {
          const currentRemainingPrice = Number(mainSplit.split_item_price);

          if (selectedSplitType === "outer") {
            const newRemainingPrice = currentRemainingPrice - Number(data);

            mainSplit.split_item_price = newRemainingPrice.toString();

            const newSplitData = {
              split_pay_id: generateUniqueId(),
              split_item_id: selectedItem.item_id,
              split_item_price: data,
              split_item_payment_id:
                paymentOptions && paymentOptions.length > 0
                  ? paymentOptions[0].id
                  : "",
              split_item_payment_method:
                paymentOptions && paymentOptions.length > 0
                  ? paymentOptions[0].paymentmethodtype
                  : "",
              is_main_split: false,
            };

            itemdata.item_split.push(newSplitData);
          } else if (selectedSplitType === "inner") {
            const newRemainingPrice = currentRemainingPrice - Number(data);

            if (newRemainingPrice < 0) {
              console.error("Split amount exceeds remaining price!");
              return; // Prevent invalid operations
            }

            mainSplit.split_item_price = newRemainingPrice.toString();

            const newSplitData = {
              split_pay_id: generateUniqueId(),
              split_item_id: selectedItem.item_id,
              split_item_price: data,
              split_item_payment_id:
                paymentOptions && paymentOptions.length > 0
                  ? paymentOptions[0].id
                  : "",
              split_item_payment_method:
                paymentOptions && paymentOptions.length > 0
                  ? paymentOptions[0].paymentmethodtype
                  : "",
              is_main_split: false,
            };

            itemdata.item_split.push(newSplitData);
          }
        }
      } else {
        // First split entry
        const remainingprice = Number(selectedPrice) - Number(data);
        itemdata.item_split = [
          {
            split_pay_id: generateUniqueId(),
            split_item_id: selectedItem.item_id,
            split_item_price: remainingprice.toString(),
            split_item_payment_id:
              paymentOptions && paymentOptions.length > 0
                ? paymentOptions[0].id
                : "",
            split_item_payment_method:
              paymentOptions && paymentOptions.length > 0
                ? paymentOptions[0].paymentmethodtype
                : "",
            is_main_split: true,
          },
          {
            split_pay_id: generateUniqueId(),
            split_item_id: selectedItem.item_id,
            split_item_price: data,
            split_item_payment_id:
              paymentOptions && paymentOptions.length > 0
                ? paymentOptions[0].id
                : "",
            split_item_payment_method:
              paymentOptions && paymentOptions.length > 0
                ? paymentOptions[0].paymentmethodtype
                : "",
            is_main_split: false,
          },
        ];
      }

      // Update the state
      setBillpayloadData((prevData: any) => ({
        ...prevData,
        items: billPayloadData.items.map((x: any) =>
          x.item_id === itemdata.item_id ? itemdata : x
        ),
      }));
    }

    setShowSplitModal(false);
  };

  const handleTaxesEdit = (body: any) => {
    const totalTaxAmount = body.reduce(
      (sum: any, tax: any) => sum + Number(tax.tax_amount),
      0
    );
    setBillpayloadData((prevData: any) => ({
      ...prevData,
      taxes: body,
      total_tax: totalTaxAmount,
    }));
    setShowTaxModal(false);
  };

  useEffect(() => {
    if (billPayloadData) {
      let total_amount =
        Number(billPayloadData?.sub_total) + Number(billPayloadData?.total_tax);
      setBillpayloadData((prevData: any) => ({
        ...prevData,
        final_total: total_amount,
        paid_amount: total_amount + Number(billPayloadData?.tips),
      }));
    }
  }, [
    billPayloadData?.items,
    billPayloadData?.taxes,
    billPayloadData?.tip_value,
  ]);

  const handlePayNow = async () => {
    try {
      if (paymentOptions && paymentOptions.length == 0) {
        message.error("Please select payment method");
        return;
      }
      const url = "/billing/checkout";

      let billPayload = billPayloadData;

      billPayload.payment = paymentOptions
        .filter((item: any) => item.payment_paid_amount > 0)
        .map((item: any) => ({
          payment_id: item.id,
          payment_name: item.paymentmethodtype,
          payment_amount: item.payment_paid_amount.toString(),
        }));

      billPayload.taxes = billPayload.taxes.map((item: any) => ({
        ...item,
        tax_amount: item.tax_amount.toString(), // Convert tax_amount to string
      }));
      billPayload.final_total = billPayloadData.final_total?.toString();
      billPayload.paid_amount = billPayloadData.paid_amount?.toString();
      billPayload.sub_total = billPayloadData.sub_total?.toString();
      billPayload.tips = billPayloadData.tips?.toString();
      billPayload.total_tax = Number(billPayloadData.total_tax || 0)
        .toFixed(2)
        .toString();
      billPayload.cart_discount = billPayloadData.cart_discount?.toString();
      billPayload.bill_paid_status = true;

      const response = await post(url, billPayloadData);

      if (response && response.data && response.data.success) {
        message.success("Successfully added!");
        navigate(`/sales/billdetails/${id}`);
      } else {
        message.error(response.data.message || "Submission failed");
      }
    } catch (error: any) {
      message.error(error || "Something went wrong");
    }
  };

  const updatePaymentSums = () => {
    if (billPayloadData) {
      const paymentSums = billPayloadData?.items.reduce(
        (acc: any, item: any) => {
          const price = parseFloat(item.item_price);
          const qty = parseFloat(item.item_qty);

          if (!isNaN(price) && !isNaN(qty)) {
            const paymentId = item.item_payment_id;
            acc[paymentId] = (acc[paymentId] || 0) + price * qty;
          }

          return acc;
        },
        {}
      );

      const taxPaymentSums = billPayloadData?.taxes.reduce(
        (acc: any, item: any) => {
          const taxamount = parseFloat(item.tax_amount);

          if (!isNaN(taxamount)) {
            const paymentId = item.tax_payment_id;
            acc[paymentId] = (acc[paymentId] || 0) + taxamount;
          }

          return acc;
        },
        {}
      );

      let tipPaymentSum = 0; // Initialize tip sum
      if (billPayloadData.tip_id) {
        const tipPaymentId =
          billPayloadData.tip_payment_id || paymentOptions[0]?.id; // Use first payment option if no tip_payment_id
        const tipValue = parseFloat(billPayloadData.tips);

        if (!isNaN(tipValue)) {
          tipPaymentSum = tipValue; // Add tip to tip sum
        }
      }

      if (paymentOptions && paymentOptions.length > 0) {
        const updatedPaymentOptions = paymentOptions.map((payment: any) => {
          const itemPayment = paymentSums[payment.id] || 0;
          const taxPayment = taxPaymentSums[payment.id] || 0;
          const totalPayment =
            itemPayment +
            taxPayment +
            (payment.id === billPayloadData.tip_payment_id ? tipPaymentSum : 0);
          return {
            ...payment,
            payment_paid_amount: totalPayment,
          };
        });

        setPaymentOptions(updatedPaymentOptions);
      }
    }
  };

  useEffect(() => {
    const allItemsSelected =
      billPayloadData?.items.every((item: any) =>
        selectedItems.has(item.item_id)
      ) &&
      billPayloadData?.taxes.every((tax: any) =>
        selectedItems.has(tax.tax_unique_id)
      ) &&
      selectedItems.has(billPayloadData?.tip_id);

    setSelectAll(allItemsSelected);
  }, [selectedItems, billPayloadData?.items, billPayloadData?.taxes]);

  useEffect(() => {
    if (selectedPaymentChanges) {
      updateSelectedItemsPaymentMethod();
    }
  }, [selectedPaymentChanges]);

  useEffect(() => {
    updatePaymentSums();
  }, [billPayloadData]);

  useEffect(() => {
    if (billData !== null) {
      setBillpayloadData({
        outlet_id: localStorage.getItem("outlet_id"),
        bill_id: id,
        ref_no: billData?.ref_no,
        tip_id: null,
        tip_rate: "",
        tip_value: "",
        tip_payment_method: "",
        tip_payment_id: "",
        taxes: [],
        items: billData?.items.map((item: any) => ({
          item_id: item.item_id,
          item_name: item.item_name,
          item_qty: item.item_qty,
          item_price: parseFloat(item.item_price),
          item_type: item.item_type,
          item_payment_method:
            paymentOptions && paymentOptions.length > 0
              ? paymentOptions[0].paymentmethodtype
              : "",
          item_payment_id:
            paymentOptions && paymentOptions.length > 0
              ? paymentOptions[0].id
              : "",
          is_split: false,
          item_split: [],
        })),
        payment: [],
        cart_discount: 0,
        sub_total: parseFloat(billData?.sub_total),
        total_tax: parseFloat(billData?.taxes),
        tips: 0,
        final_total: parseFloat(billData?.final_total),
        paid_amount: parseFloat(billData?.final_total),
      });
    }
  }, [billData]);

  const generateUniqueId = () => {
    const uniqueId = nanoid(8);
    return uniqueId;
  };

  const fetchTaxes = async () => {
    try {
      const response = await get(
        `/tax/addmanuallylist/${localStorage.getItem("outlet_id")}`
      );
      if (response.data.success) {
        await setTaxesItems(response.data.data);
      } else {
        message.error("Failed to fetch taxess");
      }
    } catch (error) {
      console.error("Error fetching taxes: ", error);
      message.error("Error fetching taxes");
    }
  };

  useEffect(() => {
    if (billPayloadData && billData) {
      if (loadData) {
        let taxes: any = [];

        billData?.items.forEach((item: any) => {
          if (item.item_tax_rate) {
            const taxAmount =
              (parseFloat(item.item_tax_rate) / 100) *
              parseFloat(item.item_price);
            taxes.push({
              tax_unique_id: generateUniqueId(),
              tax_id: item.item_tax,
              tax_name: item.item_tax_name,
              tax_rate: item.item_tax_rate,
              tax_amount: taxAmount,
              tax_payment_method:
                paymentOptions && paymentOptions.length > 0
                  ? paymentOptions[0]?.paymentmethodtype
                  : "",
              tax_payment_id:
                paymentOptions && paymentOptions.length > 0
                  ? paymentOptions[0]?.id
                  : "",
            });
          }
        });

        if (taxesItems && taxesItems.length > 0) {
          taxesItems.forEach((taxItem) => {
            const taxAmount =
              (Number(billPayloadData.sub_total) * Number(taxItem.taxrate)) /
              100;
            taxes.push({
              tax_unique_id: generateUniqueId(),
              tax_id: taxItem.id,
              tax_name: taxItem.taxname,
              tax_rate: taxItem.taxrate,
              tax_amount: taxAmount,
              tax_payment_method:
                paymentOptions && paymentOptions.length > 0
                  ? paymentOptions[0]?.paymentmethodtype
                  : "",
              tax_payment_id:
                paymentOptions && paymentOptions.length > 0
                  ? paymentOptions[0]?.id
                  : "",
            });
          });
        }

        const totalTax = taxes.reduce(
          (sum: any, tax: any) => sum + parseFloat(tax.tax_amount),
          0
        );

        setBillpayloadData((prevData: any) => ({
          ...prevData,
          taxes: taxes,
          total_tax: totalTax,
        }));
        setLoadData(false);
      }
    }
  }, [billPayloadData?.items, taxesItems]);

  useEffect(() => {
    fetchPaymentMethods();
    fetchCartDetails(id);
    setShowTipModal(true);
    fetchTaxes();
    setLoadData(true);
  }, []);
  return (
    <Content className="mainContainer">
      <Row justify="space-between" align="middle">
        <Col>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              onClick={() => navigate("/sales/billing")}
              style={{ cursor: "pointer" }}
            >
              <ArrowLeftOutlined
                style={{ marginRight: 12, color: "#325df2", fontSize: 18 }}
              />
            </div>
            <h2 style={{ margin: 0 }}>Checkout</h2>
          </div>
        </Col>
      </Row>

      {/* Main Layout */}
      <Row
        gutter={24}
        style={{
          padding: "24px 24px 24px 24px",
          overflowY: "auto",
        }}
      >
        {/* Left Section */}
        <Col
          span={18}
          style={{
            border: "1px solid #E6E6E6",
            borderRadius: "8px",
            backgroundColor: "#fafafa",
            height: "calc(100vh - 100px)",
            overflowY: "auto",
          }}
        >
          <div
            style={{
              borderBottom: ".5px solid #e0e7fe",
              padding: "16px 16px 12px 16px",
            }}
          >
            <Row gutter={24}>
              <Col span={4}>
                <Text
                  style={{
                    fontSize: "14px",
                    color: "#666",
                    marginRight: "6px",
                    lineHeight: "32px",
                    fontWeight: 700,
                  }}
                >
                  Date:
                </Text>{" "}
                <Text style={{ fontWeight: "bold" }}>
                  {billData?.order_date}
                </Text>
              </Col>
              <Col span={4}>
                <Text
                  style={{
                    fontSize: "14px",
                    color: "#666",
                    marginRight: "6px",
                    lineHeight: "32px",
                    fontWeight: 700,
                  }}
                >
                  Ref#:
                </Text>{" "}
                <Text style={{ fontWeight: "bold" }}>{billData?.ref_no}</Text>
              </Col>
              <Col span={5}>
                <Text
                  style={{
                    fontSize: "14px",
                    color: "#666",
                    marginRight: "6px",
                    lineHeight: "32px",
                    fontWeight: 700,
                  }}
                >
                  Customer:
                </Text>
                {"    "}
                <Text style={{ marginRight: "12px", fontWeight: "bold" }}>
                  {billData?.male_count}
                </Text>
                {"    "}
                <span
                  style={{
                    color: "#2e56f2",
                    marginRight: "12px",
                    lineHeight: "32px",
                    fontWeight: 700,
                  }}
                >
                  ♂
                </span>
                {"    "}
                <Text style={{ marginRight: "12px", fontWeight: "bold" }}>
                  {billData?.female_count}
                </Text>
                {"    "}
                <span
                  style={{
                    color: "#2e56f2",
                    marginRight: "12px",
                    lineHeight: "32px",
                    fontWeight: 700,
                  }}
                >
                  ♂
                </span>
              </Col>
              <Col span={5}>
                <Text
                  style={{
                    fontSize: "14px",
                    color: "#666",
                    marginRight: "6px",
                    lineHeight: "32px",
                    fontWeight: 700,
                  }}
                >
                  Customer source:
                </Text>{" "}
                <Text style={{ fontWeight: "bold" }}>Walk-in</Text>
              </Col>
            </Row>
          </div>

          <div
            style={{
              height: "calc(100% - 180px)",
              overflowY: "auto",
              padding: "12px 18px 72px 18px",
              maxHeight: "650px",
            }}
          >
            {billData?.customer_id !== "" && (
              <Card
                style={{
                  marginTop: "10px",
                  backgroundColor: "#6e7687",
                  borderRadius: "20px",
                  padding: "10px",
                  color: "#fff",
                  display: "flex",
                  position: "relative",
                  height: "80px",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  backgroundImage:
                    "radial-gradient(circle, rgba(126,130,153,1) 0%, rgba(85,90,110,1) 100%)",
                  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
                }}
                bodyStyle={{ padding: "0px", position: "relative" }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div>
                    <Avatar
                      size={48}
                      icon={<UserOutlined />}
                      style={{ backgroundColor: "#8a92a5" }}
                    />
                  </div>

                  <div
                    style={{
                      marginLeft: "15px",
                      textAlign: "justify",
                      width: "100%",
                    }}
                  >
                    <Row gutter={[16, 16]}>
                      <Col span={12}>
                        <Text
                          strong
                          style={{ color: "#fff", fontSize: "16px" }}
                        >
                          {billData?.customer_name}
                        </Text>
                        <br />
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "8px",
                          }}
                        >
                          <Text
                            style={{
                              color: "#fff",
                              fontSize: "16px",
                            }}
                          >
                            Phone number: {billData?.customer_phone}
                          </Text>
                          <EyeInvisibleOutlined
                            style={{ marginLeft: "10px", cursor: "pointer" }}
                          />
                        </div>
                      </Col>
                      <Col span={12}>
                        <Text style={{ color: "#c0c5cf", fontSize: "14px" }}>
                          customer code : {billData?.customer_code}
                        </Text>
                        <br />
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "8px",
                          }}
                        >
                          <Text
                            style={{
                              color: "#fff",
                              fontSize: "16px",
                            }}
                          >
                            Email:{" "}
                            {billData?.customer_email
                              ? billData?.customer_email
                              : "-"}
                          </Text>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Card>
            )}
            {billPayloadData?.items.map((item: any, index: any) => (
              <>
                <Row
                  key={index}
                  gutter={[12, 12]}
                  style={{
                    backgroundColor: "#fff",
                    marginBottom: "8px",
                    height: "60px",
                    alignItems: "center",
                  }}
                >
                  <Col span={1}>
                    <Checkbox
                      checked={selectedItems.has(item.item_id)}
                      onChange={(e) =>
                        handleCheckboxChange(item.item_id, e.target.checked)
                      }
                    />
                  </Col>
                  <Col span={10}>
                    <Text
                      style={{ flex: 2, marginLeft: "10px", fontWeight: 700 }}
                    >
                      {item.item_name}
                    </Text>
                  </Col>
                  <Col span={2}>
                    <Text style={{ flex: 1, textAlign: "center" }}>
                      x{Number(item.item_qty)}
                    </Text>
                  </Col>
                  <Col span={4}>
                    <img
                      src={splitIcon}
                      style={{
                        flex: 1,
                        textAlign: "left",
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        splitItem(item.id, item.item_price, item, "outer")
                      }
                    />
                  </Col>
                  <Col span={3}>
                    <Text
                      style={{
                        flex: 1,
                        textAlign: "center",
                        fontWeight: 700,
                        fontSize: "14px",
                      }}
                    >
                      ${Number(item.item_price).toFixed(2)}
                    </Text>
                  </Col>
                  <Col span={4}>
                    <Text
                      style={{
                        flex: 1,
                        textAlign: "right",
                        fontWeight: 700,
                        fontSize: "13px",
                      }}
                    >
                      {item.item_payment_method}
                    </Text>
                  </Col>
                </Row>
                {item.item_split &&
                  item.item_split.length > 0 &&
                  item?.item_split.map((inneritem: any, index: any) => (
                    <Row
                      key={index}
                      gutter={[12, 12]}
                      style={{
                        backgroundColor: "#fff",
                        marginBottom: "8px",
                        height: "60px",
                        alignItems: "center",
                      }}
                    >
                      <Col span={1}></Col>
                      <Col span={10}>
                        <Checkbox
                          checked={selectedItems.has(inneritem.split_pay_id)}
                          onChange={(e) =>
                            handleCheckboxChange(
                              inneritem.split_pay_id,
                              e.target.checked
                            )
                          }
                        />
                      </Col>
                      <Col span={2}></Col>
                      <Col span={4}>
                        {inneritem && inneritem.is_main_split && (
                          <>
                            <img
                              src={splitIcon}
                              style={{
                                flex: 1,
                                textAlign: "left",
                                width: "20px",
                                height: "20px",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                splitItem(
                                  inneritem.split_pay_id,
                                  inneritem.split_item_price,
                                  item,
                                  "inner"
                                )
                              }
                            />
                          </>
                        )}
                        {inneritem && !inneritem.is_main_split && (
                          <>
                            <img
                              src={aftersplitIcon}
                              style={{
                                flex: 1,
                                textAlign: "left",
                                width: "40px",
                                height: "20px",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                removeSplitItem(inneritem.split_pay_id, item)
                              }
                            />
                          </>
                        )}
                      </Col>
                      <Col span={3}>
                        <Text
                          style={{
                            flex: 1,
                            textAlign: "center",
                            fontWeight: 700,
                            fontSize: "14px",
                          }}
                        >
                          ${Number(inneritem.split_item_price).toFixed(2)}
                        </Text>
                      </Col>
                      <Col span={4}>
                        <Text
                          style={{
                            flex: 1,
                            textAlign: "right",
                            fontWeight: 700,
                            fontSize: "13px",
                          }}
                        >
                          {inneritem.split_item_payment_method}
                        </Text>
                      </Col>
                    </Row>
                  ))}
              </>
            ))}
            {billPayloadData?.taxes.map((item: any, index: any) => (
              <>
                <Row
                  key={index}
                  gutter={[12, 12]}
                  style={{
                    backgroundColor: "#fff",
                    height: "60px",
                    alignItems: "center",
                  }}
                >
                  <Col span={1}>
                    <Checkbox
                      checked={selectedItems.has(item.tax_unique_id)}
                      onChange={(e) =>
                        handleCheckboxChange(
                          item.tax_unique_id,
                          e.target.checked
                        )
                      }
                    />
                  </Col>
                  <Col span={12}>
                    <Text
                      style={{ flex: 2, marginLeft: "10px", fontWeight: 700 }}
                    >
                      {item.tax_name} ({Number(item.tax_rate).toFixed(2)}%)
                    </Text>
                  </Col>
                  <Col span={4}></Col>
                  <Col span={3}>
                    <Text
                      style={{
                        flex: 1,
                        textAlign: "center",
                        fontWeight: 700,
                        fontSize: "14px",
                      }}
                    >
                      ${Number(item.tax_amount).toFixed(2)}
                    </Text>
                  </Col>
                  <Col span={4}>
                    <Text
                      style={{
                        flex: 1,
                        textAlign: "right",
                        fontWeight: 700,
                        fontSize: "13px",
                      }}
                    >
                      {item.tax_payment_method}
                    </Text>
                  </Col>
                </Row>
              </>
            ))}
            {billPayloadData?.tip_id && billPayloadData?.tip_value !== "" && (
              <>
                <Row
                  key={billPayloadData.tip_id}
                  gutter={[12, 12]}
                  style={{
                    backgroundColor: "#fff",
                    height: "60px",
                    alignItems: "center",
                  }}
                >
                  <Col span={1}>
                    <Checkbox
                      checked={selectedItems.has(billPayloadData.tip_id)}
                      onChange={(e) =>
                        handleCheckboxChange(
                          billPayloadData.tip_id,
                          e.target.checked
                        )
                      }
                    />
                  </Col>
                  <Col span={12}>
                    <Text
                      style={{ flex: 2, marginLeft: "10px", fontWeight: 700 }}
                    >
                      Tips {Number(billPayloadData.tip_rate).toFixed(2)}%
                    </Text>
                  </Col>
                  <Col span={4}></Col>
                  <Col span={3}>
                    <Text
                      style={{
                        flex: 1,
                        textAlign: "center",
                        fontWeight: 700,
                        fontSize: "14px",
                      }}
                    >
                      ${Number(billPayloadData.tip_value).toFixed(2)}
                    </Text>
                  </Col>
                  <Col span={4}>
                    <Text
                      style={{
                        flex: 1,
                        textAlign: "right",
                        fontWeight: 700,
                        fontSize: "13px",
                      }}
                    >
                      {billPayloadData.tip_payment_method}
                    </Text>
                  </Col>
                </Row>
              </>
            )}
          </div>

          <Row
            justify="start"
            style={{
              position: "absolute",
              left: 0,
              right: 0,
              bottom: 0,
              background: "rgb(250, 250, 250)",
              borderRadius: "0 0 12px 12px",
              padding: "12px 24px",
              zIndex: 1,
              gap: "50px",
            }}
          >
            <Col>
              <Checkbox checked={selectAll} onChange={handleSelectAll}>
                Select All
              </Checkbox>
            </Col>
            {/* <Col>
              <Checkbox
                checked={selectAllUnpaid}
                onChange={handleSelectAllUnpaid}
              >
                {" "}
                Select All Unpaid{" "}
              </Checkbox>
            </Col> */}
          </Row>
        </Col>

        {/* Right Section */}
        <Col
          span={6}
          style={{
            border: "1px solid #E6E6E6",
            borderRadius: "8px",
            backgroundColor: "#fafafa",
            height: "calc(100vh - 100px)",
            overflowY: "auto",
          }}
        >
          <div style={{ padding: "16px 18px 12px 18px" }}>
            <span
              style={{
                display: "inline-block",
                fontFamily:
                  "SourceHanSansCN-Bold, PingFangSC-Semibold, PingFang SC",
                fontSize: "16px",
                lineHeight: "32px",
                fontWeight: 700,
              }}
            >
              Payment Methods
            </span>
          </div>
          <div
            style={{
              height: "calc(100% - 180px)",
              overflowY: "auto",
              padding: "0 18px 18px 18px",
              maxHeight: "800px",
            }}
          >
            {paymentOptions.map((option: any, index: any) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "15px",
                  background: "#fff",
                  border: "0.5px solid #e0e7fe",
                  borderRadius: "8px",
                  padding: "12px",
                  cursor: "pointer",
                }}
                onClick={async () => {
                  {
                    await handlePaymentMethodSelection(
                      option.id,
                      option.paymentmethodtype
                    );
                  }
                }}
              >
                <Row align="middle">
                  <Col>
                    <div
                      style={{
                        width: "20px",
                        height: "20px",
                        fontSize: "14px",
                        fontWeight: 700,
                        color: "#fff",
                        background: "#2e56f2",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "4px",
                        marginRight: "10px",
                      }}
                    >
                      {option.paymentmethodtype.charAt(0).toUpperCase()}
                    </div>
                  </Col>
                  <Col>
                    <Text strong style={{ fontSize: "16px" }}>
                      {option.paymentmethodtype}
                    </Text>
                  </Col>
                </Row>
                <Row align="middle">
                  <Col>
                    <Text strong style={{ fontSize: "16px" }}>
                      {option.payment_paid_amount == 0
                        ? ""
                        : `${Number(option.payment_paid_amount).toFixed(2)}`}
                    </Text>
                  </Col>
                  <Col style={{ marginLeft: "8px" }}>
                    {/* <EditOutlined
                      style={{ color: "#6B8EFA", fontSize: "18px" }}
                    /> */}
                    <div
                      style={{
                        content: "",
                        // left: "auto",
                        bottom: 0,
                        top: 0,
                        width: "20px",
                        height: "20px",
                        background: `rgba(194, 202, 221, 0.1) url(${FullPayIcon}) 50% / 20px no-repeat`,
                        textAlign: "center",
                      }}
                    ></div>
                  </Col>
                </Row>
              </div>
            ))}

            <div
              style={{
                background: "#fff",
                padding: "8px 16px",
                borderRadius: "4px",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <Text
                  style={{ color: "#999", fontSize: "13px", fontWeight: 700 }}
                >
                  Subtotal
                </Text>
                <Text
                  style={{
                    color: "#f43b00",
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                >
                  ${Number(billPayloadData?.sub_total).toFixed(2)}
                </Text>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <Text
                  style={{
                    color: "#2e56f2",
                    textDecoration: "underline",
                    fontSize: "13px",
                    fontWeight: 700,
                    textUnderlineOffset: "4px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowTaxModal(true);
                  }}
                >
                  Taxes
                </Text>
                <Text
                  style={{
                    color: "#f43b00",
                    fontWeight: 700,
                    fontSize: "14px",
                  }}
                >
                  ${Number(billPayloadData?.total_tax).toFixed(2)}
                </Text>
              </div>
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <Text
                  style={{
                    color: "#2e56f2",
                    textDecoration: "underline",
                    fontSize: "13px",
                    fontWeight: 700,
                  }}
                >
                  <a href="#">Service charge</a>
                </Text>
                <Text
                  style={{
                    color: "#ff4d4f",
                    fontWeight: 600,
                    fontSize: "16px",
                  }}
                >
                  $6
                </Text>
              </div> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <Text
                  style={{ color: "#999", fontSize: "13px", fontWeight: 700 }}
                >
                  Total
                </Text>
                <Text
                  style={{
                    color: "#f43b00",
                    fontWeight: 700,
                    fontSize: "14px",
                  }}
                >
                  ${Number(billPayloadData?.final_total).toFixed(2)}
                </Text>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Text
                  style={{
                    color: "#2e56f2",
                    textDecoration: "underline",
                    fontSize: "13px",
                    fontWeight: 700,
                    textUnderlineOffset: "4px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowTipModal(true);
                  }}
                >
                  Tips
                </Text>
                <Text
                  style={{
                    color: "#f43b00",
                    fontWeight: 700,
                    fontSize: "14px",
                  }}
                >
                  {billPayloadData?.tips
                    ? `$${Number(billPayloadData?.tips).toFixed(2)}`
                    : "-"}
                </Text>
              </div>
            </div>
          </div>

          <Row
            justify="end"
            style={{
              position: "absolute",
              left: 0,
              right: 0,
              bottom: 0,
              background: "rgb(250, 250, 250)",
              borderRadius: "0 0 12px 12px",
              padding: "12px 24px",
              borderTop: "1px solid #e0e7fe",
              zIndex: 1,
            }}
          >
            <Col span={24}>
              <Row
                justify="space-between"
                align="middle"
                style={{ marginBottom: "8px" }}
              >
                <Col>
                  <span style={{ fontWeight: 500, fontSize: "16px" }}>
                    To Pay
                  </span>
                </Col>
                <Col>
                  <span
                    style={{
                      color: "#ff4d4f",
                      fontWeight: 600,
                      fontSize: "16px",
                    }}
                  >
                    ${Number(billPayloadData?.final_total).toFixed(2)}
                  </span>
                </Col>
              </Row>
              <Row justify="end">
                <Button
                  type="primary"
                  style={{
                    width: "99px",
                    fontWeight: 700,
                    lineHeight: "20px",
                    padding: "8px 16px",
                    height: "40px",
                    backgroundColor: "#2e56f2",
                    borderRadius: "6px",
                    border: "none",
                  }}
                  onClick={handlePayNow}
                >
                  Pay now
                </Button>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <TipsModel
        visible={showTipModal}
        onCancel={() => {
          setShowTipModal(false);
        }}
        onSubmit={handleTipSubmit}
        totalvalue={
          billPayloadData !== null
            ? Number(billPayloadData?.paid_amount).toFixed(2)
            : 0
        }
      />
      <TaxModel
        visible={showTaxModal}
        taxArray={billPayloadData?.taxes}
        totalValue={Number(billPayloadData?.sub_total)}
        paymentMethod={
          paymentOptions ? paymentOptions[0]?.paymentmethodtype : ""
        }
        paymentMethodId={paymentOptions ? paymentOptions[0]?.id : ""}
        onCancel={() => {
          setShowTaxModal(false);
        }}
        onSubmit={handleTaxesEdit}
      />
      <SplitAmountModal
        visible={showSplitModal}
        onClose={() => {
          setShowSplitModal(false);
        }}
        onConfirm={handleSplitEdit}
        maxValue={selectedPrice}
        type={selectedSplitType}
      />
    </Content>
  );
};

export default Pay;
