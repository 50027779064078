import React, { useEffect, useState } from "react";
import { Row, Col, Button, Typography, Dropdown, MenuProps } from "antd";
import {
  ArrowLeftOutlined,
  EditOutlined,
  SortAscendingOutlined,
  PrinterOutlined,
  InfoCircleOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import {
  deleteData,
  get,
  post,
  put,
  uploadImg,
} from "../../services/Apiclient";
import { useNavigate, useParams } from "react-router-dom";
import "../../styles/global.css";
import { Content } from "antd/es/layout/layout";
import "./BillDetails.css";
import SelectServiceStaff from "../../components/model/billing/SelectServiceStaff";
import NotesModal from "../../components/model/billing/NotesModal";
import SelectProductStaff from "../../components/model/billing/SelectProductStaff";
import SignatureModel from "../../components/model/billing/SignatureModel";

const { Title, Text } = Typography;

const OrderCustomerIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Bc_OrderCustomer.png`;
const OrderMemberIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Bc_OrderMember.png`;
const OrderDetail_XM = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_OrderDetail_XM.png`;
const PaymentIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Payment_XJ.png`;
const CamaraIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Camera.png`;

interface checkoutData {
  ref_no: number;
  item_created_at: string;
  created_at_myt: string;
  male_count: string;
  female_count: string;
  customer_name: string;
  customer_email: string;
  phonenumber: number;
  sub_total: number;
  total_tax: number;
  tips: number;
  final_total: number;
  note: string;
  tip_rate: number;
  tip_value: number;
  tip_payment_method: string;
}

export default function BillDetails() {
  const navigate = useNavigate();
  let { id } = useParams();
  const [checkoutData, setcheckoutData] = useState<checkoutData | null>(null);
  const [serviceData, setServiceData] = useState<any>([]);
  const [productData, setProductData] = useState<any>([]);
  const [taxData, setTaxData] = useState<any>([]);
  const [paymentData, setPaymentData] = useState<any>([]);
  const [ShowServiceStaffEditModal, setShowServiceStaffEditModal] =
    useState(false);
  const [selectedService, setSelectedService] = useState<any>(null);
  const [ShowProductStaffEditModal, setShowProductStaffEditModal] =
    useState(false);
  const [selectedProduct, setSelectedProduct] = useState<any>(null);
  const [notesValue, setNotesValue] = useState("");
  const [showNotesEditModal, setShowNotesEditModal] = useState(false);
  const [showSignatureModel, setShowSignatureModel] = useState(false);
  const [signatureVal, setSignatureVal] = useState("");
  const [selectedImages, setSelectedImages] = useState<any[]>([]);
  // const [tipData, setTipData] = useState<any>([]);

  const fetchCheckoutBillingData = async () => {
    try {
      const response = await get(`/billing/checkout/${id}`);

      if (response?.data?.success) {
        // console.log(response.data.data);
        const checkout = response.data.data;
        setcheckoutData(response.data.data); // Assuming the billing data is in the `billingData` field
        setServiceData(response.data.data.services);
        setProductData(response.data.data.products);
        setTaxData(response.data.data.tax);
        // setTipData(response.data.data.tips);
        setPaymentData(response.data.data.payment);
        // Set existing images if available
        if (checkout.note_images && checkout.note_images.length > 0) {
          const existingImages = checkout.note_images.map(
            (image: { image_url: string }) => ({
              url: image.image_url, // Store URL for existing images
              isExisting: true, // Flag to distinguish existing images
            })
          );
          setSelectedImages(existingImages);
        }
        if (checkout.sign) {
          setSignatureVal(checkout.sign);
        }
      } else {
        console.log("Error in API call: ", response);
      }
    } catch (error) {
      console.error("Error fetching billing data:", error);
    }
  };

  useEffect(() => {
    fetchCheckoutBillingData();
  }, []);

  const items: MenuProps["items"] = [
    {
      key: "calculation-details",
      label: (
        <div className="orderCalcDetail-dropdown-main">
          <div className="view-title-main">
            <span className="bk-title_ordcal">Calculation Details</span>
          </div>
          <div className="view-container">
            <div className="view-cell-item">
              <div className="view-cash-main">
                <div className="view-pay-main">
                  <div className="display_def_bk-image">
                    <div className="van-image">
                      <img
                        src={PaymentIcon}
                        className="van-image__img"
                        alt="Payment"
                      />
                    </div>
                  </div>
                  <div className="view-label_ordcal">cash</div>
                  <span className="view_sn">$123</span>
                </div>
                <div className="view-list">
                  <div className="view-item_in">
                    <span className="repaymentDetail_turnover">Turnover：</span>
                    <span className="view_vale">$12</span>
                  </div>
                  <div className="view-item_in">
                    <span className="repaymentDetail_turnover">
                      Performance：
                    </span>
                    <span className="view_vale">$0</span>
                  </div>
                  <div className="view-item_in">
                    <span className="repaymentDetail_turnover">
                      Commission：
                    </span>
                    <span className="view_vale">$0</span>
                  </div>
                  <div className="view-item_cal_time">
                    <span className="repaymentDetail_turnover">
                      Calculation time：
                    </span>
                    <span className="view_vale">05/12/2024 03:19:40pm</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
    },
  ];
  const handleStaffEdit = (item: any) => {
    setSelectedService(item);
    setShowServiceStaffEditModal(true);
  };

  const handleProductEdit = (item: any) => {
    setSelectedProduct(item);
    setShowProductStaffEditModal(true);
  };

  const handleServiceStaffChange = (data: any) => {
    let newStaffArray = data
      .filter((staffItem: any) => staffItem.selectedStaff) // Filter out items where staff is null
      .map((staffItem: any) => ({
        staff_service_id: staffItem.id,
        service_category_id: staffItem.selectedStaff.staffcategory,
        staff_id: staffItem.selectedStaff.id,
        staff_name: staffItem.selectedStaff.name,
        staff_code: staffItem.selectedStaff.staffcode,
        department_id: staffItem.selectedStaff.department_id,
        department_name: staffItem.selectedStaff.department_name,
        item_service_category_name: "service category 2",
        item_staff_service_category_name: staffItem.staffservicetype,
      }));

    const updateServiceData = serviceData.map((item: any) =>
      item.item_unique_id === selectedService.item_unique_id
        ? { ...item, staff: newStaffArray }
        : item
    );
    setServiceData(updateServiceData);
    setShowServiceStaffEditModal(false);
  };

  const handleNoteAdd = (data: any) => {
    setNotesValue(data);
    setShowNotesEditModal(true);
    setcheckoutData((pre: any) => ({
      ...pre,
      note: data,
    }));
  };
  const handleSignatureValue = (data: any) => {
    setSignatureVal(data);
    setShowSignatureModel(true);
  };

  const handleProductStaffChange = (data: any) => {
    let newStaffArray = data.map((staffItem: any) => ({
      staff_service_id: staffItem.id,
      service_category_id: staffItem.staffcategory,
      staff_id: staffItem.id,
      staff_name: staffItem.name,
      staff_code: staffItem.staffcode,
      department_id: staffItem.department_id,
      department_name: staffItem.department_name,
      staff_percentage: staffItem.percentage,
    }));

    const updatedCartItems = productData.map((item: any) =>
      item.item_unique_id === selectedProduct.item_unique_id
        ? { ...item, staff: newStaffArray }
        : item
    );
    setProductData(updatedCartItems);
    setShowProductStaffEditModal(false);
  };

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      try {
        const newImages = Array.from(files).slice(0, 5 - selectedImages.length); // Limit to 5
        setSelectedImages((prev) => [...prev, ...newImages].slice(0, 5));

        // Prepare FormData
        const formData = new FormData();
        newImages.forEach((file) => {
          formData.append("file", file);
        });
        const uploadImageUrl = "/billing/uploadimage/billingnote";
        const response = await uploadImg(uploadImageUrl, formData);
        if (response.data.success) {
          // Extract image names from the API response
          const imageNames = response?.data.data.map(
            (item: { image_name: string }) => item.image_name
          );
          const url = `/billing/signupdate/${id}`;
          const outletId = localStorage.getItem("outlet_id");
          const payload = {
            ref_no: checkoutData?.ref_no,
            outlet_id: outletId,
            note: "",
            note_images: imageNames,
            sign: "",
          };
          const result = await put(url, payload);
          if (result.status == 200) {
            fetchCheckoutBillingData();
          }
        }
      } catch (error) {
        console.log("Error while updating image", error);
      }
    }
  };

  const handleRemoveImage = (index: number) => {
    setSelectedImages((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <Content className="mainContainer">
      {/* Header Section */}
      <Row justify="space-between" align="middle">
        <Col>
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            <div onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
              <ArrowLeftOutlined
                style={{ marginRight: 12, color: "#325DF2", fontSize: 18 }}
              />
            </div>
            <h2 style={{ margin: 0 }}>Bill Details</h2>
          </div>
        </Col>
        <Col>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button
              icon={<PrinterOutlined />}
              // onClick={showModal}
              style={{
                height: "45px !important",
                marginRight: "8px",
                borderColor: "#e0e7fe",
                color: "#333",
                borderRadius: "4px",
                background: "#fff",
                fontWeight: 700,
              }}
            >
              Print
            </Button>
            <Button
              style={{
                height: "45px !important",
                borderRadius: "6px 6px 6px 6px",
                border: "1px solid #2e56f2",
                fontWeight: 700,
                fontSize: "13px",
                marginRight: "8px",
                padding: "9px 18px",
                background: "#fff",
                color: "#2e56f2",
                borderColor: "#2e56f2",
              }}
            >
              Continue Billing
            </Button>
            <Button
              style={{
                height: "45px !important",
                borderRadius: "6px 6px 6px 6px",
                border: "1px solid #2e56f2",
                fontWeight: 700,
                fontSize: "13px",
                marginRight: "8px",
                background: "#fff",
                color: "#ff971e",
                borderColor: "#ff971e",
              }}
            >
              Rebill
            </Button>
            {/* <Button className="quick-settings">Quick Settings</Button> */}
            <Button
              type="primary"
              // onClick={showModal}
              style={{
                height: "45px !important",
                background: "#fff",
                borderRadius: "6px 6px 6px 6px",
                border: "1px solid #2e56f2",
                fontWeight: 700,
                fontSize: "13px",
                color: "#f43b00",
                borderColor: "#f43b00",
              }}
            >
              Void
            </Button>
          </div>
        </Col>
      </Row>

      {/* Content Section */}
      <Content className="billdetail_content">
        <Row>
          <span className="billing_title">Billed to</span>
        </Row>
        <Row gutter={16} className="view-orderBaseInfo">
          <Col span={6} className="key-billNo">
            <span className="orderDetail_title_billId">Ref#: </span>
            <div className="display_def_view-value">
              <span className="spn_refno">{checkoutData?.ref_no}</span>
            </div>
            <div className="view-tag_pay">PAID</div>
          </Col>
          <Col span={6} className="key-updateUser">
            <span className="orderDetail_title_cashier">Cashier:</span>
            <div className="view-value_cashier">
              <span>admin</span>
            </div>
          </Col>
          <Col span={6} className="key-billDate">
            <span className="orderDetail_title_billDate">
              Accounting date:{" "}
            </span>
            <div className="view-value_date">
              <span>{checkoutData?.item_created_at}</span>
            </div>
          </Col>
          <Col span={6} className="key-payTimeText">
            <span className="orderDetail_title_payTime">Payment time:</span>
            <div className="view-value_time">
              <span>{checkoutData?.created_at_myt}</span>
            </div>
          </Col>
        </Row>
        <Row gutter={16} className="view-customerInfo">
          <Col
            span={5}
            className="view-orderDetailCustomer"
            style={{
              background: `transparent url(${OrderCustomerIcon}) 50% / 100% 100% no-repeat`,
            }}
          >
            <div className="display_def">
              <span className="orderDetail_text_count">Customer count: </span>
              <div className="view-value_gender">
                <div className="view-item_male">
                  <span className="orderDetail_text_male">Male: </span>
                  <span>{checkoutData?.male_count}</span>
                </div>
                <div className="view-item_female">
                  <span className="orderDetail_text_female">Female:</span>
                  <span>{checkoutData?.female_count}</span>
                </div>
              </div>
            </div>
            <div className="display_def_cussource">
              <span className="orderDetail_text_clientSource">
                Customer source:
              </span>
              <div className="view-value_src">
                <span className="">Walk-in</span>
              </div>
            </div>
          </Col>
          <Col
            span={18}
            className="view-memberDetail"
            style={{
              background: `transparent url(${OrderMemberIcon}) 50% / 100% 100% no-repeat`,
            }}
          >
            <div className="display_def_view-info">
              <div className="view-avatar">
                <img
                  src="https://static-global.boka.vc/mase/def/Def_Empty_Avatar_Other.png"
                  style={{
                    objectFit: "cover",
                    display: "block",
                    width: "40px",
                    height: "40px",
                    fontSize: 36,
                    verticalAlign: "middle",
                    borderStyle: "none",
                  }}
                />
              </div>
              <div className="display_def_view-text">
                <div className="labelType-flex">
                  <span className="memberInfo_label_name">Name:</span>
                  <span className="view-value_cus">
                    {checkoutData?.customer_name}
                  </span>
                </div>
                <div className="view-flex_dtl">
                  <div className="labelType-flex_view-phone">
                    <span className="memberInfo_label_phone">
                      Phone number:
                    </span>
                    <span className="view-value_cus">
                      {checkoutData?.phonenumber}
                    </span>
                  </div>
                  <div className="labelType-flex_view-phone">
                    <span className="memberInfo_email">E-mail:</span>
                    <span className="view-value_cus">
                      {checkoutData?.customer_email}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        {/* Sales Details */}
        <div className="view-saleDetails">
          <Row className="view-title">
            <span className="bk-title">Sales Details</span>
          </Row>

          {/* Service */}
          {serviceData.length > 0 ? (
            <div>
              <Row>
                <div className="view-subTitle_project">
                  <span className="vertical-line"></span>
                  {/* <div
                  className="service"
                  style={{
                    background: `transparent url(${OrderDetail_XM}) 16px / 16px no-repeat;`,
                  }}
                > */}
                  <span className="bk-title_svr">Service</span>
                  {/* </div> */}
                </div>
              </Row>
              {serviceData &&
                serviceData.length > 0 &&
                serviceData.map((item: any, index: any) => (
                  <div className="view-orderDetailSaleItem">
                    <div className="view-goodsInfo">
                      <div className="view-name">
                        <span className="bk_title_servie">
                          {item.item_name}{" "}
                        </span>
                        <span className="span-count">{item.item_qty}x</span>
                      </div>
                      <div className="view-standPrice">
                        <span className="lang-standPrice">
                          Standard unit price
                        </span>
                        <span className="bk-price_color-def">
                          <span className="span-unit">$</span>
                          <span>{item.item_unit_price}</span>
                        </span>
                      </div>
                    </div>
                    <div className="view-saleEmp">
                      <div className="view-saleEmp-main">
                        <span>Served by:</span>
                        <div className="view-saleEmp-info">
                          {item.staff &&
                            item.staff.length > 0 &&
                            item.staff.map((staf: any, index: any) => (
                              <div className="view-saleEmp-item" key={index}>
                                <div className="view-name_typ">
                                  {staf.staff_name} (
                                  {staf.item_staff_service_category_name})
                                </div>
                                <div className="view-serviceCodeText">
                                  <div className="view-tag">
                                    {staf.item_service_category_name}
                                  </div>
                                </div>
                                <div className="view-achievement">
                                  <span>(Performance: $0, Commission: $0)</span>
                                  <Dropdown
                                    menu={{ items }}
                                    placement="topRight"
                                    arrow
                                  >
                                    <div className="">
                                      <InfoCircleOutlined className="infocircle" />
                                    </div>
                                  </Dropdown>
                                </div>
                              </div>
                            ))}
                        </div>
                        <div
                          className="btn-editStaff"
                          onClick={() => {
                            handleStaffEdit(item);
                          }}
                        >
                          <EditOutlined className="homeouli" />
                        </div>
                      </div>
                    </div>
                    <div className="view_bdr"></div>
                    <div className="view-payInfo">
                      <div className="view-payment">
                        <div className="view-amount">
                          <div className="view-tip">
                            <span className="">Total:</span>
                          </div>
                          <span className="bk-price_color-def">
                            <span className="span-unit">$</span>
                            <span className="span-value">
                              {item.item_price}
                            </span>
                          </span>
                        </div>
                        <div className="view-payAmount">
                          <div className="view-tip">
                            <span className="">Paid:</span>
                          </div>
                          <span className="bk-price_color-def">
                            <span className="span-unit">$</span>
                            <span className="span-value">
                              {item.item_price}
                            </span>
                          </span>
                        </div>
                        <div className="view-payText">
                          <div className="display_def_bk-image">
                            <div className="van-image">
                              {item.item_payment_method == "Cash" ? (
                                <img
                                  src={PaymentIcon}
                                  className="van-image__img"
                                  alt="Payment"
                                />
                              ) : (
                                <div className="view-sortName">
                                  {item.item_payment_method.charAt(0)}
                                </div>
                              )}
                            </div>
                          </div>
                          {item.item_payment_method}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          ) : (
            <></>
          )}

          {/* product */}
          {productData.length > 0 ? (
            <div>
              <Row>
                <div className="view-subTitle_project">
                  <span className="vertical-line"></span>
                  {/* <div
                  className="service"
                  style={{
                    background: `transparent url(${OrderDetail_XM}) 16px / 16px no-repeat;`,
                  }}
                > */}
                  <span className="bk-title_svr">Product</span>
                  {/* </div> */}
                </div>
              </Row>
              {productData &&
                productData.length > 0 &&
                productData.map((item: any, index: any) => (
                  <div className="view-orderDetailSaleItem">
                    <div className="view-goodsInfo">
                      <div className="view-name">
                        <span className="bk_title_servie">
                          {item.item_name}
                        </span>
                        <span className="span-count">{item.item_qty}x</span>
                      </div>
                      <div className="view-standPrice">
                        <span className="lang-standPrice">
                          Standard unit price
                        </span>
                        <span className="bk-price_color-def">
                          <span className="span-unit">$</span>
                          <span>{item.item_unit_price}</span>
                        </span>
                      </div>
                    </div>
                    <div className="view-saleEmp">
                      <div className="view-saleEmp-main">
                        <span>Served by:</span>
                        <div className="view-saleEmp-info">
                          {item.staff &&
                            item.staff.length > 0 &&
                            item.staff.map((prd_staf: any, index: any) => (
                              <div className="view-saleEmp-item">
                                <div className="view-name_typ">
                                  {prd_staf.staff_name}
                                </div>
                                <div className="view-serviceCodeText">
                                  <div className="view-tag">
                                    {item.item_service_category_name ||
                                      "undesignated"}
                                  </div>
                                </div>
                                <div className="view-achievement">
                                  <span>(Performance: $0, Commission: $0)</span>
                                  <div className="">
                                    <InfoCircleOutlined className="infocircle" />
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                        <div
                          className="btn-editStaff"
                          onClick={() => {
                            handleProductEdit(item);
                          }}
                        >
                          <EditOutlined className="homeouli" />
                        </div>
                      </div>
                    </div>
                    <div className="view_bdr"></div>
                    <div className="view-payInfo">
                      <div className="view-payment">
                        <div className="view-amount">
                          <div className="view-tip">
                            <span className="">Total:</span>
                          </div>
                          <span className="bk-price_color-def">
                            <span className="span-unit">$</span>
                            <span className="span-value">
                              {item.item_price}
                            </span>
                          </span>
                        </div>
                        <div className="view-payAmount">
                          <div className="view-tip">
                            <span className="">Paid:</span>
                          </div>
                          <span className="bk-price_color-def">
                            <span className="span-unit">$</span>
                            <span className="span-value">
                              {item.item_price}
                            </span>
                          </span>
                        </div>
                        <div className="view-payText">
                          <div className="display_def_bk-image">
                            <div className="van-image">
                              {item.item_payment_method == "Cash" ? (
                                <img
                                  src={PaymentIcon}
                                  className="van-image__img"
                                  alt="Payment"
                                />
                              ) : (
                                <div className="view-sortName">
                                  {item.item_payment_method.charAt(0)}
                                </div>
                              )}
                            </div>
                          </div>
                          {item.item_payment_method}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          ) : (
            <></>
          )}

          {/* Taxes */}
          {taxData.length > 0 ? (
            <div>
              <Row>
                <div className="view-subTitle_project">
                  <span className="vertical-line"></span>
                  {/* <div
                  className="service"
                  style={{
                    background: `transparent url(${OrderDetail_XM}) 16px / 16px no-repeat;`,
                  }}
                > */}
                  <span className="bk-title_svr">Taxes</span>
                  {/* </div> */}
                </div>
              </Row>
              {taxData &&
                taxData.length > 0 &&
                taxData.map((item: any, index: any) => (
                  <div className="view-orderDetailSaleItem">
                    <div className="view-goodsInfo">
                      <div className="view-name">
                        <span className="bk_title_servie">
                          {item.tax_name}{" "}
                        </span>
                      </div>
                    </div>
                    <div className="view_bdr"></div>
                    <div className="view-payInfo">
                      <div className="view-payment">
                        <div className="view-amount">
                          <div className="view-tip">
                            <span className="">Total:</span>
                          </div>
                          <span className="bk-price_color-def">
                            <span className="span-unit">$</span>
                            <span className="span-value">
                              {item.tax_amount}
                            </span>
                          </span>
                        </div>
                        <div className="view-payAmount">
                          <div className="view-tip">
                            <span className="">Paid:</span>
                          </div>
                          <span className="bk-price_color-def">
                            <span className="span-unit">$</span>
                            <span className="span-value">
                              {item.tax_amount}
                            </span>
                          </span>
                        </div>
                        <div className="view-payText">
                          <div className="display_def_bk-image">
                            <div className="van-image">
                              {item.item_payment_method == "Cash" ? (
                                <img
                                  src={PaymentIcon}
                                  className="van-image__img"
                                  alt="Payment"
                                />
                              ) : (
                                <div className="view-sortName">
                                  {item.tax_payment_method.charAt(0)}
                                </div>
                              )}
                            </div>
                          </div>
                          {item.tax_payment_method}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          ) : (
            <></>
          )}
          {/* Tips */}

          {checkoutData?.tip_value && (
            <>
              <div>
                <Row>
                  <div className="view-subTitle_project">
                    <span className="vertical-line"></span>
                    {/* <div
                  className="service"
                  style={{
                    background: `transparent url(${OrderDetail_XM}) 16px / 16px no-repeat;`,
                  }}
                > */}
                    <span className="bk-title_svr">Tips</span>
                    {/* </div> */}
                  </div>
                </Row>

                <div className="view-orderDetailSaleItem">
                  <div className="view-goodsInfo">
                    <div className="view-name">
                      <span className="bk_title_servie">
                        Tips Tips{checkoutData?.tip_rate}%{" "}
                      </span>
                    </div>
                  </div>
                  <div className="view_bdr"></div>
                  <div className="view-payInfo">
                    <div className="view-payment">
                      <div className="view-amount">
                        <div className="view-tip">
                          <span className="">Total:</span>
                        </div>
                        <span className="bk-price_color-def">
                          <span className="span-unit">$</span>
                          <span className="span-value">
                            {checkoutData?.tip_value}
                          </span>
                        </span>
                      </div>
                      <div className="view-payAmount">
                        <div className="view-tip">
                          <span className="">Paid:</span>
                        </div>
                        <span className="bk-price_color-def">
                          <span className="span-unit">$</span>
                          <span className="span-value">
                            {checkoutData?.tip_value}
                          </span>
                        </span>
                      </div>
                      <div className="view-payText">
                        <div className="display_def_bk-image">
                          <div className="van-image">
                            {checkoutData?.tip_payment_method == "Cash" ? (
                              <img
                                src={PaymentIcon}
                                className="van-image__img"
                                alt="Payment"
                              />
                            ) : (
                              <div className="view-sortName">
                                {/* {checkoutData?.tip_payment_method.charAt(0) || "P"} */}
                                P
                              </div>
                            )}
                          </div>
                        </div>
                        {checkoutData?.tip_payment_method}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        {/* Payment Summary */}
        <div className="view-paymentSummary">
          <Row className="view-title">
            <span className="bk-title">Payment Summary</span>
          </Row>
          <div className="view-orderDetailPaymentSummary">
            <div className="view-payInformation">
              {paymentData &&
                paymentData.length > 0 &&
                paymentData.map((item: any, index: any) => (
                  <div className="view-payItem_list">
                    <div className="display_def_bk-image">
                      <div className="van-image">
                        {item.payment_name == "Cash" ? (
                          <img
                            src={PaymentIcon}
                            className="van-image__img"
                            alt="Payment"
                          />
                        ) : (
                          <div className="view-sortName">
                            {item.payment_name.charAt(0)}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="view-name_pay">{item.payment_name}</div>
                    <div className="view-payAmt">
                      <span className="bk-price_color-def">
                        ${item.payment_amount}
                      </span>
                    </div>
                  </div>
                ))}
              {/* <div className="view-payItem_list">
                <div className="view-sortName">B</div>
                <div className="view-name_pay">Bank card</div>
                <div className="view-payAmt">
                  <span className="bk-price_color-def">$196</span>
                </div>
              </div> */}
            </div>
            <div className="view-rightBox">
              <div className="view-amountInfo">
                <div className="view-subtotal">
                  <div className="view-left">
                    <span>Subtotal</span>
                  </div>
                  <div className="view-value">${checkoutData?.sub_total}</div>
                </div>
                <div className="view-taxes">
                  <div className="view-left">
                    <span>Taxes</span>
                  </div>
                  <div className="view-value">
                    <span>${checkoutData?.total_tax}</span>
                  </div>
                </div>
                <div className="view-serviceCharge">
                  <div className="view-left">
                    <span>Service charge</span>
                  </div>
                  <div className="view-value">
                    <span>$0.84</span>
                  </div>
                </div>
                <div className="view-total">
                  <div className="view-left">
                    <span>Total</span>
                  </div>
                  <div className="view-value_total">
                    <span>${checkoutData?.final_total}</span>
                  </div>
                </div>
                <div className="view-tips">
                  <div className="view-left">
                    <span>Tips</span>
                  </div>
                  <div className="view-value">
                    <span>{checkoutData?.tips || "-"}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Notes & Signature */}
        <div className="view_group_note_seg_img">
          <div className="view_flex_note_seg">
            <div className="view-notes">
              <div className="view-title_note">
                <span className="bk-title_note">Notes</span>
                <div
                  className="view-btn-edt"
                  onClick={() => handleNoteAdd(checkoutData?.note)}
                >
                  <EditOutlined className="homeouli" />
                </div>
              </div>
              <div className="view-remark">{checkoutData?.note || "-"}</div>
            </div>
            <div className="view-signature">
              <div className="view-title_signature">
                <span className="bk-title_note">Signature</span>
                <div
                  className="view-btn-edt"
                  onClick={() => handleSignatureValue(signatureVal)}
                >
                  <EditOutlined className="homeouli" />
                </div>
              </div>
              <div className="view-img_sig">
                <div className="bk-image_sig">
                  <div className="van_img_sig">
                    {signatureVal ? (
                      <img
                        src={signatureVal}
                        alt="Signature"
                        style={{ width: "100%", height: "auto" }}
                      />
                    ) : (
                      <span>No signature available</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="view-remarkImgs">
            <div className="bk-upload-remarkImgs">
              <div className="bk-image_img">
                <div className="van-image_up">
                  <img
                    src={CamaraIcon}
                    className="van-image__img"
                    alt="Payment"
                  />
                </div>
              </div>
            </div>
          </div> */}
          <div className="view-remarkImgs">
            {selectedImages &&
              selectedImages.map((image, index) => (
                <div className="bk-upload-remarkImgs">
                  <div className="image-preview">
                    <img
                      // src={image}
                      // src={URL.createObjectURL(image)}
                      src={
                        image.isExisting
                          ? image.url
                          : URL.createObjectURL(image)
                      } // Check if the image is existing or new
                      alt={`Selected ${index}`}
                      className="van-image__img"
                    />
                    <button
                      className="remove-img-button"
                      onClick={() => handleRemoveImage(index)}
                    >
                      ✕
                    </button>
                  </div>
                </div>
              ))}
            {selectedImages.length < 5 && (
              <>
                <div
                  className="bk-upload-remarkImgs"
                  onClick={() => document.getElementById("fileInput")?.click()}
                  style={{ cursor: "pointer" }}
                >
                  <div className="bk-image_img">
                    <div className="van-image_up">
                      <img
                        src={CamaraIcon}
                        className="van-image__img"
                        alt="Upload"
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
            <input
              id="fileInput"
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              multiple
              onChange={handleImageChange}
            />
          </div>
        </div>
        {/* <div className="orderCalcDetail-dropdown-main">
          <div className="view-title-main">
            <span className="bk-title_ordcal">Calculation Details</span>
          </div>
          <div className="view-container">
            <div className="view-cell-item">
              <div className="view-cash-main">
                <div className="view-pay-main">
                  <div className="display_def_bk-image">
                    <div className="van-image">
                      <img
                        src={PaymentIcon}
                        className="van-image__img"
                        alt="Payment"
                      />
                    </div>
                  </div>
                  <div className="view-label_ordcal">cash</div>
                  <span className="view_sn">$123</span>
                </div>
                <div className="view-list">
                  <div className="view-item_in">
                    <span className="repaymentDetail_turnover">Turnover：</span>
                    <span className="view_vale">$12</span>
                  </div>
                  <div className="view-item_in">
                    <span className="repaymentDetail_turnover">
                      Performance：
                    </span>
                    <span className="view_vale">$0</span>
                  </div>
                  <div className="view-item_in">
                    <span className="repaymentDetail_turnover">
                      Commission：
                    </span>
                    <span className="view_vale">$0</span>
                  </div>
                  <div className="view-item_cal_time">
                    <span className="repaymentDetail_turnover">
                      Calculation time：
                    </span>
                    <span className="view_vale">05/12/2024 03:19:40pm</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </Content>
      {ShowServiceStaffEditModal && (
        <SelectServiceStaff
          visible={ShowServiceStaffEditModal}
          servicename={selectedService ? selectedService.item_name : ""}
          onClose={() => {
            setShowServiceStaffEditModal(false);
          }}
          staffArray={selectedService ? selectedService.staff : []}
          onSelect={handleServiceStaffChange}
        />
      )}

      {ShowProductStaffEditModal && (
        <>
          <SelectProductStaff
            visible={ShowProductStaffEditModal}
            servicename={selectedProduct ? selectedProduct.item_name : ""}
            onClose={() => {
              setShowProductStaffEditModal(false);
            }}
            staffArray={selectedProduct ? selectedProduct.staff : []}
            onSelect={handleProductStaffChange}
          />
        </>
      )}

      {showNotesEditModal && (
        <>
          <NotesModal
            visible={showNotesEditModal}
            onClose={() => {
              setShowNotesEditModal(false);
            }}
            onConfirm={handleNoteAdd}
            value={notesValue}
            refId={checkoutData?.ref_no}
          />
        </>
      )}

      <SignatureModel
        visible={showSignatureModel}
        onClose={() => {
          setShowSignatureModel(false);
        }}
        onConfirm={handleSignatureValue}
        value={signatureVal}
        refId={checkoutData?.ref_no}
      />
    </Content>
  );
}
