import React, { useEffect, useState } from "react";
import { Modal, Radio, Typography, Button, Divider, message } from "antd";
import { jwtDecode } from "jwt-decode";
import { put } from "../../../services/Apiclient";
import { useParams } from "react-router-dom";

const { Text } = Typography;

interface StoreSelectionModalProps {
  visible: boolean;
  onClose: () => void;
  onConfirm: () => void;
  value: string | null | undefined;
}

const StoreSelectionModal: React.FC<StoreSelectionModalProps> = ({
  visible,
  onClose,
  onConfirm,
  value,
}) => {
  const { id } = useParams();
  const [selectedStore, setSelectedStore] = useState<string | null>(null);
  const [storeData, setStoreData] = useState<any[]>([]);

  useEffect(() => {
    if (visible) {
      // Decode the token and fetch store data
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const decodedToken: any = jwtDecode(token);
          const stores = decodedToken?.tokendata?.storeData || [];
          setStoreData(stores); // Set store data array
          setSelectedStore(decodedToken?.tokendata?.outlet_id || null); // Pre-select current `outlet_id`
        } catch (error) {
          message.error("Failed to decode token.");
          console.error("Token decoding error:", error);
        }
      }
      setSelectedStore(value || localStorage.getItem("outlet_id"));
    }
  }, [visible]);

  const handleStoreChange = (e: any) => {
    setSelectedStore(e.target.value);
  };

  const handleSave = async () => {
    let payload = {
      outlet_id: selectedStore,
    };
    await put(`/customer/updatedata/${id}`, payload);
    onConfirm();
    onClose(); // Close the modal after confirming
  };

  return (
    <Modal
      visible={visible}
      title="Stores"
      onCancel={onClose}
      footer={null}
      centered
      bodyStyle={{ padding: "0px", display: "flex", flexDirection: "column" }}
    >
      {storeData.length > 0 ? (
        <Radio.Group
          onChange={handleStoreChange}
          value={selectedStore} // Pre-selected value from `outlet_id`
          style={{ display: "flex", flexDirection: "column" }}
        >
          {storeData.map((store: any) => (
            <Radio key={store.id} value={store.id}>
              {store.name} {/* Display store name */}
            </Radio>
          ))}
        </Radio.Group>
      ) : (
        <Text
          type="secondary"
          style={{ textAlign: "center", margin: "20px 0" }}
        >
          No stores available.
        </Text>
      )}
      <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
      <div style={{ textAlign: "right" }}>
        <Button onClick={onClose} style={{ marginRight: "8px" }}>
          Cancel
        </Button>
        <Button type="primary" onClick={handleSave}>
          Save
        </Button>
      </div>
    </Modal>
  );
};

export default StoreSelectionModal;
