import React from "react";
import { InputNumber, Button, Typography } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

const { Text } = Typography;

interface Product {
  product_id: string;
  product_name: string;
  product_price: string;
  qty: string;
  selling_price: string;
  rule: string;
  type: string;
  key: string;
  combo?: ComboProduct[];
}

interface ComboProduct {
  combo_id: string;
  product_id: string;
  product_name: string;
  product_price: string;
  type: string;
  max_available: string;
}

const AssignService: React.FC = () => {
  const products: Product[] = [
    {
      product_id: "QaEJrkmT",
      product_name: "lmn",
      product_price: "12",
      qty: "1",
      selling_price: "15",
      rule: "-",
      type: "Single",
      key: "QaEJrkmT",
    },
    {
      key: "ab43v1",
      combo: [
        {
          combo_id: "ab43v1",
          product_id: "vexrhQJE",
          product_name: "opq",
          product_price: "21",
          type: "Combo",
          max_available: "1",
        },
        {
          combo_id: "ab43v1",
          product_id: "QaEJrkmT",
          product_name: "lmn",
          product_price: "12",
          type: "Combo",
          max_available: "1",
        },
        {
          combo_id: "ab43v1",
          product_id: "QaEJrkmT",
          product_name: "lmn",
          product_price: "12",
          type: "Combo",
          max_available: "1",
        },
        {
          combo_id: "ab43v1",
          product_id: "QaEJrkmT",
          product_name: "lmn",
          product_price: "12",
          type: "Combo",
          max_available: "1",
        },
        {
          combo_id: "ab43v1",
          product_id: "QaEJrkmT",
          product_name: "lmn",
          product_price: "12",
          type: "Combo",
          max_available: "1",
        },
        {
          combo_id: "ab43v1",
          product_id: "QaEJrkmT",
          product_name: "lmn",
          product_price: "12",
          type: "Combo",
          max_available: "1",
        },
        {
          combo_id: "ab43v1",
          product_id: "QaEJrkmT",
          product_name: "lmn",
          product_price: "12",
          type: "Combo",
          max_available: "1",
        },
      ],
      qty: "1",
      selling_price: "15",
      rule: "all",
      product_id: "",
      product_name: "",
      product_price: "",
      type: "",
    },
  ];

  return (
    <div>
      <Typography.Title level={4}>Assign Product</Typography.Title>
      <div style={{ backgroundColor: "#f6f7fa", padding: "20px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 10,
          }}
        >
          <Text strong>Service</Text>
          <Button type="link">Add</Button>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "50%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Text style={{ width: "60%" }}>Product name</Text>
            <Text style={{ width: "20%" }}>Price</Text>
            <Text style={{ width: "20%" }}>Max available</Text>
          </div>
          <div style={{ width: "10%" }} />
          <div
            style={{
              width: "40%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Text style={{ width: "25%", textAlign: "center" }}>Qty</Text>
            <Text style={{ width: "25%", textAlign: "center" }}>
              Selling price
            </Text>
            <Text style={{ width: "25%", textAlign: "center" }}>Rule</Text>
            <Text>Action</Text>
          </div>
        </div>
        {products.map((product: any, index) => (
          <div
            key={product.key}
            style={{ position: "relative", marginBottom: "20px" }}
          >
            {/* Single Product */}
            {!product.combo && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Text style={{ width: "60%" }}>{product.product_name}</Text>
                  <Text style={{ width: "20%" }}>${product.product_price}</Text>
                  <Text style={{ width: "20%" }}>-</Text>
                </div>
                <div style={{ width: "10%" }} />
                <div
                  style={{
                    width: "40%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <InputNumber
                    min={1}
                    defaultValue={parseInt(product.qty)}
                    style={{ width: "25%" }}
                  />
                  <InputNumber
                    min={1}
                    defaultValue={1}
                    style={{ width: "25%" }}
                    value={product.selling_price}
                  />
                  <Text style={{ width: "25%", textAlign: "center" }}>
                    {product.rule}
                  </Text>
                  <Button type="link" icon={<DeleteOutlined />} danger>
                    Delete
                  </Button>
                </div>
              </div>
            )}

            {/* Combo Products */}
            {product.combo && (
              <div
                style={{
                  position: "relative",
                  paddingLeft: "30px",
                }}
              >
                {/* Vertical Line */}
                <div
                  style={{
                    position: "absolute",
                    left: "55%",
                    top: "18px",
                    bottom: "10px",
                    width: "2px",
                    backgroundColor: "#000",
                  }}
                />
                {product.combo.length > 0 && (
                  <div
                    style={{
                      position: "absolute",
                      left: "55%",
                      top: "50%",
                      height: "2px",
                      width: "15px",
                      backgroundColor: "#000",
                    }}
                  />
                )}
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ width: "59%" }}>
                    {product.combo.map((combo: any, idx: any) => (
                      <div
                        key={combo.product_id}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom:
                            idx === product?.combo.length - 1 ? "0" : "10px",
                          position: "relative",
                        }}
                      >
                        {idx === 0 && (
                          <div
                            style={{
                              position: "absolute",
                              left: "90%",
                              top: "18px",
                              height: "2px",
                              width: "15px",
                              backgroundColor: "#000",
                            }}
                          />
                        )}
                        {/* Horizontal Line */}
                        {idx === product.combo.length - 1 && (
                          <div
                            style={{
                              position: "absolute",
                              left: "90%",
                              bottom: "10px",
                              height: "2px",
                              width: "15px",
                              backgroundColor: "#000",
                            }}
                          />
                        )}

                        <div
                          style={{
                            width: "80%",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Text style={{ width: "60%" }}>
                            {combo.product_name}
                          </Text>
                          <Text style={{ width: "20%" }}>
                            ${combo.product_price}
                          </Text>
                          <InputNumber
                            min={1}
                            defaultValue={1}
                            style={{ width: "20%" }}
                            value={combo.max_available}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "41%",
                    }}
                  >
                    <InputNumber
                      min={1}
                      defaultValue={1}
                      style={{ width: "25%" }}
                      value={product.qty}
                    />
                    <InputNumber
                      min={1}
                      defaultValue={1}
                      style={{ width: "25%" }}
                      value={product.selling_price}
                    />
                    <Text style={{ width: "25%", textAlign: "center" }}>
                      {product.rule}
                    </Text>
                    <Button type="link" icon={<DeleteOutlined />} danger>
                      Delete
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AssignService;
