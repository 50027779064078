import React, { useEffect, useState } from "react";
import { Upload, Typography, message } from "antd";
import { CameraOutlined, CloseOutlined } from "@ant-design/icons";
import "./ImageUpload.css";

const { Text } = Typography;

const ImageUpload = ({
  prefilledImages = [],
  onImagesChange,
  onDeleteImage,
  showText = true,
}: any) => {
  const [images, setImages] = useState<string[]>([]); // Stores all image URLs (prefilled + new)
  const [newImages, setNewImages] = useState<File[]>([]); // Stores newly uploaded images as File objects

  // Load prefilled images on component mount
  useEffect(() => {
    setImages(Array.isArray(prefilledImages) ? prefilledImages : []);
  }, [prefilledImages]);

  // Handle uploading new images
  const handleUpload = async (fileList: File[]) => {
    const currentImageCount = images.length;
    const remainingSlots = 5 - currentImageCount; // Calculate remaining slots

    // Limit the number of images to the remaining slots
    const allowedFiles = fileList.slice(0, remainingSlots);
    const newImageUrls = allowedFiles.map((file) => URL.createObjectURL(file));

    // Use Array.from to convert Set to an array
    const uniqueNewImages = Array.from(
      new Set([...images, ...newImageUrls])
    ).slice(0, 5);

    // Update state with the unique images
    setImages(uniqueNewImages);
    setNewImages((prev) => [...prev, ...allowedFiles].slice(0, 5));

    // Notify parent about the updated new images
    const updatedNewImages = Array.from(
      new Set([...newImages, ...allowedFiles])
    ).slice(0, 5);
    onImagesChange(updatedNewImages);

    return false; // Prevent default upload behavior
  };

  // Handle removing an image
  const handleRemoveImage = (index: number) => {
    const updatedImages = images.filter((_, imgIndex) => imgIndex !== index);

    if (index < prefilledImages.length) {
      // If the image is prefilled, notify the parent to delete it
      const imageToDelete = prefilledImages[index];
      onDeleteImage([imageToDelete]);
    } else {
      // If the image is newly uploaded, update the state and notify the parent
      const updatedNewImages = newImages.filter(
        (_, imgIndex) => imgIndex !== index - prefilledImages.length
      );
      setNewImages(updatedNewImages);
      onImagesChange(updatedNewImages);
    }

    setImages(updatedImages);
  };

  // Upload properties
  const uploadProps = {
    showUploadList: false,
    beforeUpload: (file: any, fileList: any[]) => {
      handleUpload(fileList); // Call handleUpload for custom behavior
      return false; // Prevent default upload
    },
  };

  return (
    <div className="image-upload-container">
      <div className="upload-grid">
        {/* Display uploaded and prefilled images */}
        {images &&
          images.length > 0 &&
          images.map((imgUrl, index) => (
            <div key={index} className="image-preview">
              <img src={imgUrl} alt={`Uploaded ${index}`} />
              <CloseOutlined
                className="remove-icon"
                onClick={() => handleRemoveImage(index)}
              />
            </div>
          ))}
        {/* Show upload box only if less than 5 images */}
        {images.length < 5 && (
          <Upload {...uploadProps} multiple={true} accept=".jpg,.png">
            <div className="upload-box">
              <CameraOutlined className="upload-icon" />
            </div>
          </Upload>
        )}
      </div>
      {showText && (
        <Text type="secondary">
          Suggested file formats: png, jpg, pixel 750 x 750
        </Text>
      )}
    </div>
  );
};

export default ImageUpload;
