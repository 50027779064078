import axios from "axios";
// const BASE_URL = "http://localhost:9008";
// const BASE_URL = "https://tools.suibox.my";
const BASE_URL = process.env.REACT_APP_API_URL;

async function getHeaders(hasFormData = false) {
  const accessToken = localStorage.getItem("token");
  const headers = {
    "Content-Type": hasFormData ? "multipart/form-data" : "application/json",
    Accept: "application/json",
  };

  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`;
  }

  return { headers };
}

export async function get(url) {
  try {
    const headers = await getHeaders();
    const response = await axios
      .get(BASE_URL + url, headers)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response || error;
      });

    return response;
  } catch (error) {
    return error.response || error;
  }
}

export async function deleteData(url) {
  try {
    const headers = await getHeaders();
    const response = await axios
      .delete(BASE_URL + url, headers)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response || error;
      });
    return response;
  } catch (error) {
    return error.response || error;
  }
}

export async function put(url, body) {
  try {
    const headers = await getHeaders();
    const response = await axios
      .put(BASE_URL + url, body, headers)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response || error;
      });
    return response;
  } catch (error) {
    return error.response || error;
  }
}

export async function patch(url, body) {
  try {
    const headers = await getHeaders();
    const response = await axios
      .patch(BASE_URL + url, body, headers)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response || error;
      });
    return response;
  } catch (error) {
    return error.response || error;
  }
}

export async function post(url, body, hasFormData = false) {
  try {
    const headers = await getHeaders(hasFormData);
    const response = await axios
      .post(BASE_URL + url, body, headers)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response || error;
      });
    return response;
  } catch (error) {
    return error.response || error;
  }
}

export async function uploadImg(url, body) {
  try {
    const headers = await getHeaders(true);
    const response = await axios
      .post(BASE_URL + url, body, headers)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response || error;
      });
    return response;
  } catch (error) {
    return error.response || error;
  }
}
