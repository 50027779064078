import React, { useState } from 'react';
import { Modal, Table, Select, Input, Button, Row, Col } from 'antd';

const { Option } = Select;

interface PrivilegeExcPriceProps {
    visible: boolean;
    onCancel: () => void;
    onSave: (selectedItems: any) => void;
}

const PrivilegeExcPrice: React.FC<PrivilegeExcPriceProps> = ({ visible, onCancel, onSave }) => {
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);  // State to store selected row keys

    // Sample data for table
    const data = [
        {
            key: '1',
            code: '241017003',
            name: 'Silver VIP',
            price: '$699',
        },
        {
            key: '2',
            code: 'GC-202410170001',
            name: 'RM10 Voucher',
            price: '$0',
        },
        {
            key: '3',
            code: 'PKG-202410170003',
            name: 'Cleanser Buy 10 free 2',
            price: '$1990',
        },
        {
            key: '4',
            code: 'PKG-202410190003',
            name: 'ghjghj',
            price: '$446',
        },
    ];

    const columns = [
        { title: 'Code', dataIndex: 'code', key: 'code' },
        { title: 'Name', dataIndex: 'name', key: 'name' },
        { title: 'Price', dataIndex: 'price', key: 'price' },
    ];

    // Handle row selection
    const onSelectChange = (selectedKeys: any[]) => {
        setSelectedRowKeys(selectedKeys);  // Update selected row keys
    };

    const handleSave = () => {
        const selectedItems = data.filter(item => selectedRowKeys.includes(item.key));  // Get selected items
        onSave(selectedItems);  // Pass selected items to parent component
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,  // Hook up the selection change to state
    };

    return (
        <Modal
            title="Select Privilege"
            visible={visible}
            onCancel={onCancel}
            onOk={handleSave}  // Call handleSave when Save button is clicked
            okText="Save"
            cancelText="Cancel"
            width={900}
        >
            <Row gutter={[16, 16]} style={{ marginBottom: '16px' }}>
                <Col span={8}>
                    <Select placeholder="All categories" style={{ width: '100%' }} dropdownStyle={{ textAlign: "center" }}>
                        <Option value="category1">Category 1</Option>
                        <Option value="category2">Category 2</Option>
                    </Select>
                </Col>
                <Col span={16}>
                    <Input placeholder="Search by privilege name / code" />
                </Col>
            </Row>

            <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                rowSelection={rowSelection}  // Add row selection functionality
                style={{ marginTop: '16px' }}
                scroll={{ y: 240 }}
            />

            <div style={{ marginTop: '16px' }}>
                <p>Selected: {selectedRowKeys.length} items</p>
            </div>
        </Modal>
    );
};

export default PrivilegeExcPrice;
