import { Button, Col, Row, Select, Typography, Tag, message } from "antd";
import React, { useEffect, useState } from "react";
import ProductModel from "../../pages/stock/ProductModel";
import { get } from "../../services/Apiclient";
const { Text, Link } = Typography;
const { Option } = Select;

interface outletData {
  key: React.Key;
  id: string;
  name: string;
}
export default function DuplicateDistributeModel() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState<any[]>([]);
  const [outletData, setOutletData] = useState<any[]>([]);
  const [outletCode, setoutletCode] = useState("");
  const [selectedProductNames, setSelectedProductNames] = useState<string[]>(
    []
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSaveProducts = (selectedData: any) => {
    const productNames = selectedData.map(
      (product: any) => product.productname
    );

    // Filter out any products already selected to avoid duplicates
    const newSelectedProducts = selectedData.filter(
      (product: any) =>
        !selectedProducts.some((p) => p.productname === product.productname)
    );

    setSelectedProducts([...selectedProducts, ...newSelectedProducts]);
    setSelectedProductNames([...selectedProductNames, ...productNames]);

    setIsModalVisible(false);
  };

  // const getOutletDropdown = async () => {
  //   try {
  //     let url = "/outlet/dropdown";
  //     let response = await get(url);

  //     if (response && response.data) {
  //       if (response.data.success) {
  //         setOutletData(response.data.data);
  //       } else {
  //         console.log("Error in Api call: ", response.data.message);
  //       }
  //     } else {
  //       console.log("Response not found");
  //     }
  //   } catch (errors: any) {
  //     errors.inner.forEach((error: any) => {
  //       message.error(error.message);
  //     });
  //   }
  // };

  const getOutletDropdown = async () => {
    try {
      let url = `/category/dropdown/type/${localStorage.getItem(
        "outlet_id"
      )}/Service`;
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          let data = response.data.data;
          data.unshift({
            id: "null",
            name: "Select ",
          });
          setOutletData(response.data.data);
          setoutletCode("null");
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  const handleOutletChange = (value: string) => {
    setoutletCode(value);
  };

  useEffect(() => {
    getOutletDropdown();
  }, []);

  return (
    <>
      <Row justify="center" style={{ marginBottom: 24 }}>
        <Col>
          <Text type="warning">
            Note: The current copied types will replace the existing settings of
            the target store. Please proceed with caution.
          </Text>
        </Col>
      </Row>

      {/* Duplicate From */}
      <Row
        style={{ marginBottom: 24, display: "flex", flexDirection: "column" }}
      >
        <Col span={24}>
          <Text strong>Duplicate from</Text>
        </Col>
        <Col span={24} style={{ marginTop: 8 }}>
          {/* <Select
            placeholder="Select source"
            style={{ width: "232px", height: "40px" }}
          >
            <Option value="alvi">suilady</Option>
            <Option value="alvi">alvi</Option>
            <Option value="alvi">abc store</Option>
          </Select> */}

          <Select
            placeholder="Select Outlet"
            value={outletCode}
            onChange={(value) => handleOutletChange(value)}
            className="dropdown"
            style={{ width: "198.8px", height: "40px", alignItems: "center" }}
          >
            {outletData.length > 0 &&
              outletData.map((outdata: any) => (
                <Option value={outdata.id} key={outdata.id}>
                  {outdata.name}
                </Option>
              ))}
          </Select>
        </Col>
      </Row>

      {/* Distribute To */}
      <Row
        style={{ marginBottom: 24, display: "flex", flexDirection: "column" }}
      >
        <Col span={24}>
          <Text strong>Distribute to</Text>
        </Col>
        <Col span={24} style={{ marginTop: 8 }}>
          <Button
            type="link"
            style={{
              fontSize: "14px",
              padding: "0",
              boxShadow: "none",
              color: "#2e56f2",
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              fontWeight: "400",
            }}
          >
            Select the target store
          </Button>
        </Col>
      </Row>

      {/* Sharing Settings */}
      <Row
        style={{ marginBottom: 24, display: "flex", flexDirection: "column" }}
      >
        <Col span={24}>
          <Text strong>Sharing settings</Text>
        </Col>
        <Col span={24} style={{ marginTop: 8 }}>
          <Button
            type="link"
            onClick={() => showModal()}
            style={{
              fontSize: "14px",
              padding: "0",
              boxShadow: "none",
              color: "#2e56f2",
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              fontWeight: "400",
            }}
          >
            Select the target setting
          </Button>
        </Col>
      </Row>

      {/* Display Selected Products as Chips */}
      <Row
        style={{ marginBottom: 24, display: "flex", flexDirection: "column" }}
      >
        <Col span={24}>
          {selectedProductNames &&
            selectedProductNames.length > 0 &&
            selectedProductNames.map((productName, index) => (
              <Tag
                key={index}
                closable
                onClose={() => {
                  // Remove the product name from the selectedProductNames state
                  setSelectedProductNames(
                    selectedProductNames.filter((name) => name !== productName)
                  );

                  // Remove the corresponding product from selectedProducts using a unique identifier
                  setSelectedProducts(
                    selectedProducts.filter(
                      (product) => product.productname !== productName
                    )
                  );
                }}
              >
                {productName}
              </Tag>
            ))}
        </Col>
      </Row>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "12px",
          marginTop: "40px",
          border: "1px solid #D9D9D9",
          paddingTop: "10px",
          paddingBottom: "10px",
        }}
      >
        <Button onClick={handleCancel}>Cancel</Button>
        <Button type="primary" style={{ marginRight: "24px" }}>
          Save
        </Button>
      </div>

      <ProductModel
        title="Product"
        visible={isModalVisible}
        onCancel={handleCancel}
        onSave={handleSaveProducts}
        selectedProducts={selectedProducts}
      />
    </>
  );
}
