import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Button,
  Row,
  Col,
  Select,
  Divider,
  Input,
  message,
  Typography,
} from "antd";
import dayjs, { Dayjs } from "dayjs";
import RefNumModal from "./RefNumModal";
import SingleDateSelectionModal from "../SingleDateSelectionModal";
import {
  CalendarOutlined,
  DeleteFilled,
  RightOutlined,
} from "@ant-design/icons";
import ReferrerModal from "../customer/ReferrerModal";

const { Option } = Select;
const { Text } = Typography;

interface BillingInfoModelProps {
  visible: boolean;
  selectedData: any;
  customerSources: any;
  onCancel: () => void;
  onSubmit: (values: any) => void;
}

const BillingInfoModel: React.FC<BillingInfoModelProps> = ({
  visible,
  selectedData,
  customerSources,
  onCancel,
  onSubmit,
}) => {
  const [form] = Form.useForm();
  const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());
  const [refNum, setRefNum] = useState("");
  const [customerSource, setCustomerSource] = useState("");
  const [referralText, setReferralText] = useState("");
  const [referralId, setReferralId] = useState("null");
  const [referralType, setReferralType] = useState("");

  const [showRefNumModal, setShowRefNumModal] = useState(false);
  const [showSingleDateSelectionModal, setShowSingleDateSelectionModal] =
    useState(false);
  const [isReferralModalVisible, setIsReferralModalVisible] = useState(false);

  // allowClear={{ clearIcon: <CloseOutlined /> }}

  const handleSubmit = () => {
    let object = {
      selectedDate: selectedDate,
      refNum: refNum,
      selectedReferrer: referralText,
      selectedReferrerId: referralId,
      selectedCustomerSource: customerSource,
      selectedReferralType: referralType,
    };
    onSubmit(object);
  };

  const handleDateSelected = (date: Dayjs) => {
    let futuredate = dayjs(date).isAfter(dayjs());
    if (!futuredate) {
      setSelectedDate(date);
    } else {
      message.error(
        "Affected by parameter settings - bill date does not allow selection of future dates."
      );
    }
  };

  const handleSingleDateSelectionClose = () => {
    setShowSingleDateSelectionModal(false);
  };

  const handleRefNumSelection = () => {
    setShowRefNumModal(true);
  };

  const resetReferral = () => {
    setReferralText("");
    setReferralId("null");
  };

  const handleRefSelection = (refnum: any) => {
    setShowRefNumModal(false);
    setRefNum(refnum);
  };

  const handleCustomerSourceChange = (value: string) => {
    setCustomerSource(value);
  };

  const handleReferrerSelect = (selectedObject: any) => {
    setReferralText(selectedObject.name);
    setReferralId(selectedObject.id);
    setReferralType(selectedObject.type);
  };

  useEffect(() => {
    setSelectedDate(selectedData.selectedDate || dayjs());
    setRefNum(selectedData.selectedRef);
    setCustomerSource(selectedData.selectedCustomerSource);
    setReferralText(selectedData.selectedReferrer);
    setReferralId(selectedData.selectedReferrerId);
    setReferralType(selectedData.selectedReferralType);
  }, [visible]);
  return (
    <>
      <Modal
        title="Bill info"
        visible={visible}
        onCancel={onCancel}
        footer={[
          <>
            <Divider style={{ margin: "10px 0px" }} />
            <Button key="save" type="primary" onClick={handleSubmit}>
              Confirm
            </Button>
          </>,
        ]}
        style={{ top: "10px" }}
        bodyStyle={{
          height: "calc(100vh - 170px)",
          overflowY: "auto",
          overflowX: "hidden",
          scrollbarWidth: "thin",
        }}
        width={400}
      >
        <div>
          <Row>
            <Col span={24}>
              <Text
                style={{
                  backgroundColor: "transparent",
                  marginRight: "10px",
                  width: "60px",
                }}
              >
                Date:
              </Text>
              <Input
                style={{ cursor: "pointer" }}
                className="billing-input"
                value={selectedDate.format("DD/MM/YYYY")}
                readOnly={true}
                onClick={() => setShowSingleDateSelectionModal(true)}
                suffix={
                  <CalendarOutlined
                    onClick={() => setShowSingleDateSelectionModal(true)}
                  />
                }
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Text
                style={{
                  backgroundColor: "transparent",
                  marginRight: "10px",
                  width: "60px",
                }}
              >
                Ref#:
              </Text>
              <Input
                style={{ cursor: "pointer" }}
                className="billing-input"
                value={refNum}
                readOnly={true}
                onClick={handleRefNumSelection}
                suffix={<RightOutlined onClick={handleRefNumSelection} />}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Text
                style={{
                  backgroundColor: "transparent",
                  marginRight: "10px",
                  width: "60px",
                }}
              >
                Customer source:
              </Text>
              <Select
                placeholder="Please select category"
                value={customerSource}
                onChange={(value) => handleCustomerSourceChange(value)}
                style={{ width: "100%" }}
                dropdownStyle={{ textAlign: "center" }}
              >
                {customerSources &&
                  customerSources.length > 0 &&
                  customerSources.map((cate: any) => (
                    <Option value={cate.id} key={cate.id}>
                      {cate.customersourcetype}
                    </Option>
                  ))}
              </Select>
            </Col>
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <Col span={24}>
              <Text
                style={{
                  backgroundColor: "transparent",
                  marginRight: "10px",
                  width: "60px",
                }}
              >
                Referrer:
              </Text>
              <Input
                style={{ cursor: "pointer" }}
                className="billing-input"
                value={referralText}
                allowClear={{
                  clearIcon: <DeleteFilled onClick={resetReferral} />,
                }}
                onClick={() => setIsReferralModalVisible(true)}
                suffix={
                  <RightOutlined
                    onClick={() => setIsReferralModalVisible(true)}
                  />
                }
              />
            </Col>
          </Row>
        </div>
      </Modal>
      <RefNumModal
        visible={showRefNumModal}
        onClose={() => {
          setShowRefNumModal(false);
        }}
        onSelect={handleRefSelection}
      />
      <SingleDateSelectionModal
        visible={showSingleDateSelectionModal}
        initialSelectedDate={selectedDate}
        onDateSelected={handleDateSelected}
        onCancel={handleSingleDateSelectionClose}
      />
      <ReferrerModal
        visible={isReferralModalVisible}
        onClose={() => setIsReferralModalVisible(false)}
        onSelect={handleReferrerSelect}
      ></ReferrerModal>
    </>
  );
};

export default BillingInfoModel;
