import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Switch,
  Radio,
  Button,
  Row,
  Col,
  Select,
  Tabs,
  DatePicker,
  InputNumber,
  Alert,
  Table,
  Tooltip,
  Divider,
  message,
} from "antd";
import dayjs, { Dayjs } from "dayjs";
import "../../../pages/membership/Membership.css";
import { get, post } from "../../../services/Apiclient";
import { InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import SelectCategoryModal from "./SelectCategoryModal";
import SelectProductItemModal from "./SelectProductItemModal";
import SelectServiceItemModal from "./SelectServiceItemModal";
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const { TextArea } = Input;

interface GiftcardsModelProps {
  visible: boolean;
  onClose: () => void;
  onSelect: () => void;
}

const GiftcardsModel: React.FC<GiftcardsModelProps> = ({
  visible,
  onClose,
  onSelect,
}) => {
  const validationSchema = Yup.object().shape({
    giftcardcode: Yup.string().required("Please enter Gift card code"),
    cardname: Yup.string().required("Please enter gift name"),
    sellingprice: Yup.string().required("Please enter selling price"),
    Value: Yup.string().required("Please enter value"),
    validstartdate: Yup.lazy((value, options) => {
      return formValues.typeofvalidity === "Custom"
        ? Yup.string().required("Please fill in valid start date")
        : Yup.string();
    }),
    validenddate: Yup.lazy((value, options) => {
      return formValues.typeofvalidity === "Custom"
        ? Yup.string().required("Please fill in valid end date")
        : Yup.string();
    }),
    validcount: Yup.string().required("please fill in Valid validcount"),
    singleusageqty: Yup.lazy((value, options) => {
      return formValues.typeofvalidity === "Immediately"
        ? Yup.string().required("Please fill in single usage qty")
        : Yup.string();
    }),
  });

  const initialFormValues = {
    cardname: "",
    status: true,
    giftcardcode: "",
    typeofgiftcard: "Value",
    Value: 0,
    singleusageqty: 1,
    tax: null,
    sellingprice: 0,
    validstartdate: "",
    validenddate: "",
    sellingstartdate: "",
    sellingenddate: "",
    sellingperiodstartdate: "",
    sellingperiodenddate: "",
    typeofvalidity: "Custom",
    validcount: 10,
    validcountperiod: "Year",
    loyaltyType: null,
    loyaltyPoints: 0,
    note: "",
    service_applicable: true,
    allservice_applicable: true,
    servicecategory_applicable: false,
    serviceitem_applicable: false,
    servicenot_applicable: false,
    product_applicable: true,
    allproduct_applicable: true,
    productcategory_applicable: false,
    productitem_applicable: false,
    productnot_applicable: false,
    privilege_applicable: true,
    allprivilege_applicable: true,
    privilegeitem_applicable: false,
    privilegenot_applicable: false,
    store_applicable: true,
    allstore_applicable: true,
    bystore_applicable: false,
    currentstore_applicable: false,
    service_categories: [],
    product_categories: [],
    services: [],
    products: [],
    privileges: [],
    storeid: [],
    outlet_id: localStorage.getItem("outlet_id"),
  };

  const [formValues, setFormValues] = useState(initialFormValues);
  const [taxData, setTaxData] = useState([]);
  const [loyaltyPointData, setLoyaltyPointData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [isLimitedUsage, setIsLimitedUsage] = useState(true);
  const [isLimitedUsageVisitbased, isSetIsLimitedUsage] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showProductCategoryModal, setShowProductCategoryModal] =
    useState(false);
  const [showServiceCategoryModal, setshowServiceCategoryModal] =
    useState(false);
  const [showProductItemModal, setShowProductItemModal] = useState(false);
  const [showServiceItemModal, setShowServiceItemModal] = useState(false);
  const [showPrivilegesItemModal, setShowPrivilegesItemModal] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [selectedServices, setSelectedServices] = useState<any[]>([]);
  const [serviceCategoriesList, setServiceCategoriesList] = useState([]);
  const [productCategoriesList, setProductCategoriesList] = useState([]);
  const [selectedService, setSelectedService] = useState([]);
  const [activeTab, setActiveTab] = useState("Service");
  const [serviceApplicable, SetServiceApplicable] = useState("All Service");
  const [productApplicable, setProductApplicable] = useState("All Products");
  const [privilegeApplicable, setPrivilegeApplicable] =
    useState("All Privileges");
  const [storeApplicable, setStoreApplicable] = useState("All stores");
  const navigate = useNavigate();
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setSelectedType("");
  };

  const handleSaveServiceCategories = (categories: any) => {
    const formattedCategories = categories.map((cat: any) => ({
      categoryid: cat.id,
      categoryname: cat.name,
    }));
    setFormValues((prev) => ({
      ...prev,
      service_categories: formattedCategories,
    }));
  };

  const handleSaveProductCategories = (categories: any) => {
    const formattedCategories = categories.map((cat: any) => ({
      categoryid: cat.id,
      categoryname: cat.name,
    }));
    setFormValues((prev) => ({
      ...prev,
      product_categories: formattedCategories,
    }));
  };

  const getServiceCategoryList = async () => {
    try {
      let url = `/category/getlistwithoutpagination/${localStorage.getItem(
        "outlet_id"
      )}/Service`;
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          setServiceCategoriesList(response.data.data);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  const getProductCategoryList = async () => {
    try {
      let url = `/category/getlistwithoutpagination/${localStorage.getItem(
        "outlet_id"
      )}/Product`;
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          setProductCategoriesList(response.data.data);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  const handleSaveServices = (selectedData: any) => {
    const transformedArray = selectedData.map((item: any) => ({
      key: item.key,
      serviceid: item.id,
      servicecode: item.servicecode,
      servicename: item.servicename,
      price: item.costprice,
    }));
    setFormValues((prev) => ({
      ...prev,
      services: transformedArray,
    }));
  };

  const handleSaveProducts = (selectedData: any) => {
    const transformedArray = selectedData.map((item: any) => ({
      key: item.key,
      productid: item.productid,
      productcode: item.productcode,
      servicename: item.productname,
      price: item.price,
      categoryid: item.categoryid,
      supplierid: item.supplierid,
    }));
    setFormValues((prev) => ({
      ...prev,
      products: selectedData,
    }));
  };

  const handleSwitchChange = (checked: any) => {
    setIsDisabled(checked);
  };
  const handleSubmit = async () => {
    try {
      await validationSchema.validate(formValues, { abortEarly: false });

      if (formValues) {
        let body = {
          giftcard_code: formValues.giftcardcode,
          giftcard_name: formValues.cardname,
          typeof_giftcard: formValues.typeofgiftcard,
          usage_status: formValues.status,
          typeof_validity: formValues.typeofvalidity,
          valid_startdate: formValues.validstartdate,
          valid_enddate: formValues.validenddate,
          selling_startdate: formValues.sellingstartdate,
          selling_enddate: formValues.sellingenddate,
          valid_count: formValues.validcount,
          valid_countperiod: formValues.validcountperiod,
          sellingperiod_startdate: formValues.sellingstartdate,
          sellingperiod_enddate: formValues.sellingenddate,
          loyalty_pointtype: formValues.loyaltyType,
          loyalty_pointvalue: formValues.loyaltyPoints,
          note: formValues.note,
          sellingprice: formValues.sellingprice,
          value: formValues.Value,
          singleusageqty: formValues.singleusageqty,
          tax: formValues.tax,
          service_applicable: formValues.service_applicable,
          allservice_applicable: formValues.allservice_applicable,
          servicecategory_applicable: formValues.servicecategory_applicable,
          serviceitem_applicable: formValues.serviceitem_applicable,
          servicenot_applicable: formValues.servicenot_applicable,
          product_applicable: formValues.product_applicable,
          allproduct_applicable: formValues.allproduct_applicable,
          productcategory_applicable: formValues.productcategory_applicable,
          productitem_applicable: formValues.productitem_applicable,
          productnot_applicable: formValues.productnot_applicable,
          privilege_applicable: formValues.privilege_applicable,
          allprivilege_applicable: formValues.allprivilege_applicable,
          privilegeitem_applicable: formValues.privilegeitem_applicable,
          privilegenot_applicable: formValues.privilegenot_applicable,
          store_applicable: formValues.store_applicable,
          allstore_applicable: formValues.allstore_applicable,
          bystore_applicable: formValues.bystore_applicable,
          currentstore_applicable: formValues.currentstore_applicable,
          service_categories: formValues.service_categories,
          product_categories: formValues.product_categories,
          services: formValues.services,
          products: formValues.products,
          privileges: formValues.privileges,
          storeid: formValues.storeid,
          outlet_id: formValues.outlet_id,
        };

        let url = "/giftcard/addgiftcard";

        const response = await post(url, body);

        if (response && response.data) {
          if (response.data.success) {
            message.success("Saved successfully");
            await setFormValues(initialFormValues);
            onClose();
            onSelect();
          } else {
            console.log("Error in api call: ", response.data.message);
          }
        } else {
          console.log("Response not found.");
        }
      }
    } catch (errors: any) {
      console.log(errors, "errors");
      // Handle validation errors
      if (errors instanceof Yup.ValidationError) {
        const firstError = errors.inner[0];
        if (firstError) {
          message.error(firstError.message);
        }
      }
    }
  };

  const handleInputChange = (name: any, value: any) => {
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleTabChange = (key: any) => {
    setActiveTab(key);
    if (key == "Service") {
      setFormValues((prevValues) => ({
        ...prevValues,
        service_applicable: true,
        allservice_applicable: true,
        servicecategory_applicable: false,
        serviceitem_applicable: false,
        servicenot_applicable: false,
        product_applicable: true,
        allproduct_applicable: false,
        productcategory_applicable: false,
        productitem_applicable: false,
        productnot_applicable: false,
        privilege_applicable: true,
        allprivilege_applicable: false,
        privilegeitem_applicable: false,
        privilegenot_applicable: false,
        store_applicable: true,
        allstore_applicable: false,
        bystore_applicable: false,
        currentstore_applicable: false,
      }));
      SetServiceApplicable("All Service");
    } else if (key == "Product") {
      setFormValues((prevValues) => ({
        ...prevValues,
        service_applicable: true,
        allservice_applicable: false,
        servicecategory_applicable: false,
        serviceitem_applicable: false,
        servicenot_applicable: false,
        product_applicable: true,
        allproduct_applicable: true,
        productcategory_applicable: false,
        productitem_applicable: false,
        productnot_applicable: false,
        privilege_applicable: true,
        allprivilege_applicable: false,
        privilegeitem_applicable: false,
        privilegenot_applicable: false,
        store_applicable: true,
        allstore_applicable: false,
        bystore_applicable: false,
        currentstore_applicable: false,
      }));
      setProductApplicable("All Products");
    } else if (key == "Privilege") {
      setFormValues((prevValues) => ({
        ...prevValues,
        service_applicable: true,
        allservice_applicable: false,
        servicecategory_applicable: false,
        serviceitem_applicable: false,
        servicenot_applicable: false,
        product_applicable: true,
        allproduct_applicable: true,
        productcategory_applicable: false,
        productitem_applicable: false,
        productnot_applicable: false,
        privilege_applicable: true,
        allprivilege_applicable: false,
        privilegeitem_applicable: false,
        privilegenot_applicable: false,
        store_applicable: true,
        allstore_applicable: false,
        bystore_applicable: false,
        currentstore_applicable: false,
      }));
      setPrivilegeApplicable("All Privileges");
    } else if (key == "Store") {
      setFormValues((prevValues) => ({
        ...prevValues,
        service_applicable: true,
        allservice_applicable: false,
        servicecategory_applicable: false,
        serviceitem_applicable: false,
        servicenot_applicable: false,
        product_applicable: true,
        allproduct_applicable: false,
        productcategory_applicable: false,
        productitem_applicable: false,
        productnot_applicable: false,
        privilege_applicable: true,
        allprivilege_applicable: false,
        privilegeitem_applicable: false,
        privilegenot_applicable: false,
        store_applicable: true,
        allstore_applicable: true,
        bystore_applicable: false,
        currentstore_applicable: false,
      }));
      setStoreApplicable("All stores");
    }
  };

  const serviceApplicableChange = (e: any) => {
    if (e.target.value == "All Service") {
      setFormValues((prevValues) => ({
        ...prevValues,
        allservice_applicable: true,
        servicecategory_applicable: false,
        serviceitem_applicable: false,
        servicenot_applicable: false,
      }));
    } else if (e.target.value == "By Category") {
      setFormValues((prevValues) => ({
        ...prevValues,
        allservice_applicable: false,
        servicecategory_applicable: true,
        serviceitem_applicable: false,
        servicenot_applicable: false,
      }));
    } else if (e.target.value == "By Item") {
      setFormValues((prevValues) => ({
        ...prevValues,
        allservice_applicable: false,
        servicecategory_applicable: false,
        serviceitem_applicable: true,
        servicenot_applicable: false,
      }));
    } else if (e.target.value == "Not Applicable") {
      setFormValues((prevValues) => ({
        ...prevValues,
        allservice_applicable: false,
        servicecategory_applicable: false,
        serviceitem_applicable: false,
        servicenot_applicable: true,
      }));
    }
    SetServiceApplicable(e.target.value);
  };

  const productApplicableChange = (e: any) => {
    if (e.target.value == "All Products") {
      setFormValues((prevValues) => ({
        ...prevValues,
        allproduct_applicable: true,
        productcategory_applicable: false,
        productitem_applicable: false,
        productnot_applicable: false,
      }));
    } else if (e.target.value == "By Category") {
      setFormValues((prevValues) => ({
        ...prevValues,
        allproduct_applicable: false,
        productcategory_applicable: true,
        productitem_applicable: false,
        productnot_applicable: false,
      }));
    } else if (e.target.value == "By Item") {
      setFormValues((prevValues) => ({
        ...prevValues,
        allproduct_applicable: false,
        productcategory_applicable: false,
        productitem_applicable: true,
        productnot_applicable: false,
      }));
    } else if (e.target.value == "Not Applicable") {
      setFormValues((prevValues) => ({
        ...prevValues,
        allproduct_applicable: false,
        productcategory_applicable: false,
        productitem_applicable: false,
        productnot_applicable: true,
      }));
    }
    setProductApplicable(e.target.value);
  };

  const privilegesApplicableChange = (e: any) => {
    if (e.target.value == "All Privileges") {
      setFormValues((prevValues) => ({
        ...prevValues,
        allprivilege_applicable: true,
        privilegeitem_applicable: false,
        privilegenot_applicable: false,
      }));
    } else if (e.target.value == "By Item") {
      setFormValues((prevValues) => ({
        ...prevValues,
        allprivilege_applicable: false,
        privilegeitem_applicable: true,
        privilegenot_applicable: false,
      }));
    } else if (e.target.value == "Not Applicable") {
      setFormValues((prevValues) => ({
        ...prevValues,
        allprivilege_applicable: false,
        privilegeitem_applicable: false,
        privilegenot_applicable: true,
      }));
    }
    setPrivilegeApplicable(e.target.value);
  };

  const storeApplicableChange = (e: any) => {
    if (e.target.value == "All stores") {
      setFormValues((prevValues) => ({
        ...prevValues,
        allstore_applicable: true,
        bystore_applicable: false,
        currentstore_applicable: false,
      }));
    } else if (e.target.value == "Current store only") {
      setFormValues((prevValues) => ({
        ...prevValues,
        allstore_applicable: false,
        bystore_applicable: false,
        currentstore_applicable: true,
      }));
    } else if (e.target.value == "By Store") {
      setFormValues((prevValues) => ({
        ...prevValues,
        allstore_applicable: false,
        bystore_applicable: true,
        currentstore_applicable: false,
      }));
    }
    setStoreApplicable(e.target.value);
  };

  const generateGiftcardsCode = async () => {
    try {
      const response = await get(`/user/randomgenerated`);
      if (response?.data?.success) {
        const data = response.data.result;
        handleInputChange("giftcardcode", "GC-" + data);
      }
    } catch (error) {
      console.log(error);
      message.error("Failed to load room details");
    }
  };

  const getTaxDropdown = async () => {
    try {
      let url = `/tax/dropdown/${localStorage.getItem("outlet_id")}`;
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          setTaxData(response.data.data);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  const getLoyaltypointDropdown = async () => {
    try {
      let url = "/loyaltypoint/dropdown";
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          setLoyaltyPointData(response.data.data);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  // handle require lable
  const requiredLabel = (label: any) => (
    <span>
      <span style={{ color: "red" }}>* </span>
      {label}
    </span>
  );

  const cancleGiftcard = () => {
    setFormValues(initialFormValues);
    onClose();
  };

  useEffect(() => {
    getTaxDropdown();
    getLoyaltypointDropdown();
    getServiceCategoryList();
    getProductCategoryList();
  }, []);

  useEffect(() => {
    SetServiceApplicable("All Service");
    setProductApplicable("All Products");
    setPrivilegeApplicable("All Privileges");
    setStoreApplicable("All stores");
    setActiveTab("Service");
    setFormValues(initialFormValues);
  }, [visible]);

  const { TabPane } = Tabs;
  const data: any = [];
  return (
    <>
      <Modal
        title="Add New Gift Card"
        visible={visible}
        onCancel={cancleGiftcard}
        footer={[
          <>
            <Divider style={{ margin: "10px 0px" }} />
            <Button key="save" type="primary" onClick={handleSubmit}>
              Save
            </Button>
          </>,
        ]}
        style={{ top: "80px" }}
        bodyStyle={{
          height: "calc(100vh - 340px)",
          overflowY: "auto",
          overflowX: "hidden",
          scrollbarWidth: "thin",
        }}
        width={800}
      >
        <Form layout="vertical" onFinish={handleSubmit}>
          <Row gutter={16}>
            <Col span={9}>
              <Form.Item label={null} required>
                {/* <span
                  style={{
                    marginLeft: "25px",
                    color: "#2e56f2",
                    cursor: "pointer",
                  }}
                  onClick={generateGiftcardsCode}
                >
                  Auto Generated
                </span> */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "8px",
                  }}
                >
                  <label style={{ fontWeight: "500" }}>
                    {requiredLabel("Gift card code")}
                  </label>
                  <a
                    onClick={generateGiftcardsCode}
                    style={{ color: "#325df2" }}
                  >
                    Auto Generated
                  </a>
                </div>
                <Input
                  placeholder="Please enter"
                  value={formValues.giftcardcode}
                  onChange={(e) =>
                    handleInputChange("giftcardcode", e.target.value)
                  }
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={requiredLabel("Card name")}>
                <Input
                  placeholder="Please enter"
                  value={formValues.cardname}
                  onChange={(e) =>
                    handleInputChange("cardname", e.target.value)
                  }
                />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                label="Status"
                valuePropName="checked"
                tooltip={{
                  title:
                    "Once the switch is turned off, the gift card will be disabled and cannot be sold.",
                  icon: (
                    <InfoCircleOutlined
                      style={{ color: "rgba(46, 86, 242, .5)" }}
                    />
                  ),
                }}
              >
                <Switch
                  checked={formValues.status}
                  onChange={(value) => handleInputChange("status", value)}
                />
              </Form.Item>
            </Col>
          </Row>

          <div>
            <h3 style={{ display: "flex", alignItems: "center" }}>
              <span className="vertical-line"></span>Type of gift card
            </h3>
          </div>

          <Radio.Group
            value={formValues.typeofgiftcard}
            onChange={(e) =>
              handleInputChange("typeofgiftcard", e.target.value)
            }
          >
            <Radio value="Value">Value</Radio>
            <Radio value="Session">Session</Radio>
          </Radio.Group>
          {/* Prepaid membership type logic (existing) */}

          {/* Prepaid Membership Type */}
          {formValues.typeofgiftcard === "Value" && (
            <div className="visit-based-container">
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item label={requiredLabel("Selling price")}>
                    <Input
                      type="number"
                      placeholder="Please enter"
                      prefix={
                        <span
                          style={{
                            color: "rgba(46, 86, 242, .5)",
                            fontWeight: 700,
                          }}
                        >
                          $
                        </span>
                      }
                      value={formValues.sellingprice}
                      style={{ width: "350px" }}
                      onChange={(e) =>
                        handleInputChange("sellingprice", e.target.value)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Tax">
                    <Select
                      placeholder="No tax (0%)"
                      value={formValues.tax}
                      onChange={(value) => handleInputChange("tax", value)}
                      dropdownStyle={{ textAlign: "center" }}
                    >
                      {taxData.length > 0 &&
                        taxData.map((tax: any) => (
                          <Option key={tax.id} value={tax.id}>
                            {tax.taxname}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item label={requiredLabel("Value")}>
                    <Input
                      type="number"
                      placeholder="Please enter"
                      style={{
                        width: "350px",
                      }}
                      addonBefore={
                        <span
                          style={{
                            color: "rgba(46, 86, 242, .5)",
                            fontWeight: 700,
                          }}
                        >
                          $
                        </span>
                      }
                      value={formValues.Value}
                      onChange={(e) =>
                        handleInputChange("Value", e.target.value)
                      }
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item label="Single usage qty.">
                    <Input
                      type="number"
                      placeholder="Please enter"
                      style={{
                        width: "350px",
                      }}
                      value={formValues.singleusageqty}
                      onChange={(e) =>
                        handleInputChange("singleusageqty", e.target.value)
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          )}

          {/* Conditional Rendering for Discount Type */}
          {formValues.typeofgiftcard === "Session" && (
            <div className="visit-based-container">
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item label={requiredLabel("Selling price")}>
                    <Input
                      type="number"
                      placeholder="Please enter"
                      prefix={
                        <span
                          style={{
                            color: "rgba(46, 86, 242, .5)",
                            fontWeight: 700,
                          }}
                        >
                          $
                        </span>
                      }
                      value={formValues.sellingprice}
                      onChange={(e) =>
                        handleInputChange("sellingprice", e.target.value)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Tax">
                    <Select
                      placeholder="No tax (0%)"
                      value={formValues.tax}
                      onChange={(value) => handleInputChange("tax", value)}
                      dropdownStyle={{ textAlign: "center" }}
                    >
                      {taxData.length > 0 &&
                        taxData.map((tax: any) => (
                          <Option key={tax.id} value={tax.id}>
                            {tax.taxname}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item label="Session" required>
                    <Input
                      placeholder="Please enter"
                      style={{
                        width: "350px",
                      }}
                      disabled={formValues.typeofgiftcard === "Session"}
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item label={requiredLabel("Single usage qty.")}>
                    <Input
                      type="number"
                      placeholder="Please enter"
                      style={{
                        width: "350px",
                        // height: "40px",
                      }}
                      onChange={(e) =>
                        handleInputChange("singleusageqty", e.target.value)
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          )}

          <div>
            <h3 style={{ display: "flex", alignItems: "center" }}>
              <span className="vertical-line"></span>Type of Validity
            </h3>
          </div>

          {/* <Form.Item label="" name="validityType"> */}
          <Radio.Group
            value={formValues.typeofvalidity}
            onChange={(e) =>
              handleInputChange("typeofvalidity", e.target.value)
            }
          >
            <Radio value="Custom">Custom</Radio>
            <Radio value="Immediately">Immediately</Radio>
            <Radio value="EffectiveOnConsumption">
              Effective on consumption day
            </Radio>
          </Radio.Group>
          {/* </Form.Item> */}

          {formValues.typeofvalidity === "Custom" && (
            <div className="validity-section">
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label={requiredLabel("Valid for")}>
                    <RangePicker
                      style={{ width: "100%" }}
                      ranges={{
                        Today: [dayjs().startOf("day"), dayjs().endOf("day")],
                        Yesterday: [
                          dayjs().subtract(1, "day").startOf("day"),
                          dayjs().subtract(1, "day").endOf("day"),
                        ],
                        "This Week": [
                          dayjs().startOf("week"),
                          dayjs().endOf("week"),
                        ],
                        "This Month": [
                          dayjs().startOf("month"),
                          dayjs().endOf("month"),
                        ],
                        "Last Month": [
                          dayjs().subtract(1, "month").startOf("month"),
                          dayjs().subtract(1, "month").endOf("month"),
                        ],
                      }}
                      value={[
                        formValues.validstartdate
                          ? dayjs(formValues.validstartdate)
                          : null,
                        formValues.validenddate
                          ? dayjs(formValues.validenddate)
                          : null,
                      ]}
                      onChange={(dates, dateStrings: [string, string]) => {
                        const [start, end] = dates || [null, null];
                        handleInputChange(
                          "validstartdate",
                          start ? start.format("YYYY-MM-DD") : null
                        );
                        handleInputChange(
                          "validenddate",
                          end ? end.format("YYYY-MM-DD") : null
                        );
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Selling Period">
                    <RangePicker
                      style={{ width: "100%" }}
                      ranges={{
                        Today: [dayjs().startOf("day"), dayjs().endOf("day")],
                        Yesterday: [
                          dayjs().subtract(1, "day").startOf("day"),
                          dayjs().subtract(1, "day").endOf("day"),
                        ],
                        "This Week": [
                          dayjs().startOf("week"),
                          dayjs().endOf("week"),
                        ],
                        "This Month": [
                          dayjs().startOf("month"),
                          dayjs().endOf("month"),
                        ],
                        "Last Month": [
                          dayjs().subtract(1, "month").startOf("month"),
                          dayjs().subtract(1, "month").endOf("month"),
                        ],
                      }}
                      value={[
                        formValues.sellingstartdate
                          ? dayjs(formValues.sellingstartdate)
                          : null,
                        formValues.sellingenddate
                          ? dayjs(formValues.sellingenddate)
                          : null,
                      ]}
                      onChange={(dates, dateStrings: [string, string]) => {
                        const [start, end] = dates || [null, null];
                        handleInputChange(
                          "sellingstartdate",
                          start ? start.format("YYYY-MM-DD") : null
                        );
                        handleInputChange(
                          "sellingenddate",
                          end ? end.format("YYYY-MM-DD") : null
                        );
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          )}

          {formValues.typeofvalidity === "Immediately" && (
            <div className="validity-section">
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item label={requiredLabel("Valid for")}>
                    <Input
                      type="number"
                      placeholder="Please enter"
                      style={{ width: "90%", height: "46px" }}
                      value={formValues.validcount}
                      onChange={(e) =>
                        handleInputChange("validcount", e.target.value)
                      }
                      addonAfter={
                        <Select
                          value={formValues.validcountperiod}
                          onChange={(value) =>
                            handleInputChange("validcountperiod", value)
                          }
                          style={{ width: 80 }}
                        >
                          <Option value="day">day</Option>
                          <Option value="month">month</Option>
                          <Option value="year">year</Option>
                        </Select>
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <Form.Item label="Selling period">
                    <RangePicker
                      style={{ width: "100%" }}
                      ranges={{
                        Today: [dayjs().startOf("day"), dayjs().endOf("day")],
                        Yesterday: [
                          dayjs().subtract(1, "day").startOf("day"),
                          dayjs().subtract(1, "day").endOf("day"),
                        ],
                        "This Week": [
                          dayjs().startOf("week"),
                          dayjs().endOf("week"),
                        ],
                        "This Month": [
                          dayjs().startOf("month"),
                          dayjs().endOf("month"),
                        ],
                        "Last Month": [
                          dayjs().subtract(1, "month").startOf("month"),
                          dayjs().subtract(1, "month").endOf("month"),
                        ],
                      }}
                      value={[
                        formValues.sellingstartdate
                          ? dayjs(formValues.sellingstartdate)
                          : null,
                        formValues.sellingenddate
                          ? dayjs(formValues.sellingenddate)
                          : null,
                      ]}
                      onChange={(dates, dateStrings: [string, string]) => {
                        const [start, end] = dates || [null, null];
                        handleInputChange(
                          "sellingstartdate",
                          start ? start.format("YYYY-MM-DD") : null
                        );
                        handleInputChange(
                          "sellingenddate",
                          end ? end.format("YYYY-MM-DD") : null
                        );
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          )}

          {formValues.typeofvalidity === "EffectiveOnConsumption" && (
            <div className="validity-section">
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    label={requiredLabel("Valid for")}
                    name="validForImmediate"
                  >
                    <Input
                      type="number"
                      placeholder="Please enter"
                      style={{ width: "90%", height: "46px" }}
                      value={formValues.validcount}
                      onChange={(e) =>
                        handleInputChange("validcount", e.target.value)
                      }
                      addonAfter={
                        <Select
                          value={formValues.validcountperiod}
                          onChange={(value) =>
                            handleInputChange("validcountperiod", value)
                          }
                          style={{ width: 80 }}
                        >
                          <Option value="day">day</Option>
                          <Option value="month">month</Option>
                          <Option value="year">year</Option>
                        </Select>
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <Form.Item label="Selling period">
                    <RangePicker
                      style={{ width: "100%" }}
                      ranges={{
                        Today: [dayjs().startOf("day"), dayjs().endOf("day")],
                        Yesterday: [
                          dayjs().subtract(1, "day").startOf("day"),
                          dayjs().subtract(1, "day").endOf("day"),
                        ],
                        "This Week": [
                          dayjs().startOf("week"),
                          dayjs().endOf("week"),
                        ],
                        "This Month": [
                          dayjs().startOf("month"),
                          dayjs().endOf("month"),
                        ],
                        "Last Month": [
                          dayjs().subtract(1, "month").startOf("month"),
                          dayjs().subtract(1, "month").endOf("month"),
                        ],
                      }}
                      value={[
                        formValues.sellingperiodstartdate
                          ? dayjs(formValues.sellingperiodstartdate)
                          : null,
                        formValues.sellingperiodstartdate
                          ? dayjs(formValues.sellingperiodstartdate)
                          : null,
                      ]}
                      onChange={(dates, dateStrings: [string, string]) => {
                        const [start, end] = dates || [null, null];
                        handleInputChange(
                          "sellingperiodstartdate",
                          start ? start.format("YYYY-MM-DD") : null
                        );
                        handleInputChange(
                          "sellingperiodstartdate",
                          end ? end.format("YYYY-MM-DD") : null
                        );
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          )}

          <div>
            <h3 style={{ display: "flex", alignItems: "center" }}>
              <span className="vertical-line"></span>Applicable to
            </h3>
          </div>
          {/* Tabs for Service, Product, Privilege, Store */}
          <Tabs activeKey={activeTab} onChange={handleTabChange}>
            <TabPane
              tab="Service"
              key="Service"
              style={{
                backgroundColor: "#f9f9f9",
                padding: "16px",
                borderRadius: "8px",
              }}
            >
              <span
                style={{
                  color: "orange",
                  marginBottom: "5px",
                  display: "inline-block",
                }}
              >
                <InfoCircleOutlined style={{ marginRight: "8px" }} />
                Switching the selection method will clear the set data
              </span>

              {/* Radio buttons with proper layout */}
              <Radio.Group
                value={serviceApplicable}
                onChange={serviceApplicableChange}
              >
                <Row gutter={[16, 16]}>
                  <Col span={6}>
                    <Radio value="All Service">
                      <span className="radio-text">All services</span>
                    </Radio>
                  </Col>
                  <Col span={6}>
                    <Radio value="By Category">
                      <span className="radio-text">By category</span>
                    </Radio>
                  </Col>
                  <Col span={6}>
                    <Radio value="By Item">
                      <span className="radio-text">By item</span>
                    </Radio>
                  </Col>
                  <Col span={6}>
                    <Radio value="Not Applicable">
                      <span className="radio-text">Not applicable</span>
                    </Radio>
                  </Col>
                </Row>
              </Radio.Group>
              <Row
                justify="start"
                style={{ padding: "0px 0px 0px 10px", marginBottom: "10px" }}
              >
                {formValues.servicecategory_applicable &&
                  formValues.service_categories &&
                  formValues.service_categories.length == 0 && (
                    <Button
                      type="link"
                      onClick={() => setshowServiceCategoryModal(true)}
                      // className="catBtn"
                      style={{
                        fontSize: "14px",
                        boxShadow: "none",
                        color: "#2e56f2",
                        textDecoration: "underline",
                        textUnderlineOffset: "4px",
                        fontWeight: "400",
                      }}
                    >
                      Select Category
                    </Button>
                  )}

                {formValues.servicecategory_applicable &&
                  formValues.service_categories &&
                  formValues.service_categories.length > 0 && (
                    <label style={{ marginTop: "5px" }}>
                      Selected:{" "}
                      <Button
                        type="link"
                        onClick={() => setshowServiceCategoryModal(true)}
                        style={{
                          fontSize: "14px",
                          boxShadow: "none",
                          color: "#2e56f2",
                          fontWeight: "400",
                          padding: "0px",
                        }}
                      >
                        {formValues.service_categories.length}items
                      </Button>
                    </label>
                  )}

                {formValues.serviceitem_applicable &&
                  formValues.services &&
                  formValues.services.length == 0 && (
                    <Button
                      type="link"
                      onClick={() => {
                        setShowServiceItemModal(true);
                      }}
                      style={{
                        fontSize: "14px",
                        boxShadow: "none",
                        color: "#2e56f2",
                        textDecoration: "underline",
                        textUnderlineOffset: "4px",
                        fontWeight: "400",
                      }}
                    >
                      Select item
                    </Button>
                  )}
                {formValues.serviceitem_applicable &&
                  formValues.services &&
                  formValues.services.length > 0 && (
                    <label style={{ marginTop: "5px" }}>
                      Selected:{" "}
                      <Button
                        type="link"
                        onClick={() => {
                          setShowServiceItemModal(true);
                        }}
                        style={{
                          fontSize: "14px",
                          boxShadow: "none",
                          color: "#2e56f2",
                          fontWeight: "400",
                          padding: "0px",
                        }}
                      >
                        {formValues.services.length}items
                      </Button>
                    </label>
                  )}
              </Row>
            </TabPane>

            {/* Placeholder for other tabs */}
            <TabPane
              tab="Product"
              key="Product"
              style={{
                backgroundColor: "#f9f9f9",
                padding: "16px",
                borderRadius: "8px",
              }}
            >
              <span
                style={{
                  color: "orange",
                  marginBottom: "5px",
                  display: "inline-block",
                }}
              >
                <InfoCircleOutlined style={{ marginRight: "8px" }} />
                Switching the selection method will clear the set data
              </span>

              {/* Radio buttons with proper layout */}
              <Radio.Group
                value={productApplicable}
                onChange={productApplicableChange}
              >
                <Row gutter={[16, 16]}>
                  <Col span={6}>
                    <Radio value="All Products">
                      <span className="radio-text">All products</span>
                    </Radio>
                  </Col>
                  <Col span={6}>
                    <Radio value="By Category">
                      <span className="radio-text">By category</span>
                    </Radio>
                  </Col>
                  <Col span={6}>
                    <Radio value="By Item">
                      <span className="radio-text">By item</span>
                    </Radio>
                  </Col>
                  <Col span={6}>
                    <Radio value="Not Applicable">
                      <span className="radio-text">Not applicable</span>
                    </Radio>
                  </Col>
                </Row>
              </Radio.Group>
              <Row
                justify="start"
                style={{ padding: "0px 0px 0px 10px", marginBottom: "10px" }}
              >
                {formValues.productcategory_applicable &&
                  formValues.product_categories &&
                  formValues.product_categories.length == 0 && (
                    <Button
                      type="link"
                      onClick={() => setShowProductCategoryModal(true)}
                      // className="catBtn"
                      style={{
                        fontSize: "14px",
                        boxShadow: "none",
                        color: "#2e56f2",
                        textDecoration: "underline",
                        textUnderlineOffset: "4px",
                        fontWeight: "400",
                      }}
                    >
                      Select Category
                    </Button>
                  )}

                {formValues.productcategory_applicable &&
                  formValues.product_categories &&
                  formValues.product_categories.length > 0 && (
                    <label style={{ marginTop: "5px" }}>
                      Selected:{" "}
                      <Button
                        type="link"
                        onClick={() => setShowProductCategoryModal(true)}
                        style={{
                          fontSize: "14px",
                          boxShadow: "none",
                          color: "#2e56f2",
                          fontWeight: "400",
                          padding: "0px",
                        }}
                      >
                        {formValues.product_categories.length}items
                      </Button>
                    </label>
                  )}

                {formValues.productitem_applicable &&
                  formValues.products &&
                  formValues.products.length == 0 && (
                    <Button
                      type="link"
                      onClick={() => {
                        setShowProductItemModal(true);
                      }}
                      style={{
                        fontSize: "14px",
                        boxShadow: "none",
                        color: "#2e56f2",
                        textDecoration: "underline",
                        textUnderlineOffset: "4px",
                        fontWeight: "400",
                      }}
                    >
                      Select item
                    </Button>
                  )}
                {formValues.productitem_applicable &&
                  formValues.products &&
                  formValues.products.length > 0 && (
                    <label style={{ marginTop: "5px" }}>
                      Selected:{" "}
                      <Button
                        type="link"
                        onClick={() => {
                          setShowProductItemModal(true);
                        }}
                        style={{
                          fontSize: "14px",
                          boxShadow: "none",
                          color: "#2e56f2",
                          fontWeight: "400",
                          padding: "0px",
                        }}
                      >
                        {formValues.products.length}items
                      </Button>
                    </label>
                  )}
              </Row>
            </TabPane>
            <TabPane
              tab="Privilege"
              key="Privilege"
              style={{
                backgroundColor: "#f9f9f9",
                padding: "16px",
                borderRadius: "8px",
              }}
            >
              <span
                style={{
                  color: "orange",
                  marginBottom: "5px",
                  display: "inline-block",
                }}
              >
                <InfoCircleOutlined style={{ marginRight: "8px" }} />
                Switching the selection method will clear the set data
              </span>

              {/* Radio buttons with proper layout */}
              <Radio.Group
                value={privilegeApplicable}
                onChange={privilegesApplicableChange}
              >
                <Row gutter={[16, 16]}>
                  <Col span={8}>
                    <Radio value="All Privileges">
                      <span className="radio-text">All privileges</span>
                    </Radio>
                  </Col>
                  <Col span={8}>
                    <Radio value="By Item">
                      <span className="radio-text">By item</span>
                    </Radio>
                  </Col>
                  <Col span={8}>
                    <Radio value="Not Applicable">
                      <span className="radio-text">Not applicable</span>
                    </Radio>
                  </Col>
                </Row>
              </Radio.Group>
              {/* <Row
                justify="start"
                style={{ padding: "0px 0px 0px 10px", marginBottom: "10px" }}
              >
                {formValues.privilegeitem_applicable &&
                  formValues.privileges &&
                  formValues.privileges.length == 0 && (
                    <Button
                      type="link"
                      onClick={() => showModal()}
                      style={{
                        fontSize: "14px",
                        boxShadow: "none",
                        color: "#2e56f2",
                        textDecoration: "underline",
                        textUnderlineOffset: "4px",
                        fontWeight: "400",
                      }}
                    >
                      Select item
                    </Button>
                  )}
                {formValues.privilegeitem_applicable &&
                  formValues.privileges &&
                  formValues.privileges.length > 0 && (
                    <label style={{ marginTop: "5px" }}>
                      Selected:{" "}
                      <Button
                        type="link"
                        onClick={() => setShowProductCategoryModal(true)}
                        style={{
                          fontSize: "14px",
                          boxShadow: "none",
                          color: "#2e56f2",
                          fontWeight: "400",
                          padding: "0px",
                        }}
                      >
                        {formValues.privileges.length}items
                      </Button>
                    </label>
                  )}
              </Row> */}
            </TabPane>
            <TabPane
              tab="Store"
              key="Store"
              style={{
                backgroundColor: "#f9f9f9",
                padding: "16px",
                borderRadius: "8px",
              }}
            >
              <span
                style={{
                  color: "orange",
                  marginBottom: "5px",
                  display: "inline-block",
                }}
              >
                <InfoCircleOutlined style={{ marginRight: "8px" }} />
                Switching the selection method will clear the set data
              </span>

              <Radio.Group
                value={storeApplicable}
                onChange={storeApplicableChange}
              >
                <Row gutter={[16, 16]}>
                  <Col span={8}>
                    <Radio value="All stores">
                      <span className="radio-text">All stores</span>
                    </Radio>
                  </Col>
                  <Col span={10}>
                    <Radio value="Current store only">
                      <span className="radio-text">Current store only</span>
                    </Radio>
                  </Col>
                  <Col span={6}>
                    <Radio value="By Store">
                      <span className="radio-text">By store</span>
                    </Radio>
                  </Col>
                </Row>
              </Radio.Group>
              {/* <Row
                justify="start"
                style={{ padding: "0px 0px 0px 10px", marginBottom: "10px" }}
              >
                {formValues.bystore_applicable &&
                  formValues.storeid &&
                  formValues.storeid.length == 0 && (
                    <Button
                      type="link"
                      onClick={() => showModal()}
                      style={{
                        fontSize: "14px",
                        boxShadow: "none",
                        color: "#2e56f2",
                        textDecoration: "underline",
                        textUnderlineOffset: "4px",
                        fontWeight: "400",
                      }}
                    >
                      Select store
                    </Button>
                  )}
                {formValues.bystore_applicable &&
                  formValues.storeid &&
                  formValues.storeid.length > 0 && (
                    <label style={{ marginTop: "5px" }}>
                      Selected: {formValues.storeid.length} stores
                    </label>
                  )}
              </Row> */}
            </TabPane>
          </Tabs>

          {/* Loyalty Points Section */}
          <div>
            <h3 style={{ display: "flex", alignItems: "center" }}>
              <span className="vertical-line"></span>Loyalty Points
            </h3>
          </div>
          <Row gutter={16}>
            {/* Dropdown for Loyalty Type */}
            <Col span={8}>
              <Form.Item>
                <Select
                  placeholder="Please select"
                  value={formValues.loyaltyType}
                  onChange={(value) => handleInputChange("loyaltyType", value)}
                  dropdownStyle={{ textAlign: "center" }}
                  style={{ height: "40px", width: "206px" }}
                >
                  {loyaltyPointData.length > 0 &&
                    loyaltyPointData.map((point: any) => (
                      <Option key={point.id} value={point.id}>
                        {point.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>

            {/* Input field for Percentage */}
            <Col span={8}>
              <Form.Item>
                <Input
                  type="number"
                  placeholder="Please enter"
                  style={{
                    width: "206px",
                    height: "40px",
                    // lineHeight: "40px",
                  }}
                  suffix={
                    <span
                      style={{
                        color: "rgba(46, 86, 242, .5)",
                        fontWeight: 700,
                      }}
                    >
                      {formValues.loyaltyType === "fixed" ? "Points" : "%"}
                    </span>
                  }
                  value={formValues.loyaltyPoints}
                  onChange={(e) =>
                    handleInputChange("loyaltyPoints", e.target.value)
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Note Section */}
          <div>
            <h3 style={{ display: "flex", alignItems: "center" }}>
              <span className="vertical-line"></span>Note
            </h3>
          </div>
          <Form.Item name="note">
            <TextArea
              rows={4}
              placeholder="Please enter"
              autoSize={{ minRows: 4 }}
              value={formValues.note}
              onChange={(e) => handleInputChange("note", e.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>

      <SelectCategoryModal
        visible={showServiceCategoryModal}
        onClose={() => setshowServiceCategoryModal(false)}
        onSave={handleSaveServiceCategories}
        selectedCategories={formValues.service_categories}
        categoriesList={serviceCategoriesList}
      />

      <SelectCategoryModal
        visible={showProductCategoryModal}
        onClose={() => setShowProductCategoryModal(false)}
        onSave={handleSaveProductCategories}
        selectedCategories={formValues.product_categories}
        categoriesList={productCategoriesList}
      />

      <SelectProductItemModal
        title="Products"
        visible={showProductItemModal}
        onCancel={() => {
          setShowProductItemModal(false);
        }}
        onSave={handleSaveProducts}
        selectedServices={formValues.products}
        footer={null}
      />

      <SelectServiceItemModal
        title="Services"
        visible={showServiceItemModal}
        onCancel={() => {
          setShowServiceItemModal(false);
        }}
        onSave={handleSaveServices}
        selectedServices={formValues.services}
        footer={null}
      />

      {/* <ServiceByCategoryModel
        title="Services "
        visible={isModalVisible}
        onCancel={handleCancel}
        onSave={handleSaveServices}
        selectedServices={selectedServices}
        footer={null}
      /> */}
    </>
  );
};

export default GiftcardsModel;
