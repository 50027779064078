import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  Select,
  Row,
  Col,
  DatePicker,
  message,
  Dropdown,
  Menu,
  Modal,
  Checkbox,
  Pagination,
  Empty,
  Divider,
} from "antd";
import {
  PlusOutlined,
  ArrowLeftOutlined,
  FilterOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Content } from "antd/es/layout/layout";
import { useNavigate } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs"; // Import Dayjs for date manipulation
import Datatable from "../../components/Datatable";
import { deleteData, get, put } from "../../services/Apiclient";

const { RangePicker } = DatePicker;
const { Option } = Select;

interface StockOutData {
  key: string;
  refNumber: string;
  date: string;
  creationTime: string;
  storage: string;
  stockInMethod: string;
  quantity: number;
  amount: number;
  staff: string;
  status: string;
  action: string;
}

export default function StockOutList() {
  const navigate = useNavigate();
  const [dataSource, setDataSource] = useState<StockOutData[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [todaydate, setTodayDate] = useState<string | null>(
    dayjs().endOf("day").format("YYYY-MM-DD")
  ); // Set today's date as default
  const [prevdate, setPrevdate] = useState<string | null>(
    dayjs().startOf("day").format("YYYY-MM-DD")
  ); // Set today's date as default
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [storage, setStorage] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [originalDataSource, setOriginalDataSource] = useState<any[]>([]);
  const [reviewModalVisible, setReviewModalVisible] = useState(false);
  const [staffData, setStaffData] = useState([]);
  const [outletData, setOutletData] = useState([]);
  const [selectedOutlet, setSelectedOutlet] = useState(
    localStorage.getItem("outlet_id") || null
  );
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [storageOptions, setStorageOptions] = useState([]); // Dynamic storage options
  const [storageDropdownVisible, setStorageDropdownVisible] = useState(false);
  const [selectedStorage, setSelectedStorage] = useState<string[]>([]); // Selected storage
  const [stockOutMethodOptions, setStockOutMethodOptions] = useState<any[]>([]);
  const [methodDropdownVisible, setMethodDropdownVisible] = useState(false);
  const [editingId, setEditingId] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [status, setStatus] = useState<string[]>([]);
  const [method, setMethods] = useState<string[]>([]);

  const handleStorageChange = (checkedValues: any) => {
    setSelectedStorage(checkedValues);
  };

  const applyStorageFilter = () => {
    if (selectedStorage.length === 0) {
      setDataSource(originalDataSource);
    } else {
      const filteredData = originalDataSource.filter((item) =>
        selectedStorage.includes(item.storage)
      );
      setDataSource(filteredData);
    }
    setStorageDropdownVisible(false);
  };

  const resetStorageFilter = () => {
    setSelectedStorage([]);
    setDataSource(originalDataSource);
  };

  const storageFilterMenu = (
    <Menu>
      {storageOptions.length > 0 ? (
        <>
          <Checkbox.Group
            style={{
              padding: "10px",
              display: "flex",
              flexDirection: "column",
            }}
            value={selectedStorage}
            onChange={handleStorageChange}
          >
            {storageOptions.map((storage: any) => (
              <Row key={storage.id} style={{ marginBottom: "8px" }}>
                <Col span={24}>
                  <Checkbox value={storage.type}>{storage.type}</Checkbox>
                </Col>
              </Row>
            ))}
          </Checkbox.Group>
        </>
      ) : (
        <div style={{ textAlign: "center" }}>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={<span>No Filters</span>}
          />
        </div>
      )}
      <Divider style={{ margin: 0 }} />
      <Row justify="space-between" style={{ padding: "10px" }}>
        <Button
          onClick={resetStorageFilter}
          disabled={selectedStorage.length === 0}
        >
          Reset
        </Button>
        <Button type="primary" onClick={applyStorageFilter}>
          OK
        </Button>
      </Row>
    </Menu>
  );

  const filterIconStyle = {
    color: status.length > 0 ? "#1890FF" : "#aaa",
    cursor: "pointer",
    marginLeft: "8px",
  };

  const filterStorageIconStyle = {
    color: selectedStorage.length > 0 ? "#1890FF" : "#aaa",
    cursor: "pointer",
    marginLeft: "8px",
  };

  const filterMethodIconStyle = {
    color: method.length > 0 ? "#1890FF" : "#aaa",
    cursor: "pointer",
    marginLeft: "8px",
  };

  // Set dateRange to today by default, allowing null for reset
  const [dateRange, setDateRange] = useState<[Dayjs, Dayjs] | null>([
    dayjs().startOf("day"),
    dayjs().endOf("day"),
  ]);

  const setToday = () => {
    const today: [Dayjs, Dayjs] = [
      dayjs().startOf("day"),
      dayjs().endOf("day"),
    ];
    setDateRange(today);
    // Update the API date states with today's date
    setTodayDate(dayjs().endOf("day").format("YYYY-MM-DD"));
    setPrevdate(dayjs().startOf("day").format("YYYY-MM-DD"));
  };

  const handleDateChange = (dates: [Dayjs | null, Dayjs | null] | null) => {
    if (dates && dates[0] && dates[1]) {
      setDateRange([dates[0], dates[1]]);
      // Update the API date states when a date range is selected
      setTodayDate(dates[1].format("YYYY-MM-DD"));
      setPrevdate(dates[0].format("YYYY-MM-DD"));
    } else {
      setDateRange([dayjs().startOf("day"), dayjs().endOf("day")]); // Set to default empty range
      // Reset the API date states
      setTodayDate(null);
      setPrevdate(null);
    }
  };

  const handleServiceStatusChange = (checkedValues: any) => {
    setStatus(checkedValues);
  };

  const applyFilter = () => {
    if (status.length === 0) {
      setDataSource(originalDataSource);
    } else {
      const filteredData = originalDataSource.filter((item) =>
        status.includes(item.status)
      );
      setDataSource(filteredData);
    }
    setDropdownVisible(false);
  };
  const resetFilter = () => {
    setStatus([]);
    setDataSource(originalDataSource);
  };

  const serviceStatus = (
    <Menu>
      <Checkbox.Group
        style={{ padding: "10px" }}
        value={status}
        onChange={handleServiceStatusChange}
      >
        <Row>
          <Col span={24}>
            <Checkbox value="Rejected">Rejected</Checkbox>
          </Col>
          <Col span={24}>
            <Checkbox value="Approved">Approved</Checkbox>
          </Col>
          <Col span={24}>
            <Checkbox value="Pending">Pending</Checkbox>
          </Col>
        </Row>
      </Checkbox.Group>
      <Divider style={{ margin: 0 }} />
      <Row justify="space-between" style={{ padding: "10px" }}>
        <Button onClick={resetFilter} disabled={status.length === 0}>
          Reset
        </Button>
        <Button type="primary" onClick={applyFilter}>
          OK
        </Button>
      </Row>
    </Menu>
  );

  const handleStockMethodChange = (checkedValues: any) => {
    setMethods(checkedValues);
  };

  const applyMethodFilter = () => {
    if (method.length === 0) {
      setDataSource(originalDataSource);
    } else {
      const filteredData = originalDataSource.filter((item) =>
        method.includes(item.stockInMethod)
      );
      setDataSource(filteredData);
    }
    setDropdownVisible(false);
  };

  const resetMethodFilter = () => {
    setMethods([]);
    setDataSource(originalDataSource);
  };

  const stockMethods = (
    <Menu>
      {stockOutMethodOptions.length > 0 ? (
        <>
          <Checkbox.Group
            style={{
              padding: "10px",
              display: "flex",
              flexDirection: "column",
            }}
            value={method}
            onChange={handleStockMethodChange}
          >
            {stockOutMethodOptions.map((option: any) => (
              <Row key={option.id} style={{ marginBottom: "8px" }}>
                <Col span={24}>
                  <Checkbox
                    value={option.stockouttype}
                    style={{ width: "100%" }}
                  >
                    {option.stockouttype}
                  </Checkbox>
                </Col>
              </Row>
            ))}
          </Checkbox.Group>
        </>
      ) : (
        <div style={{ textAlign: "center" }}>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={<span>No Filters</span>}
          />
        </div>
      )}
      <Divider style={{ margin: 0 }} />
      <Row justify="space-between" style={{ padding: "10px" }}>
        <Button onClick={resetMethodFilter} disabled={method.length === 0}>
          Reset
        </Button>
        <Button type="primary" onClick={applyMethodFilter}>
          OK
        </Button>
      </Row>
    </Menu>
  );

  const columns = [
    {
      title: "Ref#",
      dataIndex: "refNumber",
      key: "refNumber",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date: string) => dayjs(date).format("DD-MM-YYYY"), // Correct rendering of date
      sorter: (a: StockOutData, b: StockOutData) =>
        dayjs(a.date).unix() - dayjs(b.date).unix(),
      showSorterTooltip: false,
    },
    {
      title: "Creation Time",
      dataIndex: "creationTime",
      key: "creationTime",
      render: (creationTime: string) =>
        dayjs(creationTime).format("DD/MM/YYYY HH:mm:ss"),
    },
    {
      // title: "Storage",
      title: (
        <>
          Storage
          <Dropdown
            overlay={storageFilterMenu}
            trigger={["click"]}
            visible={storageDropdownVisible}
            onVisibleChange={(flag) => setStorageDropdownVisible(flag)}
            overlayStyle={{ width: "150px" }}
          >
            <FilterOutlined style={filterStorageIconStyle} />
          </Dropdown>
        </>
      ),
      dataIndex: "storage",
      key: "storage",
    },
    {
      // title: "Stock Out Method",
      title: (
        <>
          Stock Out Method
          <Dropdown
            overlay={stockMethods}
            trigger={["click"]}
            visible={methodDropdownVisible}
            onVisibleChange={(flag) => setMethodDropdownVisible(flag)}
            overlayStyle={{ width: "150px" }}
          >
            <FilterOutlined style={filterMethodIconStyle} />
          </Dropdown>
        </>
      ),
      dataIndex: "stockInMethod",
      key: "stockInMethod",
    },
    {
      title: "Qty.",
      dataIndex: "quantity",
      key: "quantity",
      render: (quantity: number) => <span>{quantity}</span>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount: number) => <span>${amount.toFixed(2)}</span>,
    },
    {
      title: "Staff",
      dataIndex: "staff",
      key: "staff",
    },
    {
      title: (
        <>
          Status
          <Dropdown
            overlay={serviceStatus}
            trigger={["click"]}
            visible={dropdownVisible}
            onVisibleChange={(flag) => setDropdownVisible(flag)}
            overlayStyle={{ width: "150px" }} // Adjust size here
          >
            <FilterOutlined style={filterIconStyle} />
          </Dropdown>
        </>
      ),
      dataIndex: "status",
      key: "status",
      className: "column-spacing",
      render: (status: string) => (
        <div className={`status-tag ${status}`}>
          <span className={`status-dot`}></span>
          <span className="status-text">{status}</span>
        </div>
      ),
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right" as "right",
      className: "column-spacing",
      render: (text: any, record: StockOutData): React.ReactNode => (
        <>
          {record.status == "Pending" && (
            <a
              style={{
                color: "#2e56f2",
                textDecoration: "underline",
                fontWeight: 700,
                marginRight: "16px",
                textUnderlineOffset: "4px",
              }}
              onClick={() => openReviewModal(record.key)}
            >
              Review
            </a>
          )}
          <a
            style={{
              color: "#2e56f2",
              textDecoration: "underline",
              fontWeight: 700,
              marginRight: "16px",
              textUnderlineOffset: "4px",
            }}
            onClick={() =>
              navigate(`/inventory/stockoutdetails/view/${record.key}`)
            }
          >
            View
          </a>
        </>
      ),
    },
  ];

  const openReviewModal = (id: any) => {
    setReviewModalVisible(true);
    setEditingId(id);
  };

  const onCancel = () => {
    setReviewModalVisible(false);
  };

  const onReviewReject = async () => {
    let payload = {
      status: "Rejected",
    };
    await put(`/stock/statuschange/${editingId}`, payload);
    fetchData();
    setReviewModalVisible(false);
  };

  const onReviewApproved = async () => {
    let payload = {
      status: "Approved",
    };
    await put(`/stock/statuschange/${editingId}`, payload);
    fetchData();
    setReviewModalVisible(false);
  };

  const fetchData = async () => {
    try {
      const url = `/stock/stockoutlist/created_at/DESC/${currentPage}/${pageSize}/${selectedStaff}/${selectedOutlet}/${searchText}?startDate=${prevdate}&endDate=${todaydate}`;
      const response = await get(url);
      if (response?.data?.success) {
        const apiData = response.data.data.map((item: any) => ({
          key: item.id,
          refNumber: item.ref_no,
          date: item.stock_out_date,
          creationTime: item.created_at,
          storage: item.storage_name,
          stockInMethod: item.stockout_type,
          methodofcollection: item.method_collection,
          quantity: item.total_qty,
          amount: item.total_amount,
          staff: item.staff_name,
          status: item.status,
        }));
        setDataSource(apiData);
        setOriginalDataSource(apiData);
        setTotalRecords(response.data.totalRecords);
      } else {
        console.log("Error in API call: ", response);
      }
    } catch (error) {
      console.error("Fetch error: ", error);
    }
  };

  const fetchStockOutMethods = async () => {
    const response = await get(
      `/stockout/dropdown/${localStorage.getItem("outlet_id")}`
    ); // Adjust endpoint as needed
    if (response?.data?.success) {
      setStockOutMethodOptions(response.data.data);
    }
  };

  useEffect(() => {
    fetchData();
  }, [
    selectedOutlet,
    selectedStaff,
    currentPage,
    pageSize,
    todaydate,
    prevdate,
  ]);

  const handlePageChange = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    if (pageSize) {
      setPageSize(pageSize);
    }
  };

  const getStorageDropdown = async () => {
    try {
      let url = `/storage/dropdown/${localStorage.getItem("outlet_id")}`;
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          setStorageOptions(response.data.data);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  const getOutletDropdown = async () => {
    try {
      let url = "/outlet/dropdown";
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          let data = response.data.data;
          data.unshift({
            id: null,
            name: "All Store",
          });
          setOutletData(data);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  const getStaffDropdown = async () => {
    try {
      let url = `/staff/dropdown/${localStorage.getItem("outlet_id")}`;
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          let data = response.data.data;
          data.unshift({
            id: null,
            name: "All Staff",
          });
          setStaffData(data);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  useEffect(() => {
    getStorageDropdown();
    getStaffDropdown();
    getOutletDropdown();
    fetchStockOutMethods();
  }, []);

  return (
    <Content className="mainContainer">
      {/* Header Section */}
      <Row justify="space-between" align="middle">
        <Col>
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            <div
              onClick={() => navigate("/inventory")}
              style={{ cursor: "pointer" }}
            >
              <ArrowLeftOutlined
                style={{ marginRight: 12, color: "#325DF2", fontSize: 18 }}
              />
            </div>
            <h2 style={{ margin: 0 }}>Product Stock Out</h2>
          </div>
        </Col>
        <Col>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button
              style={{ marginRight: "8px" }}
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => navigate("/inventory/stockout/Add")}
              className="add-button"
            >
              Add
            </Button>
          </div>
        </Col>
      </Row>

      {/* Content Section */}
      <Content
        style={{
          marginTop: "10px",
          backgroundColor: "#ffffff",
          borderRadius: "12px",
          padding: "24px 24px 10px",
          border: "1px solid #e0e7fe",
          boxSizing: "border-box",
        }}
      >
        {/* Filters */}
        <Row gutter={16} style={{ marginBottom: "16px" }}>
          <Col>
            <Select
              placeholder="Select Service"
              value={selectedOutlet}
              onChange={(value) => {
                setSelectedOutlet(value);
              }}
              style={{ width: "198px", height: "40px !important" }}
            >
              {outletData.length > 0 &&
                outletData.map((point: any) => (
                  <Option key={point.id} value={point.id}>
                    {point.name}
                  </Option>
                ))}
            </Select>
          </Col>
          <Col>
            <Select
              placeholder="All"
              value={selectedStaff}
              onChange={(value) => {
                setSelectedStaff(value);
              }}
              style={{ width: "198px", height: "40px !important" }}
            >
              {staffData.length > 0 &&
                staffData.map((point: any) => (
                  <Option key={point.id} value={point.id}>
                    {point.name}
                  </Option>
                ))}
            </Select>
          </Col>
          <Col>
            <Input
              placeholder="Ref#"
              style={{ width: "198px", height: "40px !important" }}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              allowClear
            />
          </Col>
        </Row>

        {/* Date Picker Row */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "16px",
          }}
        >
          <div>
            <Row gutter={16}>
              <Col>
                <RangePicker
                  style={{ width: "348px", height: "42px" }}
                  value={dateRange} // Use the dateRange state for the value
                  onChange={handleDateChange} // Handle date range change
                  ranges={{
                    Today: [dayjs().startOf("day"), dayjs().endOf("day")],
                    Yesterday: [
                      dayjs().subtract(1, "day").startOf("day"),
                      dayjs().subtract(1, "day").endOf("day"),
                    ],
                    "This Week": [
                      dayjs().startOf("week"),
                      dayjs().endOf("week"),
                    ],
                    "This Month": [
                      dayjs().startOf("month"),
                      dayjs().endOf("month"),
                    ],
                    "Last Month": [
                      dayjs().subtract(1, "month").startOf("month"),
                      dayjs().subtract(1, "month").endOf("month"),
                    ],
                  }}
                />
              </Col>
              <Col>
                <Button
                  style={{
                    width: "60px",
                    height: "40px",
                    lineHeight: "40px",
                    cursor: "pointer",
                    background: "rgba(46, 86, 242, .1)",
                    borderRadius: "6px 6px 6px 6px",
                    color: "#2e56f2",
                  }}
                  type="primary"
                  onClick={setToday}
                >
                  Today
                </Button>
              </Col>
            </Row>
          </div>
          <div>
            <Button type="primary">Search</Button>
          </div>
        </div>

        {/* Table */}
        <Datatable<StockOutData>
          columns={columns}
          dataSource={dataSource}
          // currentPage={currentPage}
          // pageSize={pageSize}
          // totalRecords={totalRecords}
          // onPageChange={handlePageChange}
          scroll={{ y: "calc(100vh - 330px)" }}
          showPagination={false}
          dynamicTableHeight="calc(100vh - 290px)"
        />
        {totalRecords && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "16px",
            }}
          >
            {/* Display total items */}
            <div
              style={{
                marginLeft: "auto",
                marginRight: "16px",
                fontSize: "12px",
                fontWeight: "500",
                color: "#555",
              }}
            >
              Total {totalRecords || 0} items
            </div>

            {/* Pagination component */}
            <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={totalRecords}
              onChange={handlePageChange}
              showSizeChanger={false}
              itemRender={(page, type, originalElement) => {
                if (type === "prev") {
                  return (
                    <a
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        fontSize: "12px",
                        fontWeight: "700",
                        color: currentPage === 1 ? "#bbb" : "#2e56f2",
                        pointerEvents: currentPage === 1 ? "none" : "auto",
                      }}
                    >
                      <LeftOutlined />
                      <span>Previous</span>
                    </a>
                  );
                }
                if (type === "next") {
                  const lastPage = Math.ceil((totalRecords || 0) / pageSize!);
                  return (
                    <a
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        fontSize: "12px",
                        fontWeight: "700",
                        color: currentPage === lastPage ? "#bbb" : "#2e56f2",
                        pointerEvents:
                          currentPage === lastPage ? "none" : "auto",
                      }}
                    >
                      <span>Next</span>
                      <RightOutlined />
                    </a>
                  );
                }
                return originalElement;
              }}
            />
          </div>
        )}
      </Content>
      <Modal
        title={
          <p
            className="modal-message"
            style={{ fontWeight: "700", lineHeight: "22px", fontSize: "16px" }}
          >
            Stock out review
          </p>
        }
        visible={reviewModalVisible}
        footer={null}
        onCancel={onCancel}
        centered
        width={370}
        bodyStyle={{ textAlign: "center" }}
        maskClosable={false}
        closable={true}
        className="stock-review-modal"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "start",
            flexDirection: "column",
            padding: "20px 12px",
          }}
        >
          <p
            className="modal-message"
            style={{ textAlign: "justify", fontSize: "14px", color: "#999" }}
          >
            Please review the inventory details below and take appropriate
            action
          </p>
        </div>
        <div className="modal-divider"></div>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            padding: "12px",
          }}
        >
          <div>
            <Button
              className="cancel-button"
              type="default"
              onClick={onCancel}
              style={{
                color: "rgb(51,51,51)",
                borderColor: "rgb(224,231,254)",
                fontSize: "14px",
                fontWeight: "500",
                height: "32px",
                padding: "5px 12px",
              }}
            >
              Cancel
            </Button>
          </div>
          <div>
            <Button
              className="cancel-button"
              type="default"
              onClick={onReviewReject}
              style={{
                color: "rgb(224,0,24)",
                borderColor: "rgb(224,0,24)",
                fontSize: "14px",
                fontWeight: "500",
                height: "32px",
                padding: "5px 12px",
                marginRight: "12px",
              }}
            >
              Reject
            </Button>
            <Button
              className="confirm-button"
              type="default"
              onClick={onReviewApproved}
              style={{
                color: "rgb(255,255,255)",
                background: "rgb(46,86,242)",
                fontSize: "14px",
                fontWeight: "500",
                padding: "5px 12px",
                height: "32px",
              }}
            >
              Approve
            </Button>
          </div>
        </div>
      </Modal>
    </Content>
  );
}
