import React, { useEffect, useMemo, useState } from "react";
import { Modal, Button, Calendar, Radio } from "antd";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  LeftOutlined,
  RightOutlined,
  StepBackwardOutlined,
  StepForwardOutlined,
} from "@ant-design/icons";
import { CalendarMode } from "antd/es/calendar/generateCalendar";
import dayjs, { Dayjs } from "dayjs";

interface SingleDateSelectionModalProps {
  visible: boolean;
  initialSelectedDate?: Dayjs;
  onDateSelected: (selectedDate: Dayjs) => void;
  onCancel: () => void;
}

const SingleDateSelectionModal: React.FC<SingleDateSelectionModalProps> = ({
  visible,
  initialSelectedDate = dayjs(),
  onDateSelected,
  onCancel,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [mode, setMode] = useState<any>("date"); // Default mode to month
  const [currentYear, setCurrentYear] = useState(dayjs().year());
  const [startYear, setStartYear] = useState(dayjs().year() - 4);
  const [lastYear, setLastYear] = useState(dayjs().year() + 4);
  const [selectedDate, setSelectedDate] = useState<Dayjs>(initialSelectedDate);

  useEffect(() => {
    setCurrentYear(selectedDate.year()); // Update currentYear whenever selectedDate changes
  }, [selectedDate]);

  useEffect(() => {
    setSelectedDate(initialSelectedDate);
  }, [visible]);

  useEffect(() => {
    setCurrentYear(selectedDate.year()); // Update currentYear whenever selectedDate changes
  }, [selectedDate]);

  const handleOk = () => {
    onDateSelected(selectedDate);
    onCancel();
  };

  const handleCancel = () => {
    onCancel();
  };

  const onPanelChange = (value: Dayjs, newMode: CalendarMode) => {
    if (newMode === "month") {
      setMode("month");
    } else if (newMode === "year") {
      setMode("year");
    }
  };

  const handleMonthSelect = (value: Dayjs) => {
    setSelectedDate(selectedDate.month(value.month())); // Update only the month of the selected date
    setMode("date"); // Switch back to date mode
  };

  const handleModeChange = (e: any) => {
    const newMode = e.target.value;
    if (newMode === "custom") {
      setMode("custom");
      setStartYear(selectedDate.year() - 6); // Center the year range when switching to "year" mode
    } else {
      setMode(newMode);
    }
  };

  const years = useMemo(() => {
    return Array.from({ length: 12 }, (_, i) => startYear + i);
  }, [startYear, lastYear]);

  const renderYearGrid = () => {
    return (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(4, 1fr)",
          gap: "10px",
          padding: "20px",
        }}
      >
        {years.map((year) => (
          <Button
            key={year}
            type={year === currentYear ? "primary" : "default"}
            onClick={() => {
              setCurrentYear(year);
              setSelectedDate(selectedDate.set("year", year));
              setMode("date");
            }}
          >
            {year}
          </Button>
        ))}
      </div>
    );
  };

  const handleMonthPrevious = () => {
    setSelectedDate(selectedDate.subtract(1, "month"));
  };

  const handleYearPrevious = () => {
    if (selectedDate.year() === startYear) {
      setStartYear(selectedDate.year() - 12);
      setLastYear(selectedDate.year());
    }
    setSelectedDate(selectedDate.subtract(1, "year"));
  };

  const handleMonthNext = () => {
    setSelectedDate(selectedDate.add(1, "month"));
  };

  const handleYearNext = () => {
    if (selectedDate.year() === lastYear - 1) {
      setStartYear(selectedDate.year() + 1);
      setLastYear(selectedDate.year() + 12);
    }
    setSelectedDate(selectedDate.add(1, "year"));
  };

  return (
    <>
      <Modal
        title="Date selection"
        className="single-date-selection"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="confirm" type="primary" onClick={handleOk}>
            Confirm
          </Button>,
        ]}
        style={{ top: "10px" }}
        bodyStyle={{ height: "calc(100vh - 150px)" }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "8px",
            marginBottom: "16px",
          }}
        >
          <Button
            icon={<StepBackwardOutlined />}
            type="link"
            onClick={handleYearPrevious}
          />
          <Button
            icon={<ArrowLeftOutlined />}
            type="link"
            onClick={handleMonthPrevious}
          />
          <span style={{ fontWeight: "bold" }}>
            {selectedDate.format("DD/MM/YYYY")}
          </span>
          <Button
            icon={<ArrowRightOutlined />}
            type="link"
            onClick={handleMonthNext}
          />
          <Button
            icon={<StepForwardOutlined />}
            type="link"
            onClick={handleYearNext}
          />
          <Radio.Group
            value={mode}
            onChange={handleModeChange}
            className="tab-select-radio"
            style={{
              marginLeft: "16px",
              background: "#f4f4f4",
              padding: "4px",
              borderRadius: "4px",
            }}
          >
            <Radio.Button value="date">Day</Radio.Button>
            <Radio.Button value="year">Month</Radio.Button>
            <Radio.Button value="custom">Year</Radio.Button>
          </Radio.Group>
        </div>
        {mode === "custom" ? (
          <>{renderYearGrid()}</>
        ) : (
          <Calendar
            fullscreen={false}
            mode={mode}
            value={selectedDate}
            onSelect={(date) => {
              setSelectedDate(date);
              if (mode === "year") {
                setMode("date");
              }
            }}
            onPanelChange={onPanelChange}
            className="single-selected-date"
            headerRender={() => {
              return <></>;
            }}
          />
        )}
      </Modal>
    </>
  );
};

export default SingleDateSelectionModal;
