import React, { useEffect, useState } from "react";
import { Modal, Input, Typography, Button, Row, Col } from "antd";

const { Title, Text } = Typography;

interface SignatureModelProps {
  visible: boolean;
  onClose: () => void;
  onConfirm: (selectedObject: any) => void;
  maxValue: string;
  type: string;
}

const SplitAmountModal: React.FC<SignatureModelProps> = ({
  visible,
  onClose,
  onConfirm,
  maxValue,
  type,
}) => {
  const [inputValue, setInputValue] = useState("");
  const MAX_AMOUNT = Number(maxValue);

  const handleButtonClick = (value: string) => {
    if (value === "Clear") {
      setInputValue("");
    } else if (value === "Backspace") {
      setInputValue(inputValue.slice(0, -1));
    } else {
      const [integer, decimal] = inputValue.split(".");
      if (!decimal || decimal.length < 2) {
        let newValue = inputValue + value;
        if (parseFloat(newValue) > MAX_AMOUNT) {
          newValue = MAX_AMOUNT.toString();
        }

        setInputValue(newValue);
      }
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = e.target.value;

    if (/[^0-9.]/.test(newValue)) {
      return;
    }

    if (newValue.indexOf(".") !== newValue.lastIndexOf(".")) {
      return;
    }

    const [integer, decimal] = newValue.split(".");
    if (decimal && decimal.length > 2) {
      return;
    }

    if (parseFloat(newValue) > MAX_AMOUNT) {
      newValue = MAX_AMOUNT.toString();
    }

    setInputValue(newValue);
  };

  const handleEnter = () => {
    if (inputValue == "") {
      return;
    }
    if (Number(inputValue) == Number(MAX_AMOUNT) && type == "outer") {
      return;
    }
    onConfirm(inputValue);
  };

  useEffect(() => {
    setInputValue("");
  }, [visible]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleEnter();
    }
  };

  return (
    <Modal title="Split" visible={visible} onCancel={onClose} footer={null}>
      <Input
        placeholder="Please enter the split amount"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        style={{ marginBottom: 16, textAlign: "center", fontSize: 16 }}
      />
      <Text>
        Maximum splittable amount:{" "}
        <Text type="danger">$ {Number(MAX_AMOUNT)}</Text>
      </Text>
      <div style={{ marginTop: 16 }}>
        <Row gutter={[12, 12]}>
          <Col span={20}>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "12px" }}
            >
              <Row gutter={[12, 12]}>
                <Col span={8}>
                  <Button
                    block
                    onClick={() => handleButtonClick("1")}
                    style={{ height: 50, borderRadius: "0px" }}
                  >
                    1
                  </Button>
                </Col>
                <Col span={8}>
                  <Button
                    block
                    onClick={() => handleButtonClick("2")}
                    style={{ height: 50, borderRadius: "0px" }}
                  >
                    2
                  </Button>
                </Col>
                <Col span={8}>
                  <Button
                    block
                    onClick={() => handleButtonClick("3")}
                    style={{ height: 50, borderRadius: "0px" }}
                  >
                    3
                  </Button>
                </Col>
              </Row>
              <Row gutter={[12, 12]}>
                <Col span={8}>
                  <Button
                    block
                    onClick={() => handleButtonClick("4")}
                    style={{ height: 50, borderRadius: "0px" }}
                  >
                    4
                  </Button>
                </Col>
                <Col span={8}>
                  <Button
                    block
                    onClick={() => handleButtonClick("5")}
                    style={{ height: 50, borderRadius: "0px" }}
                  >
                    5
                  </Button>
                </Col>
                <Col span={8}>
                  <Button
                    block
                    onClick={() => handleButtonClick("6")}
                    style={{ height: 50, borderRadius: "0px" }}
                  >
                    6
                  </Button>
                </Col>
              </Row>
              <Row gutter={[12, 12]}>
                <Col span={8}>
                  <Button
                    block
                    onClick={() => handleButtonClick("7")}
                    style={{ height: 50, borderRadius: "0px" }}
                  >
                    7
                  </Button>
                </Col>
                <Col span={8}>
                  <Button
                    block
                    onClick={() => handleButtonClick("8")}
                    style={{ height: 50, borderRadius: "0px" }}
                  >
                    8
                  </Button>
                </Col>
                <Col span={8}>
                  <Button
                    block
                    onClick={() => handleButtonClick("9")}
                    style={{ height: 50, borderRadius: "0px" }}
                  >
                    9
                  </Button>
                </Col>
              </Row>
              <Row gutter={[12, 12]}>
                <Col span={8}>
                  <Button
                    block
                    onClick={() => handleButtonClick(".")}
                    style={{ height: 50, borderRadius: "0px" }}
                  >
                    .
                  </Button>
                </Col>
                <Col span={8}>
                  <Button
                    block
                    onClick={() => handleButtonClick("0")}
                    style={{ height: 50, borderRadius: "0px" }}
                  >
                    0
                  </Button>
                </Col>
                <Col span={8}>
                  <Button
                    block
                    onClick={() => handleButtonClick("Backspace")}
                    style={{ height: 50, borderRadius: "0px" }}
                  >
                    ⌫
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={4}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "12px",
                height: "100%",
              }}
            >
              <Button
                block
                onClick={() => handleButtonClick("Clear")}
                style={{ height: "50%", borderRadius: "0px" }}
              >
                Clear
              </Button>
              <Button
                block
                type="primary"
                onClick={handleEnter}
                style={{ height: "50%", borderRadius: "0px" }}
              >
                Enter
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default SplitAmountModal;
