import React, { useState } from "react";
import {
  Button,
  Input,
  Space,
  Popconfirm,
  Row,
  Col,
  Modal,
  message,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import Datatable from "../../components/Datatable";
import SupplierForm from "./SupplierForm";
import { get, deleteData, put } from "../../services/Apiclient";
import ConfirmDeleteModal from "../../components/model/DeleteModal";

interface Supplier {
  id: string;
  key: string;
  number: number;
  supplierId: string;
  supplierName: string;
  contact: string;
  phone: string;
  email: string;
  physicalAddress: string;
  street: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}

interface SupplierListProps {
  onCancel: () => void;
  suppliers: Supplier[];
  setSuppliers: React.Dispatch<React.SetStateAction<Supplier[]>>;
  currentPage: number;
  pageSize: number;
  totalRecords: number;
  onPageChange: (page: number) => void;
  onSearch: (value: string) => void;
  fetchSupplierData: () => Promise<void>;
}

const SupplierList: React.FC<SupplierListProps> = ({
  suppliers,
  setSuppliers,
  currentPage,
  pageSize,
  totalRecords,
  onPageChange,
  onSearch,
  fetchSupplierData,
}) => {
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editingSupplier, setEditingSupplier] = useState<Supplier | null>(null);
  const [formValues, setFormValues] = useState<any>(null);

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState<Supplier | null>(
    null
  );

  // Function to show the modal for adding a new supplier
  const showAddSupplierModal = () => {
    setEditingSupplier(null); // Clear the editing supplier
    setFormValues(null); // Clear form values
    setIsEditModalVisible(true); // Show the modal
  };

  const handleDeleteClick = (record: Supplier) => {
    setSelectedSupplier(record); // Set the room that is selected for deletion
    setDeleteModalVisible(true); // Show the delete modal
  };

  const handleCancelDelete = () => {
    setDeleteModalVisible(false); // Close the modal without deleting
  };

  // Function to edit an existing supplier and fetch data by ID
  const editSupplier = async (id: string) => {
    try {
      const response = await get(`/supplier/${id}`); // Fetch supplier by ID
      if (response?.data?.success) {
        setFormValues(response.data.data); // Populate form with supplier data
        setEditingSupplier(response.data.data); // Set full supplier data for tracking
        setIsEditModalVisible(true); // Show the modal
      } else {
        message.error("Failed to fetch supplier data.");
      }
    } catch (error) {
      message.error("API Error.");
      console.error("API Error: ", error);
    }
  };

  const handleConfirmDelete = async () => {
    if (selectedSupplier) {
      try {
        const url = `/supplier/remove/${selectedSupplier.key}`;
        const response = await deleteData(url);

        if (response?.status === 200 || response?.status === 204) {
          // Assuming the room was successfully deleted
          message.success("Supplier deleted successfully.");
          setSuppliers(
            suppliers.filter(
              (supplier) => supplier.key !== selectedSupplier.key
            )
          );
          // Update the dataSource to remove the deleted room from the table
          // setDataSource((prevData) => prevData.filter((item) => item.key !== selectedRoom.key));

          // Close the delete modal
          setDeleteModalVisible(false);
        } else {
          console.error("Error deleting room:", response);
          // Handle the error scenario here (e.g., show a notification or error message)
        }
      } catch (error) {
        console.error("Error during delete operation:", error);
      }
    }
  };

  // Function to call the API and delete the supplier
  const deleteSupplier = async (id: string) => {
    try {
      const response = await deleteData(`/supplier/remove/${id}`);
      if (response?.data?.success) {
        message.success("Supplier deleted successfully.");
        setSuppliers(suppliers.filter((supplier) => supplier.key !== id));
      } else {
        message.error("Failed to delete supplier.");
      }
    } catch (error) {
      message.error("API Error.");
    }
  };

  // Function to confirm deletion of a supplier
  const confirmDelete = (supplierId: string) => {
    deleteSupplier(supplierId);
  };

  // Table columns configuration
  const columns = [
    {
      title: "Number",
      dataIndex: "number",
      key: "number",
      width: 100,
    },
    {
      title: "Supplier Id",
      dataIndex: "supplierId",
      key: "supplierId",
      width: 100,
    },
    {
      title: "Supplier Name",
      dataIndex: "supplierName",
      key: "supplierName",
      width: 100,
    },
    {
      title: "Contact",
      dataIndex: "contact",
      key: "contact",
      width: 100,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      width: 100,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 100,
    },
    // {
    //   title: "Action",
    //   key: "operation",
    //   fixed: "right" as "right",
    //   width: 100,
    //   render: (text: any, record: Supplier) => (
    //     <Space size="middle">
    //       <Button
    //         icon={<EditOutlined />}
    //         onClick={() => editSupplier(record.key)} // Use record.key or record.id if available
    //       />
    //       <Popconfirm
    //         title="Are you sure to delete this supplier?"
    //         onConfirm={() => confirmDelete(record.key)}
    //       >
    //         <Button icon={<DeleteOutlined />} />
    //       </Popconfirm>
    //     </Space>
    //   ),
    // },
    {
      title: "Action",
      key: "operation",
      fixed: "right" as "right",
      width: 100,
      render: (text: any, record: Supplier) => (
        <>
          {" "}
          <a
            style={{
              color: "#2e56f2",
              textDecoration: "underline",
              fontWeight: 700,
              marginRight: "16px",
              textUnderlineOffset: "4px",
            }}
            onClick={() => editSupplier(record.key)}
          >
            Edit
          </a>
          <a
            style={{
              color: "rgb(245, 63, 63)",
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
            }}
            onClick={() => handleDeleteClick(record)}
          >
            Delete
          </a>
        </>
      ),
    },
  ];

  // Handle form submission for add or edit
  const handleEditSubmit = async (values: Supplier) => {
    setIsEditModalVisible(false);
    setEditingSupplier(null);
    fetchSupplierData();
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearch(event.target.value);
  };

  return (
    <>
      <Row justify="space-between" style={{ marginBottom: 16 }}>
        <Col>
          <Input
            placeholder="Search by supplier's name/email/phone"
            prefix={
              <SearchOutlined
                style={{
                  color: "rgba(0,0,0,.45)",
                  marginRight: "2px",
                  alignItems: "center",
                }}
              />
            }
            allowClear
            onChange={handleSearch}
            style={{ width: 350 }}
          />
        </Col>
        <Col>
          <Button
            icon={<PlusOutlined />}
            onClick={showAddSupplierModal}
            type="primary"
            className="add-button"
          >
            Add
          </Button>
        </Col>
      </Row>

      <Datatable<Supplier>
        columns={columns}
        dataSource={suppliers}
        currentPage={currentPage}
        pageSize={pageSize}
        totalRecords={totalRecords}
        onPageChange={onPageChange}
        scroll={{ y: 230 }}
        tableClass="main-bg-table"
      />

      <Modal
        title={editingSupplier ? "Edit Supplier" : "Add New Supplier"}
        open={isEditModalVisible}
        onCancel={() => setIsEditModalVisible(false)}
        footer={null}
      >
        <SupplierForm
          visible={isEditModalVisible}
          onCancel={() => setIsEditModalVisible(false)}
          onSubmit={handleEditSubmit}
          editingValue={formValues}
        />
      </Modal>
      <ConfirmDeleteModal
        visible={deleteModalVisible}
        onCancel={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        message="Delete the Supplier?"
      />
    </>
  );
};

export default SupplierList;
function handleDeleteClick(key: string): void {
  throw new Error("Function not implemented.");
}
