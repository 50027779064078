import React, { useEffect, useState } from "react";
import {
  Tabs,
  Row,
  Col,
  Select,
  DatePicker,
  InputNumber,
  Form,
  Button,
  Input,
  Card,
  Typography,
  Empty,
  Space,
  message,
  Menu,
  Dropdown,
  Avatar,
  MenuProps,
} from "antd";
import {
  SearchOutlined,
  ArrowLeftOutlined,
  EditOutlined,
  CreditCardOutlined,
  DeleteOutlined,
  MoreOutlined,
  MailFilled,
  EyeInvisibleOutlined,
  PhoneFilled,
  UserOutlined,
  ClearOutlined,
  CloseOutlined,
  FileExcelOutlined,
  DownOutlined,
  UpOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Content } from "antd/es/layout/layout";
import { Outlet, useNavigate } from "react-router-dom";
import SelectServiceStaff from "../../components/model/billing/SelectServiceStaff";
import SingleDateSelectionModal from "../../components/model/SingleDateSelectionModal";
import dayjs, { Dayjs } from "dayjs";
import { get, post } from "../../services/Apiclient";
import SelectProductStaff from "../../components/model/billing/SelectProductStaff";
import RefNumModal from "../../components/model/billing/RefNumModal";
import BillingInfoModel from "../../components/model/billing/BillingInfoModel";
import SearchCustomerModel from "../../components/model/billing/SearchCustomerModel";
import ConfirmDeleteModal from "../../components/model/DeleteModal";
import ServiceEditProductEditModel from "../../components/model/billing/ServiceEditProductEditModel";
import ProductEditModel from "../../components/model/billing/ProductEditModel";
import NotesModal from "../../components/model/billing/NotesModal";
import EditCartDiscountModel from "../../components/model/billing/EditCartDiscountModel";
import { nanoid } from "nanoid";
import GiftcardAreaOfApplicationModel from "../../components/model/billing/GiftcardAreaOfApplicationModel";
import GiftcardEditModel from "../../components/model/billing/GiftcardEditModel";

const { TabPane } = Tabs;
const { Option } = Select;
const { Text } = Typography;
const { Search } = Input;

const memberinfo = `${process.env.PUBLIC_URL}/images/logo/Def_Bc_MemberInfo_Empty.png`;
const emptyImageUrl = `${process.env.PUBLIC_URL}/images/logo/Def_Empty_noData.png`;
const emptyIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Empty_noResults.png`;
const maleIcon = `${process.env.PUBLIC_URL}/images/logo/male-icon.png`;
const femaleIcon = `${process.env.PUBLIC_URL}/images/logo/female-icon.png`;
const exchangeIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Exchange.png`;
const sortIcon = `${process.env.PUBLIC_URL}/images/logo/sorticon.png`;
const sortActiveIcon = `${process.env.PUBLIC_URL}/images/logo/sorticon-active.png`;
const imageIcon = `${process.env.PUBLIC_URL}/images/logo/imageicon.png`;
const imageActiveIcon = `${process.env.PUBLIC_URL}/images/logo/imageicon-active.png`;
const emptyImageIcon = `${process.env.PUBLIC_URL}/images/logo/Def_BaseInfo_noCoverImg.png`;
const giftcardImage = `${process.env.PUBLIC_URL}/images/logo/Def_Bc_CardItem_GiftCard.png`;
const giftcardImg = `${process.env.PUBLIC_URL}/images/logo/Def_Img_CardItem_GiftCard.png`;
const BillingPage: React.FC = () => {
  const navigate = useNavigate();
  const [activeMainTab, setActiveMainTab] = useState("Services");
  const [activeSubTab, setActiveSubTab] = useState("All");
  const [activeProductSubTab, setActiveProductSubTab] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");
  const [maleCount, setMaleCount] = useState("1"); //for show male count
  const [femaleCount, setFemaleCount] = useState("0"); //for show female count
  const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());
  const [showSingleDateSelectionModal, setShowSingleDateSelectionModal] =
    useState(false);

  const [isDeleteMemberModalVisible, setIsDeleteMemberModalVisible] =
    useState(false);

  const [isDeleteServiceModalVisible, setIsDeleteServiceModalVisible] =
    useState(false);

  const [isNoteModalVisible, setIsNoteModalVisible] = useState(false);
  const [billInfoModalVisible, setBillInfoModalVisible] = useState(false);

  const [refNum, setRefNum] = useState("");
  const [selectedCustomerSource, setSelectedCustomerSource] = useState("");
  const [selectedReferrer, setSelectedReferrer] = useState("");
  const [selectedReferralType, setSelectedReferralType] = useState("");
  const [selectedReferrerId, setSelectedReferrerId] = useState("null");

  const [serviceCategory, setServiceCategory] = useState<any[]>([]);
  const [selectedServiceTab, setSelectedServiceTab] = useState<any>("null");
  const [selectedProductTab, setSelectedProductTab] = useState<any>("null");
  const [productCategory, setProductCategory] = useState<any[]>([]);

  const [selectedType, setSelectedType] = useState("Product");

  const [productItems, setProductItems] = useState<any[]>([]);
  const [serviceItems, setServiceItems] = useState<any[]>([]);
  const [giftcardsItems, setGiftcardsItems] = useState<any[]>([]);
  const [tempgiftcardItems, setTempGiftcardItems] = useState<any[]>([]);
  const [tempProductItems, setTempProductItems] = useState<any[]>([]);
  const [tempServiceItems, setTempServiceItems] = useState<any[]>([]);
  const [cartItems, setCartItems] = useState<any[]>([]);
  const [taxesItems, setTaxesItems] = useState<any[]>([]);
  const [customerSources, setCustomerSources] = useState<any[]>([]);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [selectedCartItem, setSelectedCartItem] = useState<any>(null);
  const [selectedCustomer, setSelectedCustomer] = useState<any>(null);

  const [discountValue, setDiscountValue] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [discountType, setDiscountType] = useState("%");
  const [cartItemValue, setcartItemValue] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [taxValue, setTaxValue] = useState(0);
  const [taxRate, setTaxRate] = useState(0);

  const [sortServiceCol, setSortServiceCol] = useState("Default");
  const [sortProductCol, setSortProductCol] = useState("Default");

  const [total, setTotal] = useState(0);

  const [isDetailsVisible, setIsDetailsVisible] = useState(false);

  const toggleDetails = () => {
    setIsDetailsVisible(!isDetailsVisible);
  };

  const closeDeleteMemberModal = () => {
    setIsDeleteMemberModalVisible(false);
  };

  const handleConfirmMember = () => {
    setIsDeleteMemberModalVisible(false);
    setSelectedCustomer(null);
  };

  const closeDeleteServiceModal = () => {
    setIsDeleteServiceModalVisible(false);
  };

  const handleConfirmService = () => {
    setIsDeleteServiceModalVisible(false);
    setCartItems(
      cartItems.filter(
        (item) => item.item_unique_id !== selectedItem.item_unique_id
      )
    );
  };

  const handleNoteModal = () => {
    // setIsNoteModalVisible(true);
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <>
          <div>
            <Button
              icon={
                <EditOutlined style={{ marginRight: "4px", color: "blue" }} />
              }
              style={{
                color: "blue",
                backgroundColor: "#eoeoef",
                padding: "4px 8px",
                borderRadius: "4px",
                textDecoration: "none",
                border: "none",
              }}
              onClick={() => {
                setShowDiscountModal(true);
              }}
            >
              Edit cart discount
            </Button>
          </div>
        </>
      ),
    },
    // {
    //   key: "2",
    //   label: (
    //     <>
    //       <FileExcelOutlined style={{ marginRight: "4px", color: "red" }} />
    //       <a
    //         target="_blank"
    //         rel="noopener noreferrer"
    //         href="/"
    //         style={{
    //           color: "red",
    //           // backgroundColor: ,
    //           padding: "4px 8px",
    //           borderRadius: "4px",
    //           textDecoration: "none",
    //         }}
    //       >
    //         Cancel sale
    //       </a>
    //     </>
    //   ),
    // },
  ];

  const handleProductMenuClick = (e: any) => {
    setSortProductCol(e.key);

    let filteredProducts = [...productItems];

    // Filter and sort based on selected menu item
    if (e.key === "priceHighToLow") {
      filteredProducts.sort((a, b) => b.sellprice - a.sellprice);
    } else if (e.key === "priceLowToHigh") {
      filteredProducts.sort((a, b) => a.sellprice - b.sellprice);
    } else if (e.key === "codeASC") {
      filteredProducts.sort((a, b) => a.code.localeCompare(b.code));
    } else if (e.key === "codeDESC") {
      filteredProducts.sort((a, b) => b.code.localeCompare(a.code));
    } else if (e.key === "nameAZ") {
      filteredProducts.sort((a, b) => a.name.localeCompare(b.name));
    } else if (e.key === "nameZA") {
      filteredProducts.sort((a, b) => b.name.localeCompare(a.name));
    } else if (e.key === "createdFirst") {
      filteredProducts.sort((a, b) => {
        const dateA = new Date(b.created_at);
        const dateB = new Date(a.created_at);

        return dateB.getTime() - dateA.getTime();
      });
    } else if (e.key === "createdLast") {
      filteredProducts.sort((a, b) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);

        return dateB.getTime() - dateA.getTime();
      });
    } else if (e.key === "categoryAZ") {
      filteredProducts.sort((a, b) =>
        a.categoryname.localeCompare(b.categoryname)
      );
    } else if (e.key === "categoryZA") {
      filteredProducts.sort((a, b) =>
        b.categoryname.localeCompare(a.categoryname)
      );
    } else {
      filteredProducts = productItems;
    }

    setTempProductItems(filteredProducts);
  };

  const handleServiceMenuClick = (e: any) => {
    setSortServiceCol(e.key);

    let filteredServices = [...serviceItems];

    if (e.key === "priceHighToLow") {
      filteredServices.sort((a, b) => b.sellprice - a.sellprice);
    } else if (e.key === "priceLowToHigh") {
      filteredServices.sort((a, b) => a.sellprice - b.sellprice);
    } else if (e.key === "codeASC") {
      filteredServices.sort((a, b) => a.code.localeCompare(b.code));
    } else if (e.key === "codeDESC") {
      filteredServices.sort((a, b) => b.code.localeCompare(a.code));
    } else if (e.key === "nameAZ") {
      filteredServices.sort((a, b) => a.name.localeCompare(b.name));
    } else if (e.key === "nameZA") {
      filteredServices.sort((a, b) => b.name.localeCompare(a.name));
    } else if (e.key === "createdFirst") {
      filteredServices.sort((a, b) => {
        const dateA = new Date(b.created_at);
        const dateB = new Date(a.created_at);
        return dateB.getTime() - dateA.getTime();
      });
    } else if (e.key === "createdLast") {
      filteredServices.sort((a, b) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);

        return dateB.getTime() - dateA.getTime();
      });
    } else if (e.key === "categoryAZ") {
      filteredServices.sort((a, b) =>
        a.categoryname.localeCompare(b.categoryname)
      );
    } else if (e.key === "categoryZA") {
      filteredServices.sort((a, b) =>
        b.categoryname.localeCompare(a.categoryname)
      );
    } else {
      filteredServices = serviceItems;
    }

    setTempServiceItems(filteredServices);
  };

  const productMenu = (
    <Menu onClick={handleProductMenuClick} selectedKeys={[sortProductCol]}>
      <Menu.Item key="Default">Default</Menu.Item>
      <Menu.Item key="priceHighToLow">Price (high to low)</Menu.Item>
      <Menu.Item key="priceLowToHigh">Price (low to high)</Menu.Item>
      <Menu.Item key="codeASC">Code (ascending)</Menu.Item>
      <Menu.Item key="codeDESC">Code (descending)</Menu.Item>
      <Menu.Item key="nameAZ">Name (A-Z)</Menu.Item>
      <Menu.Item key="nameZA">Name (Z-A)</Menu.Item>
      <Menu.Item key="createdFirst">Created (newest first)</Menu.Item>
      <Menu.Item key="createdLast">Created (oldest first)</Menu.Item>
      <Menu.Item key="categoryAZ">Category (A-Z)</Menu.Item>
      <Menu.Item key="categoryZA">Category (Z-A)</Menu.Item>
    </Menu>
  );
  const serviceMenu = (
    <Menu onClick={handleServiceMenuClick} selectedKeys={[sortServiceCol]}>
      <Menu.Item key="Default">Default</Menu.Item>
      <Menu.Item key="priceHighToLow">Price (high to low)</Menu.Item>
      <Menu.Item key="priceLowToHigh">Price (low to high)</Menu.Item>
      <Menu.Item key="codeASC">Code (ascending)</Menu.Item>
      <Menu.Item key="codeDESC">Code (descending)</Menu.Item>
      <Menu.Item key="nameAZ">Name (A-Z)</Menu.Item>
      <Menu.Item key="nameZA">Name (Z-A)</Menu.Item>
      <Menu.Item key="createdFirst">Created (newest first)</Menu.Item>
      <Menu.Item key="createdLast">Created (oldest first)</Menu.Item>
      <Menu.Item key="categoryAZ">Category (A-Z)</Menu.Item>
      <Menu.Item key="categoryZA">Category (Z-A)</Menu.Item>
    </Menu>
  );

  const handleDateSelected = (date: Dayjs) => {
    let futuredate = dayjs(date).isAfter(dayjs());
    if (!futuredate) {
      setSelectedDate(date);
    } else {
      message.error(
        "Affected by parameter settings - bill date does not allow selection of future dates."
      );
    }
  };

  const handleRefNumSelection = () => {
    setShowRefNumModal(true);
  };

  const handleSingleDateSelection = () => {
    setShowSingleDateSelectionModal(true);
  };

  const handleSingleDateSelectionClose = () => {
    setShowSingleDateSelectionModal(false);
  };

  const handleDiscountValue = (data: any) => {
    setDiscountValue(data.discountvalue);
    setDiscountAmount(data.discountamount);
    setDiscountType(data.discounttype);
    setShowDiscountModal(false);
  };

  // Handle main tab changes
  const handleMainTabChange = (key: string) => {
    setActiveMainTab(key);
    if (key !== "Services") {
      setActiveSubTab("All");
    }
    if (key !== "Products") {
      setActiveProductSubTab("All");
    }
  };

  // Handle sub-tab changes for Services
  const handleSubTabChange = (key: string) => {
    setActiveSubTab(key);
  };

  // Handle sub-tab changes for Products
  const handleProductSubTabChange = (key: string) => {
    setActiveProductSubTab(key);
  };

  // Handle search input changes
  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const [showServiceSearchInput, setShowServiceSearchInput] = useState(false);
  const [searchServiceValue, setSearchServiceValue] = useState("");

  const [showProductSearchInput, setShowProductSearchInput] = useState(false);
  const [searchProductValue, setSearchProductValue] = useState("");
  const [searchGiftcardValue, setSearchGiftcardValue] = useState("");
  const [showServiceImage, setShowServiceImage] = useState(false);
  const [showProductImage, setShowProductImage] = useState(false);

  const [showProductModal, setShowProductModal] = useState(false);
  const [showServiceModal, setShowServiceModal] = useState(false);

  const [showProductEditModal, setShowProductEditModal] = useState(false);
  const [showServiceEditModal, setShowServiceEditModal] = useState(false);
  const [showGiftcardEditModal, setShowGiftcardEditModal] = useState(false);

  const [showNotesEditModal, setShowNotesEditModal] = useState(false);
  const [ShowProductStaffEditModal, setShowProductStaffEditModal] =
    useState(false);
  const [ShowServiceStaffEditModal, setShowServiceStaffEditModal] =
    useState(false);

  const [showDiscountModal, setShowDiscountModal] = useState(false);

  const [showRefNumModal, setShowRefNumModal] = useState(false);

  const [showCustomerModal, setShowCustomerModal] = useState(false);

  const [notesValue, setNotesValue] = useState("");

  const [payNowClick, setPayNowClick] = useState(false);

  const handleShowCustomerModal = () => {
    setShowCustomerModal(true);
  };

  const handleShowCustomerCancelModal = () => {
    setShowCustomerModal(false);
  };

  const selectShowProductModal = (item: any) => {
    setShowProductModal(true);
    setSelectedItem(item);
    setSelectedType("Product");
  };

  const selectShowGiftcardModal = (item: any) => {
    setShowProductModal(true);
    setSelectedItem(item);
    setSelectedType("Giftcard");
  };

  const selectShowServiceModal = (item: any) => {
    setShowServiceModal(true);
    setSelectedItem(item);
  };

  const generateUniqueId = () => {
    const uniqueId = nanoid(8); // Generates a unique 8-character string
    return uniqueId;
  };

  const addToCart = (type: string, selectedStaff: any) => {
    if (selectedItem && selectedStaff) {
      const newCartItem = {
        item_unique_id: generateUniqueId(),
        item_id: selectedItem.id,
        item_code: selectedItem.code,
        item_name: selectedItem.name,
        item_price: selectedItem.sellprice,
        item_note: "",
        item_tax: selectedItem.taxunit || "",
        item_tax_rate: selectedItem.tax || "",
        item_discount_percentage: 0,
        item_pay_amount: selectedItem.sellprice * 1,
        item_qty: 1,
        total: selectedItem.sellprice * 1,
        item_type: type,
        staff: selectedStaff
          .filter(
            (staffItem: any) =>
              staffItem.selectedStaff || type == "Product" || type == "Giftcard"
          ) // Filter out items where staff is null
          .map((staffItem: any) => ({
            staff_id:
              type === "Service" ? staffItem.selectedStaff.id : staffItem.id,
            staff_name:
              type === "Service"
                ? staffItem.selectedStaff.name
                : staffItem.name,
            staff_code:
              type === "Service"
                ? staffItem.selectedStaff.staffcode
                : staffItem.staffcode,
            department_id:
              type === "Service"
                ? staffItem.selectedStaff.department
                : staffItem.department,
            department_name:
              type === "Service"
                ? staffItem.selectedStaff.department_name
                : staffItem.department_name,
            ...(type === "Product" && {
              staff_percentage: staffItem.percentage,
            }),
            ...(type === "Giftcard" && {
              staff_percentage: staffItem.percentage,
            }),
            ...(type === "Service" && {
              staff_service_id: staffItem.id,
              service_category_id: staffItem.selectedStaff.staffcategory,
            }),
          })),
        ...(type === "Service" && {
          service_consumption: [], // Add this line for Service type
        }),
        ...(type === "Giftcard" && {
          item_giftcard_type: selectedItem.type,
          item_value: selectedItem.sessionvalue,
          item_singleusage_qty: selectedItem.singleusageqty,
          item_valid_for: selectedItem.validfor,
          item_validity_type: selectedItem.typeof_validity,
          item_valid_count: selectedItem.valid_count,
          item_valid_countperiod: selectedItem.valid_countperiod,
        }),
      };
      setCartItems([...cartItems, newCartItem]);
      message.success("The new item added successfully!");
      setSelectedItem(null);
    }
  };

  const handleServiceStaffSelection = (staff: any) => {
    // setSelectedStaff(staff);
    setShowProductModal(false);
    addToCart("Service", staff);
  };

  const handleCustomerSelection = (data: any) => {
    // setSelectedStaff(staff);
    setShowCustomerModal(false);
    setSelectedCustomer(data);
  };

  const handleProductStaffSelection = (staff: any) => {
    // setSelectedStaff(staff);
    if (selectedType == "Product") {
      setShowProductModal(false);
      addToCart("Product", staff);
    } else {
      setShowProductModal(false);
      addToCart("Giftcard", staff);
    }
  };

  const handleRefSelection = (refnum: any) => {
    setShowRefNumModal(false);
    setRefNum(refnum);
  };

  useEffect(() => {
    setcartItemValue(
      cartItems.reduce((accum, item) => accum + item.item_pay_amount, 0)
    );
  }, [cartItems]);

  useEffect(() => {
    if (cartItems && cartItems.length > 0) {
      let cartItemTotal = cartItems.reduce(
        (accum, item) => accum + item.item_pay_amount,
        0
      );
      let subtotalValue = cartItemTotal - discountAmount;
      setSubTotal(subtotalValue);
    } else {
      setDiscountType("%");
      setDiscountAmount(0);
      setSubTotal(0);
    }
  }, [cartItems, discountAmount]);

  useEffect(() => {
    if (subTotal > 0) {
      const cartTax = cartItems.reduce((sum, item) => {
        if (item.item_tax_rate !== null && item.item_tax_rate !== "") {
          return (
            sum +
            (parseFloat(item.item_pay_amount) *
              parseFloat(item.item_tax_rate)) /
              100
          );
        }
        return sum;
      }, 0);

      let taxSum = taxesItems.reduce(
        (sum, item) => sum + Number(item.taxrate),
        0
      );

      const subtotalTax = (subTotal * taxSum) / 100;

      const totalTax = cartTax + subtotalTax;

      // const perTax = (totalTax* )

      // setTaxRate(totalTax);
      setTaxValue(totalTax);

      let taxRateSum = taxesItems.reduce(
        (sum, item) => sum + Number(item.taxrate),
        0
      );
      const totalTaxRate =
        cartItems.reduce((sum, item) => {
          if (item.item_tax_rate !== null && item.item_tax_rate !== "") {
            return sum + parseFloat(item.item_tax_rate);
          }
          return sum;
        }, 0) + taxRateSum;

      setTaxRate(totalTaxRate);
    } else {
      setTaxRate(0);
      setTaxValue(0);
    }
  }, [subTotal, taxesItems, cartItems, discountAmount]);

  useEffect(() => {
    let total = subTotal;
    total = total + taxValue;
    setTotal(total);
  }, [subTotal, cartItems, taxValue, discountAmount]);

  const handleRemoveDiscount = () => {
    setDiscountValue(0);
    setDiscountAmount(0);
  };

  const calculateGrandTotal = () => {
    return cartItems.reduce((accum, item) => accum + item.total, 0);
  };

  const handleSearchServiceIconClick = () => {
    setShowServiceSearchInput(!showServiceSearchInput);
  };

  const handleSearchProductIconClick = () => {
    setShowProductSearchInput(!showProductSearchInput);
  };

  const handleClearServiceSearch = () => {
    setSearchServiceValue("");
  };

  const handleClearProductSearch = () => {
    setSearchProductValue("");
  };

  const handleClearGiftcardsSearch = () => {
    setSearchGiftcardValue("");
  };

  const handleSwapCount = () => {
    setMaleCount(femaleCount);
    setFemaleCount(maleCount);
  };

  const fetchServiceCategory = async () => {
    try {
      const response = await get(
        `/category/getlistwithoutpagination/${localStorage.getItem(
          "outlet_id"
        )}/Service`
      );
      if (response.data.success) {
        let data = response.data.data;
        data.unshift({
          id: "null",
          name: "All ",
        });
        setServiceCategory(data);
        setSelectedServiceTab("null");
      } else {
        message.error("Failed to fetch service category");
      }
    } catch (error) {
      console.error("Error fetching service category: ", error);
      message.error("Error fetching service category");
    }
  };

  const fetchProductCategory = async () => {
    try {
      const response = await get(
        `/category/getlistwithoutpagination/${localStorage.getItem(
          "outlet_id"
        )}/Product`
      );
      if (response.data.success) {
        let data = response.data.data;
        data.unshift({
          id: "null",
          name: "All ",
        });
        setProductCategory(data);
        setSelectedProductTab("null");
      } else {
        message.error("Failed to fetch brands");
      }
    } catch (error) {
      console.error("Error fetching product category: ", error);
      message.error("Error fetching product category");
    }
  };

  const fetchProducts = async () => {
    try {
      const response = await get(
        `/billing/items/${localStorage.getItem(
          "outlet_id"
        )}?type=Product&categoryid=null`
      );
      if (response.data.success) {
        setProductItems(response.data.data);
        setTempProductItems(response.data.data);
      } else {
        message.error("Failed to fetch products");
      }
    } catch (error) {
      console.error("Error fetching products: ", error);
      message.error("Error fetching products");
    }
  };

  const fetchServices = async () => {
    try {
      const response = await get(
        `/billing/items/${localStorage.getItem(
          "outlet_id"
        )}?type=Service&categoryid=null`
      );
      if (response.data.success) {
        setServiceItems(response.data.data);
        setTempServiceItems(response.data.data);
      } else {
        message.error("Failed to fetch services");
      }
    } catch (error) {
      console.error("Error fetching services: ", error);
      message.error("Error fetching services");
    }
  };

  const fetchGiftcards = async () => {
    try {
      const response = await get(
        `/billing/items/${localStorage.getItem(
          "outlet_id"
        )}?type=Giftcard&categoryid=null`
      );
      if (response.data.success) {
        console.log(response.data.data);
        setGiftcardsItems(response.data.data);
        setTempGiftcardItems(response.data.data);
      } else {
        message.error("Failed to fetch Giftcards");
      }
    } catch (error) {
      console.error("Error fetching Giftcards: ", error);
      message.error("Error fetching Giftcards ");
    }
  };

  const fetchTaxes = async () => {
    try {
      const response = await get(
        `/tax/addmanuallylist/${localStorage.getItem("outlet_id")}`
      );
      if (response.data.success) {
        setTaxesItems(response.data.data);
      } else {
        message.error("Failed to fetch taxess");
      }
    } catch (error) {
      console.error("Error fetching taxes: ", error);
      message.error("Error fetching taxes");
    }
  };

  const fetchCustomerSource = async () => {
    try {
      const response = await get(
        `/customersource/dropdown/${localStorage.getItem("outlet_id")}`
      );
      if (response.data.success) {
        setCustomerSources(response.data.data);
        if (response.data.data && response.data.data.length > 0) {
          setSelectedCustomerSource(response.data.data[0].id);
        }
      } else {
        message.error("Failed to fetch taxess");
      }
    } catch (error) {
      console.error("Error fetching taxes: ", error);
      message.error("Error fetching taxes");
    }
  };

  const fetchRefNum = async () => {
    try {
      const response = await get(`/user/randomgenerated`);
      if (response?.data?.success) {
        const data = response.data.result;
        setRefNum(data);
      }
    } catch (error) {
      console.log(error);
      message.error("Failed to load refnum");
    }
  };

  const handleProductTabChange = (key: any) => {
    setSelectedProductTab(key);
    let filteredProducts =
      key == "null"
        ? productItems
        : productItems.filter((product) => product.categorycode === key);

    setTempProductItems(filteredProducts);
  };

  const handleServiceTabChange = (key: any) => {
    setSelectedServiceTab(key);
    let filteredServices =
      key == "null"
        ? serviceItems
        : serviceItems.filter((service) => service.categorycode === key);

    setTempServiceItems(filteredServices);
  };

  const handleServiceItemSearch = (e: any) => {
    setSearchServiceValue(e.target.value);
    let filteredServices = [];

    if (e.target.value !== "") {
      filteredServices = serviceItems.filter((service) =>
        service.name.includes(e.target.value)
      );
    } else {
      filteredServices = serviceItems;
    }

    setTempServiceItems(filteredServices);
  };

  const handleProductItemSearch = (e: any) => {
    setSearchProductValue(e.target.value);
    let filteredProducts = [];

    if (e.target.value !== "") {
      filteredProducts = productItems.filter((product) =>
        product.name.includes(e.target.value)
      );
    } else {
      filteredProducts = productItems;
    }
    setTempProductItems(filteredProducts);
  };

  const handleGiftcardsItemSearch = (e: any) => {
    setSearchGiftcardValue(e.target.value);
    let filteredGiftcards = [];

    if (e.target.value !== "") {
      filteredGiftcards = giftcardsItems.filter(
        (giftcards) =>
          giftcards.name.includes(e.target.value) ||
          giftcards.code.includes(e.target.value) ||
          giftcards.sellprice.toString().includes(e.target.value)
      );
    } else {
      filteredGiftcards = giftcardsItems;
    }

    setTempGiftcardItems(filteredGiftcards);
  };

  const formattedStaffName = (Staff: any) => {
    let staffText = "";
    const staffCount = Staff.length;

    if (staffCount === 0) {
      staffText = "";
    } else if (staffCount === 1) {
      staffText = Staff[0].staff_name;
    } else {
      const staffNames = Staff.slice(0, 2).map(
        (staffMember: any) => staffMember.staff_name
      );
      const othersCount = staffCount - 2;
      staffText = `${staffNames.join("、")}${
        othersCount > 0 ? ` and ${othersCount} others` : ""
      }`;
    }
    return staffText;
  };
  const formattedText = () => {
    return notesValue.replace(/\n/g, " ");
  };

  const formatToTwoDecimalPlaces = (value: any) => {
    if (!isNaN(value)) {
      const num = parseFloat(value);
      if (!Number.isInteger(num)) {
        return num.toFixed(1);
      }
    }
    return value;
  };

  const handleProductImageShow = () => {
    setShowProductImage(!showProductImage);
  };

  const handleServiceImageShow = () => {
    setShowServiceImage(!showServiceImage);
  };

  const handleDeleteCartItem = (item: any) => {
    setSelectedItem(item);
    setIsDeleteServiceModalVisible(true);
  };

  const handlePayNow = async () => {
    if (cartItems && cartItems.length == 0) {
      message.error("Please select the content of the bill!");
    } else {
      const hasGiftcard = cartItems.some(
        (item) => item.item_type === "Giftcard"
      );
      if (hasGiftcard && !selectedCustomer) {
        message.error(
          "Please select a customer to sell memberships / gift cards /packages"
        );
        setShowCustomerModal(true);
        return;
      }
      try {
        setPayNowClick(true);
        const url = "/billing/create";
        let payload = {
          order_date: selectedDate.format("DD-MM-YYYY"),
          outlet_id: localStorage.getItem("outlet_id"),
          ref_no: refNum.trim(),
          customer_soruce: selectedCustomerSource,
          // refer_type:selectedReferralType,
          customer_refer: selectedReferrer,
          male_count: maleCount,
          female_count: femaleCount,
          customer_id: selectedCustomer ? selectedCustomer.id : "",
          customer_gender: selectedCustomer ? selectedCustomer.gender : "",
          note: "",
          note_images: [],
          bill_status: "unpaid",
          discount: discountAmount.toString(),
          discount_type: discountType,
          taxes: taxValue.toString(),
          sub_total: subTotal.toString(),
          final_total: total.toString(),
          items: cartItems.map((item) => ({
            ...item,
            item_price: String(item.item_price),
            item_discount_percentage: String(item.item_discount_percentage),
            item_pay_amount: String(item.item_pay_amount),
            item_qty: String(item.item_qty),
            total: String(item.total),
          })),
        };

        const response = await post(url, payload);

        if (response && response.data && response.data.success) {
          message.success("Successfully added!");
          setPayNowClick(false);
          navigate(`/sales/pay/${response.data.data}`);
        } else {
          setPayNowClick(false);
          message.error(response.data.message || "Submission failed");
        }
      } catch (error: any) {
        setPayNowClick(false);
        message.error(error || "Something went wrong");
      }
    }
  };

  const handleSaveUnpaid = async () => {
    if (cartItems && cartItems.length == 0) {
      message.error("Please select the content of the bill!");
      return;
    } else {
      try {
        const hasGiftcard = cartItems.some(
          (item) => item.item_type === "Giftcard"
        );
        if (hasGiftcard && !selectedCustomer) {
          message.error(
            "Please select a customer to sell memberships / gift cards /packages"
          );
          setShowCustomerModal(true);
          return;
        }
        setPayNowClick(true);
        const url = "/billing/create";
        let payload = {
          order_date: selectedDate.format("DD-MM-YYYY"),
          outlet_id: localStorage.getItem("outlet_id"),
          ref_no: refNum.trim(),
          male_count: maleCount,
          female_count: femaleCount,
          refer_type: selectedReferralType,
          customer_refer: selectedReferrer,
          customer_source: selectedCustomerSource,
          customer_id: selectedCustomer ? selectedCustomer.id : "",
          customer_gender: selectedCustomer ? selectedCustomer.gender : "",
          note: "",
          note_images: [],
          bill_status: "unpaid",
          discount: discountAmount.toString(),
          discount_type: discountType,
          taxes: taxValue.toString(),
          sub_total: subTotal.toString(),
          final_total: total.toString(),
          items: cartItems,
        };

        const response = await post(url, payload);

        if (response && response.data && response.data.success) {
          message.success("Successfully added!");
          setPayNowClick(false);
          navigate(`/sales`);
        } else {
          setPayNowClick(false);
          message.error(response.data.message || "Submission failed");
        }
      } catch (error: any) {
        setPayNowClick(false);
        message.error(error || "Something went wrong");
      }
    }
  };

  const handleItemEdit = (item: any, type: any) => {
    setSelectedItem(item);
    if (type == "Service") {
      setShowServiceEditModal(true);
    } else if (type == "Product") {
      setShowProductEditModal(true);
    } else if (type == "Giftcard") {
      setShowGiftcardEditModal(true);
    }
  };

  const handleNotesEdit = () => {
    setShowNotesEditModal(true);
  };

  const handleStaffEdit = (item: any, type: any) => {
    setSelectedItem(item);
    if (type == "Service") {
      setShowServiceStaffEditModal(true);
    } else {
      setShowProductStaffEditModal(true);
    }
  };

  useEffect(() => {
    setPayNowClick(false);
    fetchServiceCategory();
    fetchProductCategory();
    fetchProducts();
    fetchServices();
    fetchGiftcards();
    fetchRefNum();
    fetchTaxes();
    fetchCustomerSource();
  }, []);

  const handleCartProductUpdate = (data: any) => {
    const updatedCartItems = cartItems.map((item) =>
      item.item_unique_id === selectedItem.item_unique_id
        ? { ...item, ...data }
        : item
    );
    setCartItems(updatedCartItems);
    setShowProductEditModal(false);
  };

  const handleBillInfoData = (data: any) => {
    let futuredate = dayjs(data.selectedDate).isAfter(dayjs());
    if (!futuredate) {
      setSelectedDate(data.selectedDate);
    }
    setRefNum(data.refNum);
    setSelectedReferralType(data.selectedReferralType);
    setSelectedReferrer(data.selectedReferrer);
    setSelectedReferrerId(data.selectedReferrerId);
    setSelectedCustomerSource(data.selectedCustomerSource);
    setBillInfoModalVisible(false);
  };

  const handleNoteAdd = (data: any) => {
    setNotesValue(data);
    setShowNotesEditModal(false);
  };

  const handleServiceStaffChange = (data: any) => {
    let newStaffArray = data
      .filter((staffItem: any) => staffItem.selectedStaff) // Filter out items where staff is null
      .map((staffItem: any) => ({
        staff_service_id: staffItem.id,
        service_category_id: staffItem.selectedStaff.staffcategory,
        staff_id: staffItem.selectedStaff.id,
        staff_name: staffItem.selectedStaff.name,
        staff_code: staffItem.selectedStaff.staffcode,
        department_id: staffItem.selectedStaff.department_id,
        department_name: staffItem.selectedStaff.department_name,
      }));
    const updatedCartItems = cartItems.map((item) =>
      item.item_unique_id === selectedItem.item_unique_id
        ? { ...item, staff: newStaffArray } // Only update the "quantity" field
        : item
    );
    setCartItems(updatedCartItems);
    setShowServiceStaffEditModal(false);
  };

  const handleProductStaffChange = (data: any) => {
    let newStaffArray = data.map((staffItem: any) => ({
      staff_service_id: staffItem.id,
      service_category_id: staffItem.staffcategory,
      staff_id: staffItem.id,
      staff_name: staffItem.name,
      staff_code: staffItem.staffcode,
      department_id: staffItem.department_id,
      department_name: staffItem.department_name,
      staff_percentage: staffItem.percentage,
    }));
    const updatedCartItems = cartItems.map((item) =>
      item.item_unique_id === selectedItem.item_unique_id
        ? { ...item, staff: newStaffArray }
        : item
    );
    setCartItems(updatedCartItems);
    setShowProductStaffEditModal(false);
  };

  const handleCartServiceUpdate = (data: any) => {
    const updatedCartItems = cartItems.map((item) =>
      item.item_unique_id === selectedItem.item_unique_id
        ? { ...item, ...data }
        : item
    );
    setCartItems(updatedCartItems);
    setShowServiceEditModal(false);
  };

  return (
    <Content className="mainContainer">
      <Row justify="space-between" align="middle">
        <Col>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              onClick={() => navigate("/sales")}
              style={{ cursor: "pointer" }}
            >
              <ArrowLeftOutlined
                style={{ marginRight: 12, color: "#325df2", fontSize: 18 }}
              />
            </div>
            <h2 style={{ margin: 0 }}>Billing</h2>
          </div>
        </Col>
      </Row>

      {/* Main Layout */}
      <Row
        gutter={[24, 24]}
        style={{ height: "calc(100vh - 90px)", padding: "10px" }}
      >
        <Col
          span={16}
          style={{
            border: "1px solid #E6E6E6",
            padding: "12px 24px 12px 32px",
            borderRadius: "8px",
            backgroundColor: "#fafafa",
          }}
        >
          <div className="section-box">
            <Tabs
              activeKey={activeMainTab}
              onChange={handleMainTabChange}
              tabBarGutter={20}
              size="large"
              type="line"
              style={{ marginBottom: "15px" }}
            >
              <TabPane tab="Services" key="Services">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "15px",
                    flexWrap: "wrap",
                  }}
                >
                  {/* Tabs or Search Input Section */}
                  {!showServiceSearchInput ? (
                    <Tabs
                      type="line"
                      activeKey={selectedServiceTab}
                      onChange={handleServiceTabChange}
                      tabBarGutter={25}
                    >
                      {serviceCategory &&
                        serviceCategory.length > 0 &&
                        serviceCategory.map((category) => (
                          <>
                            <TabPane tab={category.name} key={category.id} />
                          </>
                        ))}
                    </Tabs>
                  ) : (
                    <Search
                      placeholder="Please enter"
                      allowClear
                      enterButton="Search"
                      size="large"
                      value={searchServiceValue}
                      onChange={handleServiceItemSearch}
                      onClear={handleClearServiceSearch}
                      prefix={
                        <SearchOutlined
                          style={{
                            color: "rgba(0,0,0,.45)",
                            marginRight: "5px",
                            alignItems: "center",
                          }}
                        />
                      }
                      style={{
                        width: "85%",
                        height: "40px",
                        alignItems: "center",
                      }}
                    />
                  )}

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "15px",
                    }}
                  >
                    <SearchOutlined
                      style={{
                        fontSize: "20px",
                        color: showServiceSearchInput ? "blue" : "#c2cadd", // Change color when active
                        cursor: "pointer",
                      }}
                      onClick={handleSearchServiceIconClick}
                    />
                    <Dropdown overlay={serviceMenu} trigger={["click"]}>
                      <img
                        src={
                          sortServiceCol !== "Default"
                            ? sortActiveIcon
                            : sortIcon
                        }
                        alt={`Uploaded image`}
                        style={{
                          width: "20px",
                          height: "20px",
                        }}
                      />
                    </Dropdown>
                    <img
                      src={showServiceImage ? imageActiveIcon : imageIcon}
                      alt={`Uploaded image`}
                      style={{
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                      }}
                      onClick={handleServiceImageShow}
                    />
                  </div>
                </div>

                <div
                  style={{ height: "calc(100vh - 300px)", overflowY: "auto" }}
                >
                  {tempServiceItems &&
                    tempServiceItems.length > 0 &&
                    tempServiceItems.map((item: any, index: any) => (
                      <>
                        <div
                          key={index}
                          style={{
                            width: "142px",
                            minHeight: "110px",
                            border: ".5px solid #e0e7fe",
                            background: "#fff",
                            marginRight: "12px",
                            marginBottom: "12px",
                            display: "inline-block",
                            verticalAlign: "top",
                            cursor: "pointer",
                            transition: "all .2s",
                          }}
                          onMouseEnter={(e) =>
                            (e.currentTarget.style.background = "#e0e7fe")
                          }
                          onMouseLeave={(e) =>
                            (e.currentTarget.style.background = "#fff")
                          }
                          onClick={() => {
                            selectShowServiceModal(item);
                          }}
                        >
                          {showServiceImage && (
                            <img
                              src={
                                item.imageUrl ? item.imageUrl : emptyImageIcon
                              }
                              height={"142px"}
                              width={"100%"}
                              alt="Product"
                            />
                          )}
                          <div style={{ padding: "10px" }}>
                            <div style={{ padding: "10px 0" }}>
                              <span
                                style={{
                                  width: "100%",
                                  overflow: "hidden",
                                  display: "-webkit-box",
                                  textOverflow: "ellipsis",
                                  WebkitLineClamp: 3,
                                  WebkitBoxOrient: "vertical",
                                  lineHeight: "18px",
                                  height: "54px",
                                  fontSize: "14px",
                                }}
                              >
                                {item.name}
                              </span>
                            </div>
                            <span
                              style={{
                                color: "#f43b00",
                                fontSize: "14px",
                                fontWeight: 700,
                              }}
                            >
                              ${item.sellprice}
                            </span>
                          </div>
                        </div>
                      </>
                    ))}

                  {tempServiceItems && tempServiceItems.length == 0 && (
                    <>
                      <Empty
                        image={emptyImageUrl}
                        imageStyle={{
                          height: 120,
                        }}
                        description={<span>No data available</span>}
                      />
                    </>
                  )}
                </div>
              </TabPane>

              <TabPane tab="Products" key="Products">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "15px",
                    flexWrap: "wrap",
                  }}
                >
                  {/* Tabs or Search Input Section */}
                  {!showProductSearchInput ? (
                    <Tabs
                      type="line"
                      activeKey={selectedProductTab}
                      onChange={handleProductTabChange}
                      tabBarGutter={25}
                    >
                      {productCategory &&
                        productCategory.length > 0 &&
                        productCategory.map((category) => (
                          <>
                            <TabPane tab={category.name} key={category.id} />
                          </>
                        ))}
                    </Tabs>
                  ) : (
                    <Search
                      placeholder="Please enter"
                      allowClear
                      enterButton="Search"
                      size="large"
                      value={searchProductValue}
                      onChange={handleProductItemSearch}
                      onClear={handleClearProductSearch}
                      prefix={
                        <SearchOutlined
                          style={{
                            color: "rgba(0,0,0,.45)",
                            marginRight: "5px",
                            alignItems: "center",
                          }}
                        />
                      }
                      style={{
                        width: "85%",
                        height: "40px",
                        alignItems: "center",
                      }}
                    />
                  )}

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "15px",
                    }}
                  >
                    <SearchOutlined
                      style={{
                        fontSize: "20px",
                        color: showProductSearchInput ? "blue" : "#c2cadd",
                        cursor: "pointer",
                      }}
                      onClick={handleSearchProductIconClick}
                    />
                    <Dropdown overlay={productMenu} trigger={["click"]}>
                      <img
                        src={
                          sortProductCol !== "Default"
                            ? sortActiveIcon
                            : sortIcon
                        }
                        alt={`Uploaded image`}
                        style={{
                          width: "20px",
                          height: "20px",
                        }}
                      />
                    </Dropdown>
                    <img
                      src={showProductImage ? imageActiveIcon : imageIcon}
                      alt={`Uploaded image`}
                      style={{
                        width: "20px",
                        height: "20px",
                      }}
                      onClick={handleProductImageShow}
                    />
                  </div>
                </div>

                <div
                  style={{ height: "calc(100vh - 300px)", overflowY: "auto" }}
                >
                  {tempProductItems &&
                    tempProductItems.length > 0 &&
                    tempProductItems.map((item: any, index: any) => (
                      <>
                        <div
                          key={index}
                          style={{
                            width: "142px",
                            minHeight: "110px",
                            border: ".5px solid #e0e7fe",
                            background: "#fff",
                            marginRight: "12px",
                            marginBottom: "12px",
                            display: "inline-block",
                            verticalAlign: "top",
                            cursor: "pointer",
                            transition: "all .2s",
                          }}
                          onMouseEnter={(e) =>
                            (e.currentTarget.style.background = "#e0e7fe")
                          }
                          onMouseLeave={(e) =>
                            (e.currentTarget.style.background = "#fff")
                          }
                          onClick={() => {
                            selectShowProductModal(item);
                          }}
                        >
                          {showProductImage && (
                            <img
                              src={
                                item.imageUrl ? item.imageUrl : emptyImageIcon
                              }
                              height={"142px"}
                              width={"100%"}
                              alt="Product"
                            />
                          )}
                          <div style={{ padding: "0 5px 0 10px" }}>
                            <div style={{ padding: "10px 0" }}>
                              <span
                                style={{
                                  width: "100%",
                                  overflow: "hidden",
                                  display: "-webkit-box",
                                  textOverflow: "ellipsis",
                                  WebkitLineClamp: 3,
                                  WebkitBoxOrient: "vertical",
                                  lineHeight: "18px",
                                  height: "54px",
                                  fontSize: "14px",
                                }}
                              >
                                {item.name}
                              </span>
                            </div>
                            <span
                              style={{
                                color: "#f43b00",
                                fontSize: "14px",
                                fontWeight: 700,
                              }}
                            >
                              ${item.sellprice}
                            </span>
                          </div>
                        </div>
                      </>
                    ))}

                  {tempProductItems && tempProductItems.length == 0 && (
                    <>
                      <Empty
                        image={emptyImageUrl}
                        imageStyle={{
                          height: 120,
                        }}
                        description={<span>No data available</span>}
                      />
                    </>
                  )}
                </div>
              </TabPane>

              <TabPane tab="Vouchers" key="Vouchers">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "15px",
                  }}
                >
                  <Input
                    placeholder="Search by name / code / price"
                    value={searchGiftcardValue}
                    onChange={handleGiftcardsItemSearch}
                    onClear={handleClearGiftcardsSearch}
                    allowClear
                    prefix={
                      <SearchOutlined
                        style={{
                          color: "rgba(0,0,0,.45)",
                          marginRight: "2px",
                          alignItems: "center",
                        }}
                      />
                    }
                    style={{
                      width: "90%",
                      height: "42px",
                      alignItems: "center",
                    }}
                  />

                  <Button
                    type="primary"
                    onClick={() => {}}
                    style={{
                      background: "#2e56f2",
                      color: "#fff",
                      borderRadius: "6px",
                      width: "81px",
                      height: "42px",
                      fontWeight: 700,
                      fontSize: "15px",
                    }}
                  >
                    Search
                  </Button>
                </div>

                <div
                  style={{
                    height: "calc(100vh - 300px)",
                    overflowY: "auto",
                    // padding: "10px 24px 24px 24px"
                  }}
                >
                  {tempgiftcardItems &&
                    tempgiftcardItems.length > 0 &&
                    tempgiftcardItems.map((item: any, index: any) => (
                      <>
                        <div
                          key={index}
                          style={{
                            color: "rgba(176, 87, 77, .8)",
                            // paddingRight: "20%",
                            position: "relative",
                            width: " 100%",
                            // height: "110px",
                            marginBottom: "16px",
                            backgroundImage: `url(${giftcardImage})`,
                            backgroundSize: "100% 100%",
                            transition: "all .2s",
                            borderRadius: "10px",
                            overflow: "hidden",
                          }}
                          onClick={() => {
                            selectShowGiftcardModal(item);
                          }}
                        >
                          <div className="gft_image-icon">
                            <div className="gft_van-image">
                              <img
                                className="gft_van-image__img"
                                src={giftcardImg}
                                alt="gift img"
                              ></img>
                            </div>
                          </div>
                          <div className="gft_view-bc">
                            <div className="gft_view-info">
                              <div className="gft_overflowText">
                                <span className="gft_title">{item.name}</span>
                                <span className="gft_saleCardItem_text_code">
                                  Code:{item.code}
                                </span>
                              </div>
                              <div className="gft_view-apply">
                                <span className="gft_view-cardApply">
                                  <div className="gft_bk-cardApply">
                                    <span className="gft_cardApply_text_applicable">
                                      Applicable to:
                                    </span>
                                    {item.applicable_to}
                                  </div>
                                </span>
                                <span className="gft_icon-gengduo">
                                  <RightOutlined />
                                </span>
                                <div className="gft_view-singleUsageQty">
                                  <span className="gft_saleCardItem_text_singleUsageQty">
                                    Single usage qty.：
                                  </span>
                                  <div className="gft_display-def">
                                    {item.singleusageqty}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="gft_view-bottom">
                            <div className="gft_view-left">
                              <div className="gft_bk-validDateText">
                                <span className="gft_saleCardItem_text_valid">
                                  Valid for:{" "}
                                </span>
                                <span className="gtf_bk-date">
                                  {item.validfor}
                                </span>
                                {/* <span>~</span>
                                <span className="gtf_bk-date">17/11/2024</span> */}
                              </div>
                              <div className=""></div>
                            </div>
                            <div className="gft_view-right">
                              <div className="gft_view-price">
                                <span className="gft_saleCardItem_text_price">
                                  Price
                                </span>
                                <span className="gft_color-def">
                                  ${item.sellprice}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="gft_dash"></div>
                          <div className="gft_view-giftCardValue">
                            {item.type === "Value" ? (
                              <div className="gft_view-value">
                                <span className="gft_color-def_bg">
                                  ${item?.sessionvalue}
                                </span>
                              </div>
                            ) : null}
                            {item.type === "Session" ? (
                              <>
                                <div className="gft_view-times">
                                  {item?.sessionvalue}
                                </div>
                                <div className="gft_view-tip">Sessions</div>
                              </>
                            ) : null}
                          </div>
                        </div>
                      </>
                    ))}

                  {tempgiftcardItems && tempgiftcardItems.length == 0 && (
                    <>
                      <Empty
                        image={emptyImageUrl}
                        imageStyle={{
                          height: 120,
                          top: 400,
                        }}
                        description={<span>No data available</span>}
                      />
                    </>
                  )}
                </div>
              </TabPane>
            </Tabs>
          </div>
        </Col>
        <Col
          span={8}
          style={{
            border: "1px solid #E6E6E6",
            paddingLeft: "15px",
            paddingTop: "12px",
            paddingRight: "15px",
            borderRadius: "8px",
            backgroundColor: "#fafafa",
          }}
        >
          <div className="section-box">
            <Form layout="vertical">
              <Row style={{ position: "relative" }}>
                <Col span={8}>
                  <Space.Compact
                    size="large"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Text
                      style={{
                        backgroundColor: "transparent",
                        marginRight: "10px",
                        width: "60px",
                      }}
                    >
                      Date:
                    </Text>
                    <Input
                      style={{ cursor: "pointer" }}
                      className="billing-input"
                      value={selectedDate.format("DD/MM/YYYY")}
                      readOnly={true}
                      onClick={handleSingleDateSelection}
                    />
                  </Space.Compact>
                </Col>
                <Col span={16}>
                  <Space.Compact
                    size="large"
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        backgroundColor: "transparent",
                        marginRight: "10px",
                        marginLeft: "10px",
                        width: "60px",
                      }}
                    >
                      Ref#:
                    </Text>
                    <Input
                      style={{ cursor: "pointer" }}
                      className="billing-input"
                      value={refNum}
                      readOnly={true}
                      onClick={handleRefNumSelection}
                    />
                  </Space.Compact>
                </Col>
                <div
                  style={{
                    zIndex: "10",
                    background: "#f6f7fa",
                    border: ".5px solid #e0e7fe",
                    borderRadius: "0px 0px 0px 20px",
                    position: "absolute",
                    top: "-15px",
                    right: "-15px",
                    width: "45px",
                    height: "45px",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <EditOutlined
                    onClick={() => setBillInfoModalVisible(true)}
                    style={{ color: "#2e56f2" }}
                  />
                </div>
              </Row>
              <Row style={{ marginTop: "8px" }}>
                <Space.Compact
                  size="large"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Text
                    style={{
                      backgroundColor: "transparent",
                      marginRight: "10px",
                      width: "100px",
                    }}
                  >
                    Count:
                  </Text>
                  <Input
                    addonBefore={
                      <>
                        <img
                          src={maleIcon}
                          alt="Before"
                          style={{ width: 12, height: 12 }}
                        />
                      </>
                    }
                    addonAfter={
                      <img
                        src={exchangeIcon}
                        alt="After"
                        style={{ width: 20, height: 20, cursor: "pointer" }}
                        onClick={handleSwapCount}
                      />
                    }
                    onChange={(e) => {
                      setMaleCount(e.target.value);
                    }}
                    className="billing-input"
                    value={maleCount}
                  />
                  <Input
                    addonBefore={
                      <img
                        src={femaleIcon}
                        alt="Before"
                        style={{ width: 12, height: 12 }}
                      />
                    }
                    onChange={(e) => {
                      setFemaleCount(e.target.value);
                    }}
                    className="billing-input"
                    value={femaleCount}
                  />
                </Space.Compact>
              </Row>
              {selectedCustomer && (
                <Card
                  style={{
                    marginTop: "10px",
                    backgroundColor: "#6e7687",
                    borderRadius: "20px",
                    padding: "10px",
                    color: "#fff",
                    display: "flex",
                    position: "relative",
                    height: "150px",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    backgroundImage:
                      "radial-gradient(circle, rgba(126,130,153,1) 0%, rgba(85,90,110,1) 100%)",
                    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
                  }}
                  bodyStyle={{ padding: "0px", position: "relative" }}
                >
                  <Button
                    icon={<CloseOutlined />}
                    type="default"
                    style={{
                      position: "absolute",
                      top: "0px",
                      right: "0px",
                      background: "transparent",
                      border: "none",
                      color: "#c2cadd",
                    }}
                    onClick={() => {
                      setIsDeleteMemberModalVisible(true);
                    }}
                  />
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Avatar
                      size={48}
                      icon={<UserOutlined />}
                      style={{ backgroundColor: "#8a92a5" }}
                    />
                    <div style={{ marginLeft: "15px", textAlign: "justify" }}>
                      <Text strong style={{ color: "#fff", fontSize: "16px" }}>
                        {selectedCustomer?.name}
                      </Text>
                      <br />
                      <Text style={{ color: "#c0c5cf", fontSize: "14px" }}>
                        {selectedCustomer?.customercode}
                      </Text>
                    </div>
                  </div>

                  {/* Contact Information */}
                  <div style={{ marginTop: "10px" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#c0c5cf",
                        marginBottom: "8px",
                      }}
                    >
                      <PhoneFilled />
                      <Text
                        style={{
                          marginLeft: "10px",
                          fontSize: "14px",
                          color: "#fff",
                          fontWeight: "700",
                        }}
                      >
                        {selectedCustomer?.phonenumber}
                      </Text>
                      <EyeInvisibleOutlined
                        style={{ marginLeft: "10px", cursor: "pointer" }}
                      />
                      {/* <CopyOutlined style={{ cursor: "pointer" }} /> */}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#c0c5cf",
                        marginBottom: "8px",
                        fontWeight: "700",
                      }}
                    >
                      <MailFilled />
                      <Text
                        style={{
                          marginLeft: "10px",
                          fontSize: "14px",
                          color: "#fff",
                        }}
                      >
                        {selectedCustomer?.email
                          ? selectedCustomer?.email
                          : "-"}
                      </Text>
                    </div>
                  </div>
                </Card>
              )}
              {!selectedCustomer && (
                <Form.Item>
                  <div
                    style={{
                      marginTop: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "150px",
                      background: "#f5f7fa",
                      border: "1px dashed #e6e6e6",
                      borderRadius: "8px",
                      textAlign: "center",
                      color: "#bfbfbf",
                      flexDirection: "column",
                      cursor: "pointer",
                      transition: "all 0.2s ease",
                    }}
                    onClick={handleShowCustomerModal}
                  >
                    <div
                      style={{
                        width: "50px",
                        height: "50px",
                        background: `rgba(194, 202, 221, 0.1) url(${memberinfo}) center / 44px no-repeat`,
                        borderRadius: "50%",
                        marginBottom: "8px",
                      }}
                    ></div>
                    <span style={{ fontSize: "14px", color: "#7d7d7d" }}>
                      Select a customer or leave empty for walk-in
                    </span>
                  </div>
                </Form.Item>
              )}
              <div style={{ height: "calc(100vh - 480px)", overflowY: "auto" }}>
                <div
                  style={{
                    borderBottom: "1px solid #E6E6E6",
                    marginBottom: "10px",
                  }}
                >
                  {cartItems && cartItems.length > 0 && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderTop: "1px solid #E6E6E6",
                        padding: "16px 30px 8px 8px",
                      }}
                    >
                      <div
                        style={{
                          color: "#333",
                          fontWeight: 700,
                          fontSize: "12px",
                        }}
                      >
                        Item
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          color: "#333",
                          fontWeight: 700,
                          fontSize: "12px",
                        }}
                      >
                        Amount
                      </div>
                    </div>
                  )}
                  {cartItems &&
                    cartItems.length > 0 &&
                    cartItems.map((item: any, index: any) => (
                      <>
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderTop: "1px solid #E6E6E6",
                            padding: "12px 0px 0px 8px",
                            marginBottom: "5px",
                          }}
                        >
                          <Button
                            type="link"
                            style={{
                              textDecoration: "underline",
                              padding: "0px",
                              color: "#2E56F2",
                              fontWeight: 700,
                            }}
                            onClick={() => {
                              handleItemEdit(item, item.item_type);
                            }}
                          >
                            {item.item_name}
                          </Button>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              alignContent: "center",
                            }}
                          >
                            <div
                              style={{ color: "#999", fontSize: "13px" }}
                              onClick={() => {
                                handleItemEdit(item, item.item_type);
                              }}
                            >
                              x{item.item_qty}
                            </div>
                            <div
                              onClick={() => {
                                handleItemEdit(item, item.item_type);
                              }}
                            >
                              <span
                                style={{
                                  marginLeft: "100px",
                                  color: "#F43B00",
                                  fontWeight: 700,
                                  fontSize: "14px",
                                }}
                              >
                                $
                                {formatToTwoDecimalPlaces(item.item_pay_amount)}
                              </span>
                            </div>
                            <div>
                              <Button
                                type="text"
                                icon={<DeleteOutlined />}
                                style={{ fontSize: "16px" }}
                                onClick={() => {
                                  handleDeleteCartItem(item);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <Row
                          style={{
                            padding: "1px 0 12px 8px",
                            marginBottom: "5px",
                          }}
                        >
                          <Col span={12}>
                            {item.staff && item.staff.length == 0 && (
                              <Button
                                type="link"
                                style={{
                                  textDecoration: "none",
                                  padding: "0px",
                                  color: "#ffb800",
                                  fontWeight: 700,
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "normal",
                                }}
                                onClick={() => {
                                  handleStaffEdit(item, item.item_type);
                                }}
                              >
                                Staff unassigned
                              </Button>
                            )}
                            {item.staff && item.staff.length > 0 && (
                              <div
                                style={{
                                  display: "flex",
                                }}
                              >
                                <span
                                  style={{
                                    color: "#666",
                                    fontSize: "12px",
                                    cursor: "pointer",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "normal",
                                  }}
                                  onClick={() => {
                                    handleStaffEdit(item, item.item_type);
                                  }}
                                >
                                  {item.item_type == "Service"
                                    ? "Served"
                                    : "Sold"}{" "}
                                  by: {formattedStaffName(item.staff)}
                                </span>
                              </div>
                            )}
                          </Col>
                          <Col span={12}>
                            {item.item_pay_amount < item.total && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                }}
                              >
                                <span
                                  style={{
                                    color: "#666",
                                    fontSize: "12px",
                                    cursor: "pointer",
                                    textDecoration: "line-through",
                                    marginRight: "30px",
                                  }}
                                  onClick={() => {
                                    handleItemEdit(item, item.item_type);
                                  }}
                                >
                                  ${formatToTwoDecimalPlaces(item.total)}
                                </span>
                              </div>
                            )}
                          </Col>
                        </Row>
                        {item.item_type == "Service" &&
                          item.service_consumption &&
                          item.service_consumption.length > 0 && (
                            <>
                              <div
                                style={{
                                  position: "relative",
                                  display: "flex",
                                  padding: "1px 0 2px 8px",
                                  marginBottom: "5px",
                                }}
                                onClick={() => {
                                  handleItemEdit(item, item.item_type);
                                }}
                              >
                                <span
                                  style={{
                                    color: "#000",
                                    fontSize: "12px",
                                    cursor: "pointer",
                                    fontWeight: 700,
                                  }}
                                >
                                  Consumed products
                                </span>
                                <span
                                  style={{
                                    width: "100px",
                                    height: "10px",
                                    content: "",
                                    position: "absolute",
                                    left: 0,
                                    right: "auto",
                                    bottom: 0,
                                    top: "auto",
                                    margin: "auto",
                                    background:
                                      "linear-gradient(90deg, rgba(46, 86, 242, .2), rgba(46, 86, 242, 0))",
                                  }}
                                ></span>
                              </div>
                            </>
                          )}
                        {item.item_type == "Service" &&
                          item.service_consumption &&
                          item.service_consumption.length > 0 &&
                          item.service_consumption.map(
                            (serviceitem: any, index: any) => (
                              <>
                                <div
                                  key={index}
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "1px 0 12px 8px",
                                    marginBottom: "5px",
                                  }}
                                  onClick={() => {
                                    handleItemEdit(item, item.item_type);
                                  }}
                                >
                                  <span
                                    style={{
                                      color: "#333",
                                      fontSize: "12px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {serviceitem.product_name}
                                  </span>
                                  <span
                                    style={{
                                      color: "#333",
                                      fontSize: "12px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {serviceitem.product_qty}{" "}
                                    {serviceitem.product_unit_name}
                                  </span>
                                </div>
                              </>
                            )
                          )}
                        <Col span={24}>
                          {item.item_note && item.item_note.length > 0 ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "start",
                              }}
                            >
                              <span
                                style={{
                                  color: "#666",
                                  fontSize: "12px",
                                  cursor: "pointer",
                                  marginRight: "30px",
                                }}
                                onClick={() => {
                                  handleItemEdit(item, item.item_type);
                                }}
                              >
                                Note : {item.item_note}
                              </span>
                            </div>
                          ) : null}
                        </Col>
                      </>
                    ))}
                </div>

                {cartItems && cartItems.length == 0 && (
                  <Form.Item>
                    <div
                      style={{
                        textAlign: "center",
                        color: "#333",
                        fontSize: "16px",
                        margin: "16px 0",
                        fontWeight: 500,
                      }}
                    >
                      Your cart is empty
                      <br />
                      <span style={{ fontSize: "14px", color: "#bfbfbf" }}>
                        Please choose at least one item for checkout
                      </span>
                    </div>
                  </Form.Item>
                )}
                <Form.Item>
                  <div
                    style={{
                      margin: "auto",
                      padding: "4px 8px",
                      background: "rgba(255, 151, 30, .1)",
                      borderRadius: 4,
                      display: "flex",
                      justifyContent: "space-between",
                      cursor: "pointer",
                    }}
                    onClick={handleNotesEdit}
                  >
                    <div
                      style={{
                        display: "flex",
                        verticalAlign: "top",
                        gap: "5px",
                      }}
                    >
                      <Typography.Text
                        style={{
                          color: "#595959",
                          fontWeight: 500,
                          whiteSpace: "nowrap",
                        }}
                      >
                        Note:
                      </Typography.Text>
                      {notesValue ? (
                        <Typography.Text
                          style={{
                            color: "rgba(0, 0, 0, .45)",
                            fontWeight: 500,
                            whiteSpace: "pre-wrap",
                            wordBreak: "break-word",
                          }}
                        >
                          {formattedText()}
                        </Typography.Text>
                      ) : (
                        <Typography.Text
                          style={{
                            color: "rgba(0, 0, 0, .45)",
                            fontWeight: 500,
                            whiteSpace: "pre-wrap",
                            wordBreak: "break-word",
                          }}
                        >
                          Write a note here
                        </Typography.Text>
                      )}
                    </div>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <EditOutlined
                        style={{
                          cursor: "pointer",
                          fontSize: "18px",
                          lineHeight: "30px",
                          color: "rgba(46, 86, 242, .5)",
                        }}
                      />
                    </div>
                  </div>
                </Form.Item>
              </div>

              <div
                style={{
                  position: "absolute",
                  left: 0,
                  right: 0,
                  bottom: 0,
                  top: "auto",
                  margin: "auto",
                  zIndex: 1,
                  padding: "8px 18px 8px 18px",
                  background: "#fff",
                  borderRadius: "0 0 12px 12px",
                  textAlign: "right",
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                {isDetailsVisible && (
                  <>
                    {discountAmount !== 0 && (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <span
                              style={{
                                color: "#2e56f2",
                                fontWeight: 700,
                                fontSize: "12px",
                                textDecoration: "underline",
                                marginRight: "10px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setShowDiscountModal(true);
                              }}
                            >
                              Cart Discount
                            </span>
                            <span>
                              <DeleteOutlined onClick={handleRemoveDiscount} />
                            </span>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <span
                              style={{
                                color: "#F43B00",
                                fontSize: "14px",
                                fontWeight: 700,
                              }}
                            >
                              -${formatToTwoDecimalPlaces(discountAmount)}
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <span
                          style={{
                            color: "#999",
                            fontWeight: 700,
                            fontSize: "12px",
                          }}
                        >
                          Subtotal
                        </span>
                      </div>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <span
                          style={{
                            color: "#F43B00",
                            fontSize: "14px",
                            fontWeight: 700,
                          }}
                        >
                          ${formatToTwoDecimalPlaces(subTotal)}
                        </span>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <span
                          style={{
                            color: " #999",
                            fontWeight: 700,
                            fontSize: "12px",
                          }}
                        >
                          Taxes
                        </span>
                      </div>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <span
                          style={{
                            color: "#F43B00",
                            fontSize: "14px",
                            fontWeight: 700,
                          }}
                        >
                          ${formatToTwoDecimalPlaces(taxValue)}
                        </span>
                      </div>
                    </div>
                  </>
                )}
                {cartItems && cartItems.length > 0 && (
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "4px",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          color: "#333",
                          fontWeight: 700,
                          fontSize: "12px",
                        }}
                      >
                        Total
                      </span>
                      <div onClick={toggleDetails}>
                        {isDetailsVisible ? (
                          <DownOutlined
                            style={{
                              fontSize: "12px",
                              color: "#666",
                              cursor: "pointer",
                            }}
                          />
                        ) : (
                          <UpOutlined
                            style={{
                              fontSize: "12px",
                              color: "#666",
                              cursor: "pointer",
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <span
                        style={{
                          color: "#F43B00",
                          fontSize: "14px",
                          fontWeight: 700,
                        }}
                      >
                        ${formatToTwoDecimalPlaces(total)}
                      </span>
                    </div>
                  </div>
                )}
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Dropdown
                    menu={{ items }}
                    placement="topLeft"
                    arrow={{ pointAtCenter: true }}
                  >
                    <Button
                      icon={<MoreOutlined />}
                      type="default"
                      // onClick={handleYearPrevious}
                    />
                  </Dropdown>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      style={{
                        background: "rgba(46, 86, 242, .1)",
                        color: "#2E56F2",
                        minWidth: "72px",
                        padding: "9px 12px",
                        fontWeight: 700,
                        marginRight: "12px",
                        lineHeight: "20px",
                        height: "40px",
                        borderRadius: "6px",
                        display: "inline-block",
                        cursor: "pointer",
                        border: "none",
                      }}
                      disabled={payNowClick}
                      onClick={handleSaveUnpaid}
                    >
                      Save as unpaid
                    </Button>
                    <Button
                      type="primary"
                      style={{
                        marginRight: 0,
                        fontWeight: 700,
                        lineHeight: "20px",
                        padding: "9px 18px",
                        height: "40px",
                        background: "#2E56F2",
                        color: "#fff",
                        borderRadius: "6px",
                        borderColor: "transparent !important",
                        cursor: "pointer",
                      }}
                      disabled={payNowClick}
                      onClick={handlePayNow}
                    >
                      Pay now
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
      <EditCartDiscountModel
        visible={showDiscountModal}
        value={discountValue}
        discountamount={discountAmount}
        valuetype={discountType}
        totalvalue={subTotal}
        taxrate={taxRate}
        subTotalvalue={subTotal}
        onClose={() => {
          setShowDiscountModal(false);
        }}
        onSubmit={handleDiscountValue}
      />
      <SingleDateSelectionModal
        visible={showSingleDateSelectionModal}
        initialSelectedDate={selectedDate} // Pass the selected date from parent to modal
        onDateSelected={handleDateSelected}
        onCancel={handleSingleDateSelectionClose}
      />
      <SelectServiceStaff
        visible={ShowServiceStaffEditModal}
        servicename={selectedItem ? selectedItem.name : ""}
        onClose={() => {
          setShowServiceStaffEditModal(false);
        }}
        staffArray={selectedItem ? selectedItem.staff : []}
        onSelect={handleServiceStaffChange}
      />
      <SelectProductStaff
        visible={ShowProductStaffEditModal}
        servicename={
          selectedItem ? selectedItem.name || selectedItem.item_name : ""
        }
        onClose={() => {
          setShowProductStaffEditModal(false);
        }}
        staffArray={selectedItem ? selectedItem.staff : []}
        onSelect={handleProductStaffChange}
      />
      <SelectServiceStaff
        visible={showServiceModal}
        servicename={selectedItem ? selectedItem.name : ""}
        onClose={() => {
          setShowServiceModal(false);
        }}
        onSelect={handleServiceStaffSelection}
      />
      <SelectProductStaff
        visible={showProductModal}
        servicename={
          selectedItem ? selectedItem.name || selectedItem.item_name : ""
        }
        onClose={() => {
          setShowProductModal(false);
        }}
        onSelect={handleProductStaffSelection}
      />
      <RefNumModal
        visible={showRefNumModal}
        onClose={() => {
          setShowRefNumModal(false);
        }}
        onSelect={handleRefSelection}
      />
      <BillingInfoModel
        visible={billInfoModalVisible}
        selectedData={{
          selecteddate: selectedDate,
          selectedRef: refNum,
          selectedCustomerSource: selectedCustomerSource,
          electedReferralType: selectedReferralType,
          selectedReferrer: selectedReferrer,
          selectedReferrerId: selectedReferrerId,
        }}
        customerSources={customerSources}
        onCancel={() => {
          setBillInfoModalVisible(false);
        }}
        onSubmit={handleBillInfoData}
      />
      <NotesModal
        visible={showNotesEditModal}
        onClose={() => {
          setShowNotesEditModal(false);
        }}
        onConfirm={handleNoteAdd}
        value={notesValue}
      />
      <ProductEditModel
        visible={showProductEditModal}
        selectedItem={selectedItem}
        servicename={selectedItem ? selectedItem.item_name : ""}
        onClose={() => {
          setShowProductEditModal(false);
        }}
        onSelect={handleCartProductUpdate}
      />
      <GiftcardEditModel
        visible={showGiftcardEditModal}
        selectedItem={selectedItem}
        servicename={selectedItem ? selectedItem.item_name : ""}
        onClose={() => {
          setShowGiftcardEditModal(false);
        }}
        onSelect={handleCartProductUpdate}
      />
      <ServiceEditProductEditModel
        visible={showServiceEditModal}
        selectedItem={selectedItem}
        servicename={selectedItem ? selectedItem.item_name : ""}
        onClose={() => {
          setShowServiceEditModal(false);
        }}
        onSelect={handleCartServiceUpdate}
      />
      <SearchCustomerModel
        visible={showCustomerModal}
        onClose={handleShowCustomerCancelModal}
        onSelect={handleCustomerSelection}
      />

      {/* <GiftcardAreaOfApplicationModel
      visible={true}
      onClose={()=>{}}
      />  */}
      <ConfirmDeleteModal
        visible={isDeleteMemberModalVisible}
        onCancel={closeDeleteMemberModal}
        // onConfirm={() => handleDeleteApi(editingRecord.key)}
        onConfirm={handleConfirmMember}
        message="Are you sure you want to remove the member?"
      />
      <ConfirmDeleteModal
        visible={isDeleteServiceModalVisible}
        onCancel={closeDeleteServiceModal}
        // onConfirm={() => handleDeleteApi(editingRecord.key)}
        onConfirm={handleConfirmService}
        header={selectedItem ? selectedItem.item_name : ""}
        message="Are you sure you want to delete?"
      />
    </Content>
  );
};

export default BillingPage;
