import React, { useEffect, useState } from "react";
import { Modal, Typography, Input, Button } from "antd";

const { TextArea } = Input;
const { Text } = Typography;

interface RefNumModalProps {
  visible: boolean;
  onClose: () => void;
  onSelect: (refnum: any) => void;
}

const RefNumModal: React.FC<RefNumModalProps> = ({
  visible,
  onClose,
  onSelect,
}) => {
  const [refnum, setRefnum] = useState<string>("");

  useEffect(() => {
    setRefnum("");
  }, [visible]);

  const handleConfirm = async () => {
    onSelect(refnum);
    onClose();
  };

  const handleCancel = async () => {
    onClose();
  };

  return (
    <Modal
      visible={visible}
      title="Ref#"
      onCancel={onClose}
      footer={null}
      centered
      bodyStyle={{ paddingBottom: "0" }}
    >
      <Input
        placeholder="Please enter the ref# number"
        value={refnum}
        minLength={5}
        onChange={(e) => setRefnum(e.target.value)}
        allowClear
      />
      <div
        style={{ textAlign: "center", marginTop: "20px", marginBottom: "12px" }}
      >
        <Text style={{ color: "#ffb800", fontWeight: "700" }}>
          The ref# number can consist of digits, letters, or a combination of
          both, with a minimum length of 5 characters
        </Text>
      </div>
      <div style={{ textAlign: "right" }}>
        <Button
          type="default"
          onClick={handleCancel}
          style={{ marginRight: "10px" }}
        >
          Cancel
        </Button>
        <Button type="primary" onClick={handleConfirm}>
          Confirm
        </Button>
      </div>
    </Modal>
  );
};

export default RefNumModal;
