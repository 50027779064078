import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Switch,
  Radio,
  Button,
  Row,
  Col,
  Select,
  Tabs,
  DatePicker,
  InputNumber,
  Table,
  Tooltip,
  Divider,
} from "antd";
// import "../../pages/membership/Membership.css"; // Import your custom CSS file
import "../../../pages/membership/Membership.css";
import ServiceExcPrice from "./ServiceExcPrice";
import ProductExcPrice from "./ProductExcPrice";
import PrivilegeExcPrice from "./PrivilegeExcPrice";
import dayjs, { Dayjs } from "dayjs";
import { InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import ServiceByCategoryModel from "./ServiceByCategory";

const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const { TextArea } = Input;

interface MembershipModelProps {
  visible: boolean;
  onCancel: () => void;
  onSubmit: (values: any) => void;
}

const MembershipModel: React.FC<MembershipModelProps> = ({
  visible,
  onCancel,
  onSubmit,
}) => {
  const initialFormValues = {};
  const [formValues, setFormValues] = useState(initialFormValues);
  const [submitting, setSubmitting] = useState(false);
  const [membershipCode, setMembershipCode] = useState<string>("");
  const [membershipType, setMembershipType] = useState<string>("prepaid");
  const [validityType, setValidityType] = useState<string>("custom");
  const [validityUnit, setValidityUnit] = useState<string>("year");
  const [selectedTab, setSelectedTab] = useState("service");
  const [activeKey, setActiveKey] = useState("service");
  const [modalVisible, setModalVisible] = useState(false);
  const [isProductExcPriceVisible, setProductExcPriceVisible] = useState(false);
  const [isPrivilegeExcPriceVisible, setPrivilegeExcPriceVisible] =
    useState(false);
  const [isLimitedUsage, setIsLimitedUsage] = useState(true);
  const [isLimitedUsageVisitbased, isSetIsLimitedUsage] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedServices, setSelectedServices] = useState<any[]>([]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSwitchChange = (checked: any) => {
    setIsDisabled(checked);
  };
  const handleSubmit = async () => {};

  const showPrivilegeExcPriceModal = () => {
    setPrivilegeExcPriceVisible(true);
  };

  const hidePrivilegeExcPriceModal = () => {
    setPrivilegeExcPriceVisible(false);
  };

  const handlePrivilegeExcPriceSave = (selectedItems: any) => {
    console.log("Selected items:", selectedItems);
    // Handle the save logic here
    setPrivilegeExcPriceVisible(false); // Close the modal after saving
  };

  const showProductExcPriceModal = () => {
    setProductExcPriceVisible(true);
  };

  const hideProductExcPriceModal = () => {
    setProductExcPriceVisible(false);
  };

  const handleProductExcPriceSave = (selectedItems: any) => {
    console.log("Selected items:", selectedItems);
    // Handle the save logic
    setProductExcPriceVisible(false); // Close the modal after saving
  };

  const handleAddClickSeviceExcPrice = () => {
    setModalVisible(true);
  };

  const handleModalCancel = () => {
    setModalVisible(false);
  };

  const handleModalSave = () => {
    // Logic for saving the selected services can go here
    console.log("Services saved");
    setModalVisible(false); // Close modal after saving
  };

  const handleSaveServices = (selectedData: any[]) => {
    const updatedProducts = selectedData.map((product, index) => ({
      ...product,
      number: index + 1,
      key: product.id || index + 1,
      expected_qty: product.stockcount || 1,
      counted_qty: product.stockcount || 1,
      difference: (product.counted_qty || 1) - (product.expected_qty || 1),
    }));
  };

  const { TabPane } = Tabs;

  // Columns definition for the table
  const columns = [
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Exclusive price",
      dataIndex: "exclusivePrice",
      key: "exclusivePrice",
      // render: (text: number, record: Service) => (
      //   <InputNumber
      //   addonBefore={<span className="textSuffix">$</span>}
      //     min={0}
      //     value={text}
      //     onChange={(value: any) => handleCountedChange(value, record.key)}
      //   />
      // ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      // render: (_: any, record: Service) => (
      //   <a
      //     style={{
      //       color: "rgb(245, 63, 63)",
      //       fontWeight: 700,
      //       textDecoration: "underline",
      //       textUnderlineOffset: "4px",
      //     }}
      //     // onClick={() => handleDeleteProduct(record.key)}
      //   >
      //     Delete
      //   </a>
      // ),
    },
  ];
  const data: any = [];
  return (
    <>
      <Modal
        title="Add New Membership"
        visible={visible}
        onCancel={onCancel}
        footer={[
          <>
            <Divider style={{ margin: "10px 0px" }} />
            <Button key="save" type="primary" onClick={handleSubmit}>
              Save
            </Button>
            ,
          </>,
        ]}
        style={{ top: "80px" }}
        bodyStyle={{
          height: "calc(100vh - 340px)",
          overflowY: "auto",
          overflowX: "hidden",
          scrollbarWidth: "thin",
        }}
        width={800}
      >
        <Form onFinish={handleSubmit} layout="vertical">
          <Row gutter={16}>
            <Col span={9}>
              <Form.Item
                label={
                  <>
                    Membership Code
                    <span
                      style={{
                        marginLeft: "25px",
                        color: "#2e56f2",
                        cursor: "pointer",
                      }}
                    >
                      Auto Generated
                    </span>
                  </>
                }
                name="membershipCode"
                required
              >
                <Input
                  placeholder="Please enter"
                  value={membershipCode}
                  onChange={(e) => setMembershipCode(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Membership Name" name="membershipName" required>
                <Input placeholder="Please enter" />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                label="Usage Status"
                name="usageStatus"
                valuePropName="checked"
                tooltip={{
                  title:
                    "Once the switch is turned off, the membership will be disabled and cannot be sold.",
                  icon: (
                    <InfoCircleOutlined
                      style={{ color: "rgba(46, 86, 242, .5)" }}
                    />
                  ),
                }}
              >
                <Switch defaultChecked />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label="Description" name="description">
            <Input.TextArea rows={3} placeholder="Please enter" />
          </Form.Item>

          <div>
            <h3 style={{ display: "flex", alignItems: "center" }}>
              <span className="vertical-line"></span>Type of Membership
            </h3>
          </div>

          <Radio.Group
            value={membershipType}
            onChange={(e) => setMembershipType(e.target.value)}
          >
            <Radio value="prepaid">Prepaid</Radio>
            <Radio value="discount">Discount</Radio>
            <Radio value="visit-based">Visit-based</Radio>
          </Radio.Group>
          {/* Prepaid membership type logic (existing) */}

          {/* Prepaid Membership Type */}
          {membershipType === "prepaid" && (
            <div className="prepaid-container">
              <Tabs defaultActiveKey="1">
                <TabPane tab="Value" key="1">
                  <Row gutter={16}>
                    <Col span={8}>
                      <Form.Item
                        label="Selling Price"
                        name="sellingPrice"
                        required
                      >
                        <Input placeholder="Please enter" />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Tax" name="tax">
                        <Select
                          defaultValue="0%"
                          dropdownStyle={{ textAlign: "center" }}
                        >
                          <Option value="0%">No tax (0%)</Option>
                          <Option value="5%">5%</Option>
                          <Option value="10%">10%</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Value" name="value" required>
                        <Input placeholder="Enter value" />
                      </Form.Item>
                    </Col>
                  </Row>

                  {/* Discounts and Commissions */}
                  <Row gutter={16}>
                    <Col span={8}>
                      <Form.Item
                        label="Product Discount"
                        name="productDiscount"
                        tooltip={{
                          title:
                            "Limited number between 1 to 100, e.g., enter 30 to apply a 30% discount",
                          icon: (
                            <InfoCircleOutlined
                              style={{ color: "rgba(46, 86, 242, .5)" }}
                            />
                          ),
                        }}
                        required
                      >
                        <Input
                          placeholder="Enter value"
                          suffix={
                            <span
                              style={{
                                color: "rgba(46, 86, 242, .5)",
                                fontWeight: 700,
                              }}
                            >
                              %
                            </span>
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Service discount"
                        name="serviceDiscount"
                        tooltip={{
                          title:
                            "Limited number between 1 to 100, eg: enter 30 to apply a 30% discount",
                          icon: (
                            <InfoCircleOutlined
                              style={{ color: "rgba(46, 86, 242, .5)" }}
                            />
                          ),
                        }}
                        required
                      >
                        <Input
                          placeholder="Enter value"
                          suffix={
                            <span
                              style={{
                                color: "rgba(46, 86, 242, .5)",
                                fontWeight: 700,
                              }}
                            >
                              %
                            </span>
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Privilege Discount"
                        name="privilegeDiscount"
                        tooltip={{
                          title:
                            "Limited number between 1 to 100, eg: enter 30 to apply a 30% discount",
                          icon: (
                            <InfoCircleOutlined
                              style={{ color: "rgba(46, 86, 242, .5)" }}
                            />
                          ),
                        }}
                        required
                      >
                        <Input
                          placeholder="Enter value"
                          suffix={
                            <span
                              style={{
                                color: "rgba(46, 86, 242, .5)",
                                fontWeight: 700,
                              }}
                            >
                              %
                            </span>
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col span={8}>
                      <Form.Item
                        label="Product Sales Performance"
                        name="productSalesPerformance"
                        required
                      >
                        <Input
                          placeholder="Enter value"
                          suffix={
                            <span
                              style={{
                                color: "rgba(46, 86, 242, .5)",
                                fontWeight: 700,
                              }}
                            >
                              %
                            </span>
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Service Sales Performance"
                        name="serviceSalesPerformance"
                        required
                      >
                        <Input
                          placeholder="Enter value"
                          suffix={
                            <span
                              style={{
                                color: "rgba(46, 86, 242, .5)",
                                fontWeight: 700,
                              }}
                            >
                              %
                            </span>
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Privilege Card Sales Performance"
                        name="privilegeCardSalesPerformance"
                        required
                      >
                        <Input
                          placeholder="Enter value"
                          suffix={
                            <span
                              style={{
                                color: "rgba(46, 86, 242, .5)",
                                fontWeight: 700,
                              }}
                            >
                              %
                            </span>
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col span={8}>
                      <Form.Item
                        label="Product Sales Commission"
                        name="productSalesCommission"
                        required
                      >
                        <Input
                          placeholder="Enter value"
                          suffix={
                            <span
                              style={{
                                color: "rgba(46, 86, 242, .5)",
                                fontWeight: 700,
                              }}
                            >
                              %
                            </span>
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Service Sales Commission"
                        name="serviceSalesCommission"
                        required
                      >
                        <Input
                          placeholder="Enter value"
                          suffix={
                            <span
                              style={{
                                color: "rgba(46, 86, 242, .5)",
                                fontWeight: 700,
                              }}
                            >
                              %
                            </span>
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Privilege Card Sales Commission"
                        name="privilegeCardSalesCommission"
                        required
                      >
                        <Input
                          placeholder="Enter value"
                          suffix={
                            <span
                              style={{
                                color: "rgba(46, 86, 242, .5)",
                                fontWeight: 700,
                              }}
                            >
                              %
                            </span>
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Conversion rate for service points"
                        name="conversionRateForServicePoints"
                      >
                        <Input
                          placeholder="Enter value"
                          suffix={
                            <span
                              style={{
                                color: "rgba(46, 86, 242, .5)",
                                fontWeight: 700,
                              }}
                            >
                              %
                            </span>
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Conversion rate for product points"
                        name="conversionRateForProductePoints"
                      >
                        <Input
                          placeholder="Enter value"
                          suffix={
                            <span
                              style={{
                                color: "rgba(46, 86, 242, .5)",
                                fontWeight: 700,
                              }}
                            >
                              %
                            </span>
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </TabPane>

                <TabPane tab="Bonus" key="2">
                  {/* Fields for Bonus tab */}
                  <Row gutter={16}>
                    <Col span={8}>
                      <Form.Item label="Bonus Value" name="bonusValue" required>
                        <Input placeholder="Enter value" />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Same with the Value Settings"
                        name="sameAsValueSettings"
                        valuePropName="checked"
                      >
                        <Switch onChange={handleSwitchChange} defaultChecked />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Product Discount"
                        name="bonusProductDiscount"
                        required
                      >
                        <Input
                          placeholder="Enter value"
                          suffix={
                            <span
                              style={{
                                color: "rgba(46, 86, 242, .5)",
                                fontWeight: 700,
                              }}
                            >
                              %
                            </span>
                          }
                          disabled={isDisabled}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  {/* Additional fields for Bonus tab */}
                  <Row gutter={16}>
                    <Col span={8}>
                      <Form.Item
                        label="Service discount"
                        name="bonusServiceDiscount"
                        required
                      >
                        <Input
                          placeholder="Enter value"
                          suffix={
                            <span
                              style={{
                                color: "rgba(46, 86, 242, .5)",
                                fontWeight: 700,
                              }}
                            >
                              %
                            </span>
                          }
                          disabled={isDisabled}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Privilege Discount"
                        name="bonusPrivilegeDiscount"
                        required
                      >
                        <Input
                          placeholder="Enter value"
                          suffix={
                            <span
                              style={{
                                color: "rgba(46, 86, 242, .5)",
                                fontWeight: 700,
                              }}
                            >
                              %
                            </span>
                          }
                          disabled={isDisabled}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Product Sales Performance"
                        name="bonusProductSalesPerformance"
                        required
                      >
                        <Input
                          placeholder="Enter value"
                          suffix={
                            <span
                              style={{
                                color: "rgba(46, 86, 242, .5)",
                                fontWeight: 700,
                              }}
                            >
                              %
                            </span>
                          }
                          disabled={isDisabled}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col span={8}>
                      <Form.Item
                        label="Service Sales Performance"
                        name="bonusServiceSalesPerformance"
                        required
                      >
                        <Input
                          placeholder="Enter value"
                          suffix={
                            <span
                              style={{
                                color: "rgba(46, 86, 242, .5)",
                                fontWeight: 700,
                              }}
                            >
                              %
                            </span>
                          }
                          disabled={isDisabled}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Privilege Card Sales Performance"
                        name="bonusPrivilegeCardSalesPerformance"
                      >
                        <Input
                          placeholder="Enter value"
                          suffix={
                            <span
                              style={{
                                color: "rgba(46, 86, 242, .5)",
                                fontWeight: 700,
                              }}
                            >
                              %
                            </span>
                          }
                          disabled={isDisabled}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Product Sales Commission"
                        name="bonusProductSalesCommission"
                        required
                      >
                        <Input
                          placeholder="Enter value"
                          suffix={
                            <span
                              style={{
                                color: "rgba(46, 86, 242, .5)",
                                fontWeight: 700,
                              }}
                            >
                              %
                            </span>
                          }
                          disabled={isDisabled}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col span={8}>
                      <Form.Item
                        label="Service Sales Commission"
                        name="bonusServiceSalesCommission"
                        required
                      >
                        <Input
                          placeholder="Enter value"
                          suffix={
                            <span
                              style={{
                                color: "rgba(46, 86, 242, .5)",
                                fontWeight: 700,
                              }}
                            >
                              %
                            </span>
                          }
                          disabled={isDisabled}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Privilege Card Sales Commission"
                        name="bonusPrivilegeCardSalesCommission"
                        required
                      >
                        <Input
                          placeholder="Enter value"
                          suffix={
                            <span
                              style={{
                                color: "rgba(46, 86, 242, .5)",
                                fontWeight: 700,
                              }}
                            >
                              %
                            </span>
                          }
                          disabled={isDisabled}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Conversion Rate for Product Points"
                        name="productPointsConversionRate"
                      >
                        <Input
                          placeholder="Enter value"
                          suffix={
                            <span
                              style={{
                                color: "rgba(46, 86, 242, .5)",
                                fontWeight: 700,
                              }}
                            >
                              %
                            </span>
                          }
                          disabled={isDisabled}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col span={8}>
                      <Form.Item
                        label="Conversion Rate for Service Points"
                        name="servicePointsConversionRate"
                      >
                        <Input
                          placeholder="Enter value"
                          suffix={
                            <span
                              style={{
                                color: "rgba(46, 86, 242, .5)",
                                fontWeight: 700,
                              }}
                            >
                              %
                            </span>
                          }
                          disabled={isDisabled}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </TabPane>
              </Tabs>
            </div>
          )}

          {/* Conditional Rendering for Discount Type */}
          {membershipType === "discount" && (
            <div className="visit-based-container">
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item label="Selling price" name="sellingPrice" required>
                    <Input
                      placeholder="Please enter"
                      prefix={
                        <span
                          style={{
                            color: "rgba(46, 86, 242, .5)",
                            fontWeight: 700,
                          }}
                        >
                          $
                        </span>
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Tax" name="tax">
                    <Select
                      defaultValue="0%"
                      dropdownStyle={{ textAlign: "center" }}
                    >
                      <Option value="0%">No tax (0%)</Option>
                      <Option value="5%">5%</Option>
                      <Option value="10%">10%</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label={
                      <>
                        Max usage
                        <span
                          style={{
                            marginLeft: "10px",
                            color: "#1890ff",
                            cursor: "pointer",
                          }}
                        >
                          <Switch
                            checked={isLimitedUsage}
                            onChange={(checked) => setIsLimitedUsage(checked)}
                            style={{ marginRight: 1 }}
                            size="small"
                          />
                          <span
                            style={{
                              fontSize: "14px",
                              color: "rgba(0, 0, 0, 0.85)",
                            }}
                          >
                            limited usage
                          </span>
                        </span>
                      </>
                    }
                    required
                  >
                    <Form.Item name="maxUsage" noStyle>
                      <Input
                        placeholder="Please enter"
                        disabled={!isLimitedUsage}
                        suffix={
                          <span
                            style={{
                              color: "rgba(46, 86, 242, 0.8)",
                              fontWeight: 600,
                            }}
                          >
                            times
                          </span>
                        }
                      />
                    </Form.Item>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    label="Product discount"
                    name="productDiscount"
                    tooltip={{
                      title:
                        "Limited number between 1 to 100, eg: enter 30 to apply a 30% discount",
                      icon: (
                        <InfoCircleOutlined
                          style={{ color: "rgba(46, 86, 242, .5)" }}
                        />
                      ),
                    }}
                    required
                  >
                    <Input
                      placeholder="Enter value"
                      suffix={
                        <span
                          style={{
                            color: "rgba(46, 86, 242, .5)",
                            fontWeight: 700,
                          }}
                        >
                          %
                        </span>
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Service discount"
                    name="serviceDiscount"
                    tooltip={{
                      title:
                        "Limited number between 1 to 100, eg: enter 30 to apply a 30% discount",
                      icon: (
                        <InfoCircleOutlined
                          style={{ color: "rgba(46, 86, 242, .5)" }}
                        />
                      ),
                    }}
                    required
                  >
                    <Input
                      placeholder="Enter value"
                      suffix={
                        <span
                          style={{
                            color: "rgba(46, 86, 242, .5)",
                            fontWeight: 700,
                          }}
                        >
                          %
                        </span>
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Privilege discount"
                    name="privilegeDiscount"
                    tooltip={{
                      title:
                        "Limited number between 1 to 100, eg: enter 30 to apply a 30% discount",
                      icon: (
                        <InfoCircleOutlined
                          style={{ color: "rgba(46, 86, 242, .5)" }}
                        />
                      ),
                    }}
                    required
                  >
                    <Input
                      placeholder="Enter value"
                      suffix={
                        <span
                          style={{
                            color: "rgba(46, 86, 242, .5)",
                            fontWeight: 700,
                          }}
                        >
                          %
                        </span>
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          )}

          {/* Visit-based membership type logic */}
          {membershipType === "visit-based" && (
            <div className="visit-based-container">
              {" "}
              {/* Add CSS class for visit-based layout */}
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item label="Service" name="service">
                    <Input
                      placeholder="Service"
                      suffix={<span className="textSuffix">&gt;</span>}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      marginTop: "40px",
                      marginLeft: "20px",
                    }}
                  >
                    <span style={{ marginRight: "6px" }}>Price: </span>
                    <span>$0</span>{" "}
                    {/* Dynamic price logic can be added here */}
                  </div>
                </Col>
                <Col span={8}>
                  <Form.Item label="Tax" name="tax">
                    <Select
                      defaultValue="0%"
                      dropdownStyle={{ textAlign: "center" }}
                    >
                      <Option value="0%">No tax (0%)</Option>
                      <Option value="5%">5%</Option>
                      <Option value="10%">10%</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={8}>
                  <div style={{ display: "flex", gap: "5px" }}>
                    <span>Usage limit</span>
                    <Switch
                      checked={isLimitedUsageVisitbased}
                      onChange={(checked) => isSetIsLimitedUsage(checked)}
                      style={{ marginRight: 1 }}
                      size="small"
                      defaultChecked
                    />
                    <span style={{ marginLeft: "420px" }}>Total Price</span>
                  </div>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item name="buyLimit">
                    <Input
                      addonBefore={<span className="textSuffix">Buy</span>}
                      placeholder="1"
                      type="number"
                      disabled={!isLimitedUsageVisitbased}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="giveawayLimit">
                    <Input
                      addonBefore={<span className="textSuffix">Giveaway</span>}
                      placeholder="Enter value"
                      type="number"
                      disabled={!isLimitedUsageVisitbased}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="" name="totalPrice" required>
                    <Input
                      placeholder="Please enter"
                      suffix={
                        <span
                          style={{
                            color: "rgba(46, 86, 242, .5)",
                            fontWeight: 700,
                          }}
                        >
                          %
                        </span>
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          )}

          <div>
            <h3 style={{ display: "flex", alignItems: "center" }}>
              <span className="vertical-line"></span>Type of Validity
            </h3>
          </div>

          {/* <Form.Item label="" name="validityType"> */}
          <Radio.Group
            value={validityType}
            onChange={(e) => setValidityType(e.target.value)}
          >
            <Radio value="custom">Custom</Radio>
            <Radio value="immediately">Immediately</Radio>
            <Radio value="effectiveOnConsumption">
              Effective on consumption day
            </Radio>
          </Radio.Group>
          {/* </Form.Item> */}

          {validityType === "custom" && (
            <div className="validity-section">
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Valid for" name="validFor" required>
                    <RangePicker style={{ width: "100%" }} 
                    ranges={{
                      Today: [dayjs().startOf("day"), dayjs().endOf("day")],
                      Yesterday: [
                        dayjs().subtract(1, "day").startOf("day"),
                        dayjs().subtract(1, "day").endOf("day"),
                      ],
                      "This Week": [
                        dayjs().startOf("week"),
                        dayjs().endOf("week"),
                      ],
                      "This Month": [
                        dayjs().startOf("month"),
                        dayjs().endOf("month"),
                      ],
                      "Last Month": [
                        dayjs().subtract(1, "month").startOf("month"),
                        dayjs().subtract(1, "month").endOf("month"),
                      ],
                    }}/>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Selling Period" name="sellingPeriod">
                    <RangePicker style={{ width: "100%" }} 
                     ranges={{
                      Today: [dayjs().startOf("day"), dayjs().endOf("day")],
                      Yesterday: [
                        dayjs().subtract(1, "day").startOf("day"),
                        dayjs().subtract(1, "day").endOf("day"),
                      ],
                      "This Week": [
                        dayjs().startOf("week"),
                        dayjs().endOf("week"),
                      ],
                      "This Month": [
                        dayjs().startOf("month"),
                        dayjs().endOf("month"),
                      ],
                      "Last Month": [
                        dayjs().subtract(1, "month").startOf("month"),
                        dayjs().subtract(1, "month").endOf("month"),
                      ],
                    }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          )}

          {validityType === "immediately" && (
            <div className="validity-section">
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    label="Valid for"
                    name="validForImmediate"
                    required
                  >
                    <Input.Group compact>
                      <InputNumber
                        min={1}
                        defaultValue={10}
                        style={{ width: "70%", height: "42px" }}
                      />
                      <Select
                        value={validityUnit}
                        onChange={(value) => setValidityUnit(value)}
                        style={{ width: "30%", height: "40px" }}
                        dropdownStyle={{ textAlign: "center" }}
                      >
                        <Option value="day">day</Option>
                        <Option value="month">month</Option>
                        <Option value="year">year</Option>
                      </Select>
                    </Input.Group>
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <Form.Item
                    label="Selling period"
                    name="sellingPeriodImmediate"
                  >
                    <RangePicker style={{ width: "100%" }} 
                     ranges={{
                      Today: [dayjs().startOf("day"), dayjs().endOf("day")],
                      Yesterday: [
                        dayjs().subtract(1, "day").startOf("day"),
                        dayjs().subtract(1, "day").endOf("day"),
                      ],
                      "This Week": [
                        dayjs().startOf("week"),
                        dayjs().endOf("week"),
                      ],
                      "This Month": [
                        dayjs().startOf("month"),
                        dayjs().endOf("month"),
                      ],
                      "Last Month": [
                        dayjs().subtract(1, "month").startOf("month"),
                        dayjs().subtract(1, "month").endOf("month"),
                      ],
                    }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          )}

          {validityType === "effectiveOnConsumption" && (
            <div className="validity-section">
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    label="Valid for"
                    name="validForImmediate"
                    required
                  >
                    <Input.Group compact>
                      <InputNumber
                        min={1}
                        defaultValue={10}
                        style={{ width: "70%", height: "42px" }}
                      />
                      <Select
                        value={validityUnit}
                        onChange={(value) => setValidityUnit(value)}
                        style={{ width: "30%", height: "40px" }}
                        dropdownStyle={{ textAlign: "center" }}
                      >
                        <Option value="day">day</Option>
                        <Option value="month">month</Option>
                        <Option value="year">year</Option>
                      </Select>
                    </Input.Group>
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <Form.Item
                    label="Selling period"
                    name="sellingPeriodImmediate"
                  >
                    <RangePicker style={{ width: "100%" }} 
                     ranges={{
                      Today: [dayjs().startOf("day"), dayjs().endOf("day")],
                      Yesterday: [
                        dayjs().subtract(1, "day").startOf("day"),
                        dayjs().subtract(1, "day").endOf("day"),
                      ],
                      "This Week": [
                        dayjs().startOf("week"),
                        dayjs().endOf("week"),
                      ],
                      "This Month": [
                        dayjs().startOf("month"),
                        dayjs().endOf("month"),
                      ],
                      "Last Month": [
                        dayjs().subtract(1, "month").startOf("month"),
                        dayjs().subtract(1, "month").endOf("month"),
                      ],
                    }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          )}

          <div>
            <h3 style={{ display: "flex", alignItems: "center" }}>
              <span className="vertical-line"></span>Applicable to
            </h3>
          </div>
          {/* Tabs for Service, Product, Privilege, Store */}
          <Tabs defaultActiveKey={activeKey} onChange={setActiveKey}>
            <TabPane
              tab="Service"
              key="service"
              style={{
                backgroundColor: "#f9f9f9",
                padding: "16px",
                borderRadius: "8px",
              }}
            >
              <span
                style={{
                  color: "orange",
                  marginBottom: "5px",
                  display: "inline-block",
                }}
              >
                <InfoCircleOutlined style={{ marginRight: "8px" }} />
                Switching the selection method will clear the set data
              </span>

              {/* Radio buttons with proper layout */}
              <Radio.Group defaultValue="allServices">
                <Row gutter={[16, 16]}>
                  <Col span={6}>
                    <Radio value="allServices">
                      <span className="radio-text">All services</span>
                    </Radio>
                  </Col>
                  <Col span={6}>
                    <Radio value="byCategory">
                      <span className="radio-text">By category</span>
                    </Radio>
                  </Col>
                  <Col span={6}>
                    <Radio value="byItem">
                      <span className="radio-text">By item</span>
                    </Radio>
                  </Col>
                  <Col span={6}>
                    <Radio value="notApplicable">
                      <span className="radio-text">Not applicable</span>
                    </Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </TabPane>

            {/* Placeholder for other tabs */}
            <TabPane
              tab="Product"
              key="product"
              style={{
                backgroundColor: "#f9f9f9",
                padding: "16px",
                borderRadius: "8px",
              }}
            >
              {/* Warning message */}
              {/* <Alert
              message=""
              type="warning"
              icon={<InfoCircleOutlined />}
              showIcon
              style={{ marginBottom: 16 }}
            /> */}

              <span
                style={{
                  color: "orange",
                  marginBottom: "5px",
                  display: "inline-block",
                }}
              >
                <InfoCircleOutlined style={{ marginRight: "8px" }} />
                Switching the selection method will clear the set data
              </span>

              {/* Radio buttons with proper layout */}
              <Radio.Group defaultValue="allServices">
                <Row gutter={[16, 16]}>
                  <Col span={6}>
                    <Radio value="allProducts">
                      <span className="radio-text">All products</span>
                    </Radio>
                  </Col>
                  <Col span={6}>
                    <Radio value="byCategory">
                      <span className="radio-text">By category</span>
                    </Radio>
                  </Col>
                  <Col span={6}>
                    <Radio value="byItem">
                      <span className="radio-text">By item</span>
                    </Radio>
                  </Col>
                  <Col span={6}>
                    <Radio value="notApplicable">
                      <span className="radio-text">Not applicable</span>
                    </Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </TabPane>
            <TabPane
              tab="Privilege"
              key="privilege"
              style={{
                backgroundColor: "#f9f9f9",
                padding: "16px",
                borderRadius: "8px",
              }}
            >
              <span
                style={{
                  color: "orange",
                  marginBottom: "5px",
                  display: "inline-block",
                }}
              >
                <InfoCircleOutlined style={{ marginRight: "8px" }} />
                Switching the selection method will clear the set data
              </span>

              {/* Radio buttons with proper layout */}
              <Radio.Group defaultValue="allServices">
                <Row gutter={[16, 16]}>
                  <Col span={8}>
                    <Radio value="allServices">
                      <span className="radio-text">All privileges</span>
                    </Radio>
                  </Col>
                  <Col span={8}>
                    <Radio value="byItem">
                      <span className="radio-text">By item</span>
                    </Radio>
                  </Col>
                  <Col span={8}>
                    <Radio value="notApplicable">
                      <span className="radio-text">Not applicable</span>
                    </Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </TabPane>
            <TabPane
              tab="Store"
              key="store"
              style={{
                backgroundColor: "#f9f9f9",
                padding: "16px",
                borderRadius: "8px",
              }}
            >
              <span
                style={{
                  color: "orange",
                  marginBottom: "5px",
                  display: "inline-block",
                }}
              >
                <InfoCircleOutlined style={{ marginRight: "8px" }} />
                Switching the selection method will clear the set data
              </span>

              <Radio.Group defaultValue="allServices">
                <Row gutter={[16, 16]}>
                  <Col span={8}>
                    <Radio value="allServices">
                      <span className="radio-text">All stores</span>
                    </Radio>
                  </Col>
                  <Col span={10}>
                    <Radio value="currentStore">
                      <span className="radio-text">Current store only</span>
                    </Radio>
                  </Col>
                  <Col span={6}>
                    <Radio value="byStore">
                      <span className="radio-text">By store</span>
                    </Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </TabPane>
          </Tabs>
          {/* Exclusive price section */}
          {/* Exclusive price section - Service */}
          {(membershipType === "prepaid" || membershipType === "discount") &&
            (activeKey === "service" ||
              activeKey === "product" ||
              activeKey === "privilege") && (
              <div style={{ marginTop: "24px" }}>
                <Row
                  justify="space-between"
                  align="middle"
                  style={{ marginBottom: "16px" }}
                >
                  <Col>
                    {/* <h3 style={{ fontWeight: 600 }}>
                  Exclusive price{" "}
                  <span style={{ fontWeight: 400, color: "#888" }}>
                    (Only applies to items selected above)
                  </span>
                </h3> */}

                    <div>
                      <h3 style={{ display: "flex", alignItems: "center" }}>
                        <span className="vertical-line"></span>Exclusive price
                        <span
                          style={{
                            fontWeight: 400,
                            color: "#888",
                            marginLeft: "2px",
                          }}
                        >
                          (Only applies to items selected above)
                        </span>
                      </h3>
                    </div>
                  </Col>
                  <Col>
                    <Button
                      type="link"
                      icon={<PlusOutlined />}
                      onClick={() => showModal()}
                      style={{ color: "#ff7a45" }}
                    >
                      Add
                    </Button>
                  </Col>
                </Row>
                <Table
                  style={{ marginTop: "20px" }}
                  columns={columns}
                  // dataSource="null" // Display selected products in the table
                  locale={{
                    emptyText: (
                      <div style={{ textAlign: "center", height: "auto" }}>
                        <img
                          src="https://static-global.boka.vc/mase/def/Def_Empty_noData.png"
                          alt="No Data"
                          style={{ width: "80px", marginBottom: "10px" }}
                        />
                        <p>No data available</p>
                      </div>
                    ),
                  }}
                  pagination={false}
                />
              </div>
            )}

          {/* Exclusive price section - Product */}
          {/* {(membershipType === "prepaid" || membershipType === "discount") && activeKey === "product" && (
            <div style={{ marginTop: "24px" }}>
              <Row
                justify="space-between"
                align="middle"
                style={{ marginBottom: "16px" }}
              >
                <Col>
                  <h3 style={{ fontWeight: 600 }}>
                    Exclusive price{" "}
                    <span style={{ fontWeight: 400, color: "#888" }}>
                      (Only applies to items selected above)
                    </span>
                  </h3>
                </Col>
                <Col>
                  <Button
                    type="link"
                    icon={<PlusOutlined />}
                    onClick={showProductExcPriceModal}
                    style={{ color: "#ff7a45" }}
                  >
                    Add
                  </Button>
                </Col>
              </Row>
              <Table
                columns={columns}
                dataSource={data}
                locale={{
                  emptyText: (
                    <div style={{ textAlign: "center" }}>
                      <img
                        src="https://example.com/no-data-image.png" // Replace with an actual image URL
                        alt="No Data"
                        style={{ width: 100, marginBottom: 8 }}
                      />
                      <p>No data available</p>
                    </div>
                  ),
                }}
                pagination={false}
                bordered
              />
            </div>
          )} */}

          {/* Exclusive price section - Privilege */}
          {/* {(membershipType === "prepaid" || membershipType === "discount") && activeKey === "privilege" && (
            <div style={{ marginTop: "24px" }}>
              <Row
                justify="space-between"
                align="middle"
                style={{ marginBottom: "16px" }}
              >
                <Col>
                  <h3 style={{ fontWeight: 600 }}>
                    Exclusive price{" "}
                    <span style={{ fontWeight: 400, color: "#888" }}>
                      (Only applies to items selected above)
                    </span>
                  </h3>
                </Col>
                <Col>
                  <Button
                    type="link"
                    icon={<PlusOutlined />}
                    onClick={showPrivilegeExcPriceModal}
                    style={{ color: "#ff7a45" }}
                  >
                    Add
                  </Button>
                </Col>
              </Row>
              <Table
                columns={columns}
                dataSource={data}
                locale={{
                  emptyText: (
                    <div style={{ textAlign: "center" }}>
                      <img
                        src="https://example.com/no-data-image.png" // Replace with an actual image URL
                        alt="No Data"
                        style={{ width: 100, marginBottom: 8 }}
                      />
                      <p>No data available</p>
                    </div>
                  ),
                }}
                pagination={false}
                bordered
              />
            </div>
          )} */}

          <ServiceExcPrice
            visible={modalVisible}
            onCancel={handleModalCancel}
            onSave={handleModalSave}
          />
          {/* Product Exclusive Price Modal */}

          <ProductExcPrice
            visible={isProductExcPriceVisible}
            onCancel={hideProductExcPriceModal}
            onSave={handleProductExcPriceSave}
          />

          <PrivilegeExcPrice
            visible={isPrivilegeExcPriceVisible}
            onCancel={hidePrivilegeExcPriceModal}
            onSave={handlePrivilegeExcPriceSave}
          />
          {/* Loyalty Points Section */}
          <div>
            <h3 style={{ display: "flex", alignItems: "center" }}>
              <span className="vertical-line"></span>Loyalty Points
            </h3>
          </div>
          <Row gutter={16}>
            {/* Dropdown for Loyalty Type */}
            <Col span={8}>
              <Form.Item name="loyaltyType">
                <Select
                  placeholder="Please select"
                  dropdownStyle={{ textAlign: "center" }}
                  style={{
                    width: "206px",
                    height: "40px",
                    lineHeight: "40px", // Center-align the placeholder text vertically
                  }}
                >
                  <Option value="standard">Standard unit price</Option>
                  <Option value="custom">Custom unit price</Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Input field for Percentage */}
            <Col span={8}>
              <Form.Item name="percentage">
                <InputNumber
                  min={-1}
                  max={100}
                  placeholder="Please enter"
                  formatter={(value) => `${value}`}
                  style={{
                    width: "208px",
                    height: "40px",
                    lineHeight: "40px", // Ensures vertical alignment
                  }}
                  addonAfter={
                    <span
                      style={{
                        color: "rgba(46, 86, 242, .5)",
                        fontWeight: 700,
                      }}
                    >
                      %
                    </span>
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Note Section */}
          <div>
            <h3 style={{ display: "flex", alignItems: "center" }}>
              <span className="vertical-line"></span>Note
            </h3>
          </div>
          <Form.Item name="note">
            <TextArea rows={4} placeholder="Please enter" />
          </Form.Item>
        </Form>
      </Modal>
      <ServiceByCategoryModel
        title="Services "
        visible={isModalVisible}
        onCancel={handleCancel}
        onSave={handleSaveServices}
        selectedServices={selectedServices}
        footer={null}
      />
    </>
  );
};

export default MembershipModel;
