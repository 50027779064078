import React, { useEffect, useState } from "react";
import { Modal, Table, Select, Input, Button, Row, Col, message } from "antd";
import { get } from "../../../services/Apiclient";
import { ArrowLeftOutlined } from "@ant-design/icons";
import Datatable from "../../Datatable";
import { useNavigate } from "react-router-dom";

const { Option } = Select;

interface ServiceByCategoryModelProps {
  title: string;
  visible: boolean;
  onCancel: () => void;
  onSave: (selectedData: any[]) => void;
  selectedServices: any[];
  footer?: React.ReactNode;
}

const ServiceByCategoryModel: React.FC<ServiceByCategoryModelProps> = ({
  title,
  visible,
  onCancel,
  onSave,
  selectedServices,
  footer,
}) => {
  const navigate = useNavigate();
  const [categoryData, setCategoryData] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [categorycode, setCategorycode] = useState("");
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  // Sample data for the table
  // Correct typing for selectedRowKeys
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
  };

  const handleCategoryChange = (value: string) => {
    setCategorycode(value);
  };

  const handleSave = () => {
    if (selectedRowKeys.length > 0) {
      // Get the selected data based on selectedRowKeys
      const selectedData = serviceData.filter((item: any) =>
        selectedRowKeys.includes(item.key)
      );

      // Call the onSave function and pass the selected data to the parent
      onSave(selectedData);

      // Close the modal after saving
      onCancel();
    } else {
      onSave([]);

      // Close the modal after saving
      onCancel();
    }
  };

  const getProductCategoryDropdown = async () => {
    try {
      let url = `/category/dropdown/type/${localStorage.getItem(
        "outlet_id"
      )}/Product`;
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          let data = response.data.data;
          data.unshift({
            id: "null",
            name: "All Categories",
          });
          setCategoryData(data);
          setCategorycode("null");
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  const getProductdata = async () => {
    try {
      // let url = `/product/${categorycode}/${suppliercode}/${currentPage}/${pageSize}/created_at/ASC/${searchText}`;
      let searchtext = searchText == "" ? null : searchText;
      let url = `/stock/stockcheckoutproductlist?category=${
        categorycode == "" ? null : categorycode
      }&outlet_id=${localStorage.getItem("outlet_id")}&search=${searchtext}`;
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          let data = response.data.data.map((product: any, index: number) => ({
            ...product,
            key: product.productid || index, // ensure each product has a unique key
          }));
          setServiceData(data);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  const handlePageChange = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    if (pageSize) {
      setPageSize(pageSize);
    }
  };

  useEffect(() => {
    if (visible) {
      getProductdata();
    }
  }, [currentPage, pageSize, visible, categorycode, searchText]);

  useEffect(() => {
    if (visible) {
      getProductCategoryDropdown();
    }
  }, [visible]);

  useEffect(() => {
    if (visible) {
      const keys = selectedServices.map((service) => service.key);
      setSelectedRowKeys(keys);
    }
  }, [selectedServices, visible]);

  return (
    <Modal
      title={
        <Row justify="space-between" align="middle">
          <Col>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <ArrowLeftOutlined
                style={{ marginRight: 12, color: "#325DF2", fontSize: 18 }}
                onClick={() => onCancel()}
              />
              <h2
                style={{
                  margin: 0,
                  fontFamily: "SourceHanSansCN-Bold",
                  fontSize: "18px",
                }}
              >
                {title}
              </h2>
            </div>
          </Col>
        </Row>
      }
      visible={visible}
      onCancel={onCancel}
      footer={footer}
      style={{ maxWidth: "1240px" }}
      width={"100%"}
      height={"100%"}
    >
      {/* Filters and Search */}
      <Row gutter={16} style={{ marginBottom: 16 }}>
        <Col span={8}>
          <Select
            placeholder="Please select category"
            value={categorycode}
            onChange={(value) => handleCategoryChange(value)}
            style={{ width: "100%" }}
            dropdownStyle={{ textAlign: "center" }}
          >
            {categoryData.length > 0 &&
              categoryData.map((cate: any) => (
                <Option value={cate.id} key={cate.id}>
                  {cate.name}
                </Option>
              ))}
          </Select>
        </Col>
        <Col span={8}>
          <Input
            placeholder="Select by service name / code"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)} // Update search text on input change
            style={{ width: "100%" }}
            allowClear
          />
        </Col>
      </Row>
      <Datatable<any>
        columns={[
          { title: "Service Code", dataIndex: "servicecode" },
          { title: "Service Name", dataIndex: "servicename" },
          {
            title: "Price",
            dataIndex: "sellprice",
            render: (sellprice: number) => `$${sellprice}`,
          },
        ]}
        dataSource={serviceData}
        currentPage={currentPage}
        pageSize={pageSize}
        totalRecords={totalRecords}
        onPageChange={handlePageChange}
        scroll={{ y: 500 }}
        showCheckbox
        rowSelection={rowSelection}
        showPagination={false}
      />
      <div
        style={{
          marginTop: 16,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <strong>Selected:</strong> {selectedRowKeys.length} items
        </div>
        <div>
          <Button type="primary" onClick={handleSave}>
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ServiceByCategoryModel;
