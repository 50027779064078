import { Layout, Row, Col, Typography } from "antd";
import { ShopOutlined, SettingOutlined, UserOutlined } from "@ant-design/icons";
import SettingCard from "./SettingCard";

const { Content } = Layout;
const { Title } = Typography;

const commondata = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_SettingMenu_CommonData.png`;
const businessdetails = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_SettingMenu_BusinessDetails.png`;
const productmanagement = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_SettingMenu_ProductManagement.png`;
const servicemanagement = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_SettingMenu_ServiceManagement.png`;
const roommanagement = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_SettingMenu_RoomManagement.png`;
const staffmanagement = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_SettingMenu_StaffList.png`;
const giftcard = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_SettingMenu_GiftCards.png`;
const memberships = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_SettingMenu_Memberships.png`;
const packages = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_SettingMenu_Packages.png`;

const settingsSections = [
  {
    title: "Basic Settings",
    settings: [
      // {
      //   title: "Parameter Settings",
      //   icon: <ShopOutlined />,
      //   link: "/parameter-settings",
      //   bgColor: "#1e90ff",
      // },
      // {
      //   title: "User Permissions",
      //   icon: <ShopOutlined />,
      //   link: "/user-permissions",
      //   bgColor: "#1e90ff",
      // },
      {
        title: "Common Data",
        Image: commondata,
        // icon: <ShopOutlined />,
        link: "/common-data",
        bgColor: "#1e90ff",
      },
      {
        title: "Business Details",
        Image: businessdetails,
        link: "/settings/business-details",
        bgColor: "#1e90ff",
      },
      {
        title: "Product Management",
        Image: productmanagement,
        link: "product/list",
        bgColor: "#1e90ff",
      },
      {
        title: "Service Management",
        Image: servicemanagement,
        link: "service/list",
        bgColor: "#1e90ff",
      },
      {
        title: "Room Management",
        Image: roommanagement,
        link: "room/list",
        bgColor: "#1e90ff",
      },
    ],
  },
  {
    title: "Privilege Settings",
    settings: [
      // {
      //   title: "Memberships",
      //   Image: memberships,
      //   link: "membership/list",
      //   bgColor: "#ff6347",
      // },
      {
        title: "Gift Cards",
        Image: giftcard,
        link: "giftCards/list",
        bgColor: "#ff6347",
      },
      // {
      //   title: "Packages",
      //   Image: packages,
      //   link: "packages/list",
      //   bgColor: "#ff6347",
      // },
    ],
  },
  {
    title: "Staff Management",
    settings: [
      {
        title: "Staff List",
        Image: staffmanagement,
        link: "staff/list",
        bgColor: "#ff6347",
      },
    ],
  },
  // {
  //   title: "Personalization",
  //   settings: [
  //     {
  //       title: "Customer Tags",
  //       icon: <UserOutlined />,
  //       link: "/customer-tags",
  //       bgColor: "#ff6347",
  //     },
  //     {
  //       title: "Work Tags",
  //       icon: <SettingOutlined />,
  //       link: "/work-tags",
  //       bgColor: "#ff6347",
  //     },
  //     {
  //       title: "Website Settings",
  //       icon: <SettingOutlined />,
  //       link: "/website-settings",
  //       bgColor: "#ff6347",
  //     },
  //     {
  //       title: "Price List Settings",
  //       icon: <SettingOutlined />,
  //       link: "/price-list-settings",
  //       bgColor: "#ff6347",
  //     },
  //     {
  //       title: "Template Management",
  //       icon: <SettingOutlined />,
  //       link: "/template-management",
  //       bgColor: "#ff6347",
  //     },
  //     {
  //       title: "Shop Settings",
  //       icon: <SettingOutlined />,
  //       link: "/shop-settings",
  //       bgColor: "#ff6347",
  //     },
  //   ],
  // },
];

function Settings() {
  const renderSettingsSection = ({ title, settings }: any, index: any) => (
    <div key={index}>
      <Title level={4} style={{ marginBottom: "16px" }}>
        {title}
      </Title>
      <Row gutter={[12, 12]}>
        {settings.map((setting: any, idx: any) => (
          <Col
            key={idx}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <SettingCard
              icon={setting.icon}
              title={setting.title}
              link={setting.link}
              bgColor={setting.bgColor}
              Image={setting.Image}
            />
          </Col>
        ))}
      </Row>
    </div>
  );

  return (
    <Layout>
      {/* Header Section */}
      <Row justify="space-between" align="middle">
        <Col>
          <h2>Settings</h2>
        </Col>
      </Row>

      {/* Content Section */}
      <Content
        style={{
          backgroundColor: "#f6f7f9",
          borderRadius: "12px",
          padding: "0px 24px 24px 24px",
          border: "1px solid #e0e7fe",
        }}
      >
        {settingsSections.map(renderSettingsSection)}
      </Content>
    </Layout>
  );
}

export default Settings;
