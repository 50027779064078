import React, { useRef } from "react";
import { Modal, Button, Typography, Image, message } from "antd";
import html2canvas from "html2canvas";
import QRCode from "react-qr-code";
const qrIcon = `${process.env.PUBLIC_URL}/images/logo/public.png`;
const FRONT_URL = process.env.REACT_APP_FRONT_URL;

const { Text } = Typography;

interface QRCodeModalProps {
  visible: boolean;
  onClose: () => void;
}

const QRCodeModal: React.FC<QRCodeModalProps> = ({ visible, onClose }) => {
  const qrRef = useRef<HTMLDivElement>(null);

  // Function to download the QR code image
  const downloadQRCode = async () => {
    if (qrRef.current) {
      try {
        const canvas = await html2canvas(qrRef.current, {
          scale: 2,
          backgroundColor: null,
        });
        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/png");
        link.download = "QRCode.png";
        link.click();
        message.success("QR code downloaded successfully!");
      } catch (error) {
        message.error("Failed to download QR code.");
      }
    }
  };

  return (
    <Modal
      visible={visible}
      title="Add Customer Profile"
      onCancel={onClose}
      footer={null}
      centered
      bodyStyle={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {/* QR Code Image Container */}
      <div ref={qrRef} style={{ display: "inline-block", margin: "50px 0px" }}>
        <QRCode
          value={`${FRONT_URL}/customer/addcustomer/${localStorage.getItem(
            "merchantid"
          )}/${localStorage.getItem("outlet_id")}`}
          size={150}
        />
      </div>

      {/* Save Button */}
      <Button
        type="primary"
        onClick={downloadQRCode}
        style={{ width: "100px" }}
      >
        Save
      </Button>
    </Modal>
  );
};

export default QRCodeModal;
