import React from "react";
import { Routes, Route } from "react-router-dom";
import OutletList from "../pages/outlet/OutletList";
import NotFound from "../pages/NotFound";
import Home from "../pages/Home";
import MainLayout from "../layouts/MainLayout";
import SignInForm from "../pages/signup/SignInForm";
import ProtectedRoute from "../routes/ProtectedRoute";
import { userRoles } from "../constants/userRoles";
import Settings from "../pages/settings/Settings";
import ProductList from "../pages/product/ProductList";
import ServiceList from "../pages/service/ServiceList";
import RoomList from "../pages/room/RoomList";
import CategoryList from "../pages/category/CategoryList";
import CustomerList from "../pages/customer/CustomerList";
import MerchantForm from "../pages/merchant/MerchantForm";
import OutletForm from "../pages/outlet/OutletForm";
import RoomAdd from "../pages/room/RoomAdd";
import StaffList from "../pages/staff/StaffList";
import StaffAdd from "../pages/staff/StaffAdd";
import MerchantList from "../pages/merchant/MerchantList";
import ProductAdd from "../pages/product/ProductAdd";
import ServiceAdd from "../pages/service/ServiceAdd";
import StockInList from "../pages/stock/StockInList";
import StockOutList from "../pages/stock/StockOutList";
import StockTakesList from "../pages/stock/StockTakesList";
import Inventory from "../pages/inventory/Inventory";
import StockInAdd from "../pages/stock/StockInAdd";
import AddStockTakes from "../pages/stock/StockTakesAdd";
import CommonData from "../pages/commonData/CommonData";
import StockOutAdd from "../pages/stock/StockOutAdd";
import StaffInfo from "../pages/staff/StaffInfo";
import StockInDetailView from "../pages/stock/StockInDetailView";
import StockOutDetailView from "../pages/stock/StockOutDetailView";
import StockTakesDetailView from "../pages/stock/StockTakesDetailView";
import CustomerForm from "../pages/customer/CustomerForm";
import CustomerProfile from "../pages/customer/CustomerProfile";
import Sales from "../pages/sales/Sales";
import Billing from "../pages/sales/Billing";
import Pay from "../pages/sales/Pay";
import CustomerFormqr from "../pages/customer/CustomerFormqr";
import MembershipList from "../pages/membership/MembershipList";
import GiftCardsList from "../pages/giftCards/GiftCardsList";
import PackagesList from "../pages/packages/PackagesList";
import AssignService from "../pages/AssignService";
import MembershipView from "../pages/membership/MembershipView";
import GiftCardsView from "../pages/giftCards/GiftCardsView";
import PackagesView from "../pages/packages/PackagesView";
import BillDetails from "../pages/sales/BillDetails";
import AccountingReport from "../pages/reports/sales/AccountingRecordSheet";

const AppRoutes: React.FC = () => (
  <Routes>
    <Route
      element={
        <ProtectedRoute
          allowedRoles={[
            userRoles.SUPERADMIN,
            userRoles.OUTLETADMIN,
            userRoles.CUSTOMER,
            userRoles.MERCHANT,
            userRoles.STAFF,
          ]}
        >
          <MainLayout />
        </ProtectedRoute>
      }
    >
      <Route
        path="/"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <Home />
          </ProtectedRoute>
        }
      />
      <Route
        path="customer"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <CustomerList />
          </ProtectedRoute>
        }
      />

      <Route
        path="merchant/list"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <MerchantList />
          </ProtectedRoute>
        }
      />
      <Route
        path="settings"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <Settings />
          </ProtectedRoute>
        }
      />
      <Route
        path="inventory"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <Inventory />
          </ProtectedRoute>
        }
      />
      <Route
        path="sales"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <Sales />
          </ProtectedRoute>
        }
      />
    </Route>

    <Route
      path="settings/product/list"
      element={
        <ProtectedRoute
          allowedRoles={[
            userRoles.SUPERADMIN,
            userRoles.OUTLETADMIN,
            userRoles.CUSTOMER,
            userRoles.MERCHANT,
            userRoles.STAFF,
          ]}
        >
          <ProductList />
        </ProtectedRoute>
      }
    />
    <Route
      path="settings/product/add"
      element={
        <ProtectedRoute
          allowedRoles={[
            userRoles.SUPERADMIN,
            userRoles.OUTLETADMIN,
            userRoles.CUSTOMER,
            userRoles.MERCHANT,
            userRoles.STAFF,
          ]}
        >
          <ProductAdd />
        </ProtectedRoute>
      }
    />
    <Route
      path="settings/product/edit/:productId"
      element={
        <ProtectedRoute
          allowedRoles={[
            userRoles.SUPERADMIN,
            userRoles.OUTLETADMIN,
            userRoles.CUSTOMER,
            userRoles.MERCHANT,
            userRoles.STAFF,
          ]}
        >
          <ProductAdd />
        </ProtectedRoute>
      }
    />
    <Route
      path="settings/product/duplicate/:productId"
      element={
        <ProtectedRoute
          allowedRoles={[
            userRoles.SUPERADMIN,
            userRoles.OUTLETADMIN,
            userRoles.CUSTOMER,
            userRoles.MERCHANT,
            userRoles.STAFF,
          ]}
        >
          <ProductAdd />
        </ProtectedRoute>
      }
    />

    <Route
      path="settings/service/list"
      element={
        <ProtectedRoute
          allowedRoles={[
            userRoles.SUPERADMIN,
            userRoles.OUTLETADMIN,
            userRoles.CUSTOMER,
            userRoles.MERCHANT,
            userRoles.STAFF,
          ]}
        >
          <ServiceList />
        </ProtectedRoute>
      }
    />
    <Route
      path="settings/service/add"
      element={
        <ProtectedRoute
          allowedRoles={[
            userRoles.SUPERADMIN,
            userRoles.OUTLETADMIN,
            userRoles.CUSTOMER,
            userRoles.MERCHANT,
            userRoles.STAFF,
          ]}
        >
          <ServiceAdd />
        </ProtectedRoute>
      }
    />
    <Route
      path="settings/service/edit/:serviceId"
      element={
        <ProtectedRoute
          allowedRoles={[
            userRoles.SUPERADMIN,
            userRoles.OUTLETADMIN,
            userRoles.CUSTOMER,
            userRoles.MERCHANT,
            userRoles.STAFF,
          ]}
        >
          <ServiceAdd />
        </ProtectedRoute>
      }
    />

    <Route
      path="settings/room/list"
      element={
        <ProtectedRoute
          allowedRoles={[
            userRoles.SUPERADMIN,
            userRoles.OUTLETADMIN,
            userRoles.CUSTOMER,
            userRoles.MERCHANT,
            userRoles.STAFF,
          ]}
        >
          <RoomList />
        </ProtectedRoute>
      }
    />
    <Route
      path="settings/room/add"
      element={
        <ProtectedRoute
          allowedRoles={[
            userRoles.SUPERADMIN,
            userRoles.OUTLETADMIN,
            userRoles.CUSTOMER,
            userRoles.MERCHANT,
            userRoles.STAFF,
          ]}
        >
          <RoomAdd />
        </ProtectedRoute>
      }
    />
    <Route
      path="settings/room/edit/:roomId"
      element={
        <ProtectedRoute
          allowedRoles={[
            userRoles.SUPERADMIN,
            userRoles.OUTLETADMIN,
            userRoles.CUSTOMER,
            userRoles.MERCHANT,
            userRoles.STAFF,
          ]}
        >
          <RoomAdd />
        </ProtectedRoute>
      }
    />
    <Route
      path="settings/room/duplicate/:roomId"
      element={
        <ProtectedRoute
          allowedRoles={[
            userRoles.SUPERADMIN,
            userRoles.OUTLETADMIN,
            userRoles.CUSTOMER,
            userRoles.MERCHANT,
            userRoles.STAFF,
          ]}
        >
          <RoomAdd />
        </ProtectedRoute>
      }
    />

    <Route
      path="outlet"
      element={
        <ProtectedRoute
          allowedRoles={[
            userRoles.SUPERADMIN,
            userRoles.OUTLETADMIN,
            userRoles.CUSTOMER,
            userRoles.MERCHANT,
            userRoles.STAFF,
          ]}
        >
          <OutletList />
        </ProtectedRoute>
      }
    />
    <Route
      path="outlet/add"
      element={
        <ProtectedRoute
          allowedRoles={[
            userRoles.SUPERADMIN,
            userRoles.OUTLETADMIN,
            userRoles.CUSTOMER,
            userRoles.MERCHANT,
            userRoles.STAFF,
          ]}
        >
          <OutletForm />
        </ProtectedRoute>
      }
    />
    <Route
      path="outlet/:id"
      element={
        <ProtectedRoute
          allowedRoles={[
            userRoles.SUPERADMIN,
            userRoles.OUTLETADMIN,
            userRoles.CUSTOMER,
            userRoles.MERCHANT,
            userRoles.STAFF,
          ]}
        >
          <OutletForm />
        </ProtectedRoute>
      }
    />

    <Route
      path="settings/business-details"
      element={
        <ProtectedRoute
          allowedRoles={[
            userRoles.SUPERADMIN,
            userRoles.OUTLETADMIN,
            userRoles.CUSTOMER,
            userRoles.MERCHANT,
            userRoles.STAFF,
          ]}
        >
          <OutletList />
        </ProtectedRoute>
      }
    />
    <Route
      path="settings/business-details/add"
      element={
        <ProtectedRoute
          allowedRoles={[
            userRoles.SUPERADMIN,
            userRoles.OUTLETADMIN,
            userRoles.CUSTOMER,
            userRoles.MERCHANT,
            userRoles.STAFF,
          ]}
        >
          <OutletForm />
        </ProtectedRoute>
      }
    />
    <Route
      path="settings/business-details/:id"
      element={
        <ProtectedRoute
          allowedRoles={[
            userRoles.SUPERADMIN,
            userRoles.OUTLETADMIN,
            userRoles.CUSTOMER,
            userRoles.MERCHANT,
            userRoles.STAFF,
          ]}
        >
          <OutletForm />
        </ProtectedRoute>
      }
    />

    <Route
      path="common-data"
      element={
        <ProtectedRoute
          allowedRoles={[
            userRoles.SUPERADMIN,
            userRoles.OUTLETADMIN,
            userRoles.CUSTOMER,
            userRoles.MERCHANT,
            userRoles.STAFF,
          ]}
        >
          <CommonData />
        </ProtectedRoute>
      }
    />

    <Route
      path="inventory/stockin/list"
      element={
        <ProtectedRoute
          allowedRoles={[
            userRoles.SUPERADMIN,
            userRoles.OUTLETADMIN,
            userRoles.CUSTOMER,
            userRoles.MERCHANT,
            userRoles.STAFF,
          ]}
        >
          <StockInList />
        </ProtectedRoute>
      }
    />
    <Route
      path="inventory/stockin/Add"
      element={
        <ProtectedRoute
          allowedRoles={[
            userRoles.SUPERADMIN,
            userRoles.OUTLETADMIN,
            userRoles.CUSTOMER,
            userRoles.MERCHANT,
            userRoles.STAFF,
          ]}
        >
          <StockInAdd />
        </ProtectedRoute>
      }
    />
    <Route
      path="inventory/stockin/edit/:id"
      element={
        <ProtectedRoute
          allowedRoles={[
            userRoles.SUPERADMIN,
            userRoles.OUTLETADMIN,
            userRoles.CUSTOMER,
            userRoles.MERCHANT,
            userRoles.STAFF,
          ]}
        >
          <StockInAdd />
        </ProtectedRoute>
      }
    />
    <Route
      path="inventory/stockout/list"
      element={
        <ProtectedRoute
          allowedRoles={[
            userRoles.SUPERADMIN,
            userRoles.OUTLETADMIN,
            userRoles.CUSTOMER,
            userRoles.MERCHANT,
            userRoles.STAFF,
          ]}
        >
          <StockOutList />
        </ProtectedRoute>
      }
    />
    <Route
      path="inventory/stockout/Add"
      element={
        <ProtectedRoute
          allowedRoles={[
            userRoles.SUPERADMIN,
            userRoles.OUTLETADMIN,
            userRoles.CUSTOMER,
            userRoles.MERCHANT,
            userRoles.STAFF,
          ]}
        >
          <StockOutAdd />
        </ProtectedRoute>
      }
    />
    <Route
      path="inventory/stockout/edit/:id"
      element={
        <ProtectedRoute
          allowedRoles={[
            userRoles.SUPERADMIN,
            userRoles.OUTLETADMIN,
            userRoles.CUSTOMER,
            userRoles.MERCHANT,
            userRoles.STAFF,
          ]}
        >
          <StockOutAdd />
        </ProtectedRoute>
      }
    />
    <Route
      path="inventory/stocktakes/list"
      element={
        <ProtectedRoute
          allowedRoles={[
            userRoles.SUPERADMIN,
            userRoles.OUTLETADMIN,
            userRoles.CUSTOMER,
            userRoles.MERCHANT,
            userRoles.STAFF,
          ]}
        >
          <StockTakesList />
        </ProtectedRoute>
      }
    />
    <Route
      path="inventory/stocktakes/add"
      element={
        <ProtectedRoute
          allowedRoles={[
            userRoles.SUPERADMIN,
            userRoles.OUTLETADMIN,
            userRoles.CUSTOMER,
            userRoles.MERCHANT,
            userRoles.STAFF,
          ]}
        >
          <AddStockTakes />
        </ProtectedRoute>
      }
    />
    <Route
      path="inventory/stocktakes/edit/:id"
      element={
        <ProtectedRoute
          allowedRoles={[
            userRoles.SUPERADMIN,
            userRoles.OUTLETADMIN,
            userRoles.CUSTOMER,
            userRoles.MERCHANT,
            userRoles.STAFF,
          ]}
        >
          <AddStockTakes />
        </ProtectedRoute>
      }
    />
    <Route
      path="inventory/stockindetails/view/:id"
      element={
        <ProtectedRoute
          allowedRoles={[
            userRoles.SUPERADMIN,
            userRoles.OUTLETADMIN,
            userRoles.CUSTOMER,
            userRoles.MERCHANT,
            userRoles.STAFF,
          ]}
        >
          <StockInDetailView />
        </ProtectedRoute>
      }
    />
    <Route
      path="inventory/stockoutdetails/view/:id"
      element={
        <ProtectedRoute
          allowedRoles={[
            userRoles.SUPERADMIN,
            userRoles.OUTLETADMIN,
            userRoles.CUSTOMER,
            userRoles.MERCHANT,
            userRoles.STAFF,
          ]}
        >
          <StockOutDetailView />
        </ProtectedRoute>
      }
    />
    <Route
      path="inventory/stocktakesdetails/view/:id"
      element={
        <ProtectedRoute
          allowedRoles={[
            userRoles.SUPERADMIN,
            userRoles.OUTLETADMIN,
            userRoles.CUSTOMER,
            userRoles.MERCHANT,
            userRoles.STAFF,
          ]}
        >
          <StockTakesDetailView />
        </ProtectedRoute>
      }
    />

    <Route
      path="settings/staff/list"
      element={
        <ProtectedRoute
          allowedRoles={[
            userRoles.SUPERADMIN,
            userRoles.OUTLETADMIN,
            userRoles.CUSTOMER,
            userRoles.MERCHANT,
            userRoles.STAFF,
          ]}
        >
          <StaffList />
        </ProtectedRoute>
      }
    />
    <Route
      path="settings/staff/add"
      element={
        <ProtectedRoute
          allowedRoles={[
            userRoles.SUPERADMIN,
            userRoles.OUTLETADMIN,
            userRoles.CUSTOMER,
            userRoles.MERCHANT,
            userRoles.STAFF,
          ]}
        >
          <StaffAdd />
        </ProtectedRoute>
      }
    />
    <Route
      path="settings/staff/edit/:id"
      element={
        <ProtectedRoute
          allowedRoles={[
            userRoles.SUPERADMIN,
            userRoles.OUTLETADMIN,
            userRoles.CUSTOMER,
            userRoles.MERCHANT,
            userRoles.STAFF,
          ]}
        >
          <StaffAdd />
        </ProtectedRoute>
      }
    />

    <Route
      path="customer/add"
      element={
        <ProtectedRoute
          allowedRoles={[
            userRoles.SUPERADMIN,
            userRoles.OUTLETADMIN,
            userRoles.CUSTOMER,
            userRoles.MERCHANT,
            userRoles.STAFF,
          ]}
        >
          <CustomerForm />
        </ProtectedRoute>
      }
    />
    <Route
      path="customer/view/:id"
      element={
        <ProtectedRoute
          allowedRoles={[
            userRoles.SUPERADMIN,
            userRoles.OUTLETADMIN,
            userRoles.CUSTOMER,
            userRoles.MERCHANT,
            userRoles.STAFF,
          ]}
        >
          <CustomerProfile />
        </ProtectedRoute>
      }
    />
    <Route
      path="customer/edit/:id"
      element={
        <ProtectedRoute
          allowedRoles={[
            userRoles.SUPERADMIN,
            userRoles.OUTLETADMIN,
            userRoles.CUSTOMER,
            userRoles.MERCHANT,
            userRoles.STAFF,
          ]}
        >
          <CustomerForm />
        </ProtectedRoute>
      }
    />
    <Route
      path="customer/addcustomer/:merchant_id/:outlet_id"
      element={<CustomerFormqr />}
    />

    <Route
      path="settings/membership/list"
      element={
        <ProtectedRoute
          allowedRoles={[
            userRoles.SUPERADMIN,
            userRoles.OUTLETADMIN,
            userRoles.CUSTOMER,
            userRoles.MERCHANT,
            userRoles.STAFF,
          ]}
        >
          <MembershipList />
        </ProtectedRoute>
      }
    />
    <Route
      path="settings/membership/view"
      element={
        <ProtectedRoute
          allowedRoles={[
            userRoles.SUPERADMIN,
            userRoles.OUTLETADMIN,
            userRoles.CUSTOMER,
            userRoles.MERCHANT,
            userRoles.STAFF,
          ]}
        >
          <MembershipView />
        </ProtectedRoute>
      }
    />

    <Route
      path="settings/giftCards/list"
      element={
        <ProtectedRoute
          allowedRoles={[
            userRoles.SUPERADMIN,
            userRoles.OUTLETADMIN,
            userRoles.CUSTOMER,
            userRoles.MERCHANT,
            userRoles.STAFF,
          ]}
        >
          <GiftCardsList />
        </ProtectedRoute>
      }
    />
    <Route
      path="settings/giftCards/view"
      element={
        <ProtectedRoute
          allowedRoles={[
            userRoles.SUPERADMIN,
            userRoles.OUTLETADMIN,
            userRoles.CUSTOMER,
            userRoles.MERCHANT,
            userRoles.STAFF,
          ]}
        >
          <GiftCardsView />
        </ProtectedRoute>
      }
    />

    <Route
      path="settings/packages/list"
      element={
        <ProtectedRoute
          allowedRoles={[
            userRoles.SUPERADMIN,
            userRoles.OUTLETADMIN,
            userRoles.CUSTOMER,
            userRoles.MERCHANT,
            userRoles.STAFF,
          ]}
        >
          <PackagesList />
        </ProtectedRoute>
      }
    />
    <Route
      path="settings/packages/view"
      element={
        <ProtectedRoute
          allowedRoles={[
            userRoles.SUPERADMIN,
            userRoles.OUTLETADMIN,
            userRoles.CUSTOMER,
            userRoles.MERCHANT,
            userRoles.STAFF,
          ]}
        >
          <PackagesView />
        </ProtectedRoute>
      }
    />

    <Route
      path="merchant/add"
      element={
        <ProtectedRoute
          allowedRoles={[
            userRoles.SUPERADMIN,
            userRoles.OUTLETADMIN,
            userRoles.CUSTOMER,
            userRoles.MERCHANT,
            userRoles.STAFF,
          ]}
        >
          <MerchantForm />
        </ProtectedRoute>
      }
    />
    <Route
      path="merchant/edit/:id"
      element={
        <ProtectedRoute
          allowedRoles={[
            userRoles.SUPERADMIN,
            userRoles.OUTLETADMIN,
            userRoles.CUSTOMER,
            userRoles.MERCHANT,
            userRoles.STAFF,
          ]}
        >
          <MerchantForm />
        </ProtectedRoute>
      }
    />
    <Route
      path="merchant/list"
      element={
        <ProtectedRoute
          allowedRoles={[
            userRoles.SUPERADMIN,
            userRoles.OUTLETADMIN,
            userRoles.CUSTOMER,
            userRoles.MERCHANT,
            userRoles.STAFF,
          ]}
        >
          <MerchantList />
        </ProtectedRoute>
      }
    />
    <Route
      path="settings/staff/info/:id"
      element={
        <ProtectedRoute
          allowedRoles={[
            userRoles.SUPERADMIN,
            userRoles.OUTLETADMIN,
            userRoles.CUSTOMER,
            userRoles.MERCHANT,
            userRoles.STAFF,
          ]}
        >
          <StaffInfo />
        </ProtectedRoute>
      }
    />
    <Route
      path="settings/category/list"
      element={
        <ProtectedRoute
          allowedRoles={[
            userRoles.SUPERADMIN,
            userRoles.OUTLETADMIN,
            userRoles.CUSTOMER,
            userRoles.MERCHANT,
            userRoles.STAFF,
          ]}
        >
          <CategoryList />
        </ProtectedRoute>
      }
    />

    <Route
      path="sales/billing"
      element={
        <ProtectedRoute
          allowedRoles={[
            userRoles.SUPERADMIN,
            userRoles.OUTLETADMIN,
            userRoles.CUSTOMER,
            userRoles.MERCHANT,
            userRoles.STAFF,
          ]}
        >
          <Billing />
        </ProtectedRoute>
      }
    />
    <Route
      path="sales/pay/:id"
      element={
        <ProtectedRoute
          allowedRoles={[
            userRoles.SUPERADMIN,
            userRoles.OUTLETADMIN,
            userRoles.CUSTOMER,
            userRoles.MERCHANT,
            userRoles.STAFF,
          ]}
        >
          <Pay />
        </ProtectedRoute>
      }
    />
    <Route
      path="sales/billdetails/:id"
      element={
        <ProtectedRoute
          allowedRoles={[
            userRoles.SUPERADMIN,
            userRoles.OUTLETADMIN,
            userRoles.CUSTOMER,
            userRoles.MERCHANT,
            userRoles.STAFF,
          ]}
        >
          <BillDetails />
        </ProtectedRoute>
      }
    />
    <Route
      path="designshow"
      element={
        <ProtectedRoute
          allowedRoles={[
            userRoles.SUPERADMIN,
            userRoles.OUTLETADMIN,
            userRoles.CUSTOMER,
            userRoles.MERCHANT,
            userRoles.STAFF,
          ]}
        >
          <AssignService />
        </ProtectedRoute>
      }
    />
    <Route
      path="reports/accounting"
      element={
        <ProtectedRoute
          allowedRoles={[
            userRoles.SUPERADMIN,
            userRoles.OUTLETADMIN,
            userRoles.CUSTOMER,
            userRoles.MERCHANT,
            userRoles.STAFF,
          ]}
        >
          <AccountingReport />
        </ProtectedRoute>
      }
    />

    <Route path="/login" element={<SignInForm />} />

    <Route path="*" element={<NotFound />} />
  </Routes>
);

export default AppRoutes;
