import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage, FieldProps } from "formik";
import * as Yup from "yup";
import {
  Input,
  Button,
  Select,
  Switch,
  Checkbox,
  InputNumber,
  Row,
  Col,
  Modal,
  TimePicker,
  message,
  Typography,
  Card,
} from "antd";
import dayjs from "dayjs";
import { get, post, put } from "../../services/Apiclient";
import { useNavigate, useParams } from "react-router-dom";
import { Content } from "antd/es/layout/layout";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { encryptPassword } from "../../constants/encryptionUtils";
import PhoneInput from "react-phone-input-2";
import OpeningHoursSettings from "../../components/model/merchant/OpeningHoursSettings";
//import "./MerchantStyle.css";

const { Option } = Select;
const { Text, Link } = Typography;

interface OpeningHours {
  [key: string]: string;
}

interface FormValues {
  store_name: string;
  email: string;
  countrycode: string;
  phone_number: string;
  store_username: string;
  address: string;
  country: string;
  password: string;
  time_zone: string;
  language: string;
  currency: string;
  store_introduction: string;
  time_format: string;
  postal_code: string;
  website: string;
  facebook: string;
  youtube: string;
  latitude: number | undefined;
  longitude: number | undefined;
  store_status: boolean;
  parent_store: string;
  tax_id?: string;
  business_type: string[];
  opening_hours: OpeningHours;
}

const websiteIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Website.png`;
const facebookIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Facebook.png`;
const youtubeIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Youtobe.png`;

const sectionTitleStyle = {
  fontWeight: "bold",
  fontSize: "16px",
  marginBottom: "16px",
};

const buttonStyle = {
  backgroundColor: "#1890ff",
  color: "#fff",
  border: "none",
  borderRadius: "4px",
  padding: "6px 12px",
  margin: "5px",
};

const dayStyle = {
  display: "flex",
  flexDirection: "column" as const,
  alignItems: "center",
  marginBottom: "16px",
};

const MerchantForm: React.FC = () => {
  const { id } = useParams();
  const validationSchema = Yup.object().shape({
    store_name: Yup.string().required("Store name is required"),
    store_username: Yup.string().required("Store username is required"),
    password: Yup.lazy(() => {
      return isEditMode
        ? Yup.string().notRequired()
        : Yup.string().required("Password is required");
    }),
    currency: Yup.string().required("Currency is required"),
    language: Yup.string().required("Language is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    phone_number: Yup.string()
      .required("Phone number is required")
      .min(10, "Phone number must be at least 10 characters")
      .max(13, "Phone number must be at MAx 13 characters"),
    address: Yup.string().required("Address is required"),
    latitude: Yup.number()
      .typeError("Latitude must be a number")
      .required("Latitude is required"),
    longitude: Yup.number()
      .typeError("Longitude must be a number")
      .required("Longitude is required"),
  });
  // const initialValues: FormValues = {
  //   store_name: "",
  //   email: "",
  //   phone_number: "",
  //   address: "",
  //   country: "",
  //   postal_code: "",
  //   store_username: "",
  //   time_zone: "",
  //   password: "",
  //   store_introduction: "",
  //   time_format: "",
  //   currency: "",
  //   website: "",
  //   facebook: "",
  //   youtube: "",
  //   language: "",
  //   latitude: undefined,
  //   longitude: undefined,
  //   store_status: true,
  //   parent_store: "",
  //   tax_id: "",
  //   business_type: [],
  //   opening_hours: {
  //     sunday: "09:00-18:00",
  //     monday: "09:00-18:00",
  //     tuesday: "09:00-18:00",
  //     wednesday: "09:00-18:00",
  //     thursday: "09:00-18:00",
  //     friday: "09:00-18:00",
  //     saturday: "09:00-18:00",
  //   },
  // };

  const [initialValues, setInitialValues] = useState<FormValues>({
    store_name: "",
    email: "",
    countrycode: "60",
    phone_number: "",
    address: "",
    country: "",
    postal_code: "",
    store_username: "",
    time_zone: "",
    password: "",
    store_introduction: "",
    time_format: "",
    currency: "",
    website: "",
    facebook: "",
    youtube: "",
    language: "",
    latitude: undefined,
    longitude: undefined,
    store_status: true,
    parent_store: "",
    tax_id: "",
    business_type: [],
    opening_hours: {
      sunday: "09:00-18:00",
      monday: "09:00-18:00",
      tuesday: "09:00-18:00",
      wednesday: "09:00-18:00",
      thursday: "09:00-18:00",
      friday: "09:00-18:00",
      saturday: "09:00-18:00",
    },
  });

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentDay, setCurrentDay] = useState<string>("");
  const [openingHours, setOpeningHours] = useState<OpeningHours>(
    initialValues.opening_hours
  );
  const [businessOptions, setBusinessOptions] = useState([]);
  const [timeZoneOptions, setTimeZoneOptions] = useState([]);
  const [parentStoreOptions, setParentStoreOptions] = useState([]);
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [languageOptions, setLanguageOptions] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const navigate = useNavigate();

  const fetchTimeZoneList = async () => {
    try {
      const response = await get("/merchant/timezonelist");

      if (response.data.success) {
        const options = response.data.data.map((timeZone: any) => ({
          label: timeZone.name,
          value: timeZone.name,
        }));
        setTimeZoneOptions(options); // Update state with fetched time zone options
      } else {
        console.error("Failed to fetch time zone options:", response.message);
      }
    } catch (error) {
      console.error("Error fetching time zone options:", error);
    }
  };

  const handleOpeningHoursSave = (updatedHours: any) => {
    setOpeningHours(updatedHours); // Update the opening hours state
  };

  const fetchParentStores = async () => {
    try {
      const response = await get("/outlet/dropdown");
      if (response.data.success) {
        const options = response.data.data.map((store: any) => ({
          label: store.name,
          value: store.id,
        }));
        setParentStoreOptions(options); // Update state with fetched options
      } else {
        console.error(
          "Failed to fetch parent store options:",
          response.message
        );
      }
    } catch (error) {
      console.error("Error fetching parent store options:", error);
    }
  };

  const fetchData = async () => {
    try {
      const url = `/business/list`;
      const response = await get(url);
      if (response.data.success) {
        const options = response.data.data.map((business: any) => ({
          label: business.name,
          value: business.id.toString(),
        }));
        setBusinessOptions(options);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchCurrencyList = async () => {
    try {
      const response = await get("/merchant/currencylist");
      if (response.data.success) {
        const options = response.data.data.map((currency: any) => ({
          label: currency.name,
          value: currency.name,
        }));
        setCurrencyOptions(options); // Update state with fetched currency options
      } else {
        console.error("Failed to fetch currency options:", response.message);
      }
    } catch (error) {
      console.error("Error fetching currency options:", error);
    }
  };

  const fetchMerchantById = async (merchantId: string) => {
    try {
      const response = await get(`/merchant/${merchantId}`);
      if (response.data.success) {
        const merchantData = response.data.data;
        const mappedOpeningHours = merchantData.opening_hours.reduce(
          (acc: any, curr: any) => {
            acc[curr.dayname] = `${curr.starttime}-${curr.endtime}`;
            return acc;
          },
          {}
        );
        setInitialValues({
          ...initialValues,
          store_name: merchantData.name,
          email: merchantData.email,
          countrycode: merchantData.countrycode || "60",
          phone_number:
            `${merchantData.countrycode}${merchantData.phone_number}` || "",
          address: merchantData.address,
          country: merchantData.country,
          postal_code: merchantData.postal_code,
          store_username: merchantData.username,
          time_zone: merchantData.time_zone,
          store_introduction: merchantData.store_introduction,
          time_format: merchantData.time_format,
          currency: merchantData.currency,
          website: merchantData.website,
          facebook: merchantData.facebook,
          youtube: merchantData.youtube,
          language: merchantData.language,
          latitude: merchantData.latitude,
          longitude: merchantData.longitude,
          store_status: merchantData.store_status,
          parent_store: merchantData.parentstore,
          tax_id: merchantData.tax_id,
          business_type: merchantData.business_type.map((type: string) => type),
          opening_hours: mappedOpeningHours,
        });
        setOpeningHours(mappedOpeningHours);
      } else {
        console.error(
          "Failed to fetch merchant details:",
          response.data.message
        );
      }
    } catch (error) {
      console.error("Error fetching merchant details:", error);
    }
  };

  // Fetch Language List
  const fetchLanguageList = async () => {
    try {
      const response = await get("/merchant/languagelist");

      if (response.data.success) {
        const options = response.data.data.map((language: any) => ({
          label: language.name,
          value: language.name,
        }));
        setLanguageOptions(options); // Update state with fetched language options
      } else {
        console.error("Failed to fetch language options:", response.message);
      }
    } catch (error) {
      console.error("Error fetching language options:", error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchTimeZoneList();
    // fetchParentStores();
    fetchCurrencyList();
    fetchLanguageList();
    if (id) {
      setIsEditMode(true);
      fetchMerchantById(id);
    }
  }, []);

  const showEditModal = () => {
    setIsModalVisible(true);
  };

  const handleTimeChange = (times: any) => {
    if (times && times[0] && times[1]) {
      const updatedHours = {
        ...openingHours,
        [currentDay]: `${times[0].format("HH:mm")}-${times[1].format("HH:mm")}`,
      };
      setOpeningHours(updatedHours);
    }
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSubmit = async (values: FormValues) => {
    const businessTypeArray = values.business_type.map((id) =>
      typeof id === "string" ? parseInt(id.trim(), 10) : id
    );

    const convertSchedule = (scheduleObj: { [key: string]: unknown }) => {
      return Object.entries(scheduleObj).map(([day, time]) => {
        if (typeof time === "string") {
          const [starttime, endtime] = time.split("-");
          return {
            day: day.charAt(0).toUpperCase() + day.slice(1),
            starttime,
            endtime,
          };
        } else {
          throw new Error(
            `Expected a string for time, but received ${typeof time}`
          );
        }
      });
    };

    let formattedPhoneNumber = values.phone_number;

    // Remove country code from phone_number
    if (values.countrycode) {
      formattedPhoneNumber = values.phone_number.replace(
        new RegExp(`^\\+?${values.countrycode}`),
        ""
      );
    }

    const formData = {
      name: values.store_name,
      username: values.store_username,
      email: values.email,
      countrycode: values.countrycode,
      phone_number: formattedPhoneNumber,
      password: values.password ? encryptPassword(values.password) : undefined,
      parentstore: values.parent_store || "-",
      store_status: values.store_status,
      address: values.address,
      business_type: businessTypeArray,
      opening_hours: convertSchedule(openingHours),
      time_zone: values.time_zone,
      tax_id: values.tax_id,
      currency: values.currency,
      website: values.website,
      facebook: values.facebook,
      youtube: values.youtube,
      time_format: values.time_format,
      latitude: values.latitude,
      longitude: values.longitude,
      language: values.language,
      storeimage: "http://example.com/image.jpg",
      store_introduction: values.store_introduction,
    };

    try {
      const response = id
        ? await put(`/merchant/${id}`, formData) // Update merchant
        : await post("/merchant/addaccount", formData); // Create new merchant

      if ((id && response.status === 200) || (!id && response.status === 201)) {
        message.success("Form submitted successfully");
        navigate("/merchant/list");
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      console.error("An error occurred during form submission:", error);
    }
  };

  return (
    <Content className="mainContainer">
      <Row justify="space-between" align="middle">
        <Col>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              onClick={() => navigate("/merchant/list")}
              style={{ cursor: "pointer" }}
            >
              <ArrowLeftOutlined
                style={{ marginRight: 12, color: "#325df2", fontSize: 18 }}
              />
            </div>
            <h2 style={{ margin: 0 }}>
              {" "}
              {isEditMode ? "Edit Merchant" : "Add new Merchant"}
            </h2>
          </div>
        </Col>
      </Row>
      <Content
        style={{
          marginTop: "10px",
          backgroundColor: "#fafafa",
          borderRadius: "12px",
          border: "1px solid #e0e7fe",
          boxSizing: "border-box",
          height: "600px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div className="form-scrollable-content">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ setFieldValue, values }) => (
              <Form
                style={{
                  // maxWidth: "900px",
                  // margin: "auto",
                  // backgroundColor: "#fff",
                  // padding: "24px",
                  // borderRadius: "8px",
                  // boxShadow: "0 0 10px rgba(0,0,0,0.1)",
                  paddingTop: "15px",
                }}
              >
                {/* Basic Info Section */}
                <div style={sectionTitleStyle}>Basic Info</div>
                <Row gutter={16}>
                  <Col xs={24} sm={12} lg={8}>
                    <label>Store Name *</label>
                    <Field name="store_name">
                      {({ field }: any) => (
                        <Input {...field} placeholder="Please enter" />
                      )}
                    </Field>
                    <ErrorMessage name="store_name">
                      {(msg) => (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </Col>
                  <Col xs={24} sm={12} lg={8}>
                    <label>Store Username *</label>
                    <Field name="store_username">
                      {({ field }: any) => (
                        <Input {...field} placeholder="Please enter" />
                      )}
                    </Field>
                    <ErrorMessage name="store_username">
                      {(msg) => (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </Col>
                  <Col xs={24} sm={12} lg={8} style={{ marginTop: "2%" }}>
                    <label style={{ marginRight: "2.5%" }}>Store Status</label>
                    <Switch
                      checked={values.store_status}
                      onChange={(checked) =>
                        setFieldValue("store_status", checked)
                      }
                    />
                  </Col>
                </Row>
                <Row gutter={16} style={{ marginTop: "16px" }}>
                  <Col xs={24} sm={12} lg={8}>
                    <label>Email *</label>
                    <Field name="email">
                      {({ field }: any) => (
                        <Input {...field} placeholder="Please enter" />
                      )}
                    </Field>
                    <ErrorMessage name="email">
                      {(msg) => (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </Col>
                  {!isEditMode && (
                    <Col xs={24} sm={12} lg={8}>
                      <label>Password *</label>
                      <Field name="password">
                        {({ field }: any) => (
                          <Input {...field} placeholder="Please enter" />
                        )}
                      </Field>
                      <ErrorMessage name="password">
                        {(msg) => (
                          <div style={{ color: "red", fontSize: "12px" }}>
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </Col>
                  )}
                </Row>
                <Row gutter={16} style={{ marginTop: "16px" }}>
                  <Col xs={24} sm={12} lg={8}>
                    <label>Phone Number *</label>
                    {/* <Field name="phone_number">
                      {({ field }: any) => (
                        <Input {...field} placeholder="Please enter" />
                      )}
                    </Field> */}
                    <Field name="phone_number">
                      {({ field, form }: any) => (
                        <PhoneInput
                          country={"my"} // Set default country code, change this as needed
                          value={
                            typeof field.value === "string" ? field.value : ""
                          }
                          enableSearch
                          countryCodeEditable={false}
                          onChange={(value: any, country: any) => {
                            form.setFieldValue("countrycode", country.dialCode);
                            form.setFieldValue("phone_number", value);
                          }}
                          inputStyle={{
                            width: "100%",
                            height: "46px",
                            paddingLeft: "35px", // Adjust space for the flag
                            borderColor: "#e0e0e0", // Light border color for a softer look
                            borderRadius: "5px",
                            color: "#5c5c5c", // Softer text color
                            backgroundColor: "#f8f9fd", // Light background color
                          }}
                          containerStyle={{
                            width: "100%",
                          }}
                          placeholder="Please enter" // Optional: style customization
                        />
                      )}
                    </Field>
                    <ErrorMessage name="phone_number">
                      {(msg) => (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </Col>
                  <Col span={8} xs={24} sm={12} lg={8}>
                    <label>Tax ID</label>
                    <Field name="tax_id">
                      {({ field }: any) => (
                        <Input {...field} placeholder="Please enter" />
                      )}
                    </Field>
                  </Col>

                  <Col span={8} xs={24} sm={12} lg={8}>
                    <label>Address *</label>
                    <Field name="address">
                      {({ field }: any) => (
                        <Input {...field} placeholder="Please enter" />
                      )}
                    </Field>
                    <ErrorMessage name="address">
                      {(msg) => (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </Col>
                </Row>

                <Row gutter={16} style={{ marginTop: "16px" }}>
                  <Col
                    span={8}
                    xs={24}
                    sm={12}
                    lg={8}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <label>Time Zone</label>
                    <Select
                      value={values.time_zone}
                      onChange={(value) => setFieldValue("time_zone", value)}
                      placeholder="Select time zone"
                    >
                      {timeZoneOptions.map((option: any) => (
                        <Option key={`${option.value}`} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                  </Col>

                  <Col
                    span={8}
                    xs={24}
                    sm={12}
                    lg={8}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <label>Time Format</label>
                    <Select
                      value={values.time_format}
                      onChange={(value) => setFieldValue("time_format", value)}
                      placeholder="Select time format"
                    >
                      <Option value="12-hour">12-hour</Option>
                      <Option value="24-hour">24-hour</Option>
                    </Select>
                  </Col>

                  <Col
                    span={8}
                    xs={24}
                    sm={12}
                    lg={8}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <label>Currency *</label>
                    <Select
                      value={values.currency}
                      onChange={(value) => setFieldValue("currency", value)}
                      placeholder="Select currency"
                    >
                      {currencyOptions.map((option: any) => (
                        <Option key={`${option.value}`} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                    <ErrorMessage name="currency">
                      {(msg) => (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </Col>
                </Row>
                <Row gutter={16} style={{ marginTop: "16px" }}>
                  <Col
                    span={8}
                    xs={24}
                    sm={12}
                    lg={8}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <label>Language *</label>
                    <Select
                      value={values.language}
                      onChange={(value) => setFieldValue("language", value)}
                      placeholder="Select language"
                    >
                      {languageOptions.map((option: any) => (
                        <Option key={`${option.value}`} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                    <ErrorMessage name="language">
                      {(msg) => (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </Col>
                  <Col xs={24} sm={12} lg={8}>
                    <label>Latitude *</label>
                    <InputNumber
                      value={values.latitude}
                      onChange={(value) => setFieldValue("latitude", value)}
                      placeholder="Latitude"
                      style={{
                        width: "100%",
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    />
                    <ErrorMessage name="latitude">
                      {(msg) => (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </Col>
                  <Col xs={24} sm={12} lg={8}>
                    <label>Longitude *</label>
                    <InputNumber
                      value={values.longitude}
                      onChange={(value) => setFieldValue("longitude", value)}
                      placeholder="Longitude"
                      style={{
                        width: "100%",
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    />
                    <ErrorMessage name="longitude">
                      {(msg) => (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </Col>
                </Row>

                {/* More Business Info Section */}
                <div style={sectionTitleStyle}>More Business Info</div>
                <Row gutter={16} style={{ marginTop: "16px" }}>
                  <Col span={8} xs={24} sm={12} lg={8}>
                    <label>Store Introduction</label>
                    <Field name="store_introduction">
                      {({ field }: any) => (
                        <Input {...field} placeholder="Please enter" />
                      )}
                    </Field>
                    <ErrorMessage name="store_introduction">
                      {(msg) => (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </Col>

                  <Col span={8} xs={24} sm={12} lg={8}>
                    {/* <label>Website</label>
                    <Field name="website">
                      {({ field }: any) => (
                        <Input {...field} placeholder="Please enter" />
                      )}
                    </Field> */}
                    <label>Website</label>
                    <Field name="website">
                      {({ field }: FieldProps) => (
                        <Input
                          {...field}
                          placeholder="Please enter"
                          prefix={
                            <img
                              src={websiteIcon}
                              alt="Website"
                              style={{ width: 24 }}
                            />
                          }
                        />
                      )}
                    </Field>
                  </Col>

                  <Col span={8} xs={24} sm={12} lg={8}>
                    {/* <label>Facebook</label>
                    <Field name="facebook">
                      {({ field }: any) => (
                        <Input {...field} placeholder="Please enter" />
                      )}
                    </Field> */}
                    <label>Facebook</label>
                    <Field name="facebook">
                      {({ field }: FieldProps) => (
                        <Input
                          {...field}
                          placeholder="Please enter"
                          prefix={
                            <img
                              src={facebookIcon}
                              alt="Facebook"
                              style={{ width: 24 }}
                            />
                          }
                        />
                      )}
                    </Field>
                  </Col>
                  <Col span={8} xs={24} sm={12} lg={8}>
                    {/* <label>YouTube</label>
                    <Field name="youtube">
                      {({ field }: any) => (
                        <Input {...field} placeholder="Please enter" />
                      )}
                    </Field> */}
                    <label>YouTube</label>
                    <Field name="youtube">
                      {({ field }: FieldProps) => (
                        <Input
                          {...field}
                          placeholder="Please enter"
                          prefix={
                            <img
                              src={youtubeIcon}
                              alt="YouTube"
                              style={{ width: 24 }}
                            />
                          }
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
                <Row gutter={16} style={{ marginTop: "16px" }}>
                  <Col span={24}>
                    <label style={{ marginRight: "16px" }}>
                      Business Type :{" "}
                    </label>
                    <Checkbox.Group
                      value={values.business_type} // Prefill from form values
                      options={businessOptions} // Use fetched options
                      onChange={
                        (checkedValues: string[]) =>
                          setFieldValue("business_type", checkedValues) // Update form field with selected values
                      }
                    />
                  </Col>
                </Row>

                {/* Opening Hours Section */}
                {/* <div style={{ marginTop: "24px" }}>
                  <label style={sectionTitleStyle}>Opening hours</label>
                  <Row>
                    {Object.keys(openingHours).map((day) => (
                      <Col
                        span={3}
                        style={dayStyle}
                        xs={12}
                        sm={8}
                        md={6}
                        lg={3}
                        key={day}
                      >
                        <Button
                          style={buttonStyle}
                          onClick={() => showEditModal(day)}
                        >
                          {day.charAt(0).toUpperCase() + day.slice(1)}
                        </Button>
                        <span>{openingHours[day]}</span>
                      </Col>
                    ))}
                  </Row>
                </div> */}
                <div style={{ marginTop: "16px" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "16px",
                    }}
                  >
                    <Text
                      strong
                      style={{ fontSize: "16px", marginRight: "8px" }}
                    >
                      Opening hours
                    </Text>
                    <Link
                      style={{
                        textDecoration: "underline",
                        textUnderlineOffset: "4px",
                      }}
                      onClick={() => showEditModal()}
                    >
                      Edit
                    </Link>
                  </div>
                  <Row gutter={16}>
                    {Object.keys(openingHours).map((day) => (
                      <Col xs={24} sm={12} md={6} lg={3} key={day}>
                        <Card
                          // className="day-card"
                          className={`${
                            openingHours[day] !== null &&
                            openingHours[day] !== "closed-null"
                              ? "day-card"
                              : "day-card-closed"
                          }`}
                          bodyStyle={{
                            padding: "0px 0px 20px 0px",
                            textAlign: "center",
                          }}
                        >
                          <div
                            className={`${
                              openingHours[day] !== null &&
                              openingHours[day] !== "closed-null"
                                ? "day-header"
                                : "day-header-closed"
                            }`}
                          >
                            {day.charAt(0).toUpperCase() + day.slice(1)}
                          </div>
                          <div style={{ paddingTop: "10px" }}>
                            {openingHours[day] !== null &&
                            openingHours[day] !== "closed-null"
                              ? openingHours[day]
                              : "Closed"}
                          </div>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </div>

                <OpeningHoursSettings
                  visible={isModalVisible}
                  onClose={() => setIsModalVisible(false)}
                  openingHours={openingHours}
                  onSave={handleOpeningHoursSave}
                />

                {/* <Modal
                  title={`Edit ${
                    currentDay.charAt(0).toUpperCase() + currentDay.slice(1)
                  } Hours`}
                  visible={isModalVisible}
                  onOk={handleOk}
                  onCancel={handleCancel}
                >
                  <TimePicker.RangePicker
                    format="HH:mm"
                    onChange={handleTimeChange}
                    defaultValue={[
                      dayjs(openingHours[currentDay]?.split("-")[0], "HH:mm"),
                      dayjs(openingHours[currentDay]?.split("-")[1], "HH:mm"),
                    ]}
                  />
                </Modal> */}

                {/* <Row style={{ marginTop: "24px", textAlign: "right" }}>
                  <Col span={24}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ width: "70px" }}
                    >
                      Save
                    </Button>
                  </Col>
                </Row> */}
                <div className="save-section">
                  <Button
                    type="default"
                    onClick={() => navigate("/merchant/list")}
                    style={{ marginRight: 8 }}
                  >
                    Cancel
                  </Button>
                  <Button type="primary" htmlType="submit">
                    Save
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Content>

      {/* Sticky Save Section */}
    </Content>
  );
};

export default MerchantForm;
