import React, { FC, useEffect, useState } from "react";
import {
  Modal,
  Tabs,
  Input,
  Row,
  Col,
  Typography,
  Select,
  Form,
  Divider,
  Button,
  InputNumber,
  Menu,
  DatePicker,
} from "antd";
import * as Yup from "yup";
import { RightOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import Item from "antd/es/list/Item";
import SelectProductStaff from "./SelectProductStaff";
import dayjs, { Dayjs } from "dayjs";
const { RangePicker } = DatePicker;

const { Text } = Typography;
const { Option } = Select;

interface GiftcardEditModelModelProps {
  visible: boolean;
  selectedItem: any;
  servicename: string;
  onClose: () => void;
  onSelect: (selectedObject: any) => void;
}

const GiftcardEditModel: FC<GiftcardEditModelModelProps> = ({
  visible,
  selectedItem = null,
  servicename,
  onClose,
  onSelect,
}) => {
  const validationSchema = Yup.object().shape({
    item_pay_amount: Yup.number()
      .positive("Total must be a positive number")
      .min(1, "Total must be at least 1"),
    item_card_value: Yup.number()
      .positive("Card Value must be a positive number")
      .min(1, "Card Value must be at least 1"),
    item_singleusage_qty: Yup.number()
      .positive("Single Usage qty. must be a positive number")
      .min(1, "Single Usage qty. must be at least 1"),
    item_validity_type: Yup.string().required("Type of validity is required"),
    item_qty: Yup.number()
      .positive("Qty must be a positive number")
      .min(1, "Qty must be at least 0"),
  });
  const initialFormValues = {
    item_giftcard_type: "Value",
    item_total: 0,
    item_pay_amount: 0,
    item_card_value: 0,
    item_singleusage_qty: 0,
    item_validity_type: "",
    item_valid_startdate: "",
    item_valid_enddate: "",
    item_valid_count: 0,
    item_valid_countperiod: "",
    item_qty: 1,
    item_note: "",
  };
  const [selectedStaffItem, setSelectedStaffItem] = useState<any>(null);
  const [showServiceStaffModal, setShowServiceStaffModal] = useState(false);

  const selectShowProductModal = (item: any) => {
    setShowServiceStaffModal(true);
    // setSelectedStaffItem(item);
  };

  const handleProductStaffSelection = (staff: any) => {
    let newStaffArray = staff.map((staffItem: any) => ({
      staff_service_id: staffItem.id,
      service_category_id: staffItem.staffcategory,
      staff_id: staffItem.id,
      staff_name: staffItem.name,
      staff_code: staffItem.staffcode,
      department_id: staffItem.department_id,
      department_name: staffItem.department_name,
    }));
    setSelectedStaffItem(newStaffArray);
    let staffText = "";
    let staffData = staff.filter((staffItem: any) => staffItem); // Filter to only the staff with selectedStaff
    const staffCount = staffData.length;

    // Logic to generate the staff text
    if (staffCount === 0) {
      staffText = ""; // If no staff selected, set as empty string
    } else if (staffCount === 1) {
      staffText = staffData[0].name; // If only one staff selected, use their name
    } else {
      const staffNames = staffData
        .slice(0, 2) // Get the names of the first two staff
        .map((staffMember: any) => staffMember.name); // Extract the names
      const othersCount = staffCount - 2; // Calculate how many other staff are there
      staffText = `${staffNames.join("、")}${
        othersCount > 0 ? ` and ${othersCount} others` : ""
      }`;
    }

    // Update the staff text state
    setStaffText(staffText);
    setShowServiceStaffModal(false);
  };

  const [formValues, setFormValues] = useState(initialFormValues);
  const [staffText, setStaffText] = useState("");
  const [previousTotal, setPreviousTotal] = useState<any>(0);

  const handleInputChange = (name: string, value: any) => {
    const updatedValues = {
      ...formValues,
      [name]: value,
    };

    setFormValues(updatedValues);
  };

  const handleQuantityChange = (change: number) => {
    const newQty = Math.max(formValues.item_qty + change, 1);

    const newPayAmount = previousTotal * newQty;
    setFormValues((prevValues) => ({
      ...prevValues,
      item_qty: newQty,
      item_total: newPayAmount,
      item_pay_amount: newPayAmount,
    }));
  };

  useEffect(() => {
    if (selectedItem) {
      setPreviousTotal(selectedItem.item_price);
      let startdate = null;
      let enddate = null;
      if (selectedItem.item_valid_for) {
        let date = selectedItem.item_valid_for
          .split("~")
          .map((date: any) => date.trim());
        startdate = date[0];
        enddate = date[1];
      }
      setFormValues({
        item_total: selectedItem.total,
        item_pay_amount: selectedItem.item_pay_amount,
        item_giftcard_type: selectedItem.item_giftcard_type,
        item_card_value: selectedItem.item_card_value,
        item_singleusage_qty: selectedItem.item_singleusage_qty,
        item_validity_type: selectedItem.item_validity_type,
        item_valid_startdate: startdate,
        item_valid_enddate: enddate,
        item_valid_count: selectedItem.item_valid_count,
        item_valid_countperiod: selectedItem.item_valid_countperiod,
        item_qty: selectedItem.item_qty,
        item_note: selectedItem.item_note,
      });
      setSelectedStaffItem(selectedItem.staff);
    }
  }, [visible]);

  useEffect(() => {
    if (selectedItem) {
      let staffText = "";
      let staffdata = selectedItem;
      if (staffdata?.staff) {
        const staffCount = staffdata?.staff.length;
        // If there's no staff, return an empty string
        if (staffCount === 0) {
          staffText = "";
        } else if (staffCount === 1) {
          staffText = staffdata?.staff[0].staff_name;
        } else {
          const staffNames = staffdata?.staff
            .slice(0, 2)
            .map((staffMember: any) => staffMember.staff_name);
          const othersCount = staffCount - 2;
          staffText = `${staffNames.join("、")}${
            othersCount > 0 ? ` and ${othersCount} others` : ""
          }`;
        }
        setStaffText(staffText);
      }
    }
  }, [visible, selectedItem]);

  const handleSave = () => {
    const newCartItem = {
      item_id: selectedItem.item_id,
      item_code: selectedItem.item_code,
      item_name: selectedItem.item_name,
      item_price: selectedItem.item_price,
      item_note: formValues.item_note,
      item_discount_percentage: selectedItem.item_discount_percentage,
      item_pay_amount: Number(formValues.item_pay_amount),
      item_qty: Number(formValues.item_qty),
      total: Number(formValues.item_total),
      item_type: selectedItem.item_type,
      staff: selectedStaffItem,
      item_giftcard_type: selectedItem.item_giftcard_type,
      item_card_value: Number(formValues.item_card_value),
      item_singleusage_qty: Number(formValues.item_singleusage_qty),
      item_valid_for:
        formValues.item_valid_startdate !== "" || formValues.item_valid_enddate
          ? `${formValues.item_valid_startdate} ~ ${formValues.item_valid_enddate}`
          : null,
      item_validity_type: formValues.item_validity_type,
      item_valid_count: Number(formValues.item_valid_count),
      item_valid_countperiod: formValues.item_valid_countperiod,
    };
    onSelect(newCartItem);
    onClose();
  };

  const handleSubmit = async () => {};
  const menu = (
    <Menu onClick={() => {}}>
      <Item key="warehouse">Warehouse</Item>
      <Item key="qa">QA</Item>
    </Menu>
  );
  return (
    <Modal
      visible={visible}
      title={servicename}
      onCancel={onClose}
      footer={[
        <>
          <Divider style={{ margin: "10px 0px 10px" }} />
          <Row justify="space-between">
            <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <Typography style={{ marginRight: "5px" }}>Qty.</Typography>
              <Button
                style={{ border: ".5px solid #c2cadd", width: "32px" }}
                onClick={() => handleQuantityChange(-1)}
              >
                -
              </Button>
              <input
                style={{
                  display: "block",
                  padding: "4px",
                  flex: 1,
                  width: "86px",
                  touchAction: "manipulation",
                  backgroundColor: "transparent !important",
                  border: "none",
                  boxShadow: "none",
                  textAlign: "center",
                  fontFamily: "PingFangSC-Regular",
                  fontWeight: 700,
                  borderRadius: "2px",
                  height: "100%",
                }}
                className="numberinput"
                type="number"
                value={formValues.item_qty}
                onChange={(e) =>
                  handleInputChange("item_qty", parseInt(e.target.value) || 1)
                }
                placeholder="please enter"
              ></input>
              <Button
                style={{ border: ".5px solid #c2cadd", width: "32px" }}
                onClick={() => handleQuantityChange(1)}
              >
                +
              </Button>
            </div>

            <div style={{ display: "flex", justifyContent: "end" }}>
              <Button key="save" type="primary" onClick={handleSave}>
                Save
              </Button>
            </div>
          </Row>
        </>,
      ]}
      width="800px"
      style={{
        top: "10px",
      }}
      bodyStyle={{
        height: "calc(100vh - 180px)",
        scrollbarWidth: "thin",
        overflowX: "hidden",
        overflowY: "auto",
      }}
    >
      <Row
        justify="space-between"
        style={{
          display: "flex",
          background: "#f6f7fa",
          borderRadius: "4px",
          padding: "0 18px",
          height: "40px",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", justifyContent: "flex-start" }}>
          <Col>
            <Typography.Text
              style={{
                fontWeight: 700,
                color: "#ccc",
                marginRight: "20px",
              }}
            >
              Gift card code
            </Typography.Text>
          </Col>
          <Col>
            <Typography.Text
              style={{
                display: "inline-block",
                color: "#666",
                fontWeight: 700,
                fontSize: "14px",
              }}
            >
              {selectedItem?.item_code}
            </Typography.Text>
          </Col>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Col>
            <Typography.Text
              style={{
                fontWeight: 700,
                color: "#ccc",
                marginRight: "20px",
              }}
            >
              Standard unit price
            </Typography.Text>
          </Col>
          <Col>
            <Typography.Text
              style={{
                display: "inline-block",
                color: "#666",
                fontWeight: 700,
                fontSize: "14px",
              }}
            >
              $ {selectedItem?.item_price}
            </Typography.Text>
          </Col>
        </div>
      </Row>

      <Form layout="vertical" onFinish={handleSubmit}>
        <Row gutter={16} style={{ marginTop: "10px" }}>
          <Col span={12}>
            <Form.Item label="Total price">
              <Input
                className="numberinput"
                type="number"
                value={formValues.item_pay_amount}
                onChange={(e) =>
                  handleInputChange("item_pay_amount", e.target.value)
                }
                prefix={
                  <span
                    style={{
                      color: "rgba(46, 86, 242, .5)",
                      fontWeight: 700,
                      marginTop: "2px",
                    }}
                  >
                    $
                  </span>
                }
                placeholder="Please enter"
              />
            </Form.Item>
          </Col>
          {formValues.item_giftcard_type !== "Session" && (
            <>
              <Col span={12}>
                <Form.Item label="Card value">
                  <Input
                    className="numberinput"
                    type="number"
                    value={formValues.item_card_value}
                    onChange={(e) =>
                      handleInputChange("item_card_value", e.target.value)
                    }
                    prefix={
                      <span
                        style={{
                          color: "rgba(46, 86, 242, .5)",
                          fontWeight: 700,
                          marginTop: "2px",
                        }}
                      >
                        $
                      </span>
                    }
                    placeholder="Please enter"
                  />
                </Form.Item>
              </Col>
            </>
          )}
          <Col span={12}>
            <Form.Item label="Single usage qty">
              <InputNumber
                min={0}
                max={9999}
                step={1}
                defaultValue={0}
                value={formValues.item_singleusage_qty}
                className="editgiftcardvalue"
                style={{ width: "100%", height: "40px" }}
                onChange={(value) =>
                  handleInputChange("item_singleusage_qty", value)
                }
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Type of validity">
              <Select
                placeholder="Select type of validity"
                value={formValues.item_validity_type}
                onChange={(value) =>
                  handleInputChange("item_validity_type", value)
                }
              >
                <Option value="Custom">Custom</Option>
                <Option value="Immediately">Immediately</Option>
                <Option value="EffectiveOnConsumption">
                  After the consumption date
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            {formValues.item_validity_type == "Custom" ? (
              <Form.Item label="Valid for">
                <RangePicker
                  style={{ width: "100%" }}
                  ranges={{
                    Today: [dayjs().startOf("day"), dayjs().endOf("day")],
                    Yesterday: [
                      dayjs().subtract(1, "day").startOf("day"),
                      dayjs().subtract(1, "day").endOf("day"),
                    ],
                    "This Week": [
                      dayjs().startOf("week"),
                      dayjs().endOf("week"),
                    ],
                    "This Month": [
                      dayjs().startOf("month"),
                      dayjs().endOf("month"),
                    ],
                    "Last Month": [
                      dayjs().subtract(1, "month").startOf("month"),
                      dayjs().subtract(1, "month").endOf("month"),
                    ],
                  }}
                  value={[
                    formValues.item_valid_startdate
                      ? dayjs(formValues.item_valid_startdate)
                      : null,
                    formValues.item_valid_enddate
                      ? dayjs(formValues.item_valid_enddate)
                      : null,
                  ]}
                  onChange={(dates) => {
                    if (dates) {
                      const [start, end] = dates;
                      setFormValues((prev: any) => ({
                        ...prev,
                        item_valid_startdate: start
                          ? start.format("YYYY-MM-DD")
                          : null,
                        item_valid_enddate: end
                          ? end.format("YYYY-MM-DD")
                          : null,
                      }));
                    } else {
                      // Clear the dates if user cancels the selection
                      setFormValues((prev: any) => ({
                        ...prev,
                        item_valid_startdate: null,
                        item_valid_enddate: null,
                      }));
                    }
                  }}
                />
              </Form.Item>
            ) : null}
            {formValues.item_validity_type == "Immediately" ||
            formValues.item_validity_type == "EffectiveOnConsumption" ? (
              <Form.Item label="Valid for">
                <Input
                  type="number"
                  placeholder="Please enter"
                  style={{ width: "90%", height: "46px" }}
                  value={formValues.item_valid_count}
                  onChange={(e) =>
                    handleInputChange("item_valid_count", e.target.value)
                  }
                  addonAfter={
                    <Select
                      value={formValues.item_valid_countperiod}
                      onChange={(value) =>
                        handleInputChange("item_valid_countperiod", value)
                      }
                      style={{ width: 80 }}
                    >
                      <Option value="day">day</Option>
                      <Option value="month">month</Option>
                      <Option value="year">year</Option>
                    </Select>
                  }
                />
              </Form.Item>
            ) : null}
          </Col>
          <Col span={12}>
            <Form.Item label="Staff">
              <Input
                style={{ cursor: "pointer" }}
                className="billing-input"
                value={staffText}
                readOnly={true}
                placeholder="Please select"
                suffix={<RightOutlined />}
                onClick={selectShowProductModal}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Note">
              <TextArea
                rows={6}
                placeholder="Please enter"
                value={formValues.item_note}
                onChange={(e) => handleInputChange("item_note", e.target.value)}
                style={{ width: "100%", resize: "none" }}
                autoSize={{ minRows: 4, maxRows: 12 }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <SelectProductStaff
        visible={showServiceStaffModal}
        staffArray={selectedStaffItem}
        servicename={selectedStaffItem ? selectedItem?.item_name : ""}
        onClose={() => {
          setShowServiceStaffModal(false);
        }}
        onSelect={handleProductStaffSelection}
      />
    </Modal>
  );
};

export default GiftcardEditModel;
