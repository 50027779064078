import React, { useEffect, useRef, useState } from "react";
import { Modal, Button } from "antd";
import SignaturePad from "signature_pad";
import { useParams } from "react-router-dom";
import { put, uploadImg } from "../../../services/Apiclient";

interface SignatureModelProps {
  visible: boolean;
  onClose: () => void;
  onConfirm: (selectedObject: any) => void;
  value: string | null | undefined;
  refId: number | undefined;
}

const SignatureModel: React.FC<SignatureModelProps> = ({
  visible,
  onClose,
  onConfirm,
  value,
  refId,
}) => {
  const { id } = useParams();
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const signaturePadRef = useRef<SignaturePad | null>(null);
  const [signatureVal, setSignatureVal] = useState("");

  useEffect(() => {
    // Initialize the signature pad
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const signaturePad = new SignaturePad(canvas);
      signaturePadRef.current = signaturePad;

      // If there is an existing value, load it
      if (value) {
        const image = new Image();
        image.src = value;
        image.onload = () => {
          const context = canvas.getContext("2d");
          if (context) {
            context.clearRect(0, 0, canvas.width, canvas.height);
            context.drawImage(image, 0, 0);
          }
        };
      }
    }
  }, [value, visible]);

  const handleClear = () => {
    if (signaturePadRef.current) {
      signaturePadRef.current.clear();
      setSignatureVal("");
    }
  };

  const handleConfirm = async () => {
    if (signaturePadRef.current) {
      if (!signaturePadRef.current.isEmpty()) {
        const dataUrl = signaturePadRef.current.toDataURL();
        setSignatureVal(dataUrl);
        onConfirm(dataUrl);
        try {
          const base64ToBlob = (base64: string, contentType = "image/png") => {
            const byteCharacters = atob(base64.split(",")[1]);
            const byteNumbers = new Array(byteCharacters.length)
              .fill(0)
              .map((_, i) => byteCharacters.charCodeAt(i));
            const byteArray = new Uint8Array(byteNumbers);
            return new Blob([byteArray], { type: contentType });
          };

          const blob = base64ToBlob(dataUrl);

          // Create a FormData object to send the image
          const formData = new FormData();
          formData.append("file", blob, "signature.png");
          // formData.forEach((file) => {
          //   console.log("signature formdata.....", file);
          // });

          const uploadImageUrl = "/billing/uploadimage/sign";
          const response = await uploadImg(uploadImageUrl, formData);
          if (response.data.success) {
            // Extract image names from the API response
            const imageNames = response?.data.data.map(
              (item: { image_name: string }) => item.image_name
            );
            const url = `/billing/signupdate/${id}`;
            const outletId = localStorage.getItem("outlet_id");
            const payload = {
              ref_no: refId,
              outlet_id: outletId,
              note: "",
              note_images: [],
              sign: imageNames[0],
            };
            await put(url, payload);
          }
        } catch (error) {
          console.log("Error while updating signature", error);
        }
      } else {
        onConfirm(null); // No signature provided
      }
    }
    onClose();
  };
  return (
    <Modal
      visible={visible}
      title="Signature"
      onCancel={onClose}
      footer={null}
      centered
      bodyStyle={{ paddingBottom: "0" }}
    >
      <canvas
        ref={canvasRef}
        style={{
          border: "1px solid #ccc",
          width: "100%",
          height: "200px",
        }}
      />
      <div style={{ textAlign: "right", marginTop: "10px" }}>
        <Button
          type="default"
          style={{ marginRight: "10px" }}
          onClick={handleClear}
        >
          Clear
        </Button>
        <Button
          type="default"
          style={{ marginRight: "10px" }}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button type="primary" onClick={handleConfirm}>
          Confirm
        </Button>
      </div>
    </Modal>
  );
};

export default SignatureModel;
