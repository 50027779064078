import React, { FC, useEffect, useState } from "react";
import {
  Modal,
  Tabs,
  Input,
  Card,
  Checkbox,
  Row,
  Col,
  Button,
  Empty,
  Typography,
  Tag,
  Avatar,
  Select,
  message,
} from "antd";
import {
  ClearOutlined,
  DeleteOutlined,
  ReloadOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import { get } from "../../../services/Apiclient";

const { Text } = Typography;
const { Option } = Select;

const StaffIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Empty_Avatar_EmpM.png`;
const emptyIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Empty_noResults.png`;

interface SelectStaffModalProps {
  visible: boolean;
  servicename: string;
  staffArray?: any;
  onClose: () => void;
  onSelect: (selectedObject: any) => void;
}

const SelectProductStaff: FC<SelectStaffModalProps> = ({
  visible,
  servicename,
  staffArray,
  onClose,
  onSelect,
}) => {
  const { TabPane } = Tabs;

  const [staffData, setStaffData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStaff, setSelectedStaff] = useState<any[]>([]);
  const [rotated, setRotated] = useState(false);

  const getStaffData = () => {
    get(`/staff/staffwithdest/${localStorage.getItem("outlet_id")}`)
      .then((response) => {
        if (response.data.success) {
          setStaffData(response.data.data);
        }
      })
      .catch((error) => console.error("Error fetching staff data:", error));
  };

  useEffect(() => {
    if (visible) {
      getStaffData();
      setSelectedStaff([]);
      if (staffArray && staffArray.length > 0) {
        const transformedArray = staffArray.map((item: any) => ({
          id: item.staff_id,
          name: item.staff_name,
          staffcode: item.staff_code,
          department: item.department_id,
          department_name: item.department_name,
          percentage: item.staff_percentage,
          profile_pic: "",
        }));
        setSelectedStaff(transformedArray);
      }
    }
  }, [visible]);

  const handleSearch = (designationData: any) => {
    return designationData.filter((staff: any) =>
      staff.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  const handleClick = () => {
    setRotated(true);
    getStaffData();
    message.success("Refresh successfully!");
  };

  const handleCardClick = (staff: any) => {
    const isAlreadySelected = selectedStaff.some(
      (selected) => selected.id === staff.id
    );

    let updatedStaff = [...selectedStaff];

    if (isAlreadySelected) {
      // Remove the staff from the selected list
      updatedStaff = updatedStaff.filter(
        (selected) => selected.id !== staff.id
      );
    } else {
      // Add the staff to the selected list
      updatedStaff.push(staff);
    }

    // Calculate the share rate based on the number of selected staff
    const totalStaff = updatedStaff.length;
    const shareRate: any = totalStaff > 0 ? (100 / totalStaff).toFixed(2) : 0;

    // Set the percentage for each selected staff based on the calculated share rate
    updatedStaff = updatedStaff.map((staff) => ({
      ...staff,
      percentage: parseFloat(shareRate),
    }));

    setSelectedStaff(updatedStaff);
  };

  const calculateShareRate = () => {
    const totalStaff = selectedStaff.length;
    return totalStaff > 0 ? (100 / totalStaff).toFixed(2) : 0;
  };

  const handleSelectedStaffRemove = () => {
    setSelectedStaff([]);
  };

  const staffRemove = (staffId: number) => {
    const updatedStaff = selectedStaff.filter((staff) => staff.id !== staffId);

    if (updatedStaff.length > 0) {
      // If only one staff remains, set their percentage to 100
      if (updatedStaff.length === 1) {
        updatedStaff[0].percentage = 100;
      } else {
        // Recalculate the share rate for each remaining staff
        const newShareRate = (100 / updatedStaff.length).toFixed(2);
        updatedStaff.forEach((staff) => {
          staff.percentage = parseFloat(newShareRate);
        });
      }
    }

    setSelectedStaff(updatedStaff);
  };

  const renderStaffCards = (designationData: any) =>
    handleSearch(designationData).map((staff: any) => (
      <Col span={12}>
        <Card
          key={staff.id}
          onClick={() => handleCardClick(staff)}
          style={{
            margin: 10,
            cursor: "pointer",
            display: "flex",
          }}
          bodyStyle={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            alignItems: "center",
            padding: "10px",
            cursor: "pointer",
            background: "#f6f7fa",
          }}
        >
          <div>
            <Checkbox checked={selectedStaff.some((s) => s.id === staff.id)} />
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <div>
              {staff.profile_pic && staff.profile_pic !== "" && (
                <img
                  src={staff.profile_pic || StaffIcon}
                  alt="Staff Avatar"
                  style={{ width: 40, borderRadius: "50%" }}
                />
              )}
              {staff.profile_pic == "" && (
                <img
                  src={StaffIcon}
                  alt="Staff Avatar"
                  style={{ width: 40, borderRadius: "50%" }}
                />
              )}
            </div>
            <div>
              <div style={{ fontWeight: "700" }}>{staff.name}</div>
              <div>{staff.staffcode}</div>
            </div>
          </div>
        </Card>
      </Col>
    ));

  const handleConfirm = () => {
    console.log(selectedStaff, "selectedStaff");
    onSelect(selectedStaff);
    onClose();
  };

  return (
    <Modal
      visible={visible}
      title={`${servicename} - Select sales staff`}
      onCancel={onClose}
      footer={
        <div
          style={{
            display: "flex",
            borderTop: "1px solid #ddd",
            justifyContent: "space-between",
            padding: "20px 0px",
          }}
        >
          <div>
            <Checkbox>Show staffs on staffing</Checkbox>
            <Checkbox>Show formerly dispatched staffs</Checkbox>
          </div>

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button key="save" type="primary" onClick={handleConfirm}>
              Confirm
            </Button>
          </div>
        </div>
      }
      width="60vw"
      style={{
        top: "10px",
      }}
      bodyStyle={{
        height: "calc(100vh - 195px)",
        overflowY: "auto",
      }}
      // className="selection-staff-modal"
    >
      <Row>
        <Col
          span={14}
          style={{
            background: "#fff",
            borderTop: "1px solid #f4f4f4",
            borderRight: "1px solid #f4f4f4",
          }}
        >
          {staffData.length > 0 ? (
            <Tabs
              defaultActiveKey="null"
              className="scrollable-tabs"
              tabBarExtraContent={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "5px",
                  }}
                >
                  <Button
                    icon={<ClearOutlined />}
                    style={{
                      color: "#1890ff",
                      border: "none",
                      backgroundColor: "transparent",
                      marginRight: 10,
                    }}
                    onClick={handleSelectedStaffRemove}
                  />
                  <Button
                    icon={
                      <ReloadOutlined
                        className={
                          rotated ? "rotate-icon reverse-icon" : "reverse-icon"
                        }
                        style={{ fontSize: "20px" }}
                      />
                    }
                    style={{
                      color: "#1890ff",
                      border: "none",
                      backgroundColor: "transparent",
                    }}
                    onClick={handleClick}
                  />
                </div>
              }
            >
              {staffData.map((designation: any) => (
                <TabPane tab={designation.designationName} key={designation.id}>
                  <Input
                    placeholder="Search by staff code/name"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    style={{ marginBottom: 20, width: "50%" }}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      maxHeight: "calc(100vh - 315px)",
                      overflowY: "auto",
                    }}
                  >
                    {renderStaffCards(designation.data)}
                  </div>
                </TabPane>
              ))}
            </Tabs>
          ) : (
            <Empty
              image={emptyIcon}
              imageStyle={{ height: 100 }}
              description="No results found"
            />
          )}
        </Col>
        <Col
          span={10}
          style={{
            background: "#fff",
            borderTop: "1px solid #f4f4f4",
            maxHeight: "calc(100vh - 195px)",
            overflowY: "auto",
          }}
        >
          {selectedStaff.length > 0 &&
            selectedStaff.map((staff: any, index: any) => (
              <>
                <Card
                  key={staff.id}
                  style={{
                    padding: "10px",
                    border: "none",
                    borderRadius: "8px",
                    marginBottom: "10px",
                  }}
                  bodyStyle={{ padding: "0" }}
                >
                  {/* Service Type Title */}
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: "8px",
                      }}
                    >
                      <div>
                        <span className="vertical-line"></span>
                        <Text strong style={{ fontSize: "14px" }}>
                          Staff {index + 1}
                        </Text>
                        <Tag
                          style={{
                            background: "#ff6f1e",
                            color: "#fff",
                            fontSize: "12px",
                            height: "20px",
                            marginLeft: "10px",
                          }}
                        >
                          {staff.department_name}
                        </Tag>
                      </div>
                      <Button
                        type="text"
                        danger
                        icon={<DeleteOutlined />}
                        style={{ fontSize: "16px" }}
                        onClick={() => staffRemove(staff.id)}
                      />
                    </div>

                    {/* Staff Information */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        background: "#f6f7fa",
                        padding: "12px",
                        borderRadius: "5px",
                        border: "1px solid #e0e7fe",
                        gap: "5px",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        {/* <Avatar
                          src={`${process.env.PUBLIC_URL}/images/logo/Def_Empty_Avatar_EmpM.png`}
                          size={40}
                          style={{ marginRight: "10px" }}
                        /> */}
                        {staff.profile_pic && staff.profile_pic !== "" && (
                          <img
                            src={staff.profile_pic || StaffIcon}
                            alt="Staff Avatar"
                            style={{ width: 40, borderRadius: "50%" }}
                          />
                        )}
                        {staff.profile_pic == "" && (
                          <img
                            src={StaffIcon}
                            alt="Staff Avatar"
                            style={{ width: 40, borderRadius: "50%" }}
                          />
                        )}
                        <div style={{ flex: 1, marginLeft: "15px" }}>
                          <Text
                            strong
                            style={{ fontSize: "14px", color: "#333" }}
                          >
                            {staff.name}
                          </Text>
                          <div style={{ fontSize: "12px", color: "#888" }}>
                            {staff.staffcode}
                          </div>
                        </div>
                      </div>
                      <div>
                        <Input
                          addonBefore={
                            <span
                              style={{
                                color: "#666",
                                fontWeight: 700,
                              }}
                            >
                              Performance share rate:
                            </span>
                          }
                          suffix={
                            <span
                              style={{
                                color: "rgba(46, 86, 242, .5)",
                                fontWeight: 700,
                              }}
                            >
                              %
                            </span>
                          }
                          value={staff.percentage || 0} // Default value from calculateShareRate or input
                          min={0}
                          onChange={(e) => {
                            const updatedStaff = [...selectedStaff];
                            const staffIndex = updatedStaff.findIndex(
                              (s) => s.id === staff.id
                            );

                            // Check if the input is empty or invalid
                            const value = e.target.value;
                            if (value === "" || isNaN(parseFloat(value))) {
                              // If the input is empty or invalid, set the percentage to 100
                              updatedStaff[staffIndex].percentage = 0;
                            } else {
                              // Otherwise, update the percentage with the valid input value
                              updatedStaff[staffIndex].percentage =
                                parseFloat(value);
                            }

                            setSelectedStaff(updatedStaff);
                          }}
                          style={{
                            textAlign: "right",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Card>
              </>
            ))}
        </Col>
      </Row>
    </Modal>
  );
};

export default SelectProductStaff;
