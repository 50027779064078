import React, { useEffect, useState } from "react";
import {
  Layout,
  Button,
  Input,
  Select,
  Row,
  Col,
  Dropdown,
  Menu,
  Checkbox,
  Radio,
  message,
  Pagination,
} from "antd";
import {
  PlusOutlined,
  FilterOutlined,
  SearchOutlined,
  RightOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import { get, post } from "../../services/Apiclient";
import Datatable from "../../components/Datatable";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import ImportModal from "../../components/model/ImportModal";
const { Option } = Select;
const { Content } = Layout;

interface StaffData {
  key: React.Key;
  staffcode: string;
  staffname: string;
  phone: string;
  gender: string;
  position: string;
  dateofbirth: string;
  employeementstatus: string;
  usagestatus: string;
}

export default function StaffList() {
  const navigate = useNavigate();
  const [dataSource, setDataSource] = useState<StaffData[]>([]);
  const [originalDataSource, setOriginalDataSource] = useState<StaffData[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [showDispatchedStaffs, setShowDispatchedStaffs] = useState(false);
  const [isImportModalVisible, setIsImportModalVisible] = useState(false);

  // Employment Status State
  const [selectedEmploymentStatus, setSelectedEmploymentStatus] = useState<
    string[]
  >([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  // Usage Status State
  const [selectedUsageStatus, setSelectedUsageStatus] = useState<string[]>([]);
  const [usageDropdownVisible, setUsageDropdownVisible] = useState(false);
  const [outletData, setOutletData] = useState<any[]>([]);
  const [selectedoutletValue, setSelectedoutletValue] = useState(
    localStorage.getItem("outlet_id") || null
  );

  const getOutletDropdown = async () => {
    try {
      let url = "/outlet/dropdown";
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          setOutletData(response.data.data);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  // Show modal
  const showImportModal = () => {
    setIsImportModalVisible(true);
  };

  // Hide modal and handle save
  const handleImportModalCancel = () => {
    setIsImportModalVisible(false);
  };

  const apiEndpoint = "/staff/importstaffcsv/import";

  const handleImportModalSave = async (file: any) => {
    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await post(apiEndpoint, formData, true); // Using dynamic endpoint
      if (response?.data?.success) {
        message.success("File uploaded successfully");
        setIsImportModalVisible(false);
      } else {
        message.error(response?.data?.message || "Failed to upload the file");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      message.error("Error uploading the file");
    }
  };

  // Handle Employment Status change
  const handleEmploymentStatusChange = (checkedValues: any) => {
    setSelectedEmploymentStatus(checkedValues);
  };

  // Handle Usage Status change
  const handleUsageStatusChange = (e: any) => {
    setSelectedUsageStatus(e.target.value);
  };

  const handleShowDispatchedChange = (e: any) => {
    setShowDispatchedStaffs(e.target.checked);
  };

  // Apply Employment Status filter
  const applyFilter = () => {
    if (selectedEmploymentStatus.length === 0) {
      setDataSource(originalDataSource);
    } else {
      const filteredData = originalDataSource.filter((item) =>
        selectedEmploymentStatus.includes(item.employeementstatus)
      );
      setDataSource(filteredData);
    }
    setDropdownVisible(false);
  };

  // Apply Usage Status filter
  const applyUsageFilter = () => {
    if (selectedUsageStatus.length === 0) {
      setDataSource(originalDataSource);
    } else {
      const filteredData = originalDataSource.filter((item) =>
        selectedUsageStatus.includes(item.usagestatus)
      );
      setDataSource(filteredData);
    }
    setUsageDropdownVisible(false);
  };

  // Reset Employment Status filter
  const resetFilter = () => {
    setSelectedEmploymentStatus([]);
    setDataSource(originalDataSource);
  };

  // Reset Usage Status filter
  const resetUsageFilter = () => {
    setSelectedUsageStatus([]);
    setDataSource(originalDataSource);
  };

  // Employment Status Menu
  const employmentStatusMenu = (
    <Menu>
      <Checkbox.Group
        style={{ padding: "10px" }}
        value={selectedEmploymentStatus}
        onChange={handleEmploymentStatusChange}
      >
        <Row>
          <Col span={24}>
            <Checkbox value="Full-time">Full-time</Checkbox>
          </Col>
          <Col span={24}>
            <Checkbox value="Part-time">Part-time</Checkbox>
          </Col>
          <Col span={24}>
            <Checkbox value="Resigned">Resigned</Checkbox>
          </Col>
        </Row>
      </Checkbox.Group>
      <Row justify="space-between" style={{ padding: "10px" }}>
        <Button onClick={resetFilter}>Reset</Button>
        <Button type="primary" onClick={applyFilter}>
          OK
        </Button>
      </Row>
    </Menu>
  );

  // Usage Status Menu
  const usageStatusMenu = (
    <Menu>
      <Radio.Group
        style={{ padding: "10px" }}
        value={selectedUsageStatus}
        onChange={handleUsageStatusChange}
      >
        <Row>
          <Col span={24}>
            <Radio value="Active">Active</Radio>
          </Col>
          <Col span={24}>
            <Radio value="Inactive">Inactive</Radio>
          </Col>
        </Row>
      </Radio.Group>
      <Row justify="space-between" style={{ padding: "10px" }}>
        <Button onClick={resetUsageFilter}>Reset</Button>
        <Button type="primary" onClick={applyUsageFilter}>
          OK
        </Button>
      </Row>
    </Menu>
  );

  // Dynamic styling for dropdown and upload icon
  const filterIconStyle = {
    color: selectedEmploymentStatus.length > 0 ? "#1890ff" : "#aaa",
    cursor: "pointer",
    marginLeft: "8px",
  };

  const usageFilterIconStyle = {
    color: selectedUsageStatus.length > 0 ? "#1890ff" : "#aaa",
    cursor: "pointer",
    marginLeft: "8px",
  };

  const columns = [
    {
      title: "Staff Code",
      dataIndex: "staffcode",
      key: "staffcode",
      width: 150,
    },
    {
      title: "Staff Name",
      dataIndex: "staffname",
      key: "staffname",
      width: 200,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      width: 150,
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      width: 100,
    },
    {
      title: "Position",
      dataIndex: "position",
      key: "position",
      width: 150,
    },
    {
      title: "Date of Birth",
      dataIndex: "dateofbirth",
      key: "dateofbirth",
      width: 150,
    },
    {
      title: (
        <>
          Employment Status
          <Dropdown
            overlay={employmentStatusMenu}
            trigger={["click"]}
            visible={dropdownVisible}
            onVisibleChange={(flag) => setDropdownVisible(flag)}
            overlayStyle={{ width: "150px" }} // Adjust size here
          >
            <FilterOutlined style={filterIconStyle} />
          </Dropdown>
        </>
      ),
      dataIndex: "employeementstatus",
      key: "employeementstatus",
      width: 150,
    },
    {
      title: (
        <>
          Usage Status
          <Dropdown
            overlay={usageStatusMenu}
            trigger={["click"]}
            visible={usageDropdownVisible}
            onVisibleChange={(flag) => setUsageDropdownVisible(flag)}
            overlayStyle={{ width: "150px" }} // Adjust size here
          >
            <FilterOutlined style={usageFilterIconStyle} />
          </Dropdown>
        </>
      ),
      dataIndex: "usagestatus",
      key: "usagestatus",
      width: 150,
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right" as "right",
      width: 100,
      render: (text: any, record: StaffData): React.ReactNode => (
        <>
          <a
            style={{
              color: "#2e56f2",
              textDecoration: "underline",
              fontWeight: 700,
              marginRight: "16px",
              textUnderlineOffset: "4px",
            }}
            onClick={() => navigate(`/settings/staff/info/${record.key}`)}
          >
            View
          </a>
        </>
      ),
    },
  ];

  const fetchStaffData = async () => {
    try {
      const url = `/staff/list/${selectedoutletValue}/${currentPage}/${pageSize}`;
      const response = await get(url);
      if (response?.data?.success) {
        const apiData = response.data.data.map((item: any) => ({
          key: item.id,
          staffcode: item.staffcode,
          staffname: item.name,
          phone: item.phone_number,
          gender: item.gender,
          position: item.designation,
          dateofbirth: item.dob,
          employeementstatus: item.emp_status,
          usagestatus: item.Active,
        }));
        setDataSource(apiData);
        setOriginalDataSource(apiData);
        setTotalRecords(response.data.totalRecordsAll);
      } else {
        console.log("Error in API call: ", response);
      }
    } catch (error) {
      console.error("Fetch error: ", error);
    }
  };

  useEffect(() => {
    fetchStaffData();
  }, [currentPage, pageSize]);

  useEffect(() => {
    getOutletDropdown();
  }, []);

  useEffect(() => {
    fetchStaffData();
  }, [selectedoutletValue]);

  const handlePageChange = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    if (pageSize) {
      setPageSize(pageSize);
    }
  };

  const handleExport = async () => {
    try {
      const response = await post("/staff/exportstaffcsv/stafflistcsv", {}); // Adjust URL if needed
      if (response.data.success) {
        const fileUrl = response.data.fileUrl;
        const link = document.createElement("a");
        link.href = fileUrl;
        link.setAttribute("download", "staff_data.csv"); // Set the file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error("Export failed:", response.data.message);
      }
    } catch (error) {
      console.error("Export error:", error);
    }
  };

  return (
    <div style={{ backgroundColor: "#f0f3f8" }} className="mainContainer">
      <Content>
        <Row justify="space-between" align="middle">
          <Col>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <div
                onClick={() => navigate("/settings")}
                style={{ cursor: "pointer" }}
              >
                <ArrowLeftOutlined
                  style={{ marginRight: 12, color: "#325df2", fontSize: 18 }}
                />
              </div>
              <h2 style={{ margin: 0 }}>Staff List</h2>
            </div>
          </Col>
          <Col>
            <Button
              style={{ marginRight: "8px", fontWeight: 700, height: "40px" }}
              onClick={showImportModal}
            >
              Import
            </Button>
            <Button
              onClick={handleExport}
              style={{ marginRight: "8px", fontWeight: 700, height: "40px" }}
            >
              Export as
            </Button>
            <Button
              style={{ background: "#ff971e", fontWeight: 700, height: "40px" }}
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => navigate("/settings/staff/Add")}
            >
              Add
            </Button>
          </Col>
        </Row>

        <Content
          style={{
            marginTop: "10px",
            backgroundColor: "#f6f7f9",
            borderRadius: "12px",
            padding: "24px 24px 10px",
            border: "1px solid #e0e7fe",
            boxSizing: "border-box",
          }}
        >
          <Row gutter={16} style={{ marginBottom: "16px" }}>
            <Col>
              <Select
                placeholder="Select Outlet"
                className="dropdown"
                style={{ width: "198px", height: "40px", alignItems: "center" }}
                value={selectedoutletValue}
                onSelect={(value) => setSelectedoutletValue(value)}
                onClear={() => setSelectedoutletValue(null)}
                allowClear
              >
                {outletData.map((outlet: any) => (
                  <Option
                    key={outlet.id}
                    value={outlet.id}
                    style={{
                      textAlign: "center",
                      color:
                        selectedoutletValue === outlet.id ? "blue" : "black",
                    }}
                  >
                    {outlet.name}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col flex="auto">
              <Input
                placeholder="Search by staff code,name,phone number"
                style={{ width: "424px", height: "46px", alignItems: "center" }}
                allowClear
              />
            </Col>
            <Col>
              <Button type="primary">Search</Button>
            </Col>
          </Row>

          <Datatable<StaffData>
            columns={columns}
            dataSource={dataSource}
            scroll={{ y: "calc(100vh - 260px)" }}
            showPagination={false}
            dynamicTableHeight="calc(100vh - 240px)"
          />

          <Row
            justify="space-between"
            style={{
              marginTop: "10px",
              padding: "20px 20px 0px 20px",
              alignItems: "center",
            }}
          >
            <Col>
              <Checkbox onChange={handleShowDispatchedChange}>
                Show formerly dispatched staffs
              </Checkbox>
            </Col>
            {totalRecords && (
              <Col>
                <Row
                  justify="space-between"
                  style={{ alignItems: "center", gap: "10px" }}
                >
                  {/* Total items count */}
                  <Col>
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "rgba(0,0,0,.85)",
                      }}
                    >
                      Total {totalRecords} items
                    </span>
                  </Col>

                  {/* Pagination */}
                  <Col>
                    <Pagination
                      current={currentPage}
                      pageSize={pageSize}
                      total={totalRecords}
                      onChange={handlePageChange}
                      showSizeChanger={false}
                      itemRender={(page, type, originalElement) => {
                        if (type === "prev") {
                          const isDisabled = currentPage === 1;
                          return (
                            <a
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                                fontSize: "12px",
                                fontWeight: "700",
                                color: isDisabled ? "#bbb" : "#2e56f2",
                                pointerEvents: isDisabled ? "none" : "auto",
                              }}
                            >
                              <LeftOutlined />
                              <span>Previous</span>
                            </a>
                          );
                        }
                        if (type === "next") {
                          const isDisabled =
                            currentPage === Math.ceil(totalRecords / pageSize);
                          return (
                            <a
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                                fontSize: "12px",
                                fontWeight: "700",
                                color: isDisabled ? "#bbb" : "#2e56f2",
                                pointerEvents: isDisabled ? "none" : "auto",
                              }}
                            >
                              <span>Next</span>
                              <RightOutlined />
                            </a>
                          );
                        }
                        return originalElement;
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            )}
          </Row>

          <ImportModal
            visible={isImportModalVisible}
            onCancel={handleImportModalCancel}
            onSave={handleImportModalSave}
          />
        </Content>
      </Content>
    </div>
  );
}
