import React, { useEffect, useState } from "react";
import { Form, Input, Button, Row, Col, Typography, message } from "antd";
import * as Yup from "yup";
import { post, put } from "../../services/Apiclient";

interface SupplierFormProps {
  visible: boolean;
  onCancel: () => void;
  onSubmit: (values: any) => void;
  editingValue: any;
}

const SupplierForm: React.FC<SupplierFormProps> = ({
  visible,
  onCancel,
  onSubmit,
  editingValue,
}) => {
  const initialFormValues = {
    supplierid: "",
    name: "",
    contactfirstname: "",
    contactlastname: "",
    phone: "",
    email: "",
    street: "",
    city: "",
    state: "",
    zipcode: "",
    country: "",
    outlet_id: localStorage.getItem("outlet_id"),
  };

  const [formValues, setFormValues] = useState(initialFormValues);
  const [submitting, setSubmitting] = useState(false); // Add a submitting flag to prevent double submission

  const validationSchema = Yup.object().shape({
    supplierid: Yup.string().required("Please enter Supplier ID"),
    name: Yup.string().required("Please enter Supplier Name"),
    contactfirstname: Yup.string(),
    contactlastname: Yup.string(),
    phone: Yup.string(),
    email: Yup.string().email("Invalid email format"),
    street: Yup.string(),
    city: Yup.string(),
    state: Yup.string(),
    zipcode: Yup.string(),
    country: Yup.string(),
  });

  const handleSubmit = async () => {
    if (submitting) return; // Prevent multiple submissions
    setSubmitting(true); // Set the flag to true

    try {
      await validationSchema.validate(formValues, { abortEarly: false });

      let url = editingValue
        ? `/supplier/${editingValue.id}`
        : "/supplier/addsupplier";
      formValues.outlet_id = localStorage.getItem("outlet_id");
      const response = editingValue
        ? await put(url, formValues)
        : await post(url, formValues);

      if (response && response.data.success) {
        message.success(
          editingValue
            ? "Supplier updated successfully!"
            : "Supplier added successfully!"
        );
        setFormValues(initialFormValues);
        onSubmit(formValues); // Pass data back to parent
        onCancel(); // Close modal
      } else {
        message.error(response.data.message);
      }
    } catch (errors: any) {
      if (errors.inner) {
        errors.inner.forEach((error: any) => {
          message.error(error.message);
        });
      } else {
        message.error("Something went wrong. Please try again.");
      }
    } finally {
      setSubmitting(false); // Reset the flag
    }
  };

  const handleInputChange = (name: any, value: any) => {
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (editingValue) {
      setFormValues({
        supplierid: editingValue.supplierid,
        name: editingValue.name,
        contactfirstname: editingValue.contactfirstname,
        contactlastname: editingValue.contactlastname,
        phone: editingValue.phone,
        email: editingValue.email,
        street: editingValue.street,
        city: editingValue.city,
        state: editingValue.state,
        zipcode: editingValue.zipcode,
        country: editingValue.country,
        outlet_id: editingValue.outlet_id,
      });
    } else {
      setFormValues(initialFormValues);
    }
  }, [editingValue, visible]);

  return (
    <Form
      layout="vertical"
      onFinish={handleSubmit}
      style={{ display: visible ? "block" : "none" }}
    >
      {/* <Typography.Title level={4}>
        {editingValue ? "Edit Supplier" : "Add New Supplier"}
      </Typography.Title> */}
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Supplier ID" required>
            <Input
              name="supplierid"
              onChange={(e) => handleInputChange("supplierid", e.target.value)}
              value={formValues.supplierid}
              placeholder="Please enter"
              allowClear
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Supplier Name" required>
            <Input
              name="name"
              placeholder="Please enter"
              onChange={(e) => handleInputChange("name", e.target.value)}
              value={formValues.name}
              allowClear
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Contact Name">
            <Input
              name="contactfirstname"
              placeholder="First name"
              onChange={(e) =>
                handleInputChange("contactfirstname", e.target.value)
              }
              value={formValues.contactfirstname}
              allowClear
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label=" ">
            <Input
              name="contactlastname"
              placeholder="Last name"
              onChange={(e) =>
                handleInputChange("contactlastname", e.target.value)
              }
              value={formValues.contactlastname}
              allowClear
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="Phone Number">
            <Input
              name="phone"
              placeholder="Please enter"
              onChange={(e) => handleInputChange("phone", e.target.value)}
              value={formValues.phone}
              allowClear
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="E-mail">
            <Input
              name="email"
              placeholder="Please enter"
              onChange={(e) => handleInputChange("email", e.target.value)}
              value={formValues.email}
              allowClear
            />
          </Form.Item>
        </Col>
      </Row>
      <Typography.Title level={4}>Physical Address</Typography.Title>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Street">
            <Input
              name="street"
              placeholder="Please enter"
              onChange={(e) => handleInputChange("street", e.target.value)}
              value={formValues.street}
              allowClear
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="City">
            <Input
              name="city"
              placeholder="Please enter"
              onChange={(e) => handleInputChange("city", e.target.value)}
              value={formValues.city}
              allowClear
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="State">
            <Input
              name="state"
              placeholder="Please enter"
              onChange={(e) => handleInputChange("state", e.target.value)}
              value={formValues.state}
              allowClear
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Zip / Postal Code">
            <Input
              name="zipcode"
              placeholder="Please enter"
              onChange={(e) => handleInputChange("zipcode", e.target.value)}
              value={formValues.zipcode}
              allowClear
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Country">
            <Input
              name="country"
              placeholder="Please enter"
              onChange={(e) => handleInputChange("country", e.target.value)}
              value={formValues.country}
              allowClear
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <div style={{ textAlign: "right" }}>
          <Button onClick={onCancel} style={{ marginRight: "8px" }}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" disabled={submitting}>
            Save
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default SupplierForm;
