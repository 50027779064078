import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Divider } from "antd";
import { get, post } from "../../../services/Apiclient";

interface TipsModelModelProps {
  visible: boolean;
  onCancel: () => void;
  onSubmit: (values: any) => void;
  totalvalue?: any;
  value?: any;
}

const TipsModel: React.FC<TipsModelModelProps> = ({
  visible,
  onCancel,
  onSubmit,
  totalvalue = 0,
  value = null,
}) => {
  const [tipsData, setTipsData] = useState<any[]>([]);
  const [activeDiv, setActiveDiv] = useState(value);
  const [tipRate, setTipRate] = useState(value);
  const [tipValue, setTipValue] = useState(value);

  const handleClick = (tip_id: any, tip_rate: any, tip_value: any) => {
    setActiveDiv(tip_id);
    setTipRate(tip_rate);
    setTipValue(tip_value);
  };

  const fetchTipsData = async () => {
    try {
      const response = await get(
        `/tips/dropdown/${localStorage.getItem("outlet_id")}`
      );

      if (response?.data?.success) {
        setTipsData(response.data.data);
      } else {
        console.log("Error in API call: ", response);
      }
    } catch (error) {
      console.error("Error fetching billing data:", error);
    }
  };

  const handleSubmit = () => {
    let body = {
      tip_id: activeDiv,
      tip_rate: tipRate,
      tip_value: tipValue,
    };
    onSubmit(body);
    onCancel();
  };

  useEffect(() => {
    fetchTipsData();
  }, []);

  const commonStyle = {
    display: "inline-block",
    verticalAlign: "top",
    width: "112px",
    height: "96px",
    fontSize: "13px",
    marginRight: "10px",
    marginBottom: "10px",
    cursor: "pointer",
    border: ".5px solid #e0e7fe",
  };

  const activeStyle = {
    border: "2px solid #2e56f2",
    boxShadow:
      "0 2px 4px -1px rgba(0, 0, 0, .12), 0 4px 5px 0 rgba(0, 0, 0, .08), 0 1px 10px 0 rgba(0, 0, 0, .05)",
    // fontWeight: 700,
  };

  return (
    <>
      <Modal
        title="Tips"
        visible={visible}
        onCancel={onCancel}
        footer={[
          <>
            <Divider style={{ margin: "10px 0px" }} />
            <Row justify={"end"}>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <Button key="save" type="primary" onClick={handleSubmit}>
                  Confirm
                </Button>
              </div>
            </Row>
          </>,
        ]}
        style={{ top: "80px" }}
        bodyStyle={{
          height: "calc(100vh - 340px)",
          overflowY: "auto",
          overflowX: "hidden",
          scrollbarWidth: "thin",
        }}
        width={800}
      >
        <div style={{ paddingBottom: "16px" }}>
          <div
            style={{
              ...commonStyle,
              ...(activeDiv === null ? activeStyle : {}),
            }}
            onClick={() => handleClick(null, "", "")}
          >
            <div style={{ textAlign: "center", paddingTop: "34px" }}>
              <span
                style={{
                  verticalAlign: "top",
                }}
              >
                No tip
              </span>
            </div>
          </div>
        </div>
        <div style={{ paddingBottom: "16px" }}>
          {tipsData &&
            tipsData.length > 0 &&
            tipsData.map((item: any, index: any) => (
              <div
                key={item.id}
                style={{
                  ...commonStyle,
                  ...(activeDiv === item.id ? activeStyle : {}), // Adjust activeDiv logic
                }}
                onClick={() =>
                  handleClick(
                    item.id,
                    item.tips_rate,
                    (
                      (Number(totalvalue) * Number(item.tips_rate)) /
                      100
                    ).toFixed(2)
                  )
                } // Set active to index + 1 on click
              >
                <div style={{ padding: "0 5px 0 10px" }}>
                  <div style={{ padding: "10px 0px 0px 0px" }}>
                    <div
                      style={{
                        height: "50px",
                        fontWeight: 700,
                      }}
                    >
                      {item.tips_rate}%
                    </div>
                  </div>
                  <span
                    style={{
                      color: "#f43b00",
                      fontSize: "14px",
                      fontWeight: 700,
                      top: "5px",
                    }}
                  >
                    {/* ${grandTotal.toFixed(2)} */}$
                    {(
                      (Number(totalvalue) * Number(item.tips_rate)) /
                      100
                    ).toFixed(2)}
                  </span>
                </div>
              </div>
            ))}
        </div>
      </Modal>
    </>
  );
};

export default TipsModel;
