import React, { useEffect, useState } from "react";
import {
  Layout,
  Row,
  Col,
  Button,
  Typography,
  Input,
  Dropdown,
  Menu,
  message,
  Modal,
  Pagination,
  Radio,
  Card,
  Switch,
} from "antd";
import {
  ArrowLeftOutlined,
  EditOutlined,
  SortAscendingOutlined,
} from "@ant-design/icons";
import { deleteData, get, post, put } from "../../services/Apiclient";
import { useNavigate } from "react-router-dom";
import "../../styles/global.css";
import { Content } from "antd/es/layout/layout";
import { display } from "html2canvas/dist/types/css/property-descriptors/display";

const { Title, Text } = Typography;

export default function GiftCardsView() {
  const navigate = useNavigate();
  return (
    <Content className="mainContainer">
      {/* Header Section */}
      <Row justify="space-between" align="middle">
        <Col>
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            <div onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
              <ArrowLeftOutlined
                style={{ marginRight: 12, color: "#325DF2", fontSize: 18 }}
              />
            </div>
            <h2 style={{ margin: 0 }}>Gift Cards</h2>
          </div>
        </Col>
        <Col>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button
              icon={<SortAscendingOutlined />}
              style={{
                height: "32px !important",
                background: "#fff",
                borderRadius: "6px 6px 6px 6px",
                border: "1px solid #2e56f2",
                fontWeight: 700,
                fontSize: "13px",
                color: "#2e56f2",
                marginRight: "8px",
              }}
            >
              Sync to purchased customers
            </Button>
            {/* <Button className="quick-settings">Quick Settings</Button> */}
            <Button
              type="primary"
              icon={<EditOutlined />}
              // onClick={showModal}
              style={{
                height: "32px !important",
                background: "#fff",
                borderRadius: "6px 6px 6px 6px",
                border: "1px solid #2e56f2",
                fontWeight: 700,
                fontSize: "13px",
                color: "#2e56f2",
              }}
            >
              Edit
            </Button>
          </div>
        </Col>
      </Row>

      {/* Content Section */}
      <Content
        style={{
          margin: "auto",
          left: 0,
          right: 0,
          marginTop: "10px",
          borderRadius: "12px",
          boxSizing: "border-box",
          maxWidth: "1280px",
          minWidth: "920px",
          height: "calc(100%-40px)",
          padding: "24px",
          overflowY: "auto",
          border: ".5px solid #e0e7fe",
          backgroundColor: "hsla(0, 0%, 100%, .5)",
        }}
      >
        <div
          // bordered={false}
          style={{
            // width: "100%",
            height: "96px",
            // display: "flex",
            padding: "12px 24px",
            // justifyContent: "space-between",
            borderRadius: "12px 12px 12px 12px",
            background: "#fff linear-gradient(161deg, #fbe0df, #ffbab6)",
          }}
        >
          <Row
            justify="space-between"
            align="middle"
            style={{ width: "100%", padding: "0px !important" }}
          >
            <Col style={{ display: "flex", gap: "10px" }}>
              <Title
                level={5}
                style={{
                  margin: 0,
                  fontWeight: 700,
                  height: "26px",
                  fontSize: "18px",
                  lineHeight: "26px",
                  color: "rgba(176, 87, 77, .8)",
                }}
              >
                Simoli
              </Title>
              <Text
                style={{
                  marginTop: "5px",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "16px",
                  marginLeft: "12px",
                  color: "rgba(176, 87, 77, .6)",
                }}
              >
                No: GC-202411210001
              </Text>
            </Col>
            <Col
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Text
                style={{
                  marginLeft: "10px",
                  verticalAlign: "top",
                  color: "rgba(176, 87, 77, .6)",
                  fontSize: "12px",
                  fontWeight: 400,
                }}
              >
                Usage Status
              </Text>
              <Switch defaultChecked />
            </Col>
          </Row>
          <Text
            style={{
              display: "flex",
              marginTop: "50px",
              justifyContent: "flex-end",
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "18px",
              color: "rgba(176, 87, 77, .6)",
            }}
          >
            Valid for: 10 years
          </Text>
        </div>

        <div
          style={{
            // width:"100%",
            marginTop: "12px",
            padding: "16px 24px",
            border: ".5px solid #e0e7fe",
            borderRadius: "12px 12px 12px 12px",
            background: "hsla(0, 0%, 100%, .5)",
          }}
        >
          <Row
            style={{
              display: "flex",
              alignItems: "center",
              paddingBottom: "8px",
              justifyContent: "space-between",
              borderBottom: "1px solid #e0e7fe",
            }}
          >
            <Col
              span={12}
              style={{ color: "#666", fontSize: "16px", verticalAlign: "top" }}
            >
              <Text strong>Value</Text>
              {/* <div>
               
              </div> */}
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "5px",
              }}
            >
              <Text strong>Selling Price:</Text>
              <div>
                <Text style={{ color: "#ff4d4f" }}>$100</Text>
              </div>
            </Col>
          </Row>
          <Row
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Col
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "5px",
              }}
            >
              <Text>Value:</Text>
              <div>
                <Text style={{ color: "#333" }}>$100</Text>
              </div>
            </Col>
            <Col span={12}>
              <Text>Single usage qty.: 1</Text>
            </Col>
          </Row>
        </div>

        <div
          style={{
            marginTop: "12px",
            padding: "16px 24px",
            border: ".5px solid #e0e7fe",
            borderRadius: "12px 12px 12px 12px",
            background: "hsla(0, 0%, 100%, .5)",
          }}
        >
          <Text
            style={{
              fontWeight: 700,
              color: "#333",
              fontSize: "14px",
              lineHeight: "16px",
            }}
          >
            Applicable to
          </Text>
          <div style={{ marginTop: "10px" }}>
            <div style={{ display: "flex", gap: "8px" }}>
              <Text style={{ color: "#666" }}>Service: </Text>
              <Text style={{ color: "#333" }}>All services</Text>
            </div>
            <div style={{ display: "flex", gap: "8px" }}>
              <Text style={{ color: "#666" }}>Product: </Text>
              <Text style={{ color: "#333" }}>All products</Text>
            </div>
            <div style={{ display: "flex", gap: "8px" }}>
              <Text style={{ color: "#666" }}>Privilege:</Text>
              <Text style={{ color: "#333" }}>All privileges</Text>
            </div>
            <div style={{ display: "flex", gap: "8px" }}>
              <Text style={{ color: "#666" }}>Store:</Text>
              <Text style={{ color: "#333" }}>All stores</Text>
            </div>
          </div>
        </div>
      </Content>
    </Content>
  );
}
