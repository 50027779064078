import React, { FC, useEffect, useState } from "react";
import {
  Modal,
  Tabs,
  Input,
  Row,
  Col,
  Typography,
  Select,
  Form,
  Divider,
  Button,
  InputNumber,
  Dropdown,
  Menu,
} from "antd";
import * as Yup from "yup";
import {
  RightOutlined,
  DownOutlined,
  CloseOutlined,
  PlusOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import { Content } from "antd/es/layout/layout";
import { get } from "../../../services/Apiclient";
import TextArea from "antd/es/input/TextArea";
import { display } from "html2canvas/dist/types/css/property-descriptors/display";
import Item from "antd/es/list/Item";
import { icons } from "antd/es/image/PreviewGroup";
import SelectProductStaff from "./SelectProductStaff";
import SelectServiceStaff from "./SelectServiceStaff";
import ProductModel from "../../../pages/stock/ProductModel";
import SelectStaffModal from "../customer/SelectStaffModal";

const { Text } = Typography;
const { Option } = Select;

interface Product {
  product_id: string;
  product_name: string;
  product_qty: number;
  product_consumption_unit: string;
  product_unit_name: string;
  staff_id: string;
  staff_name: string;
  storage_id: string;
}

interface ServiceEditProductEditModelProps {
  visible: boolean;
  selectedItem: any;
  servicename: string;
  onClose: () => void;
  onSelect: (selectedObject: any) => void;
}

const ServiceEditProductEditModel: FC<ServiceEditProductEditModelProps> = ({
  visible,
  selectedItem = null,
  servicename,
  onClose,
  onSelect,
}) => {
  const validationSchema = Yup.object().shape({
    total: Yup.number()
      .positive("Total must be a positive number")
      .min(1, "Total must be at least 1"),
    item_pay_amount: Yup.number()
      .positive("Pay Amount must be a positive number")
      .min(1, "Pay Amount must be at least 1"),
    item_discount_percentage: Yup.number()
      .positive("Discount must be a positive number")
      .min(1, "Discount must be at least 1"),
    item_note: Yup.string().max(255, "Note maximum is 255 characters"),
    item_qty: Yup.number()
      .positive("Qty must be a positive number")
      .min(1, "Qty must be at least 0"),
  });
  const initialFormValues = {
    total: 0,
    item_pay_amount: 0,
    item_discount_percentage: 0,
    item_note: "",
    item_qty: 1,
  };
  const [selectedStaffItem, setSelectedStaffItem] = useState<any>(null);
  const [previousTotal, setPreviousTotal] = useState<any>(0);
  const [selectedService_consumpation, setSelectedService_consumpation] =
    useState<Product[]>([]);
  const [showServiceStaffModal, setShowServiceStaffModal] = useState(false);

  const selectShowProductModal = (item: any) => {
    setShowServiceStaffModal(true);
    // setSelectedStaffItem(item);
  };

  const handleProductStaffSelection = (staff: any) => {
    let newStaffArray = staff
      .filter((staffItem: any) => staffItem.selectedStaff) // Filter out items where staff is null
      .map((staffItem: any) => ({
        staff_service_id: staffItem.id,
        service_category_id: staffItem.selectedStaff.staffcategory,
        staff_id: staffItem.selectedStaff.id,
        staff_name: staffItem.selectedStaff.name,
        staff_code: staffItem.selectedStaff.staffcode,
        department_id: staffItem.selectedStaff.department_id,
        department_name: staffItem.selectedStaff.department_name,
      }));
    setSelectedStaffItem(newStaffArray);
    let staffText = "";
    let staffData = staff.filter((staffItem: any) => staffItem.selectedStaff); // Filter to only the staff with selectedStaff
    const staffCount = staffData.length;

    // Logic to generate the staff text
    if (staffCount === 0) {
      staffText = ""; // If no staff selected, set as empty string
    } else if (staffCount === 1) {
      staffText = staffData[0].selectedStaff.name; // If only one staff selected, use their name
    } else {
      const staffNames = staffData
        .slice(0, 2) // Get the names of the first two staff
        .map((staffMember: any) => staffMember.selectedStaff.name); // Extract the names
      const othersCount = staffCount - 2; // Calculate how many other staff are there
      staffText = `${staffNames.join("、")}${
        othersCount > 0 ? ` and ${othersCount} others` : ""
      }`;
    }
    setStaffText(staffText);
    setShowServiceStaffModal(false);
  };

  const [formValues, setFormValues] = useState(initialFormValues);
  const [staffText, setStaffText] = useState("");

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showSelectStaffModal, setShowSelectStaffModal] = useState(false);
  const [selectIndex, setSelectIndex] = useState<number | null>(null);
  const [storageOptions, setStorageOptions] = useState<any[]>([]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleReferralModalShow = (index: any) => {
    setSelectIndex(index);
    setShowSelectStaffModal(true);
  };

  const handleStaffSelect = (data: any) => {
    setSelectedService_consumpation((prevState) =>
      prevState.map((item, idx) => {
        if (idx === selectIndex) {
          return { ...item, staff_id: data.id, staff_name: data.name }; // Increase quantity
        }
        return item;
      })
    );
    setSelectIndex(null);
  };

  const [activeTab, setActiveTab] = useState("item");

  const handleTabChange = (key: any) => {
    setActiveTab(key);
  };

  const handleInputChange = (name: string, value: any) => {
    const updatedValues = {
      ...formValues,
      [name]: value,
    };

    // Recalculate 'total' and 'item_pay_amount' whenever item_qty or discount changes
    if (name === "item_qty" || name === "item_discount_percentage") {
      const newTotal = updatedValues.item_qty * updatedValues.total;
      const newPayAmount =
        newTotal - (newTotal * updatedValues.item_discount_percentage) / 100;
      updatedValues.total = newTotal;
      updatedValues.item_pay_amount = newPayAmount;
    }

    if (name == "item_pay_amount") {
      updatedValues.item_discount_percentage =
        ((updatedValues.total - updatedValues.item_pay_amount) /
          updatedValues.total) *
        100;
    }

    if (name == "total") {
      if (updatedValues.item_discount_percentage == 0) {
        updatedValues.item_pay_amount = value;
      } else {
        updatedValues.item_pay_amount =
          updatedValues.total -
          (updatedValues.total * updatedValues.item_discount_percentage) / 100;
      }
      setPreviousTotal(value);
    }

    setFormValues(updatedValues);
  };

  const handleQuantityChange = (change: number) => {
    const newQty = Math.max(formValues.item_qty + change, 1);

    // Calculate new total and item_pay_amount
    const newTotal = newQty * previousTotal; // Assuming item_price exists in formValues
    const discount =
      (formValues.item_discount_percentage / 100) * newTotal || 0;
    const newPayAmount = newTotal - discount;

    // Update the form values
    setFormValues((prevValues) => ({
      ...prevValues,
      item_qty: newQty,
      total: newTotal,
      item_pay_amount: newPayAmount,
    }));
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSaveProducts = (selectedData: any[]) => {
    const updatedProducts: any = selectedData.map((product, index) => ({
      product_id: product.id,
      product_name: product.productname,
      product_qty: 1,
      product_consumption_unit: product.purchaseunittype,
      product_unit_name: product.unit_type,
      staff_id: "",
      staff_name: "",
      storage_id: "",
    }));

    // setSelectedService_consumpation(updatedProducts);
    setSelectedService_consumpation((prevState: any) => [
      ...prevState, // Add the existing products first
      ...updatedProducts, // Then add the new products
    ]);
    setIsModalVisible(false); // Close the modal after saving products
  };

  const handleQuantityServiceconsumptionChange = (
    index: number,
    value: string
  ) => {
    const newQuantity = parseInt(value, 10);

    // Ensure the quantity is a valid positive number (you can add more validation if needed)
    if (!isNaN(newQuantity) && newQuantity > 0) {
      setSelectedService_consumpation((prevState) =>
        prevState.map((item, idx) => {
          if (idx === index) {
            return { ...item, product_qty: newQuantity }; // Update the product_qty
          }
          return item;
        })
      );
    }
  };

  const handleIncreaseQuantity = (index: number) => {
    setSelectedService_consumpation((prevState) =>
      prevState.map((item, idx) => {
        if (idx === index) {
          return { ...item, product_qty: item.product_qty + 1 }; // Increase quantity
        }
        return item;
      })
    );
  };

  // Handle decreasing the quantity of a product based on index
  const handleDecreaseQuantity = (index: number) => {
    setSelectedService_consumpation((prevState) =>
      prevState.map((item, idx) => {
        if (idx === index && item.product_qty > 1) {
          return { ...item, product_qty: item.product_qty - 1 }; // Decrease quantity if > 1
        }
        return item;
      })
    );
  };

  const handleDeleteItem = (index: number) => {
    setSelectedService_consumpation(
      (prevState) => prevState.filter((_, idx) => idx !== index) // Remove item at the given index
    );
  };

  const [selectedProducts, setSelectedProducts] = useState<any[]>([]);

  const handleStorageSelect = (index: number, storageId: string) => {
    setSelectedService_consumpation((prevState) =>
      prevState.map((item, idx) => {
        if (idx === index) {
          return {
            ...item,
            storage_id: storageId,
            storage_name: getStorageName(storageId),
          }; // Update storage for selected item
        }
        return item;
      })
    );
  };

  const renderStorageDropdown = (index: number, selectedStorageId: string) => (
    <Dropdown
      overlay={
        <Menu onClick={(e) => handleStorageSelect(index, e.key)}>
          {storageOptions.map((storage) => (
            <Menu.Item key={storage.id}>{storage.type}</Menu.Item>
          ))}
        </Menu>
      }
      trigger={["click"]}
    >
      <Button
        style={{
          border: "none",
          background: "none",
          padding: 0,
          width: "150px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "pre-wrap",
          wordBreak: "break-word",
        }}
      >
        {selectedStorageId
          ? getStorageName(selectedStorageId)
          : "Select Storage"}
        <RightOutlined />
      </Button>
    </Dropdown>
  );

  // Function to get the storage name by ID
  const getStorageName = (storageId: string) => {
    const storage = storageOptions.find((storage) => storage.id === storageId);
    return storage ? storage.type : "";
  };

  useEffect(() => {
    if (visible) {
      setActiveTab("item");
      if (selectedItem) {
        setPreviousTotal(selectedItem.total);
        setFormValues({
          total: selectedItem.total,
          item_pay_amount: selectedItem.item_pay_amount,
          item_discount_percentage: selectedItem.item_discount_percentage,
          item_note: selectedItem.item_note,
          item_qty: selectedItem.item_qty || 1,
        });
        setSelectedService_consumpation(selectedItem.service_consumption);
        setSelectedStaffItem(selectedItem.staff);
      }

      get(`/storage/dropdown/${localStorage.getItem("outlet_id")}`)
        .then((response) => {
          setStorageOptions(response.data.data);
        })
        .catch((error) => {
          console.error("Error fetching storage options:", error);
        });
    }
  }, [visible]);

  useEffect(() => {
    if (selectedItem) {
      let staffText = "";
      let staffdata = selectedItem;
      if (staffdata?.staff) {
        const staffCount = staffdata?.staff.length;
        // If there's no staff, return an empty string
        if (staffCount === 0) {
          staffText = "";
        } else if (staffCount === 1) {
          staffText = staffdata?.staff[0].staff_name;
        } else {
          const staffNames = staffdata?.staff
            .slice(0, 2)
            .map((staffMember: any) => staffMember.staff_name);
          const othersCount = staffCount - 2;
          staffText = `${staffNames.join("、")}${
            othersCount > 0 ? ` and ${othersCount} others` : ""
          }`;
        }
        setStaffText(staffText);
      }
    }
  }, [visible, selectedItem]);

  const handleSave = () => {
    const newCartItem = {
      item_id: selectedItem.item_id,
      item_code: selectedItem.item_code,
      item_name: selectedItem.item_name,
      item_price: selectedItem.item_price,
      item_note: Number(formValues.item_note),
      item_discount_percentage: Number(formValues.item_discount_percentage),
      item_pay_amount: Number(formValues.item_pay_amount),
      item_qty: Number(formValues.item_qty),
      total: Number(formValues.total),
      item_type: selectedItem.item_type,
      staff: selectedStaffItem,
      service_consumption: selectedService_consumpation,
    };
    onSelect(newCartItem);
    onClose();
  };

  const handleSubmit = async () => {};
  const menu = (
    <Menu onClick={() => {}}>
      <Item key="warehouse">Warehouse</Item>
      <Item key="qa">QA</Item>
    </Menu>
  );
  return (
    <Modal
      visible={visible}
      title={servicename}
      onCancel={onClose}
      footer={[
        <>
          <Divider style={{ margin: "10px 0px 10px" }} />
          <Row justify={`${activeTab === "item" ? "space-between" : "end"}`}>
            {activeTab === "item" ? (
              <div
                style={{ display: "flex", gap: "5px", alignItems: "center" }}
              >
                <Typography style={{ marginRight: "5px" }}>Qty.</Typography>
                <Button
                  style={{ border: ".5px solid #c2cadd", width: "32px" }}
                  onClick={() => handleQuantityChange(-1)}
                >
                  -
                </Button>
                <input
                  style={{
                    display: "block",
                    padding: "4px",
                    flex: 1,
                    width: "86px",
                    //   lineHeight: "20px",
                    touchAction: "manipulation",
                    backgroundColor: "transparent !important",
                    border: "none",
                    boxShadow: "none",
                    textAlign: "center",
                    fontFamily: "PingFangSC-Regular",
                    fontWeight: 700,
                    borderRadius: "2px",
                    height: "100%",
                  }}
                  type="number"
                  className="numberinput"
                  value={formValues.item_qty}
                  onChange={(e) =>
                    handleInputChange("item_qty", parseInt(e.target.value) || 1)
                  }
                  placeholder="please enter"
                ></input>
                <Button
                  style={{ border: ".5px solid #c2cadd", width: "32px" }}
                  onClick={() => handleQuantityChange(1)}
                >
                  +
                </Button>
              </div>
            ) : (
              <></>
            )}

            <div style={{ display: "flex", justifyContent: "end" }}>
              <Button key="save" type="primary" onClick={handleSave}>
                Save
              </Button>
            </div>
          </Row>
        </>,
      ]}
      width="800px"
      style={{
        top: "10px",
      }}
      bodyStyle={{
        height: "calc(100vh - 180px)",
      }}
    >
      <Tabs
        activeKey={activeTab} // Use activeTab to manage active tab state
        defaultActiveKey="item"
        onChange={handleTabChange}
        tabBarExtraContent={
          activeTab === "productConsumption" ? ( // Conditionally render based on active tab
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button
                icon={<PlusOutlined />}
                style={{
                  color: "#1890ff",
                  border: "none",
                  backgroundColor: "transparent",
                  marginRight: 10,
                }}
                onClick={showModal}
              >
                Add
              </Button>
            </div>
          ) : null // Return null if the tab is not "productConsumption"
        }
      >
        <Tabs.TabPane tab="Item" key="item">
          <Row
            justify="space-between"
            style={{
              display: "flex",
              background: "#f6f7fa",
              borderRadius: "4px",
              padding: "0 18px",
              height: "40px",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <Col>
                <Typography.Text
                  style={{
                    fontWeight: 700,
                    color: "#ccc",
                    marginRight: "20px",
                  }}
                >
                  Service code
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Text
                  style={{
                    display: "inline-block",
                    color: "#666",
                    fontWeight: 700,
                    fontSize: "14px",
                  }}
                >
                  {selectedItem?.item_code}
                </Typography.Text>
              </Col>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Col>
                <Typography.Text
                  style={{
                    fontWeight: 700,
                    color: "#ccc",
                    marginRight: "20px",
                  }}
                >
                  Standard unit price
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Text
                  style={{
                    display: "inline-block",
                    color: "#666",
                    fontWeight: 700,
                    fontSize: "14px",
                  }}
                >
                  $ {selectedItem?.item_price}
                </Typography.Text>
              </Col>
            </div>
          </Row>

          <Form layout="vertical" onFinish={handleSubmit}>
            <Row gutter={16} style={{ marginTop: "10px" }}>
              <Col span={12}>
                <Form.Item label="Total">
                  <Input
                    className="numberinput"
                    type="number"
                    value={formValues.total}
                    onChange={(e) => handleInputChange("total", e.target.value)}
                    prefix={
                      <span
                        style={{
                          color: "rgba(46, 86, 242, .5)",
                          fontWeight: 700,
                          marginTop: "2px",
                        }}
                      >
                        $
                      </span>
                    }
                    placeholder="Please enter"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Discount">
                  <Input
                    placeholder="Please enter"
                    type="number"
                    className="numberinput"
                    min={0}
                    value={formValues.item_discount_percentage}
                    onChange={(e) =>
                      handleInputChange(
                        "item_discount_percentage",
                        e.target.value
                      )
                    }
                    suffix={
                      <span
                        style={{
                          color: "rgba(46, 86, 242, .5)",
                          fontWeight: 700,
                          marginTop: "2px",
                        }}
                      >
                        %
                      </span>
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="To pay">
                  <Input
                    className="numberinput"
                    type="number"
                    placeholder="Please enter"
                    value={formValues.item_pay_amount}
                    onChange={(e) =>
                      handleInputChange("item_pay_amount", e.target.value)
                    }
                    prefix={
                      <span
                        style={{
                          color: "rgba(46, 86, 242, .5)",
                          fontWeight: 700,
                          marginTop: "2px",
                        }}
                      >
                        $
                      </span>
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Staff">
                  <Input
                    style={{ cursor: "pointer" }}
                    className="billing-input"
                    value={staffText}
                    readOnly={true}
                    placeholder="Please select"
                    suffix={<RightOutlined />}
                    onClick={selectShowProductModal}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Note">
                  <TextArea
                    rows={6}
                    placeholder="Please enter"
                    value={formValues.item_note}
                    onChange={(e) =>
                      handleInputChange("item_note", e.target.value)
                    }
                    style={{ width: "100%", resize: "none" }}
                    autoSize={{ minRows: 4, maxRows: 12 }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Tabs.TabPane>

        <Tabs.TabPane tab="Product Consumption" key="productConsumption">
          <div
            style={{
              height: "calc(100vh - 240px)",
              scrollbarWidth: "thin",
              overflowX: "hidden",
              overflowY: "auto",
            }}
          >
            {selectedService_consumpation &&
              selectedService_consumpation.length > 0 &&
              selectedService_consumpation.map((item: any, index: any) => (
                <div
                  style={{
                    background: "#f6f7fa",
                    borderRadius: "2px",
                    padding: "0 16px",
                    position: "relative",
                    marginRight: "10px",
                    marginLeft: "10px",
                    marginBottom: "18px",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      left: "auto",
                      right: "-10px",
                      bottom: "auto",
                      top: "-10px",
                      margin: "auto",
                      borderRadius: "50%",
                      overflow: "hidden",
                      border: "none",
                      height: "28px",
                      width: "28px",
                      background: "#dcdcdc",
                      color: "#666",
                    }}
                  >
                    <Button
                      style={{
                        display: "flex",
                        marginTop: "-2px",
                        marginLeft: "-8px",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        background: "#dcdcdc",
                        color: "#666",
                      }}
                      onClick={() => handleDeleteItem(index)}
                    >
                      <CloseOutlined />
                    </Button>
                  </div>
                  <Row
                    justify="space-between"
                    style={{ padding: "12px 12px 12px 0" }}
                  >
                    <Col
                      span={6}
                      style={{
                        lineHeight: "100%",
                        textAlign: "left",
                        display: "flex",
                        alignItems: "center",
                        marginRight: "10px",
                        fontSize: "14px",
                        color: "#333",
                      }}
                    >
                      Product Name
                    </Col>
                    <Col
                      span={6}
                      style={{ flex: 1, textAlign: "right", fontWeight: 700 }}
                    >
                      {item.product_name}
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ borderTop: ".5px solid #e0e7fe" }}>
                    <Col span={12}>
                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                          padding: "12px 16px 12px 8px",
                          borderRight: ".5px solid #e0e7fe",
                        }}
                      >
                        <Typography>Qty.</Typography>
                        <Button
                          style={{
                            border: ".5px solid #c2cadd",
                            width: "32px",
                          }}
                          onClick={() => handleDecreaseQuantity(index)}
                        >
                          -
                        </Button>
                        <input
                          style={{
                            display: "block",
                            padding: "4px",
                            flex: 1,
                            width: "86px",
                            touchAction: "manipulation",
                            backgroundColor: "transparent !important",
                            border: "none",
                            boxShadow: "none",
                            textAlign: "center",
                            fontFamily: "PingFangSC-Regular",
                            fontWeight: 700,
                            borderRadius: "2px",
                            height: "100%",
                            minHeight: "30px",
                            margin: "0 10px",
                          }}
                          value={item.product_qty}
                          type="number"
                          className="numberinput"
                          onChange={(e: any) =>
                            handleQuantityServiceconsumptionChange(
                              index,
                              e.target.value
                            )
                          }
                          placeholder="please enter"
                        ></input>
                        <Button
                          style={{
                            border: ".5px solid #c2cadd",
                            width: "32px",
                          }}
                          onClick={() => handleIncreaseQuantity(index)}
                        >
                          +
                        </Button>
                      </div>
                    </Col>
                    <Col
                      span={12}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div>Consumption Unit</div>
                      <div
                        style={{
                          flex: 1,
                          lineHeight: "30px",
                          padding: 0,
                          textAlign: "right",
                          color: "#666",
                        }}
                      >
                        {item.product_unit_name}
                      </div>
                    </Col>
                  </Row>

                  <Row
                    gutter={24}
                    style={{
                      borderTop: ".5px solid #e0e7fe",
                      // padding: "8px 0 8px 0",
                    }}
                  >
                    <Col
                      span={12}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderRight: ".5px solid #e0e7fe",
                      }}
                    >
                      <div style={{}}>
                        <Typography>Staff</Typography>
                      </div>
                      {item.staff_name ? (
                        <div
                          style={{ color: "#333", cursor: "pointer" }}
                          onClick={() => {
                            handleReferralModalShow(index);
                          }}
                        >
                          {item.staff_name}{" "}
                          <RightOutlined
                            style={{ color: "#333" }}
                            onClick={() => {
                              handleReferralModalShow(index);
                            }}
                          />
                        </div>
                      ) : (
                        <div
                          style={{ color: "#ccc", cursor: "pointer" }}
                          onClick={() => {
                            handleReferralModalShow(index);
                          }}
                        >
                          Please select{" "}
                          <RightOutlined
                            style={{ color: "#ccc" }}
                            onClick={() => {
                              handleReferralModalShow(index);
                            }}
                          />
                        </div>
                      )}
                    </Col>
                    <Col
                      span={12}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "8px 0 8px 16px",
                        width: "100px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        wordBreak: "break-word",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      <div>Storage</div>
                      {renderStorageDropdown(index, item.storage_id)}
                    </Col>
                  </Row>
                </div>
              ))}
          </div>
        </Tabs.TabPane>
      </Tabs>
      <SelectServiceStaff
        visible={showServiceStaffModal}
        staffArray={selectedStaffItem}
        servicename={selectedStaffItem ? selectedItem?.item_name : ""}
        onClose={() => {
          setShowServiceStaffModal(false);
        }}
        onSelect={handleProductStaffSelection}
      />
      <ProductModel
        title="Product"
        visible={isModalVisible}
        onCancel={handleCancel}
        onSave={handleSaveProducts}
        selectedProducts={selectedProducts}
        showBackButton={false}
      />
      <SelectStaffModal
        visible={showSelectStaffModal}
        onClose={() => {
          setShowSelectStaffModal(false);
        }}
        onSelect={handleStaffSelect}
      ></SelectStaffModal>
    </Modal>
  );
};

export default ServiceEditProductEditModel;
